/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';

export interface IANHomeProps { }

export const ANHome: React.FunctionComponent<IANHomeProps> = (props: React.PropsWithChildren<IANHomeProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANHome]");
  }, []);


  let navigate = useNavigate();


  return (
    <>
      <Stack tokens={Consts.stackTokens}>

        <h1 className="flu-page-title2 flu-bottomborder1 wbss">Advisory Notes<span className="flu-page-title-sub2">Finsolutia</span></h1>

        <div className='flu-section'>

          <h2 className="flu-heading1 wbss">Actions</h2>

          {/* <div className='flu-section-body wbss'>Helloworld.</div> */}

          <Stack tokens={Consts.stackTokens} horizontal>

            <PrimaryButton text="Create New" onClick={() => {
              navigate(`new`);
            }} allowDisabledFocus />

            {/* <PrimaryButton text="Open Note Id=1" onClick={() => {
              navigate(`1`);
            }} allowDisabledFocus /> */}

            {/* <PrimaryButton text="Open Note Id=2744" onClick={() => {
              navigate(`2744`);
            }} allowDisabledFocus /> */}

            {/* <PrimaryButton text="Open Note Id=6" onClick={() => {
              navigate(`6`);
            }} allowDisabledFocus /> */}

            {/* <PrimaryButton text="Open Note Id=2750" onClick={() => {
              navigate(`2750`);
            }} allowDisabledFocus /> */}

          </Stack>

          {/* <ul>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=1">Test-1</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=2">Test-2</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=3">Test-3</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=4">Test-4</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=5">Test-5</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=6">Test-6</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=7">Test-7</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=8">Test-8</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=9">Test-9</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=10">Test-10</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=11">Test-11</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=12">Test-12</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=13">Test-13</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=14">Test-14</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=15">Test-15</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=16">Test-16</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=17">Test-17</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=18">Test-18</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=19">Test-19</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=20">Test-20</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=21">Test-21</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=22">Test-22</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=23">Test-23</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=24">Test-24</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=25">Test-25</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=26">Test-26</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=27">Test-27</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=28">Test-28</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=29">Test-29</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2767?p1=30">Test-30</a></li>
          </ul>

          <ul>
            <li><a href="http://localhost:3000/Finsolutia/2767">Test-2767</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2766">Test-2766</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2765">Test-2765</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2764">Test-2764</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2763">Test-2763</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2762">Test-2762</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2761">Test-2761</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2760">Test-2760</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2759">Test-2759</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2758">Test-2758</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2757">Test-2757</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2756">Test-2756</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2755">Test-2755</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2754">Test-2754</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2753">Test-2753</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2752">Test-2752</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2751">Test-2751</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2750">Test-2750</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2749">Test-2749</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2748">Test-2748</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2747">Test-2747</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2746">Test-2746</a></li>
            <li><a href="http://localhost:3000/Finsolutia/2745">Test-2745</a></li>
          </ul> */}

        </div>

      </Stack>
    </>
  );
};