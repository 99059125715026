/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Checkbox,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';

import { useAccount, useMsal } from '@azure/msal-react';

import * as Consts from "../../Helpers/Consts";
import * as GenUtil from '../../Helpers/GenUtil2';
import * as AppHelper from '../../Helpers/AppHelper';
import * as GraphDataService from '../../Services/Finsolutia/GraphDataService';
// import * as JsonXmlHelper from '../../Helpers/JsonXmlConverter';
import * as StaticData from "../../StaticData/Finsolutia/StaticData";
import * as GraphHelper from '../../Helpers/GraphHelper';


export interface ITest7Props { }

export const Test7: React.FunctionComponent<ITest7Props> = (props: React.PropsWithChildren<ITest7Props>) => {


  const { instance, accounts } = useMsal();


  const handleLogout = (logoutType: any) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }


  return (
    <>

      <h3>Testing</h3>

      <Stack tokens={Consts.stackTokens} horizontal>
        <PrimaryButton text='Sign out using Popup' onClick={() => handleLogout("popup")}></PrimaryButton>
        <PrimaryButton text='Sign out using Redirect' onClick={() => handleLogout("redirect")}></PrimaryButton>
      </Stack>

    </>
  );
};
