/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Finsolutia/GraphDataService';
import * as StaticData from "../../../StaticData/Finsolutia/StaticData";
import { ProposedCostsRow } from './ProposedCostsRow';
import { ProposedCostsDetails1, ProposedCostsDetailsItem } from '../../../Models/Finsolutia/GridModels/ProposedCostsDetails1';
import { ProposedCostsDetailsN } from '../../../Models/Finsolutia/GridModels/ProposedCostsDetailsN';
import { ANVendor } from '../../../Models/Finsolutia/ANVendor';
import { ANTypeOfCost } from '../../../Models/Finsolutia/ANTypeOfCost';


export interface IProposedCostsBodyProps {
  data: string | undefined;
  vendors: ANVendor[];
  typeOfCosts: ANTypeOfCost[];
  onDataUpdated: (s: string, d: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const ProposedCostsBody: React.FunctionComponent<IProposedCostsBodyProps> = (props: React.PropsWithChildren<IProposedCostsBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposedCostsBody]");
  }, []);


  const [stateRows, setStateRows] = useState<ProposedCostsDetailsItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('propcosts', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ProposedCosts', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as ProposedCostsDetails1).RepeaterData.Items.Item;

          let item: ProposedCostsDetailsItem = {
            // rpt_PropCostRowNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PropCostRowNumber)),
            rpt_PropCostRowNumber: GenUtil.generateGuid(),
            dd_Vendors: GenUtil.safeTrim(AppHelper.getText(_t.dd_Vendors)),
            cv_VendorsValue: GenUtil.safeTrim(AppHelper.getText(_t.cv_VendorsValue)),
            rpt_decimal_Costs: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_Costs)),
            // rpt_decimal_TotalCosts: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_TotalCosts)),
            // rpt_decimal_VAT: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_VAT)),
            rpt_CostsCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CostsCurrency)),
            rpt_Description: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Description)),
            dd_TypeOfCosts: GenUtil.safeTrim(AppHelper.getText(_t.dd_TypeOfCosts)),
            cv_TypeOfCosts: GenUtil.safeTrim(AppHelper.getText(_t.cv_TypeOfCosts)),
            rpt_VendorLabel: GenUtil.safeTrim(AppHelper.getText(_t.rpt_VendorLabel)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.cv_VendorsValue).length) > 0) {
            setStateRows([item]);
          }

        }
        else {
          let _t = obj as ProposedCostsDetailsN;

          let _col: ProposedCostsDetailsItem[] = _t.RepeaterData.Items.Item.map((_t, i) => {
            return {
              // rpt_PropCostRowNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PropCostRowNumber)),
              rpt_PropCostRowNumber: GenUtil.generateGuid(),
              dd_Vendors: GenUtil.safeTrim(AppHelper.getText(_t.dd_Vendors)),
              cv_VendorsValue: GenUtil.safeTrim(AppHelper.getText(_t.cv_VendorsValue)),
              rpt_decimal_Costs: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_Costs)),
              // rpt_decimal_TotalCosts: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_TotalCosts)),
              // rpt_decimal_VAT: GenUtil.safeToNumber(AppHelper.getText(_t.rpt_decimal_VAT)),
              rpt_CostsCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CostsCurrency)),
              rpt_Description: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Description)),
              dd_TypeOfCosts: GenUtil.safeTrim(AppHelper.getText(_t.dd_TypeOfCosts)),
              cv_TypeOfCosts: GenUtil.safeTrim(AppHelper.getText(_t.cv_TypeOfCosts)),
              rpt_VendorLabel: GenUtil.safeTrim(AppHelper.getText(_t.rpt_VendorLabel)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.cv_VendorsValue));

          setStateRows(_col);
        }

      } catch (error) { console.warn(`Error parsing Xml in ProposedCostsBody`, props.data, error); }
    }
    else {
      setStateRows([]);
    }
  }, [props.data])


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        rpt_PropCostRowNumber: GenUtil.generateGuid(),
        dd_Vendors: '',
        cv_VendorsValue: '',
        rpt_decimal_Costs: undefined,
        // rpt_decimal_TotalCosts: 0,
        // rpt_decimal_VAT: 0,
        rpt_CostsCurrency: StaticData.costsCurrency,
        rpt_Description: '',
        dd_TypeOfCosts: '',
        cv_TypeOfCosts: '',
        rpt_VendorLabel: ''
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];
      if (GenUtil.eq(fieldName, 'vendor')) {
        if (!!fieldVal) {
          o.dd_Vendors = GenUtil.safeTrim(fieldVal.key);
          o.cv_VendorsValue = GenUtil.safeTrim(fieldVal.name);
          o.rpt_VendorLabel = GenUtil.safeTrim(fieldVal.name);
        }
        else {
          o.dd_Vendors = '';
          o.cv_VendorsValue = '';
        }
      }
      else if (GenUtil.eq(fieldName, 'typeOfCosts')) {
        if (!!fieldVal) {
          o.dd_TypeOfCosts = GenUtil.safeTrim(fieldVal.key);
          o.cv_TypeOfCosts = GenUtil.safeTrim(fieldVal.text);
          o.rpt_VendorLabel = GenUtil.safeTrim(fieldVal.text);
        }
        else {
          o.dd_TypeOfCosts = '';
          o.cv_TypeOfCosts = '';
        }
      }
      else if (GenUtil.eq(fieldName, 'cost')) o.rpt_decimal_Costs = GenUtil.safeToNumber(fieldVal, 2);
      else if (GenUtil.eq(fieldName, 'descr')) o.rpt_Description = GenUtil.safeTrim(fieldVal);
      return t;
    });
  }


  const memoTotalCollections = useMemo(() => {
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToNumber(o.rpt_decimal_Costs));
    return tot;
  }, [stateRows]);


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3'>
          <thead>

            <tr>
              <th>&nbsp;</th>
              <th>{`Vendor / Type of Cost`}</th>
              <th>{`Costs Excluding VAT`}</th>
              <th>{`Costs Currency`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <ProposedCostsRow
                  key={o.rpt_PropCostRowNumber}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  vendors={props.vendors}
                  typeOfCosts={props.typeOfCosts}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly} />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <Label>{`Total:`}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoTotalCollections)}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{StaticData.costsCurrency}</Label>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow}>Add More Proposed Costs</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }


      </Stack>

    </>
  );
};