/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import { Label, IconButton } from '@fluentui/react';

import * as Consts from "../../../Helpers/Consts";
import { BorrowerInfoItem } from '../../../Models/TDX/GridModels/BorrowerInfo1';

export interface IBorrowerInfoRowProps {
  data: BorrowerInfoItem;
  handleDeleteItem: (a: string) => void;
  isReadOnly: boolean;
}

export const BorrowerInfoRow: React.FunctionComponent<IBorrowerInfoRowProps> = (props: React.PropsWithChildren<IBorrowerInfoRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorrowerInfoRow]");
  }, []);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.data.cv_BorrowerID);
  }


  return (
    <>

      <tr className='sep'>

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_ConnId}
            </td>
          )
        }

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_BorrowerID}
            </td>
          )
        }

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.data.rpt_BorrowerName}
          </Label>
        </td>

        {
          !props.isReadOnly && (
            <>
              <td style={{ maxWidth: 25, textAlign: 'right' }}>
                <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
              </td>
            </>
          )
        }

      </tr>

    </>
  );
};