/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { AssetLevelMatrixThreeItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixThree1';


export interface IALM3RowProps {
  data: AssetLevelMatrixThreeItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ALM3Row: React.FunctionComponent<IALM3RowProps> = (props: React.PropsWithChildren<IALM3RowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM3Row]");
  }, []);


  // user controlled input
  const [stateALMActualGP, setStateALMActualGP] = useState(props.data.rpt_Actual2Gross);
  const onChangeALMActualGP = useCallback((evt: any, v?: string) => { setStateALMActualGP(v || ''); }, []);

  const [stateALMActualNP, setStateALMActualNP] = useState(props.data.rpt_Actual2Net);
  const onChangeALMActualNP = useCallback((evt: any, v?: string) => { setStateALMActualNP(v || ''); }, []);

  const [stateALMActualExitDate, setStateALMActualExitDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.rpt_dt_ALAct2Exit));

  const [stateALMBusPlanGP, setStateALMBusPlanGP] = useState(props.data.rpt_BP2Gross);
  const onChangeALMBusPlanGP = useCallback((evt: any, v?: string) => { setStateALMBusPlanGP(v || ''); }, []);

  const [stateALMBusPlanNP, setStateALMBusPlanNP] = useState(props.data.rpt_BP2Net);
  const onChangeALMBusPlanNP = useCallback((evt: any, v?: string) => { setStateALMBusPlanNP(v || ''); }, []);

  const [stateALMBusPlanExitDate, setStateALMBusPlanExitDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.rpt_dt_ALBP2Exit));


  // memos for calculating deltas
  const memoDeltaGross = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMActualGP, stateALMBusPlanGP);
  }, [stateALMActualGP, stateALMBusPlanGP]);

  const memoDeltaNet = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMActualNP, stateALMBusPlanNP);
  }, [stateALMActualNP, stateALMBusPlanNP]);


  // send field updates back to parent
  useEffect(() => {
    props.updateFieldVal(props.counter, 'ALMActualGP', GenUtil.safeTrim(stateALMActualGP));
  }, [stateALMActualGP]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'ALMActualNP', GenUtil.safeTrim(stateALMActualNP));
  }, [stateALMActualNP]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'ALMBusPlanGP', GenUtil.safeTrim(stateALMBusPlanGP));
  }, [stateALMBusPlanGP]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'ALMBusPlanNP', GenUtil.safeTrim(stateALMBusPlanNP));
  }, [stateALMBusPlanNP]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'DeltaGross', memoDeltaGross);
  }, [memoDeltaGross]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'DeltaNet', memoDeltaNet);
  }, [memoDeltaNet]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.counter, 'ALMActualExitDate', stateALMActualExitDate);
  }, [stateALMActualExitDate]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.counter, 'ALMBusPlanExitDate', stateALMBusPlanExitDate);
  }, [stateALMBusPlanExitDate]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //#region 'validation'
  //-------------------------

  const memoIsErrALMActualGP = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMActualGP();
  }, [props.stateFormSubmitted, stateALMActualGP]);

  function isErrALMActualGP() {
    return GenUtil.safeToNumberOrNull(stateALMActualGP) == null ? true : false;
  }


  const memoIsErrALMActualNP = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMActualNP();
  }, [props.stateFormSubmitted, stateALMActualNP]);

  function isErrALMActualNP() {
    return GenUtil.safeToNumberOrNull(stateALMActualNP) == null ? true : false;
  }


  const memoIsErrALMBusPlanGP = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMBusPlanGP();
  }, [props.stateFormSubmitted, stateALMBusPlanGP]);

  function isErrALMBusPlanGP() {
    return GenUtil.safeToNumberOrNull(stateALMBusPlanGP) == null ? true : false;
  }


  const memoIsErrALMBusPlanNP = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMBusPlanNP();
  }, [props.stateFormSubmitted, stateALMBusPlanNP]);

  function isErrALMBusPlanNP() {
    return GenUtil.safeToNumberOrNull(stateALMBusPlanNP) == null ? true : false;
  }


  const memoIsErrALMActualExitDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMActualExitDate();
  }, [props.stateFormSubmitted, stateALMActualExitDate]);

  function isErrALMActualExitDate() {
    return !(stateALMActualExitDate);
  }


  const memoIsErrALMBusPlanExitDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrALMBusPlanExitDate();
  }, [props.stateFormSubmitted, stateALMBusPlanExitDate]);

  function isErrALMBusPlanExitDate() {
    return !(stateALMBusPlanExitDate);
  }

  //#endregion


  return (
    <>

      <tr className='sep'>
        <td>
          Actual
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeALMActualGP} value={stateALMActualGP} className='' maxLength={255} errorMessage={memoIsErrALMActualGP ? 'Number is required.' : ''} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateALMActualGP}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeALMActualNP} value={stateALMActualNP} className='' maxLength={255} errorMessage={memoIsErrALMActualNP ? 'Number is required.' : ''} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateALMActualNP}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <Stack tokens={{ childrenGap: 4 }}>
                <DatePicker
                  formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                  placeholder="Select a date..."
                  value={stateALMActualExitDate}
                  onSelectDate={setStateALMActualExitDate as (date: Date | null | undefined) => void}
                  allowTextInput
                  className={`${(memoIsErrALMActualExitDate ? "invalid-datepicker" : "")}`}
                />
                {
                  memoIsErrALMActualExitDate && (
                    <Label className='red-msg-text clr'>Date is required</Label>
                  )
                }
              </Stack>
            ) : (
              <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(stateALMActualExitDate)}</Label>
            )
          }


        </td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            props.totCount > 1 && !props.isReadOnly && (
              <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>
      </tr>

      <tr>
        <td>
          Business Plan
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeALMBusPlanGP} value={stateALMBusPlanGP} className='' maxLength={255} errorMessage={memoIsErrALMBusPlanGP ? 'Number is required.' : ''} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateALMBusPlanGP}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeALMBusPlanNP} value={stateALMBusPlanNP} className='' maxLength={255} errorMessage={memoIsErrALMBusPlanNP ? 'Number is required.' : ''} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateALMBusPlanNP}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <Stack tokens={{ childrenGap: 4 }}>
                <DatePicker
                  formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                  placeholder="Select a date..."
                  value={stateALMBusPlanExitDate}
                  onSelectDate={setStateALMBusPlanExitDate as (date: Date | null | undefined) => void}
                  allowTextInput
                  className={`${(memoIsErrALMBusPlanExitDate ? "invalid-datepicker" : "")}`}
                />
                {
                  memoIsErrALMBusPlanExitDate && (
                    <Label className='red-msg-text clr'>Date is required</Label>
                  )
                }
              </Stack>
            ) : (
              <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(stateALMBusPlanExitDate)}</Label>
            )
          }

        </td>

        <td></td>
      </tr>

      <tr>
        <td>
          Delta
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField value={memoDeltaGross} readOnly onChange={() => { }} className='bgGrey' />
            ) : (
              <Label className='ms-fontWeight-regular'>{memoDeltaGross}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField value={memoDeltaNet} readOnly onChange={() => { }} className='bgGrey' />
            ) : (
              <Label className='ms-fontWeight-regular'>{memoDeltaNet}</Label>
            )
          }
        </td>

        <td>
          &nbsp;
        </td>

        <td></td>
      </tr>

    </>
  );
};