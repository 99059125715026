/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Gescobro/GraphDataService';
import * as StaticData from "../../../StaticData/Gescobro/StaticData";
import { AssetInfoItem } from '../../../Models/Gescobro/GridModels/AssetInfo1';

export interface IAssetInfoRowProps {
  data: AssetInfoItem;
  showAssetLocation: boolean;
  showCommercialActivity: boolean;
  showPublicationDate: boolean;
  updateFieldVal: (id: string, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (id: string) => void;
  isReadOnly: boolean;
}

export const AssetInfoRow: React.FunctionComponent<IAssetInfoRowProps> = (props: React.PropsWithChildren<IAssetInfoRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: AssetInfoRow]");
  }, []);


  // user controlled input

  const [stateSqmUnits, setStateSqmUnits] = useState(props.data.rpt_SqmUnits);
  const onChangeSqmUnits = useCallback((evt: any, v?: string) => { setStateSqmUnits(v || ''); }, []);

  const [stateSelVacant, setStateSelVacant] = useState<IDropdownOption | undefined>(GenUtil.isNull(props.data.rpt_Vacant) ? undefined : { key: props.data.rpt_Vacant, text: props.data.rpt_Vacant });
  const onChangeVacant = (event: any, option?: IDropdownOption, index?: number) => { setStateSelVacant(option); };

  const [stateCommercialActivity, setStateCommercialActivity] = useState(props.data.rpt_CommAct);
  const onChangeCommercialActivity = useCallback((evt: any, v?: string) => { setStateCommercialActivity(v || ''); }, []);

  const [statePublicationDate, setStatePublicationDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_PubDate));


  // send field updates back to parent

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_AssetID, 'sqm', GenUtil.safeTrim(stateSqmUnits));
  }, [stateSqmUnits]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.data.rpt_AssetID, 'vacant', (stateSelVacant ? stateSelVacant.key : ''));
  }, [stateSelVacant]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_AssetID, 'commAct', GenUtil.safeTrim(stateCommercialActivity));
  }, [stateCommercialActivity]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_AssetID, 'pubDate', statePublicationDate);
  }, [statePublicationDate]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.data.rpt_AssetID);
  }


  function colCount() {
    let n = 6;
    n += Consts.isWorkbench() ? 1 : 0;
    n += props.showAssetLocation ? 1 : 0;
    return n;
  }


  return (
    <>

      <tr className='sep'>
        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_ConnId}
            </td>
          )
        }
        <td>
          {props.data.rpt_CESAssetID}
        </td>
        <td>
          {props.data.cv_AssetID}
        </td>
        {
          props.showAssetLocation && (
            <td>
              {props.data.cv_AssetAddr}
            </td>
          )
        }
        <td>
          {GenUtil.numberToCurrency(props.data.cv_REValue)}
        </td>
        <td>
          {props.data.rpt_District}
        </td>
        <td>
          {props.data.cv_AssetType}
        </td>
        <td style={{ maxWidth: 25, textAlign: 'right' }}>
          {
            !props.isReadOnly && (
              <>
                <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
              </>
            )
          }
        </td>
      </tr>

      <tr>
        <td colSpan={colCount()}>
          <Stack tokens={Consts.stackTokens} horizontal>

            {
              props.isReadOnly ? (
                <>
                  <Label>{`sqm:`}</Label>
                  <Label className='ms-fontWeight-regular'>{props.data.rpt_SqmUnits}</Label>
                </>
              ) : (
                <>
                  <Label className='wbs'>{`sqm:`}</Label>
                  <TextField
                    onChange={onChangeSqmUnits}
                    value={stateSqmUnits}
                    placeholder='Please enter a value'
                    className='w200'
                    maxLength={255}
                  />
                </>
              )
            }

            {
              props.isReadOnly ? (
                <>
                  <Label>{`Vacant:`}</Label>
                  <Label className='ms-fontWeight-regular'>{props.data.rpt_Vacant}</Label>
                </>
              ) : (
                <>
                  <Label style={{ paddingLeft: 12 }}>{`Vacant:`}</Label>
                  <Dropdown
                    className='w150'
                    selectedKey={stateSelVacant ? stateSelVacant.key : undefined}
                    onChange={onChangeVacant}
                    placeholder="Please select a value"
                    options={StaticData.luVacant.split(',').map(o => { return { key: o, text: o }; })}
                  />
                </>
              )
            }


            {
              props.showPublicationDate && (
                <>
                  {
                    props.isReadOnly ? (
                      <>
                        <Label style={{ paddingLeft: 12 }}>{`Publication Date:`}</Label>
                        <Label className='ms-fontWeight-regular'>{props.data.rpt_PubDate}</Label>
                      </>
                    ) : (
                      <>
                        <Label style={{ paddingLeft: 12 }}>{`Publication Date:`}</Label>
                        <DatePicker
                          className='w175'
                          formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                          placeholder="Select a date"
                          value={statePublicationDate}
                          onSelectDate={setStatePublicationDate as (date: Date | null | undefined) => void}
                          allowTextInput
                        />
                      </>
                    )
                  }
                </>
              )
            }

          </Stack>
        </td>
      </tr>

      {
        props.showCommercialActivity && (
          <>
            <tr>
              <td colSpan={colCount()}>
                {
                  props.isReadOnly ? (
                    <>
                      <Label>{`Commercial activity (Leads, Visits, Offers):`}</Label>
                      <Label className='ms-fontWeight-regular'>{props.data.rpt_CommAct}</Label>
                    </>
                  ) : (
                    <>
                      <Label>{`Commercial activity (Leads, Visits, Offers):`}</Label>
                      <TextField onChange={onChangeCommercialActivity} value={stateCommercialActivity} placeholder='Please enter text here' className='w98p wbs' maxLength={255} />
                    </>
                  )
                }
              </td>
            </tr>
          </>
        )
      }

    </>
  );
};