/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Altamira/GraphDataService';
import * as StaticData from "../../../StaticData/Altamira/StaticData";

import { LoanInfoRow } from './LoanInfoRow';
import { LoanInfo1, LoanInfoItem } from '../../../Models/Altamira/GridModels/LoanInfo1';
import { LoanInfoN } from '../../../Models/Altamira/GridModels/LoanInfoN';
import { ANConnection } from '../../../Models/Altamira/ANConnection';
import { ANCurrency } from '../../../Models/Altamira/ANCurrency';


export interface ILoanInfoBodyProps {
  data: string | undefined;
  options: IDropdownOption[];
  connections: ANConnection[];
  currencys: ANCurrency[];
  onDataUpdated: (s: string, d: any) => void;
  isReadOnly: boolean;
  removeConnId: string;
}

export const LoanInfoBody: React.FunctionComponent<ILoanInfoBodyProps> = (props: React.PropsWithChildren<ILoanInfoBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: LoanInfoBody]");
  }, []);


  const [stateRows, setStateRows] = useState<LoanInfoItem[]>([]);

  const [stateSelItem, setStateSelItem] = useState<IDropdownOption>();
  const onChange = (event: any, option?: IDropdownOption, index?: number) => { setStateSelItem(option); };

  const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);


  useEffect(() => {
    props.onDataUpdated('loans', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // use local state for options, since we have to remove options that are already added to the list
    // remove from options any item that is added to the Grid
    let ids = stateRows.map(o => o.rpt_ServicerLoanNumber);
    let col = [...props.options].filter(o => ids.indexOf(o.key + '') < 0);
    setStateOptions(col);
  }, [props.options, stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: LoanInfoItem[] = [];

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('LoanInfo', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as LoanInfo1).RepeaterData.Items.Item;

          let item: LoanInfoItem = {

            cv_ConnId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId)),

            rpt_ServicerLoanNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ServicerLoanNumber)),

            rpt_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LoanID)),
            // cv_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.cv_LoanID)),

            rpt_ServicerLoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ServicerLoanID)),
            // cv_SrvcLoanID: GenUtil.safeTrim(AppHelper.getText(_t.cv_SrvcLoanID)),

            cv_decimal_UPB: GenUtil.safeToNumber(AppHelper.getText(_t.cv_decimal_UPB)),
            // cv_upb: GenUtil.safeToNumber(AppHelper.getText(_t.cv_upb)),

            cv_UPBCurrency: GenUtil.safeTrim(AppHelper.getText(_t.cv_UPBCurrency)),
            // cv_UPBCurrency: GenUtil.safeTrim(AppHelper.getText(_t.cv_UPBCurrency)),

            cv_OrigUPB: GenUtil.safeToNumber(AppHelper.getText(_t.cv_OrigUPB)),
            cv_OrigCurr: GenUtil.safeTrim(AppHelper.getText(_t.cv_OrigCurr)),

            rpt_DispUPB: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DispUPB)),
            rpt_DispCurr: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DispCurr)),

            rpt_JrLiensEnc: GenUtil.safeTrim(AppHelper.getText(_t.rpt_JrLiensEnc)),
            cv_RentalIncome: GenUtil.safeTrim(AppHelper.getText(_t.cv_RentalIncome)),

          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_ServicerLoanID).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as LoanInfoN;

          let _col: LoanInfoItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {

              cv_ConnId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId)),

              rpt_ServicerLoanNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ServicerLoanNumber)),

              rpt_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LoanID)),
              // cv_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.cv_LoanID)),

              rpt_ServicerLoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ServicerLoanID)),
              // cv_SrvcLoanID: GenUtil.safeTrim(AppHelper.getText(_t.cv_SrvcLoanID)),

              cv_decimal_UPB: GenUtil.safeToNumber(AppHelper.getText(_t.cv_decimal_UPB)),
              // cv_upb: GenUtil.safeToNumber(AppHelper.getText(_t.cv_upb)),

              cv_UPBCurrency: GenUtil.safeTrim(AppHelper.getText(_t.cv_UPBCurrency)),
              // cv_UPBCurrency: GenUtil.safeTrim(AppHelper.getText(_t.cv_UPBCurrency)),

              cv_OrigUPB: GenUtil.safeToNumber(AppHelper.getText(_t.cv_OrigUPB)),
              cv_OrigCurr: GenUtil.safeTrim(AppHelper.getText(_t.cv_OrigCurr)),

              rpt_DispUPB: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DispUPB)),
              rpt_DispCurr: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DispCurr)),

              rpt_JrLiensEnc: GenUtil.safeTrim(AppHelper.getText(_t.rpt_JrLiensEnc)),
              cv_RentalIncome: GenUtil.safeTrim(AppHelper.getText(_t.cv_RentalIncome)),

            }
          });

          // only add if the object is not nintex empty xml record
          col = _col.filter(o => !GenUtil.isNull(o.rpt_ServicerLoanID));
        }

      } catch (error) { console.warn(`Error parsing Xml in LoanInfoBody`, props.data, error); }
    }
    else {
      col = [];
    }

    setStateRows(col);

  }, [props.data]);


  function onClickAddID() {
    // add specific item from DDL to grid

    if (!stateSelItem) {
      return;
    }

    let selKey = GenUtil.safeTrim(stateSelItem.key);

    if (GenUtil.isNull(selKey)) {
      return;
    }

    // prevent same item adding more than once
    if (stateRows.filter(x => GenUtil.eq(x.rpt_ServicerLoanNumber, selKey)).length > 0) return;

    let col = props.connections.filter(o => GenUtil.eq(o.fields.Servicer_x0020_Loan_x0020_Number, selKey)).map((o, i) => {

      // convert currency to EUR
      let origAmt = GenUtil.safeToNumber(o.fields.UPB);
      let origCurr = GenUtil.safeTrim(o.fields.UPB_x0020_Currency);

      let newAmt = AppHelper.convertCurrency(origAmt, origCurr, props.currencys);
      let newCurr = StaticData.costsCurrency;

      let dispAmt = GenUtil.eq(origCurr, StaticData.costsCurrency) ? GenUtil.numberToCurrency(newAmt) : `${GenUtil.numberToCurrency(newAmt)} (${GenUtil.numberToCurrency(origAmt)})`;
      let dispCurr = GenUtil.eq(origCurr, StaticData.costsCurrency) ? StaticData.costsCurrency : `${StaticData.costsCurrency} (${origCurr})`;

      return {

        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),

        rpt_ServicerLoanNumber: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_Number),

        rpt_LoanID: GenUtil.safeTrim(o.fields.Loan_x0020_ID),
        // cv_LoanID: GenUtil.safeTrim(o.fields.Loan_x0020_ID),

        rpt_ServicerLoanID: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID),
        // cv_SrvcLoanID: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID),

        cv_decimal_UPB: newAmt,
        // cv_upb: upb,
        cv_UPBCurrency: newCurr,
        // cv_UPBCurrency: curr,

        cv_OrigUPB: origAmt,
        cv_OrigCurr: origCurr,

        rpt_DispUPB: dispAmt,
        rpt_DispCurr: dispCurr,

        // user controlled
        rpt_JrLiensEnc: '',
        cv_RentalIncome: '',

      };
    });

    if (col.length <= 0) {
      return;
    }

    let obj = col[0];

    setStateRows(p => {
      return [...p, obj];
    });
  }


  function onClickAddAll() {
    // add all items from DDL to grid

    let col = [...stateRows];

    let col2 = props.connections.filter(a => {
      return col.findIndex(b => b.rpt_ServicerLoanNumber === a.fields.Servicer_x0020_Loan_x0020_Number) < 0 && !GenUtil.isNull(a.fields.Servicer_x0020_Loan_x0020_Number);
    }).map(o => {

      // convert currency to EUR
      let origAmt = GenUtil.safeToNumber(o.fields.UPB);
      let origCurr = GenUtil.safeTrim(o.fields.UPB_x0020_Currency);

      let newAmt = AppHelper.convertCurrency(origAmt, origCurr, props.currencys);
      let newCurr = StaticData.costsCurrency;

      let dispAmt = GenUtil.eq(origCurr, StaticData.costsCurrency) ? GenUtil.numberToCurrency(newAmt) : `${GenUtil.numberToCurrency(newAmt)} (${GenUtil.numberToCurrency(origAmt)})`;
      let dispCurr = GenUtil.eq(origCurr, StaticData.costsCurrency) ? StaticData.costsCurrency : `${StaticData.costsCurrency} (${origCurr})`;

      return {

        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),

        rpt_ServicerLoanNumber: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_Number),

        rpt_LoanID: GenUtil.safeTrim(o.fields.Loan_x0020_ID),
        //cv_LoanID: GenUtil.safeTrim(o.fields.Loan_x0020_ID),

        rpt_ServicerLoanID: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID),
        //cv_SrvcLoanID: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID),

        cv_decimal_UPB: newAmt,
        // cv_upb: upb,
        cv_UPBCurrency: newCurr,
        // cv_UPBCurrency: curr,

        cv_OrigUPB: origAmt,
        cv_OrigCurr: origCurr,

        rpt_DispUPB: dispAmt,
        rpt_DispCurr: dispCurr,

        // user controlled
        rpt_JrLiensEnc: '',
        cv_RentalIncome: ''

      };
    });

    col = [...col, ...col2];

    setStateRows(col);
  }


  function handleDeleteItem(id: string) {
    // remove the item from the grid

    setStateRows(p => {
      return p.filter(o => !GenUtil.eq(o.rpt_ServicerLoanID, id));
    });
  }


  useEffect(() => {
    // delete all rows with the connId sent from parent
    let id = GenUtil.safeTrim(props.removeConnId);

    if (!GenUtil.isNull(id)) {
      setStateRows(p => {
        return p.filter(o => !GenUtil.eq(o.cv_ConnId, id));
      });
    }
  }, [props.removeConnId]);


  function updateFieldVal(id: string, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let idx = t.findIndex(o => o.rpt_ServicerLoanID === id);
      if (idx >= 0) {
        let o = t[idx];
        if (GenUtil.eq(fieldName, 'jrLiensEnc')) o.rpt_JrLiensEnc = GenUtil.safeTrim(fieldVal);
        if (GenUtil.eq(fieldName, 'rentalIncome')) o.cv_RentalIncome = GenUtil.safeTrim(fieldVal);
      }
      return t;
    });
  }


  function onClickReset() {
    setStateRows([]);
    setStateOptions([...props.options]);
    if (props.options.length > 1) setStateSelItem(undefined);
    // setStateSelItem(undefined); // do not reset this, single value DDLs get stuck, cannot trigger an onchange
  }


  const memoTotalCalc = useMemo(() => {
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToNumber(o.cv_decimal_UPB));
    return tot;
  }, [stateRows]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        {
          !props.isReadOnly && (
            <>
              <Stack tokens={Consts.stackTokens} horizontal className='wbss' verticalAlign='baseline'>
                <div>{`Loan ID:`}</div>
                <Dropdown
                  className='w400'
                  selectedKey={stateSelItem ? stateSelItem.key : undefined}
                  onChange={onChange}
                  placeholder={stateOptions.length > 0 ? "Select a Loan" : 'No Loans Found'}
                  options={stateOptions}
                />
                <PrimaryButton text="Add Loan ID" allowDisabledFocus onClick={onClickAddID} disabled={stateOptions.length <= 0} />
                <PrimaryButton text="Add All" allowDisabledFocus onClick={onClickAddAll} disabled={stateOptions.length <= 0} />
                {stateRows.length > 0 && <PrimaryButton text="Reset" allowDisabledFocus onClick={onClickReset} />}
              </Stack>
            </>
          )
        }

        <table className='sub-table3'>
          <thead>

            <tr>
              {/* NOTE: this is on purpose, show the loan servicer id as loan id in the grid, but saving both in xml/object */}
              {
                Consts.isWorkbench() && (
                  <th style={{ color: 'purple' }}>
                    {`Connection ID`}
                  </th>
                )
              }
              <th>{`CES Loan ID`}</th>
              <th>{`Servicer Loan No.`}</th>
              <th>{`Outstanding Balance`}</th>
              {/* <th>{`UPB (€)`}</th> */}
              {/* <th>{`UPB Currency`}</th> */}
              <th>{`OB Currency`}</th>
              {/* <th>{`Rental Income`}</th> */}
              <th>{`Jr. Liens & Encumbrances`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <LoanInfoRow
                  key={i}
                  data={o}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  isReadOnly={props.isReadOnly} />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  {
                    Consts.isWorkbench() && (
                      <td></td>
                    )
                  }
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    <Label>{`Total:`}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoTotalCalc)}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{`EUR`}</Label>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>props.options: {JSON.stringify(props.options, null, 2)}</li>
              <li>stateOptions: {JSON.stringify(stateOptions, null, 2)}</li>
              <li>stateSelItem: {JSON.stringify(stateSelItem, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};