/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox, getWindow, Toggle,
  Dialog, DialogType, DialogFooter
} from '@fluentui/react';
import _ from 'lodash';

import * as Consts from "../../Helpers/Consts";
import * as GenUtil from '../../Helpers/GenUtil2';
import * as AppHelper from '../../Helpers/AppHelper';

import * as AppModels from '../../Models/AppModels';

import { Space } from '../Controls/Space';


export interface IANAttachmentsProps {
  savedANAtts: AppModels.SavedFile[];
  attType: string; // each Attachment component must have a different AttType label, so files can be broken into sets, "" is ok if using only single Attachments component in form
  onDataUpdated: (attType: string, newFiles: AppModels.FileUpload[], delFiles: string[]) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ANAttachments: React.FunctionComponent<IANAttachmentsProps> = (props: React.PropsWithChildren<IANAttachmentsProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANAttachments]");
  }, []);


  const _attType = GenUtil.safeTrim(props.attType);


  const [stateSelFile, setStateSelFile] = useState<AppModels.FileUpload | null>(null);


  // temp collection of files from upload control, to upload on save
  const [stateTmpFiles, setStateTmpFiles] = useState<AppModels.FileUpload[]>([]);


  // collection of files retrieved from SP belonging to this AN
  // const [stateAllSavedFiles, setStateAllSavedFiles] = useState<AppModels.SavedFile[]>(props.savedANAtts); // all atts not filtered by type
  const [stateSavedFiles, setStateSavedFiles] = useState<AppModels.SavedFile[]>([]); // only atts with specific type

  useEffect(() => {
    // only atts with specific type
    let t = props.savedANAtts.filter(f => GenUtil.eq(f.attType, _attType));
    setStateSavedFiles(t);
  }, [props.savedANAtts]);


  function handleFileChange(event: any) {
    let f = event.target.files[0];
    setStateSelFile(f);
    // event.target.value = null; // is this needed?
  }


  function handleAddFile() {
    // add the file from file upload control to state

    if (stateSelFile == null) {
      // make sure a file is selected
      return;
    }

    if (stateTmpFiles.findIndex(x => GenUtil.eq(x.name, stateSelFile.name)) >= 0 ||
      stateSavedFiles.findIndex(x => GenUtil.eq(AppHelper.getFilename(x.fullUrl), stateSelFile.name)) >= 0 ||
      props.savedANAtts.findIndex(x => GenUtil.eq(AppHelper.getFilename(x.fullUrl), stateSelFile.name)) >= 0) {
      // filename must be unique, in entire collection (temp files to upload, and files prior saved to this AN, and in other ATT sections on the form for mapping/upload)
      // LATEST: because we are saving them as listitem attachments via logicapp service
      AppHelper.toastWarn('File with same name already added to collection. Please choose a different file or rename the file before uploading.');
      return;
    }

    setStateTmpFiles(p => {
      return [...p, stateSelFile];
    });

    setStateSelFile(null);
  }


  function handleClearFile() {
    setStateSelFile(null);
  }


  function deleteNewFile(name: string) {
    // delete the tmp file immed, it was not uploaded, just remove from collection
    setStateTmpFiles(p => [...p].filter(x => !GenUtil.eq(x.name, name)));
  }


  function deleteSavedFile(identifier: string) {
    // mark the saved file to delete later when submitting form
    setStateSavedFiles(p => {
      let t = [...p];
      let idx = t.findIndex(o => GenUtil.isEqual(identifier, o.fullUrl));
      if (idx >= 0) { t[idx].deleteMe = true; }
      return t;
    });
  }


  useEffect(() => {
    // when new files are added to upload, or saved files are marked for delete, send the updates to parent
    props.onDataUpdated(
      _attType,
      stateTmpFiles,
      stateSavedFiles.filter(f => f.deleteMe).map(f => f.fullUrl));
  }, [stateTmpFiles, stateSavedFiles]);


  return (
    <>

      <Space sizeIsMed>

        {
          !props.isReadOnly && !stateSelFile && (
            <input type="file" onChange={handleFileChange} />
          )
        }

        {
          !props.isReadOnly && stateSelFile && stateSelFile.name && (
            <Space horizontal>
              <div>File Selected: {stateSelFile.name}</div>
              <div><DefaultButton className='fluSmallButton' text="Add" onClick={handleAddFile} disabled={props.saving} /></div>
              <div><DefaultButton className='fluSmallButton' text="Clear" onClick={handleClearFile} disabled={props.saving} /></div>
            </Space>
          )
        }

        {
          (stateTmpFiles.length + stateSavedFiles.filter(o => !o.deleteMe).length > 0) && (
            <ul className='ul-an-atts'>
              {
                // NOTE: these are pending files to be uploaded on form submit
                stateTmpFiles.map((o, i) => (
                  <li key={i} style={{ margin: '4px 0' }}>
                    <Space horizontal>
                      <IconButton style={{ height: 'auto' }} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={() => deleteNewFile(o.name)}></IconButton>
                      {o.name}
                    </Space>
                  </li>
                ))
              }
              {
                // NOTE: these are files saved in SP
                stateSavedFiles.filter(o => !o.deleteMe).map((o, i) => (
                  <li key={i} style={{ margin: '4px 0' }}>
                    <Space horizontal>
                      {!props.isReadOnly && <IconButton style={{ height: 'auto' }} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={() => deleteSavedFile(o.fullUrl)}></IconButton>}
                      <a style={{ marginLeft: 0 }} href={o.fullUrl} target='_blank' rel="noreferrer">{AppHelper.getFilename(o.fullUrl)}</a>
                    </Space>
                  </li>
                ))
              }
            </ul>
          )
        }

        {
          props.isReadOnly && (stateTmpFiles.length + stateSavedFiles.filter(o => !o.deleteMe).length) <= 0 && (
            <>
              <span>None</span>
            </>
          )
        }

        {
          !props.isReadOnly && (stateTmpFiles.length + stateSavedFiles.filter(o => !o.deleteMe).length) <= 0 && (
            <>
              <MessageBar messageBarType={MessageBarType.info} className='wts12 flu-ovr-msgbar-info'>
                {`No attachments found.`}
              </MessageBar>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.attType: {props.attType + ''}</li>
              <li>props.savedANAtts: {props.savedANAtts.length}, {JSON.stringify(props.savedANAtts, null, 2)}</li>
              <li>stateTmpFiles: {stateTmpFiles.length}, {JSON.stringify(stateTmpFiles, null, 2)}</li>
              <li>stateSavedFiles: {stateSavedFiles.length}, {JSON.stringify(stateSavedFiles, null, 2)}</li>
            </ul>
          )
        }

      </Space>

    </>
  );
};
