/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Label, Spinner, SpinnerSize } from '@fluentui/react';

export interface ITest3Props { }

export const Test3: React.FunctionComponent<ITest3Props> = (props: React.PropsWithChildren<ITest3Props>) => {


  function GetANConnectionAsync(): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      resolve(
        [{ Title: 'Mock List', Id: '1' },
        { Title: 'Mock List 2', Id: '2' },
        { Title: 'Mock List 3', Id: '3' }]
      );
    });
  }


  const [loading, setLoading] = useState(false);

  const [conns, setConns] = useState<any[]>([]);


  useEffect(() => {
    let ignore = false;

    setLoading(true);

    setTimeout(async () => {

      let conns = await GetANConnectionAsync();

      console.log(conns);

      if (!ignore) {
        setLoading(false);
        setConns(conns);
      }

    }, 1500);

    return () => { ignore = true; }
  }, []);


  return (
    <>

      {
        loading && (
          <div className=''>
            <Spinner label="Loading..." size={SpinnerSize.large} />
          </div>
        )
      }

      {
        !loading && (
          <>
            <ul>
              {conns.map((o, i) => { return <li key={i}>{`${o.Title} (${o.Id})`}</li> })}
            </ul>
          </>
        )
      }

    </>
  );
};