/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';

import { NoAuthLauncher } from './NoAuthLauncher';
import { AuthLauncher } from './AuthLauncher';

import { initializeIcons } from '@fluentui/font-icons-mdl2';

import '../fabric.min.css';

import '../standard.css';

import '../myapp.css';

import * as Consts from '../Helpers/Consts';

import * as Config from '../Helpers/ConfigData';

export interface ILauncherProps { }


export const Launcher: React.FunctionComponent<ILauncherProps> = (props: React.PropsWithChildren<ILauncherProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: Launcher]");
  }, []);


  const [stateLoading, setStateLoading] = useState<boolean>(true);


  React.useEffect(() => {
    // init fluent ui icons (should only be done once)
    initializeIcons();
    // show app version info
    console.log(`App version: ${Consts.version}`);
  }, []);


  useEffect(() => {
    // load Config Settings from json file in public home directory.
    // NOTE: this file will be updated using JSON transformation in Azure DevOps pipelines and using staging variables
    // saving these settings to window object, can be read anywhere in app using Config helper
    let ignore = false;

    setStateLoading(true);

    setTimeout(async () => {
      let resp = await fetch(`/configsettings.json?_tid=${new Date().getTime()}`);
      let data = await resp.json();

      if (!ignore) {
        (window as any).ConfigSettings = data.Config;
        setStateLoading(false);
      }
    }, 100);

    return () => { ignore = true; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function render() {
    return <AuthLauncher></AuthLauncher>;
    // return <NoAuthLauncher></NoAuthLauncher>;
  }


  return (
    <>
      <div className='ms-Fabric clr'>
        {
          !stateLoading && render()
        }
      </div>
    </>
  );
};
