/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";

import { Space } from '../../Controls/Space';
import { CompletedAN } from '../../../Models/Link/CompletedAN';


export interface ICompletedANsROProps {
  data: CompletedAN[];
}

export const CompletedANsRO: React.FunctionComponent<ICompletedANsROProps> = (props: React.PropsWithChildren<ICompletedANsROProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: CompletedANsRO]");
  }, []);


  return (
    <>

      <h2 className="flu-heading1 wbsss">{`Historical ANs`}</h2>

      {
        props.data.length <= 0 && (
          <>
            <MessageBar messageBarType={MessageBarType.warning} className='wts12'>
              {`No associated Completed ANs found with matching Connection ID.`}
            </MessageBar>
          </>
        )
      }

      {
        props.data.length > 0 && (
          <>
            <table className='sub-table3'>
              <thead>
                <tr>
                  <th>AN Number</th>
                  <th>Connection ID</th>
                  <th>Connection Name</th>
                  <th>Date of Approval</th>
                  <th>AN Type</th>
                  <th>AN Subtype</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.data.map((o, i) => (
                    <tr key={o.id}>
                      <td className='nowrap'>
                        <Stack horizontal verticalAlign='center'>
                          <Link href={`${o.webUrl}`} target='_blank'>{o.fields.ANNumber}</Link>
                          <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} allowDisabledFocus title="Open" ariaLabel="Open" onClick={() => window.open(o.webUrl, "_blank")}></IconButton>
                        </Stack>
                      </td>
                      <td>{o.fields.ConnectionID}</td>
                      <td>{o.fields.ConnectionName}</td>
                      <td>{GenUtil.getCalDate(o.fields.Created)}</td>
                      <td>{o.fields.ANType}</td>
                      <td>{o.fields.ANSubtype}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        )
      }

    </>
  );
};