import { GraphBaseResp } from "../GraphBaseResp";
import { SpItemBase } from "../SpItemBase";

export interface ANNote extends GraphBaseResp { // expecting single response back from Graph (not a collection)
  createdDateTime: Date;
  eTag: string;
  id: string;
  lastModifiedDateTime: Date;
  webUrl: string;
  createdBy: UserBy;
  lastModifiedBy: UserBy;
  contentType: ContentType;
  fields: Fields;
}

export interface ContentType {
  id: string;
  name: string;
}

export interface UserBy {
  user: User;
}

export interface User {
  email: string;
  id: string;
  displayName: string;
}

export interface Fields extends SpItemBase {

  // choices
  BrokerCode: string;
  Connection_status: string;
  Lender_approval_Security_release: string;
  Servicer: string;

  // date/times
  ALActExit: Date;
  ALBPExit: Date;
  // AppL1CustReqDate: Date; // replaced with string fields below
  DateSubmitted: Date;
  DateTime: Date;

  // mlots
  Details: string;
  Property_Details: string;
  Proposal: string;
  Pros: string;
  SupportingTables: string;
  AssetInfo: string;
  AssetLevelMatrix1: string;
  AssetLevelMatrix2: string;
  AssetLevelMatrix3: string;
  AssetLevelMatrix4: string;
  BorrowerInfo: string;
  ConnectionDetails: string;
  LitigationMatrix: string;
  LoanInfo: string;
  ProposedCostsDetails: string;
  SupportingDetails: string;
  App10Comments: string;
  App1Comments: string;
  App2Comments: string;
  App3Comments: string;
  App4Comments: string;
  App5Comments: string;
  App6Comments: string;
  App7Comments: string;
  App8Comments: string;
  App9Comments: string;
  AppL1Comments: string;
  AppL2Comments: string;
  Browser: string;
  Comments: string;
  CostDescription: string;
  Recommendation: string;
  SendEmailTo: string;
  SFS_Assessment: string;
  SFS_ReasonsWhy: string;
  WFInstanceID: string;
  Addtl_Info: string;
  Background_information: string;
  CancelReason: string;
  EmailBody: string;
  HTMLFullForm: string;

  // numbers

  ALActGross: number | null;
  ALActNet: number | null;
  ALBPGross: number | null;
  ALBPNet: number | null;
  ALDelGross: number | null;
  ALDelNet: number | null;

  AssetValue1: number | null;
  AssetValue2: number | null;
  AssetValue3: number | null;

  CMGrossBP: number | null;
  CMGrossDelta: number | null;
  CMGrossUW: number | null;
  CMIRRBP: number | null;
  CMIRRDelta: number | null;
  CMIRRUW: number | null;
  CMMultBP: number | null;
  CMMultDelta: number | null;
  CMMultUW: number | null;
  CMWALBP: number | null;
  CMWALDelta: number | null;
  CMWALUM: number | null;

  Cost1: number | null;
  Cost2: number | null;
  Costs_additional: number | null;
  Costs_net: number | null;
  Costs: number | null;

  LoanValue1: number | null;
  LoanValue2: number | null;
  LoanValue3: number | null;

  WorkflowRunning: number | null;

  // slots
  AdvisoryNoteSubtype: string;
  AdvisoryNoteType: string;
  ALMTypeNum: string;
  AN_No: string;
  App10Action: string;
  App10Date: string;
  App10Email: string;
  App10Name: string;
  App1Action: string;
  App1Date: string;
  App1Email: string;
  App1Name: string;
  App2Action: string;
  App2ActionKeyNo: string;
  App2ActionKeyYes: string;
  App2BBP: string;
  App2Date: string;
  App2Email: string;
  App2Name: string;
  App3Action: string;
  App3Date: string;
  App3Email: string;
  App3Name: string;
  App4Action: string;
  App4ActionReq: string;
  App4Date: string;
  App4Email: string;
  App4Name: string;
  App5Action: string;
  App5Date: string;
  App5Email: string;
  App5Name: string;
  App6Action: string;
  App6BBP: string;
  App6Date: string;
  App6Email: string;
  App6Name: string;
  App7Action: string;
  App7BBP: string;
  App7Date: string;
  App7Email: string;
  App7Name: string;
  App8Action: string;
  App8Date: string;
  App8Email: string;
  App8Name: string;
  App8OVR: string; // NEW: 6/14/23, "regulated entity to override" checkbox, just like BBP
  App8KeyDecOvr: string; // NEW: 7/7/23, instead of using the App8OVR, lets use this field to give the user the ability to override the KeyDec at App8, thus changing the workflowpath
  App9Action: string;
  App9Date: string;
  App9Email: string;
  App9Name: string;
  AppL1Action: string;
  AppL1CustReq: string;
  AppL1Date: string;
  AppL1DeadlineForResponse: string;
  AppL1Email: string;
  AppL1Name: string;
  AppL2Action: string;
  AppL2Date: string;
  AppL2Email: string;
  AppL2Name: string;
  AssetLabel1: string;
  AssetLabel2: string;
  AssetLabel3: string;
  BeaconStage: string;
  BeaconStageEnd: string;
  BeaconStart: string;
  BorrowerID: string;
  CancelDate: string;
  CaseManager: string;
  CESReviewed: string;
  CollectionsTotal: string;
  Connection_ID: string;
  Connection_Name: string;
  Cost1Ccy: string;
  Cost1Desc: string;
  Cost2Ccy: string;
  Cost2Desc: string;
  CostsCount: string;
  CostsCurrency: string;
  CreatedByEmail: string;
  CreatedByName: string;
  CurActionGroup: string;
  DatePDFANEmailedtoLink: string;
  DecisionMadeDT: string;
  EditForm: string;
  EmailSubject: string;
  Entities: string;
  ExternalLegalTitleHolder: string;
  FormStatus: string;
  InFolder: string;
  IntExt: string;
  isSubmitted: string;
  KeyDecision: string;
  LoanDriver: string;
  LoanID1: string;
  LoanLabel1: string;
  LoanLabel2: string;
  LoanLabel3: string;
  LogicAppRunning: string;
  LTHName: string;
  Moved: string;
  PassThruID: string;
  PegaID: string;
  Project: string;
  Regulated: string;
  ReqAsseMetricsCES: string;
  ReqConnMetricsCES: string;
  RunWorkflow: string;
  SendEmail: string;
  SFS_AbilityToRepay: string;
  SFS_CapOfArrears: string;
  SFS_CMhaveSFS: string;
  SFS_DebtWriteDown: string;
  SFS_ExtLoanTerm: string;
  SFS_IFC: string;
  SFS_Info_Available: string;
  SFS_IO: string;
  SFS_LongTIO: string;
  SFS_LTIO: string;
  SFS_Moratorium: string;
  SFS_MTR: string;
  SFS_RA: string;
  SFS_Settlement: string;
  SFS_SplitMortgage: string;
  SFS_SustainableARA: string;
  SFS_VoluntarySurrender: string;
  SFS_VS: string;
  SignoffStatus: string;
  Test: string;
  TotalCollections: string;
  TotalREValue: string;
  UPBTotal: string;
  ViewedCES1: string;
  ViewedCES2: string;
  ViewedCGI1: string;
  ViewedCGI2: string;
  ViewedEO: string;
  ViewedLink1: string;
  ViewedLink2: string;
  ViewedLTH: string;
  ViewedPSI: string;
  ViewedRPE1: string;
  ViewedRPE2: string;
  ViewedSRV: string;
  WFStatus: string;
  WorkflowPath: string;
  WorkflowStatus: string;

  // spusers/persons
  CaseManagerPPLLookupId: string;
  CGI1PPLLookupId: string;
  Link_x0020_ReviewerLookupId: string; // in SP the spuser field is named "Link_x0020_Reviewer", and "LookupId" is added as a suffix, and the field becomes a string with a number that is a FK to the hidden User Information List splistitem

  // lookups
  AN_TypeLookupId: string;
  AN_Secondary_OptionLookupId: string;
  EntityLookupId: string;

  // calculated fields
  CalcCol: string;
  Date_x0020_AN_x0020_Sent_x0020_t: string;
  Date_x0020_Customer_x0020_Reques: string;
  Date_x0020_Of_x0020_CES1_x0020_R: string;
  Date_x0020_Of_x0020_Link_x0020_R: string;
  Date_x0020_sent_x0020_for_x0020_: string;
  DatePDFApproved: string;
  DDMMYY: string;
  EU_x0020_Created: string;

  MarkAsUrgent: boolean; // yes/no

  // new fields: link/poc/bcm buildout, q1/2 2023
  ProposalBreakdown: string; // mlot/plain, xml
  OtherNPLevel: string; // mlot/plain, xml
  LegalTracker: string; // mlot/plain, json
  JsVersion: string;  // slot
  TrackingInfo: string;  // slot

  AppL1CustReqDateEur: string; // save Euro version of date, using browser local time, in format 'dd/MM/yyyy'
  AppL1CustReqDateAct: string; // save JSON version of date, with timezone info

  LitTotal1: string;  // slot
  ProposalBreakdownTotal1: string;  // slot
  PropCostTotal1: string; // slot

  AttachmentFilenames: string; // mlot
  AttachmentData: string; // mlot

  PropCostNA: string; // slot, "Yes/No"
  LitigationMatrixNA: string; // slot, "Yes/No"
  OtherNPLevelPDF: string // mlot/plain, xml

}

export const anNoteFieldNames: string[] = [
  "BrokerCode",
  "Connection_status",
  "Lender_approval_Security_release",
  "Servicer",
  "DateTime",
  "AppL1CustReqDate",
  "ALActExit",
  "ALBPExit",
  "DateSubmitted",
  "App1Comments",
  "App7Comments",
  "Browser",
  "CostDescription",
  "Pros",
  "Recommendation",
  "SendEmailTo",
  "Addtl_Info",
  "App10Comments",
  "App2Comments",
  "App3Comments",
  "App4Comments",
  "App5Comments",
  "App6Comments",
  "App8Comments",
  "App9Comments",
  "AppL1Comments",
  "AppL2Comments",
  "AssetInfo",
  "AssetLevelMatrix1",
  "AssetLevelMatrix2",
  "AssetLevelMatrix3",
  "AssetLevelMatrix4",
  "Background_information",
  "BorrowerInfo",
  "CancelReason",
  "Comments",
  "ConnectionDetails",
  "Details",
  "EmailBody",
  "HTMLFullForm",
  "LitigationMatrix",
  "LoanInfo",
  "Property_Details",
  "Proposal",
  "ProposedCostsDetails",
  "SFS_Assessment",
  "SFS_ReasonsWhy",
  "SupportingDetails",
  "SupportingTables",
  "WFInstanceID",
  "WorkflowRunning",
  "ALActGross",
  "ALActNet",
  "ALBPGross",
  "ALBPNet",
  "ALDelGross",
  "ALDelNet",
  "AssetValue1",
  "AssetValue2",
  "AssetValue3",
  "CMGrossBP",
  "CMGrossDelta",
  "CMGrossUW",
  "CMIRRBP",
  "CMIRRDelta",
  "CMIRRUW",
  "CMMultBP",
  "CMMultDelta",
  "CMMultUW",
  "CMWALBP",
  "CMWALDelta",
  "CMWALUM",
  "Costs",
  "LoanValue1",
  "LoanValue2",
  "LoanValue3",
  "Cost1",
  "Cost2",
  "Costs_additional",
  "Costs_net",
  "CaseManager",
  "ExternalLegalTitleHolder",
  "KeyDecision",
  "LogicAppRunning",
  "RunWorkflow",
  "TotalCollections",
  "TotalREValue",
  "UPBTotal",
  "AdvisoryNoteSubtype",
  "AdvisoryNoteType",
  "ALMTypeNum",
  "AN_No",
  "App10Action",
  "App10Date",
  "App10Email",
  "App10Name",
  "App1Action",
  "App1Date",
  "App1Email",
  "App1Name",
  "App2Action",
  "App2ActionKeyNo",
  "App2ActionKeyYes",
  "App2BBP",
  "App2Date",
  "App2Email",
  "App2Name",
  "App3Action",
  "App3Date",
  "App3Email",
  "App3Name",
  "App4Action",
  "App4ActionReq",
  "App4Date",
  "App4Email",
  "App4Name",
  "App5Action",
  "App5Date",
  "App5Email",
  "App5Name",
  "App6Action",
  "App6BBP",
  "App6Date",
  "App6Email",
  "App6Name",
  "App7Action",
  "App7BBP",
  "App7Date",
  "App7Email",
  "App7Name",
  "App8Action",
  "App8Date",
  "App8Email",
  "App8Name",
  "App9Action",
  "App9Date",
  "App9Email",
  "App9Name",
  "AppL1Action",
  "AppL1CustReq",
  "AppL1Date",
  "AppL1DeadlineForResponse",
  "AppL1Email",
  "AppL1Name",
  "AppL2Action",
  "AppL2Date",
  "AppL2Email",
  "AppL2Name",
  "AssetLabel1",
  "AssetLabel2",
  "AssetLabel3",
  "BeaconStage",
  "BeaconStageEnd",
  "BeaconStart",
  "BorrowerID",
  "CancelDate",
  "CESReviewed",
  "CollectionsTotal",
  "Connection_ID",
  "Connection_Name",
  "Cost1Ccy",
  "Cost1Desc",
  "Cost2Ccy",
  "Cost2Desc",
  "CostsCount",
  "CostsCurrency",
  "CreatedByEmail",
  "CreatedByName",
  "CurActionGroup",
  "DatePDFANEmailedtoLink",
  "DecisionMadeDT",
  "EditForm",
  "EmailSubject",
  "Entities",
  "FormStatus",
  "InFolder",
  "IntExt",
  "isSubmitted",
  "LoanDriver",
  "LoanID1",
  "LoanLabel1",
  "LoanLabel2",
  "LoanLabel3",
  "LTHName",
  "Moved",
  "PassThruID",
  "PegaID",
  "Project",
  "Regulated",
  "ReqAsseMetricsCES",
  "ReqConnMetricsCES",
  "SendEmail",
  "SFS_AbilityToRepay",
  "SFS_CapOfArrears",
  "SFS_CMhaveSFS",
  "SFS_DebtWriteDown",
  "SFS_ExtLoanTerm",
  "SFS_IFC",
  "SFS_Info_Available",
  "SFS_IO",
  "SFS_LongTIO",
  "SFS_LTIO",
  "SFS_Moratorium",
  "SFS_MTR",
  "SFS_RA",
  "SFS_Settlement",
  "SFS_SplitMortgage",
  "SFS_SustainableARA",
  "SFS_VoluntarySurrender",
  "SFS_VS",
  "SignoffStatus",
  "Test",
  "ViewedCES1",
  "ViewedCES2",
  "ViewedCGI1",
  "ViewedCGI2",
  "ViewedEO",
  "ViewedLink1",
  "ViewedLink2",
  "ViewedLTH",
  "ViewedPSI",
  "ViewedRPE1",
  "ViewedRPE2",
  "ViewedSRV",
  "WFStatus",
  "WorkflowPath",
  "WorkflowStatus",

  "MarkAsUrgent",
  "ProposalBreakdown",
  "OtherNPLevel",
  "LegalTracker",
  "JsVersion",
  "TrackingInfo",
  "AppL1CustReqDateEur",
  "AppL1CustReqDateAct",
  "LitTotal1",
  "ProposalBreakdownTotal1",
  "PropCostTotal1",
  "App8OVR",
  "App8KeyDecOvr",
  "AttachmentFilenames",
  "AttachmentData",

  "PropCostNA",
  "LitigationMatrixNA",
  "OtherNPLevelPDF"

];