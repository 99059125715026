import { IStackTokens } from "@fluentui/react";
import * as GenUtil from "./GenUtil2";
import { Settings } from "./ConfigData";


export default class Consts {
  // use this for helpful intellisense, type "Consts" and it comes up to import this file, then change it to "import * as Consts from [...]"
}


export const version: string = "1.0.20240403.2"; // #changeme

export const cacheEnabled: boolean = false; // #changeme should be false
export const cacheExpiresMinutes: number = 15; // good values: 2, 5, 15, 30

export const disableSavingData: boolean = false; // #changeme should be false
export const disableNavAwayWarning: boolean = true; // #changeme should be false

export const redirectUseFluentUI: boolean = false;
export const redirectBackToListAfterSave: boolean = false;

export const showMounted: boolean = true; // #changeme should be false, show the componentdidmount message in console for all components

export const showAccessDeniedWFSection: boolean = true;

export const enableMsalLogging: boolean = false; // #changeme should be false

export const msalAuthorityUri: string = 'https://login.microsoftonline.com/';

export const sleepMsSignIn: number = 1000;

export const sleepMsAjax: number = 100;

export const stackTokens: IStackTokens = {
  childrenGap: 10
};

export const graphMaxAllItemsCount: number = 5000; // max records to request in odata using "?top=5000", use this for loading global lookup data
export const graphMaxForPickerCount: number = 25; // max records to request in odata using "?top=50", use this for loading picker results

export const graphMinCharsForSearch: number = 4; // make this '4', min number of chars to enter to start Picker search (NOTE: Link/BCM have connection IDs for some projects that are very short, so special rules in that form)

export const toastDismissShort: number = 10; // in seconds
export const toastDismissMedium: number = 30; // in seconds
export const toastDismissLong: number = 1000; // in seconds

export const vendorOtherLabel: string = '--Other--'; // the pattern to find when removing whitespace, case DOES matter

export const maxLengthComments: number = 2500;


// ============================

/** Checking if specific AzureAD tentant id. */
export function isDevTenant() {
  return GenUtil.eq(Settings().AppRegTenantId, '911ec695-528e-4cdd-830a-d6f882d9c93e');
}


/** Checking if hosting url is 'localhost:3000'. */
export function isWorkbench(): boolean {
  let url: string = (window as any).location.href;
  if (GenUtil.contains(url, '://localhost:3000/')) // ex: react dev env is "localhost:3000"
    return true;
  else
    return false;
}


/** Return TRUE if not the prod URL (i.e. in orig dev tenant, in workbench/vscode mode, or in hosted azure dev env checking path has "-dev."). */
export function isDevEnv(): boolean {
  return isDevTenant() || isWorkbench() || GenUtil.contains(GenUtil.getLocationInfo(), "-dev.");
}


// #changeme common admin overrides, this should be [false] for PROD
const bigAdmOverride: boolean = false;
// const bigAdmOverride: boolean = true;


export const admNamesWhiteList: string[] = ['bsteinhaus', 'kdcastillo', 'jvkuijk', 'astreaman', 'asokolov']; // adm mode is only avail for these users, this check is done before impersonating too
export const admNamesWhiteList2: string[] = ['kdcastillo', 'jvkuijk', 'astreaman', 'asokolov']; // same list, but remove bstein, so he can test situations


export function admOvrShowDebugInfo(): boolean {
  // show debug info for entire form and sections
  // ex: admOvrShowDebugInfo=1
  if (bigAdmOverride) return true;
  return GenUtil.safeToBool(GenUtil.getUrlParameter('admOvrShowDebugInfo'));
}

export function admOvrShowAdminSection(): boolean {
  // show admin section with special command buttons
  // ex: admOvrShowAdminSection=1
  if (bigAdmOverride) return true;
  return GenUtil.safeToBool(GenUtil.getUrlParameter('admOvrShowAdminSection'));
}

export function admOvrAddCurUserToAllRoles(): boolean {
  // add the current username(email address) to all the roles so current user can work on all parts of workflow
  // works with admOvrUsername too (can impersonate another user, and have that other user added into all roles on the fly)
  // ex: admOvrAddCurUserToAllRoles=1
  if (bigAdmOverride) return true;
  return GenUtil.safeToBool(GenUtil.getUrlParameter('admOvrAddCurUserToAllRoles'));
}

export function admOvrShowAllSectionsFields(): boolean {
  // show all the sections and fields that would be usually hidden based on certain AN data/fields
  // ex: admOvrShowAllSectionsFields=1
  return GenUtil.safeToBool(GenUtil.getUrlParameter('admOvrShowAllSectionsFields'));
}

export function admOvrEditMode(): boolean {
  // for existing AN, make the formstatus=DRAFT, so editing is enabled, saving is done through a special button to preserve workflow and real status
  // NOTE: must disable the real save/submit buttons, and only show the special button
  // ex: admOvrEditMode=1
  return GenUtil.safeToBool(GenUtil.getUrlParameter('admOvrEditMode'));
}

export function admOvrUsername(): string {
  // override the username of current user, for overriding custom app permissions (email address string comparisons) (not overriding real identity in azure/graph/sp)
  // ex: admOvrUsername=bsteinhauser@test.onmicrosoft.com
  return GenUtil.getUrlParameter('admOvrUsername');
}
