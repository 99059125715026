
// NEW WAY: using JSON configsettings file in public folder, and JSON transformations in release pipeline

export function Settings(): IConfigSettings {
  return (window as any).ConfigSettings as IConfigSettings;
}

export function Scopes(): any {
  return {
    scopes: Settings().AppRegScopes.split('|')
  };
}

export interface IConfigSettings {
  AppRegTenantId: string;
  AppRegClientId: string;
  AppRegScopes: string;
  HostName: string;

  AppInsightsConnString: string;
  AppInsightsEnabled: string;
  AppInsightsTrackPageView: string;

  SiteRelPathLink: string;
  AttSvcLogicAppUrlLink: string;
  AttSvcSiteAbsUrlLink: string;
  GraphAttsTest1Link: string; // #testing
  ListTitleANNotesLink: string;
  ListTitleANTypesLink: string;
  ListTitleIrishBankHolidaysLink: string;
  ListTitleEntitiesLink: string;
  ListTitleVendorsLink: string;
  ListTitleCurrencyLink: string;
  ListTitleANUsersLink: string;
  ListTitleConnectionsLink: string;
  ListTitleANLegalTitleHolderMapLink: string;
  ListTitleANLinkApproversLink: string;
  ListTitleANServicerSubmittersLink: string;
  ListTitleANCGIDirectorsLink: string;
  ListTitleANRateCardItemsLink: string;
  ListTitleANLegalTrackerLink: string;
  ListTitleANAttachmentsLink: string;
  ListTitleCompletedANsLink: string;
  ListTitleARADetailsLink: string;

  SiteRelPathCabot: string;
  AttSvcLogicAppUrlCabot: string;
  AttSvcSiteAbsUrlCabot: string;
  ListTitleANNotesCabot: string;
  ListTitleANTypesCabot: string;
  ListTitleEntitiesCabot: string;
  ListTitleVendorsCabot: string;
  ListTitleCurrencyCabot: string;
  ListTitleANUsersCabot: string;
  ListTitleConnectionsCabot: string;
  ListTitleANServicerSubmittersCabot: string;
  ListTitleANRateCardItemsCabot: string;
  ListTitleANLegalTrackerCabot: string;
  ListTitleANAttachmentsCabot: string;
  ListTitleCompletedANsCabot: string;
  ListTitleARADetailsCabot: string;

  SiteRelPathFinsolutia: string;
  AttSvcLogicAppUrlFinsolutia: string;
  AttSvcSiteAbsUrlFinsolutia: string;
  ListTitleANNotesFinsolutia: string;
  ListTitleANTypesFinsolutia: string;
  ListTitleEntitiesFinsolutia: string;
  ListTitleVendorsFinsolutia: string;
  ListTitleCurrencyFinsolutia: string;
  ListTitleANUsersFinsolutia: string;
  ListTitleConnectionsFinsolutia: string;
  ListTitleTypeOfCostsFinsolutia: string;

  SiteRelPathAltamira: string;
  ListTitleANNotesAltamira: string;
  ListTitleANTypesAltamira: string;
  ListTitleEntitiesAltamira: string;
  ListTitleVendorsAltamira: string;
  ListTitleLSCaseManagersAltamira: string;
  ListTitleCurrencyAltamira: string;
  ListTitleANUsersAltamira: string;
  ListTitleConnectionsAltamira: string;
  ListTitleTypeOfCostsAltamira: string;

  SiteRelPathCopernicus: string;
  AttSvcLogicAppUrlCopernicus: string;
  AttSvcSiteAbsUrlCopernicus: string;
  ListTitleANNotesCopernicus: string;
  ListTitleANTypesCopernicus: string;
  ListTitleEntitiesCopernicus: string;
  ListTitleVendorsCopernicus: string;
  ListTitleCurrencyCopernicus: string;
  ListTitleANUsersCopernicus: string;
  ListTitleConnectionsCopernicus: string;
  ListTitleTypeOfCostsCopernicus: string;

  SiteRelPathGescobro: string;
  AttSvcLogicAppUrlGescobro: string;
  AttSvcSiteAbsUrlGescobro: string;
  ListTitleANNotesGescobro: string;
  ListTitleANTypesSecuredGescobro: string;
  ListTitleANTypesUnsecuredGescobro: string;
  ListTitleEntitiesGescobro: string;
  ListTitleVendorsGescobro: string;
  ListTitleCurrencyGescobro: string;
  ListTitleANUsersGescobro: string;
  ListTitleConnectionsGescobro: string;
  ListTitleTypeOfCostsGescobro: string;
  ContentTypeIdSecuredGescobro: string;
  ContentTypeIdUnsecuredGescobro: string;

  SiteRelPathTDX: string;
  AttSvcLogicAppUrlTDX: string;
  AttSvcSiteAbsUrlTDX: string;
  ListTitleANNotesTDX: string;
  ListTitleANTypesSecuredTDX: string;
  ListTitleANTypesUnsecuredTDX: string;
  ListTitleEntitiesTDX: string;
  ListTitleVendorsTDX: string;
  ListTitleCurrencyTDX: string;
  ListTitleANUsersTDX: string;
  ListTitleConnectionsTDX: string;
  ListTitleTypeOfCostsTDX: string;
  ContentTypeIdSecuredTDX: string;
  ContentTypeIdUnsecuredTDX: string;

  SiteRelPathIntrum: string;
  AttSvcLogicAppUrlIntrum: string;
  AttSvcSiteAbsUrlIntrum: string;
  ListTitleANNotesIntrum: string;
  ListTitleANTypesSecuredIntrum: string;
  ListTitleANTypesUnsecuredIntrum: string;
  ListTitleEntitiesIntrum: string;
  ListTitleVendorsIntrum: string;
  ListTitleCurrencyIntrum: string;
  ListTitleANUsersIntrum: string;
  ListTitleConnectionsIntrum: string;
  ListTitleTypeOfCostsIntrum: string;
  ContentTypeIdSecuredIntrum: string;
  ContentTypeIdUnsecuredIntrum: string;
}
