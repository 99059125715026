/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import * as GraphHelper from '../Helpers/GraphHelper';
import * as GenUtil from '../Helpers/GenUtil2';
import * as Consts from '../Helpers/Consts';
import * as Config from '../Helpers/ConfigData';
import { msalScopes, msalScopesDefault } from '../Helpers/AuthConfig';
import { GraphBaseResp } from '../Models/GraphBaseResp';
import { FileUpload } from '../Models/AppModels';


/**
 * 
 * NOTE: shared functions across loan servicers
 * these functions should not reference anything specific to any loan servicer
 * 
 */


// export async function getTokenSilent(accounts: AccountInfo[], instance: IPublicClientApplication): Promise<string> {
//   // helper to get the access token (bearer token)
//   let response = await instance.acquireTokenSilent({ ...msalScopes(), account: accounts[0] });
//   return response.accessToken;
// }


/**
 * Return the string access token, or aquire the token using MSAL internals.
 * NOTE: using a scope from the config file to send to MS Graph
*/
export async function getToken(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<string> {
  if (!token) {
    try {
      let response = await instance.acquireTokenSilent({ ...msalScopes(), account: accounts[0] });
      return response.accessToken;
    } catch (error) {
      console.error("ERROR @ GraphDataService.getToken: instance.acquireTokenSilent failed", error);
      throw error;
    }
  }
  else {
    return token;
  }
}


/**
 * Return the string access token, or aquire the token using MSAL internals.
 * NOTE: using a different scope to get a clean AAD token to send to the logicapp
 */
export async function getTokenDefault(accounts: AccountInfo[], instance: IPublicClientApplication): Promise<string> {
  try {
    let response = await instance.acquireTokenSilent({ ...msalScopesDefault(), account: accounts[0] });
    return response.accessToken;
  } catch (error) {
    console.error("ERROR @ GraphDataService.getTokenDefault: instance.acquireTokenSilent failed", error);
    throw error;
  }
}



//=========================================


function buildGraphBaseUri(sitePath: string) {

  let a = `https://graph.microsoft.com/v1.0/sites/${Config.Settings().HostName}`;

  let b = sitePath.trim().length === 0 ?
    '' :
    `:${encodeURIComponent(sitePath)}:`;

  return `${a}${b}`;
}


/**
 * Create SPItem.
 * Payload expected = { "fields": { "Title": "test", ... } }
 */
export async function insertItem(accounts: AccountInfo[], instance: IPublicClientApplication,
  siteRelPath: string,
  listName: string,
  payload: any
): Promise<GraphBaseResp> {

  /**
   * NOTE: the field properties must be wrapped in an object
   * 
      POST https://graph.microsoft.com/v1.0/sites/{site-id}/lists/{list-id}/items
      Content-Type: application/json

      {
        "fields": {
          "Title": "Widget",
          "Color": "Purple",
          "Weight": 32
        }
      }
   * 
   */

  let tok = await getToken(accounts, instance);

  let url = `${buildGraphBaseUri(siteRelPath)}/lists/${listName}/items`;

  let data = await GraphHelper.postAsync(url, tok, payload, 'POST');

  return data;
}


/**
 * Update SPItem.
 * Payload expected = { "Title": "test", ... }
 */
export async function updateItem(accounts: AccountInfo[], instance: IPublicClientApplication,
  siteRelPath: string,
  listName: string,
  itemId: number | string,
  payload: any
): Promise<GraphBaseResp> {

  /**
   * NOTE: the field properties are NOT wrapped in an object
   * 
      PATCH https://graph.microsoft.com/v1.0/sites/{site-id}/lists/{list-id}/items/{item-id}/fields
      Content-Type: application/json

      {
          "Color": "Fuchsia",
          "Quantity": 934
      }
   * 
   */

  let tok = await getToken(accounts, instance);

  let url = `${buildGraphBaseUri(siteRelPath)}/lists/${listName}/items/${itemId}/fields`;

  let data = await GraphHelper.postAsync(url, tok, payload, 'PATCH');

  return data;
}



/**
 * SPECIAL CASE: copied from "updateItem" to set the contenttypeid for a new item created.
 * NOTE: this is needed because the CTID cannot be set on itemcreated, only on itemupdated, in sponline via graph.
 */
export async function updateItemCtId(accounts: AccountInfo[], instance: IPublicClientApplication,
  siteRelPath: string,
  listName: string,
  itemId: number | string,
  ctId: any
): Promise<GraphBaseResp> {

  let tok = await getToken(accounts, instance);

  let url = `${buildGraphBaseUri(siteRelPath)}/lists/${listName}/items/${itemId}`;

  let data = await GraphHelper.postAsync(url, tok, {
    "fields": {
      "ContentTypeId": ctId
    }
  }, 'PATCH');

  return data;
}



/**
 * Delete SPItem.
 */
export async function deleteItem(accounts: AccountInfo[], instance: IPublicClientApplication,
  siteRelPath: string,
  listName: string,
  itemId: number | string
): Promise<GraphBaseResp> {

  // DELETE https://graph.microsoft.com/v1.0/sites/test.sharepoint.com:/sites/CerberusPOC1:/lists/ANAttachments1/items/9

  let tok = await getToken(accounts, instance);

  let url = `${buildGraphBaseUri(siteRelPath)}/lists/${listName}/items/${itemId}`;

  let data = await GraphHelper.postAsync(url, tok, null, 'DELETE');

  return data;
}


//=========================================


/**
 * Create SPFile upload session with Graph, to upload larger than 4MB files.
 */
export async function createUploadSession(accounts: AccountInfo[], instance: IPublicClientApplication,
  graphSiteListUrl: string,
  fileName: string,
  conflictBehavior: string = 'rename'
): Promise<GraphBaseResp> {

  // using Graph to upload files larger than 4MB to a SharePoint library

  /**
   * 
   * can use this to get the SiteWebList ids and path elements:
   *   https://graph.microsoft.com/v1.0/sites/test.sharepoint.com:/sites/CerberusPOC1:/lists/ANAttachments1
   *   will return the listid in id, and the tenant/siteid/webid in parentReference.siteId
   * can use this query in graph explorer to get the driveId from the SPList:
   *   https://graph.microsoft.com/v1.0/sites/test.sharepoint.com:/sites/CerberusPOC1/finsolutia1:/lists/Documents/drive
   * then when we have the driveId, can get the drive root folder Id:
   *   https://graph.microsoft.com/v1.0/drives/b!PmC4208Tr0yDWfPbd2nnp4MmjAghdJVDl7igW4omQbw7rXrECe_9RaEiB18qdgQl/root
   * and get the items(files/folders) in the root folder:
   *   https://graph.microsoft.com/v1.0/drives/b!PmC4208Tr0yDWfPbd2nnp4MmjAghdJVDl7igW4omQbw7rXrECe_9RaEiB18qdgQl/root/children
   * 
   */

  let tok = await getToken(accounts, instance);

  let url = `${graphSiteListUrl}/drive/root:/${encodeURIComponent(fileName)}:/createUploadSession`;

  let payload: any = {
    "item": {
      "@microsoft.graph.conflictBehavior": conflictBehavior // options are: fail | replace | rename  =>  (default=fail)
    },
    "deferCommit": false // note: If set to true, the final creation of the file in the destination will require an explicit request
  };

  let data = await GraphHelper.postAsync(url, tok, payload, 'POST');

  return data;
}


/**
 * Upload file blob to SharePoint using temp url from create session.
 */
export async function uploadFile(
  url: string,
  file: FileUpload
): Promise<GraphBaseResp> {

  // using the upload url provided from "createUploadSession", use a regular PUT request to upload the file, no auth needed, the upload url will be a temporary url

  let resp = await fetch(url, {
    "method": "PUT",
    "headers": {
      "Content-Length": `${file.size}`,
      "Content-Range": `bytes 0-${file.size - 1}/${file.size}`
    },
    "body": (file as any)
  });

  let data = await resp.json();

  return {
    ...data,
    httpStatus: resp.status,
    httpStatusText: resp.statusText
  };
}


/**
 * Get listitem data about the drive item just uploaded using Graph.
 */
export async function getDriveItemListItem(accounts: AccountInfo[], instance: IPublicClientApplication,
  graphSiteListUrl: string,
  driveItemId: string
): Promise<GraphBaseResp> {

  // once a file is uploaded, use the returned DriveItemId to get the SharePoint Listitem info (including its ID), so updates can be made to the SPListItem (like setting ANNoteID foreign key)

  let tok = await getToken(accounts, instance);

  let url = `${graphSiteListUrl}/drive/items/${driveItemId}/listItem`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


/**
 * Get basic drive info about the root folder using Graph.
 */
export async function getDriveInfo(accounts: AccountInfo[], instance: IPublicClientApplication,
  graphSiteListUrl: string,
  suffix: string = ""
): Promise<GraphBaseResp> {

  let tok = await getToken(accounts, instance);

  let url = `${graphSiteListUrl}/drive/root${suffix}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}
