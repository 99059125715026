/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';

import * as StaticData from "../../../StaticData/Link/StaticData";
import { AssetInfoItem } from '../../../Models/Link/GridModels/AssetInfo1';


export interface IAssetInfoRowProps {
  data: AssetInfoItem;
  handleDeleteItem: (id: string) => void;
  isReadOnly: boolean;
  saving: boolean;
}

export const AssetInfoRow: React.FunctionComponent<IAssetInfoRowProps> = (props: React.PropsWithChildren<IAssetInfoRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: AssetInfoRow]");
  }, []);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.data.rpt_AssetID);
  }


  return (
    <>

      <tr className={`sep ${GenUtil.safeToBool(props.data.rpt_AssetStatus) ? "assetinfo-tr-y" : "assetinfo-tr-n"}`}>

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_ConnId}
            </td>
          )
        }

        <td>
          {props.data.rpt_AssetID}
        </td>

        <td>
          {props.data.rpt_AssetName}
        </td>

        <td>
          {props.data.rpt_REValueDisp}
        </td>

        <td>
          {props.data.rpt_RECurrencyDisp}
        </td>

        <td style={{ maxWidth: 25, textAlign: 'right' }}>
          {
            !props.isReadOnly && (
              <>
                <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
              </>
            )
          }
        </td>

      </tr>

      <tr className={`${GenUtil.safeToBool(props.data.rpt_AssetStatus) ? "assetinfo-tr-y" : "assetinfo-tr-n"}`}>

        {Consts.isWorkbench() && (
          <td></td>
        )}

        <td colSpan={5}>
          <Stack tokens={Consts.stackTokens} horizontal className='asset-info-address-row'>
            <Label>{`Asset Address:`}</Label>
            <Label className='ms-fontWeight-regular' style={{ minWidth: '200px' }}>{props.data.rpt_AssetAddress}</Label>
            <Label style={{ paddingLeft: 15 }}>{`Asset Status - Sold?`}</Label>
            <Label className='ms-fontWeight-regular'>{props.data.rpt_AssetStatus}</Label>
            <Label style={{ paddingLeft: 15 }}>{`Enforcement Status:`}</Label>
            <Label className='ms-fontWeight-regular'>{props.data.rpt_AssetStrategy}</Label>
          </Stack>
        </td>

      </tr>

    </>
  );
};