/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { ProposedCostsRow } from './ProposedCostsRow';

import { ProposedCostsDetails1, ProposedCostsDetailsItem } from '../../../Models/Cabot/GridModels/ProposedCostsDetails1';
import { ProposedCostsDetailsN } from '../../../Models/Cabot/GridModels/ProposedCostsDetailsN';
import { ANVendor } from '../../../Models/Cabot/ANVendor';
import { ANRateCardItem } from '../../../Models/Cabot/ANRateCardItem';
import { ANType } from '../../../Models/Cabot/ANType';


export interface IProposedCostsBodyProps {
  data: string | undefined;
  vendors: ANVendor[];
  rateCardItems: ANRateCardItem[];
  selANSubType: ANType | null;
  onDataUpdated: (s: string, d: any) => void;
  onTotalUpdated: (s: string, d: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ProposedCostsBody: React.FunctionComponent<IProposedCostsBodyProps> = (props: React.PropsWithChildren<IProposedCostsBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposedCostsBody]");
  }, []);


  const [stateRows, setStateRows] = useState<ProposedCostsDetailsItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('propcosts', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: ProposedCostsDetailsItem[] = [];

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ProposedCosts', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as ProposedCostsDetails1).RepeaterData.Items.Item;

          let item: ProposedCostsDetailsItem = {
            rpt_PropCostRowNumber: GenUtil.generateGuid(),
            dd_Vendors: GenUtil.safeTrim(AppHelper.getText(_t.dd_Vendors)),
            rpt_VendorsValue: GenUtil.safeTrim(AppHelper.getText(_t.rpt_VendorsValue)),
            rpt_decimal_Costs: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_Costs)),
            rpt_CostsCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CostsCurrency)),
            rpt_Description: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Description)),
            rpt_RateCard: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RateCard)),
            rpt_RCType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCType)),
            cv_RCTypeId: GenUtil.safeTrim(AppHelper.getText(_t.cv_RCTypeId)),
            rpt_RCInstrType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCInstrType)),
            cv_RCInstrTypeId: GenUtil.safeTrim(AppHelper.getText(_t.cv_RCInstrTypeId)),
            rpt_RCAmount: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCAmount)),
            rpt_RCQuantity: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCQuantity)),
            rpt_RCTotal: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCTotal)),
            rpt_RCCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCCurrency)),
            rpt_PaymentMethod: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PaymentMethod)),
            rpt_PaidByReceiver: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PaidByReceiver)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.dd_Vendors).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as ProposedCostsDetailsN;

          let _col: ProposedCostsDetailsItem[] = _t.RepeaterData.Items.Item.map((_t, i) => {
            return {
              rpt_PropCostRowNumber: GenUtil.generateGuid(),
              dd_Vendors: GenUtil.safeTrim(AppHelper.getText(_t.dd_Vendors)),
              rpt_VendorsValue: GenUtil.safeTrim(AppHelper.getText(_t.rpt_VendorsValue)),
              rpt_decimal_Costs: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_Costs)),
              rpt_CostsCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CostsCurrency)),
              rpt_Description: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Description)),
              rpt_RateCard: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RateCard)),
              rpt_RCType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCType)),
              cv_RCTypeId: GenUtil.safeTrim(AppHelper.getText(_t.cv_RCTypeId)),
              rpt_RCInstrType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCInstrType)),
              cv_RCInstrTypeId: GenUtil.safeTrim(AppHelper.getText(_t.cv_RCInstrTypeId)),
              rpt_RCAmount: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCAmount)),
              rpt_RCQuantity: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCQuantity)),
              rpt_RCTotal: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCTotal)),
              rpt_RCCurrency: GenUtil.safeTrim(AppHelper.getText(_t.rpt_RCCurrency)),
              rpt_PaymentMethod: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PaymentMethod)),
              rpt_PaidByReceiver: GenUtil.safeTrim(AppHelper.getText(_t.rpt_PaidByReceiver)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.dd_Vendors));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in ProposedCostsBody`, props.data, error); }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        rpt_PropCostRowNumber: GenUtil.generateGuid(),
        dd_Vendors: '',
        rpt_VendorsValue: '',
        rpt_decimal_Costs: '',
        rpt_CostsCurrency: '',
        rpt_Description: '',
        rpt_RateCard: 'No',
        rpt_RCType: '',
        cv_RCTypeId: '',
        rpt_RCInstrType: '',
        cv_RCInstrTypeId: '',
        rpt_RCAmount: '',
        rpt_RCQuantity: '',
        rpt_RCTotal: '',
        rpt_RCCurrency: '',
        rpt_PaymentMethod: '',
        rpt_PaidByReceiver: '',
      }];
    }

    setStateRows(col);

  }, [props.data])


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        rpt_PropCostRowNumber: GenUtil.generateGuid(),
        dd_Vendors: '',
        rpt_VendorsValue: '',
        rpt_decimal_Costs: '',
        rpt_CostsCurrency: '',
        rpt_Description: '',
        rpt_RateCard: 'No',
        rpt_RCType: '',
        cv_RCTypeId: '',
        rpt_RCInstrType: '',
        cv_RCInstrTypeId: '',
        rpt_RCAmount: '',
        rpt_RCQuantity: '',
        rpt_RCTotal: '',
        rpt_RCCurrency: '',
        rpt_PaymentMethod: '',
        rpt_PaidByReceiver: '',
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];

      if (GenUtil.eq(fieldName, 'Vendor')) {
        if (!!fieldVal) {
          o.dd_Vendors = GenUtil.safeTrim(fieldVal.key);
          o.rpt_VendorsValue = GenUtil.safeTrim(fieldVal.name);
        }
        else {
          o.dd_Vendors = '';
          o.rpt_VendorsValue = '';
        }
      }
      else if (GenUtil.eq(fieldName, 'Cost')) o.rpt_decimal_Costs = GenUtil.numberToCurrency(GenUtil.safeToNumber(fieldVal, 2));
      else if (GenUtil.eq(fieldName, 'CostsCurr')) o.rpt_CostsCurrency = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'Descr')) o.rpt_Description = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'RateCard')) o.rpt_RateCard = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'RCType')) o.rpt_RCType = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'RCTypeId')) o.cv_RCTypeId = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'RCInstrType')) o.rpt_RCInstrType = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'RCInstrTypeId')) o.cv_RCInstrTypeId = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'RCAmount')) o.rpt_RCAmount = GenUtil.safeTrim(fieldVal); // from memo, already formatted
      else if (GenUtil.eq(fieldName, 'RCQuantity')) o.rpt_RCQuantity = GenUtil.safeTrim(fieldVal); // from user input, trim this up
      else if (GenUtil.eq(fieldName, 'RCTotal')) o.rpt_RCTotal = GenUtil.safeTrim(fieldVal); // from memo, calc, already formatted
      else if (GenUtil.eq(fieldName, 'RCCurrency')) o.rpt_RCCurrency = GenUtil.safeTrim(fieldVal); // from lookup, already trimmed

      else if (GenUtil.eq(fieldName, 'PaymentMethod')) o.rpt_PaymentMethod = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'PaidByReceiver')) o.rpt_PaidByReceiver = GenUtil.safeTrim(fieldVal);

      return t;
    });
  }


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  function isConsistentCurrencies() {
    if (stateRows.length <= 0)
      return false;
    if (stateRows.length === 1)
      return true;
    else {
      let result = true;
      let prev = "";

      stateRows.forEach(o => {
        // determine which currency to use (Rate Card = Yes, use lookup value, otherwise use DDL)
        let currency = GenUtil.safeToBool(o.rpt_RateCard) ? o.rpt_RCCurrency : o.rpt_CostsCurrency;

        if (GenUtil.isNull(currency)) {
          result = false;
        }
        else if (prev !== "" && !GenUtil.eq(currency, prev)) {
          result = false;
        }
        prev = currency;
      });

      return result;
    }
  }


  const memoIsConsistentCurrencies = useMemo<boolean>(() => {
    return isConsistentCurrencies();
  }, [stateRows]);


  const memoSumTotCosts = useMemo<number>(() => {
    if (!memoIsConsistentCurrencies) return 0;
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToBool(o.rpt_RateCard) ? GenUtil.safeToNumber(o.rpt_RCTotal) : GenUtil.safeToNumber(o.rpt_decimal_Costs));
    return tot;
  }, [stateRows, memoIsConsistentCurrencies]);


  useEffect(() => {
    props.onTotalUpdated('PropCosts', memoSumTotCosts);
  }, [memoSumTotCosts]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3'>

          {/* <thead>

            <tr>
              <th>&nbsp;</th>
              <th>{`Vendors`}</th>
              <th>{`Proposed Costs`}</th>
              <th>{`Costs Currency`}</th>
              <th>{`Rate Card`}</th>
              <th></th>
            </tr>

          </thead> */}

          <tbody>

            {
              stateRows.map((o, i) =>
                <ProposedCostsRow
                  key={o.rpt_PropCostRowNumber}
                  data={o}
                  vendors={props.vendors}
                  rateCardItems={props.rateCardItems}
                  selANSubType={props.selANSubType}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving} />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  <td colSpan={1}>
                    <Stack tokens={Consts.stackTokens} horizontal>
                      <Label className='nowrap'>{`Total Costs Excluding VAT (CCY):`}</Label>
                      {
                        memoIsConsistentCurrencies ? (
                          <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumTotCosts)}</Label>
                        ) : (
                          <MessageBar messageBarType={MessageBarType.warning} className=''>
                            {`Total unavailable, inconsistent currencies selected.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton disabled={props.saving} iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow}>Add a new row</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};