/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { LitigationMatrixRow } from './LitigationMatrixRow';
import { LitigationMatrix1, LitigationMatrixItem } from '../../../Models/Cabot/GridModels/LitigationMatrix1';
import { LitigationMatrixN } from '../../../Models/Cabot/GridModels/LitigationMatrixN';


export interface ILitigationMatrixBodyProps {
  data: string | undefined;
  onDataUpdated: (s: string, d: any) => void;
  onTotalUpdated: (s: string, d: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const LitigationMatrixBody: React.FunctionComponent<ILitigationMatrixBodyProps> = (props: React.PropsWithChildren<ILitigationMatrixBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: LitigationMatrixBody]");
  }, []);


  const [stateRows, setStateRows] = useState<LitigationMatrixItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('litigationmatrix', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: LitigationMatrixItem[] = [];

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('LitigationMatrix', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as LitigationMatrix1).RepeaterData.Items.Item;

          let item: LitigationMatrixItem = {
            cv_RowNum: GenUtil.generateGuid(),
            rpt_LMProceedings: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMProceedings)),
            rpt_LMLitigationReference: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMLitigationReference)),
            rpt_LMRecordNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMRecordNumber)),
            rpt_LMLegalBudget: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMLegalBudget)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_LMLitigationReference).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as LitigationMatrixN;

          let _col: LitigationMatrixItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {
              cv_RowNum: GenUtil.generateGuid(),
              rpt_LMProceedings: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMProceedings)),
              rpt_LMLitigationReference: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMLitigationReference)),
              rpt_LMRecordNumber: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMRecordNumber)),
              rpt_LMLegalBudget: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LMLegalBudget)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.rpt_LMLitigationReference));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in LitigationMatrix`, props.data, error); }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        cv_RowNum: GenUtil.generateGuid(),
        rpt_LMProceedings: '',
        rpt_LMLitigationReference: '',
        rpt_LMRecordNumber: '',
        rpt_LMLegalBudget: ''
      }];
    }

    setStateRows(col);

  }, [props.data]);


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        cv_RowNum: GenUtil.generateGuid(),
        rpt_LMProceedings: '',
        rpt_LMLitigationReference: '',
        rpt_LMRecordNumber: '',
        rpt_LMLegalBudget: ''
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];
      if (GenUtil.eq(fieldName, 'LitRef')) o.rpt_LMLitigationReference = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'Proceedings')) o.rpt_LMProceedings = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'RecNum')) o.rpt_LMRecordNumber = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'LegalBudget')) o.rpt_LMLegalBudget = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      return t;
    });
  }


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  const memoSumLegalBudget = useMemo<number>(() => {
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToNumber(o.rpt_LMLegalBudget));
    return tot;
  }, [stateRows]);

  useEffect(() => {
    props.onTotalUpdated('LitigationMatrix', memoSumLegalBudget);
  }, [memoSumLegalBudget]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3'>
          <thead>

            <tr>
              <th>{`Litigation Reference`}</th>
              <th>{`Proceedings`}</th>
              <th>{`Record Number`}</th>
              <th>{`Legal Budget (€)`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <LitigationMatrixRow
                  key={o.cv_RowNum}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving} />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  <td style={{ textAlign: 'right' }} colSpan={3}>
                    <Label>{`Total (€):`}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumLegalBudget)}</Label>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton disabled={props.saving} iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow}>Add a new row</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};