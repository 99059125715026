/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { AssetLevelMatrixTwoItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixTwo1';


export interface IALM2RowProps {
  data: AssetLevelMatrixTwoItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ALM2Row: React.FunctionComponent<IALM2RowProps> = (props: React.PropsWithChildren<IALM2RowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM2Row]");
  }, []);


  // user controlled input
  const [stateAssetId, setStateAssetId] = useState(props.data.rpt_ALM2_AssetID);
  const onChangeAssetId = useCallback((evt: any, v?: string) => { setStateAssetId(v || ''); }, []);

  const [stateReserve, setStateReserve] = useState(props.data.rpt_decimal_ALM2_Reserve);
  const onChangeReserve = useCallback((evt: any, v?: string) => { setStateReserve(v || ''); }, []);

  const [stateEstNetSales, setStateEstNetSales] = useState(props.data.rpt_decimal_ALM2_EstNetSalesProceeds);
  const onChangeEstNetSales = useCallback((evt: any, v?: string) => { setStateEstNetSales(v || ''); }, []);

  const [stateBPGdp, setStateBPGdp] = useState(props.data.rpt_decimal_ALM2_BPGDP);
  const onChangeBPGdp = useCallback((evt: any, v?: string) => { setStateBPGdp(v || ''); }, []);

  const [stateBPNet, setStateBPNet] = useState(props.data.rpt_decimal_ALM2_BPNet);
  const onChangeBPNet = useCallback((evt: any, v?: string) => { setStateBPNet(v || ''); }, []);

  const [stateBPExitDate, setStateBPExitDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_ALM2_BPExitDate));

  const [stateComments, setStateComments] = useState(props.data.rpt_ALM2_Comments);
  const onChangeComments = useCallback((evt: any, v?: string) => { setStateComments(v || ''); }, []);


  // send field updates back to parent
  useEffect(() => {
    props.updateFieldVal(props.counter, 'AssetId', GenUtil.safeTrim(stateAssetId));
  }, [stateAssetId]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'Reserve', GenUtil.safeTrim(stateReserve));
  }, [stateReserve]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'EstNetSales', GenUtil.safeTrim(stateEstNetSales));
  }, [stateEstNetSales]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'BPGdp', GenUtil.safeTrim(stateBPGdp));
  }, [stateBPGdp]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'BPNet', GenUtil.safeTrim(stateBPNet));
  }, [stateBPNet]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.counter, 'BPExitDate', stateBPExitDate);
  }, [stateBPExitDate]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'Comments', GenUtil.safeTrim(stateComments));
  }, [stateComments]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //#region 'validation'
  //-------------------------

  const memoIsErrAssetId = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrAssetId();
  }, [props.stateFormSubmitted, stateAssetId]);

  function isErrAssetId() {
    return GenUtil.isNull(stateAssetId);
  }


  const memoIsErrBPGdp = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrBPGdp();
  }, [props.stateFormSubmitted, stateBPGdp]);

  function isErrBPGdp() {
    return GenUtil.safeToNumberOrNull(stateBPGdp) == null ? true : false;
  }


  const memoIsErrBPNet = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrBPNet();
  }, [props.stateFormSubmitted, stateBPNet]);

  function isErrBPNet() {
    return GenUtil.safeToNumberOrNull(stateBPNet) == null ? true : false;
  }


  const memoIsErrEstNetSales = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrEstNetSales();
  }, [props.stateFormSubmitted, stateEstNetSales]);

  function isErrEstNetSales() {
    return GenUtil.safeToNumberOrNull(stateEstNetSales) == null ? true : false;
  }


  const memoIsErrReserve = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrReserve();
  }, [props.stateFormSubmitted, stateReserve]);

  function isErrReserve() {
    return GenUtil.safeToNumberOrNull(stateReserve) == null ? true : false;
  }


  const memoIsErrBPExitDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrBPExitDate();
  }, [props.stateFormSubmitted, stateBPExitDate]);

  function isErrBPExitDate() {
    return !(stateBPExitDate);
  }

  //#endregion


  return (
    <>

      <tr className='sep'>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeAssetId} value={stateAssetId} placeholder='Please enter text here' className='' errorMessage={memoIsErrAssetId ? 'Value is required.' : ''} maxLength={255} />
            ) : (
              stateAssetId
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeReserve} value={stateReserve} placeholder='Please enter text here' className='' errorMessage={memoIsErrReserve ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              stateReserve
            )
          }
        </td>

        <td>  {
          !props.isReadOnly ? (
            <TextField onChange={onChangeEstNetSales} value={stateEstNetSales} placeholder='Please enter text here' className='' errorMessage={memoIsErrEstNetSales ? 'Number is required.' : ''} maxLength={255} />
          ) : (
            stateEstNetSales
          )
        }

        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeBPGdp} value={stateBPGdp} placeholder='Please enter text here' className='' errorMessage={memoIsErrBPGdp ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              stateBPGdp
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeBPNet} value={stateBPNet} placeholder='Please enter text here' className='' errorMessage={memoIsErrBPNet ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              stateBPNet
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <Stack tokens={{ childrenGap: 4 }}>
                <DatePicker
                  formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                  placeholder="Select a date"
                  value={stateBPExitDate}
                  onSelectDate={setStateBPExitDate as (date: Date | null | undefined) => void}
                  allowTextInput
                  className={`w175 ${(memoIsErrBPExitDate ? "invalid-datepicker" : "")}`}
                />
                {
                  memoIsErrBPExitDate && (
                    <Label className='red-msg-text clr'>Date is required</Label>
                  )
                }
              </Stack>
            ) : (
              GenUtil.getCalDate(stateBPExitDate)
            )
          }
        </td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            props.totCount > 1 && !props.isReadOnly && (
              <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>

      </tr>

      <tr>

        <td colSpan={6}>
          {
            !props.isReadOnly ? (
              <Stack tokens={Consts.stackTokens} horizontal>
                <Label>Comments:</Label>
                <TextField onChange={onChangeComments} value={stateComments} placeholder='Please enter text here' className='w100p' multiline rows={2} maxLength={255} />
              </Stack>
            ) : (
              <Stack tokens={Consts.stackTokens} horizontal>
                <Label>Comments:</Label>
                <Label className='ms-fontWeight-regular'>{stateComments}</Label>
              </Stack>
            )
          }
        </td>

        <td></td>

      </tr>

    </>
  );
};