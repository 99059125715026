/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { usePrevious } from '../../../Helpers/UseHooksDotCom';

import { BorLoanBorItem, BorLoanLoanItem } from '../../../Models/Cabot/GridModels/BorLoan';
import { OtherNPLevel1, OtherNPLevelItem } from '../../../Models/Cabot/GridModels/OtherNPLevel1';
import { OtherNPLevelN } from '../../../Models/Cabot/GridModels/OtherNPLevelN';
import { ARADetailItem } from '../../../Models/Cabot/ARADetail';

import { OtherNPLevelRow } from './OtherNPLevelRow';


export interface IOtherNPLevelBodyProps {
  xml: string | undefined;
  loans: BorLoanBorItem[];
  loansAsJson: string; // NOTE: used to force a render when the deep collection changes (loan col in bor col)
  araDetails: ARADetailItem[];
  onDataUpdated: (s: string, d: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const OtherNPLevelBody: React.FunctionComponent<IOtherNPLevelBodyProps> = (props: React.PropsWithChildren<IOtherNPLevelBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: OtherNPLevelBody]");
  }, []);


  const [stateSavedData, setStateSavedData] = useState<OtherNPLevelItem[]>([]);

  useEffect(() => {
    let col = loadDataFromXML();
    setStateSavedData(col);
  }, [props.xml]);


  const [stateRows, setStateRows] = useState<OtherNPLevelItem[]>([]);

  useEffect(() => {
    props.onDataUpdated('OtherNPLevel', stateRows);
  }, [stateRows]);


  const [stateFlag1, setStateFlag1] = useState<Boolean>(false);

  useEffect(() => {
    // after N seconds (after this component loads!), set this flag, after this flag is set we no longer restore the data from saved xml from SP
    let ignore = false;
    setTimeout(() => {
      if (!ignore) {
        setStateFlag1(true);
        console.log('Timer: [OtherNPLevelBody]: setting [Flag1] to [true]');
      }
    }, 1000 * 8);
    return () => { ignore = true; }
  }, []);


  function loadDataFromXML(): OtherNPLevelItem[] {

    let col: OtherNPLevelItem[] = [];

    if (!!props.xml && !GenUtil.isNull(props.xml) && !GenUtil.isInt(props.xml)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('OtherNPLevel', props.xml);

        if (!AppHelper.xmlHasMultipleItems(props.xml)) {
          let _t = (obj as OtherNPLevel1).RepeaterData.Items.Item;

          let item: OtherNPLevelItem = {

            cv_RowNum: GenUtil.generateGuid(),
            rpt_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LoanID)),
            cv_NotApplicableChecked: GenUtil.safeTrim(AppHelper.getText(_t.cv_NotApplicableChecked)),
            rpt_CommittedOption: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CommittedOption)),
            rpt_StartDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_StartDate)),
            cv_StartDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_StartDate)),
            rpt_EndDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_EndDate)),
            cv_EndDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_EndDate)),
            rpt_Capatilised: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Capatilised)),
            rpt_ARABroken: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ARABroken)),
            rpt_MonthlyARAPayment: GenUtil.safeTrim(AppHelper.getText(_t.rpt_MonthlyARAPayment)),
            rpt_DetailOnARA: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DetailOnARA)),
            rpt_WarehouseSplitAmt: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WarehouseSplitAmt)),
            rpt_WritedownAmt: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WritedownAmt)),
            rpt_WritedownDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WritedownDate)),
            cv_WritedownDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_WritedownDate)),
            rpt_TermExtensionDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_TermExtensionDate)),
            cv_TermExtensionDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_TermExtensionDate)),
            rpt_UpdatedInterestDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_UpdatedInterestDate)),
            cv_UpdatedInterestDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_UpdatedInterestDate)),
            rpt_UpdatedInterestRate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_UpdatedInterestRate)),
            rpt_InterestRateType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_InterestRateType)),

          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_LoanID).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as OtherNPLevelN;

          let _col: OtherNPLevelItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {

              cv_RowNum: GenUtil.generateGuid(),
              rpt_LoanID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LoanID)),
              cv_NotApplicableChecked: GenUtil.safeTrim(AppHelper.getText(_t.cv_NotApplicableChecked)),
              rpt_CommittedOption: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CommittedOption)),
              rpt_StartDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_StartDate)),
              cv_StartDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_StartDate)),
              rpt_EndDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_EndDate)),
              cv_EndDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_EndDate)),
              rpt_Capatilised: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Capatilised)),
              rpt_ARABroken: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ARABroken)),
              rpt_MonthlyARAPayment: GenUtil.safeTrim(AppHelper.getText(_t.rpt_MonthlyARAPayment)),
              rpt_DetailOnARA: GenUtil.safeTrim(AppHelper.getText(_t.rpt_DetailOnARA)),
              rpt_WarehouseSplitAmt: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WarehouseSplitAmt)),
              rpt_WritedownAmt: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WritedownAmt)),
              rpt_WritedownDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_WritedownDate)),
              cv_WritedownDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_WritedownDate)),
              rpt_TermExtensionDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_TermExtensionDate)),
              cv_TermExtensionDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_TermExtensionDate)),
              rpt_UpdatedInterestDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_UpdatedInterestDate)),
              cv_UpdatedInterestDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_UpdatedInterestDate)),
              rpt_UpdatedInterestRate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_UpdatedInterestRate)),
              rpt_InterestRateType: GenUtil.safeTrim(AppHelper.getText(_t.rpt_InterestRateType)),

            } as OtherNPLevelItem
          });

          // only add if the object is not nintex empty xml record
          col = _col.filter(o => !GenUtil.isNull(o.rpt_LoanID));
        }

      } catch (error) { console.warn(`Error parsing Xml in OtherNPLevel`, props.xml, error); }
    }
    else {
      col = [];
    }

    return col;
  }



  /**
   * 
   * load the active loans from the BorLoan collection
   * for each active loan found, we need a corresponding row in this section (OtherNPLevel)
   * when new loans are added, or enabled/disabled, then they must be added, removed, and preserved/merged
   * how to get the saved data from prior save?
   * this seems tricky, since this effect will trigger on the loan items changing (from BorLoan section), and also the xml data in a string
   * which could be a race condition
   * but we also need the saved xml merged in
   * we only want the data merged into the empty OtherNPLevel
   * any empty item added to this section can be rehyrated from the prior saved XML
   * but if the corresponding loan is added/removed, or marked active/inactive, then it would get rehydrated
   * i could prevent the rehyrdrating from a different trigger, like only when the saved XML string changes from blank/null, number, string?
   * 
   */




  useEffect(() => {

    // get the active loans from the BorLoan collection
    let loans: BorLoanLoanItem[] = [];

    props.loans.forEach(bo => {
      bo.col_LoanItems.forEach(lo => {
        if (lo.cv_IsActive) {
          loans = [...loans, { ...lo }];
        }
      });
    });


    // convert them to new/empty rows of OtherNPLevel data
    let col = loans.map(x => {
      return {
        cv_RowNum: GenUtil.generateGuid(),
        rpt_LoanID: x.rpt_ServicerLoanID, // #todo which loan id to use? make sure its unique!
        cv_NotApplicableChecked: '',
        rpt_CommittedOption: '',
        rpt_StartDate: '',
        cv_StartDate: '',
        rpt_EndDate: '',
        cv_EndDate: '',
        rpt_Capatilised: '',
        rpt_ARABroken: '',
        rpt_MonthlyARAPayment: '',
        rpt_DetailOnARA: '',
        rpt_WarehouseSplitAmt: '',
        rpt_WritedownAmt: '',
        rpt_WritedownDate: '',
        cv_WritedownDate: '',
        rpt_TermExtensionDate: '',
        cv_TermExtensionDate: '',
        rpt_UpdatedInterestDate: '',
        cv_UpdatedInterestDate: '',
        rpt_UpdatedInterestRate: '',
        rpt_InterestRateType: '',
      } as OtherNPLevelItem;
    });


    // restore saved data into mapped objects
    // only until the flag is set, then do not restore saved data
    if (!stateFlag1) {
      col.forEach(o => {
        let idx = stateSavedData.findIndex(x => GenUtil.eq(x.rpt_LoanID, o.rpt_LoanID));
        if (idx >= 0) {
          let el = stateSavedData[idx];
          o.cv_RowNum = el.cv_RowNum;
          o.rpt_LoanID = el.rpt_LoanID;
          o.cv_NotApplicableChecked = el.cv_NotApplicableChecked;
          o.rpt_CommittedOption = el.rpt_CommittedOption;
          o.rpt_StartDate = el.rpt_StartDate;
          o.cv_StartDate = el.cv_StartDate;
          o.rpt_EndDate = el.rpt_EndDate;
          o.cv_EndDate = el.cv_EndDate;
          o.rpt_Capatilised = el.rpt_Capatilised;
          o.rpt_ARABroken = el.rpt_ARABroken;
          o.rpt_MonthlyARAPayment = el.rpt_MonthlyARAPayment;
          o.rpt_DetailOnARA = el.rpt_DetailOnARA;
          o.rpt_WarehouseSplitAmt = el.rpt_WarehouseSplitAmt;
          o.rpt_WritedownAmt = el.rpt_WritedownAmt;
          o.rpt_WritedownDate = el.rpt_WritedownDate;
          o.cv_WritedownDate = el.cv_WritedownDate;
          o.rpt_TermExtensionDate = el.rpt_TermExtensionDate;
          o.cv_TermExtensionDate = el.cv_TermExtensionDate;
          o.rpt_UpdatedInterestDate = el.rpt_UpdatedInterestDate;
          o.cv_UpdatedInterestDate = el.cv_UpdatedInterestDate;
          o.rpt_UpdatedInterestRate = el.rpt_UpdatedInterestRate;
          o.rpt_InterestRateType = el.rpt_InterestRateType;
        }
      });
    }


    // merge the mapped OtherNPLevel data into the local state
    // new entries are added, but old entries (where the loan was already processed/converted into OtherNPLevel and may have user data) are preserved

    setStateRows(p => {
      let t = [...p];
      let ret: OtherNPLevelItem[] = [];

      col.forEach(o => {
        let idx = t.findIndex(x => GenUtil.eq(x.rpt_LoanID, o.rpt_LoanID));
        if (idx < 0) {
          // not found, add it
          ret = [...ret, { ...o }];
        }
        else {
          // found existing object, keep it
          // NOTE: this is likey the place causing problems for merging changes (from BorLoans selected/loaded, saved xml, and UI manual changes in this comp)
          ret = [...ret, { ...t[idx] }];
        }
      });

      return ret;
    });

  }, [stateSavedData, props.loans, props.loansAsJson]);


  function updateFieldVal(id: string, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let idx = t.findIndex(x => GenUtil.eq(x.rpt_LoanID, id));
      let o = t[idx];

      if (GenUtil.eq(fieldName, 'CommittedOption')) o.rpt_CommittedOption = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'NotApplicable')) o.cv_NotApplicableChecked = GenUtil.boolToHtmlYesNo(GenUtil.safeToBool(fieldVal));

      else if (GenUtil.eq(fieldName, 'MonthlyARAPayment')) o.rpt_MonthlyARAPayment = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'WarehouseSplitAmt')) o.rpt_WarehouseSplitAmt = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'WritedownAmt')) o.rpt_WritedownAmt = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'UpdatedInterestRate')) o.rpt_UpdatedInterestRate = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'InterestRateType')) o.rpt_InterestRateType = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'Capitalised')) o.rpt_Capatilised = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'ARABroken')) o.rpt_ARABroken = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'DetailOnARA')) o.rpt_DetailOnARA = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'StartDate')) {
        o.cv_StartDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_StartDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }
      else if (GenUtil.eq(fieldName, 'EndDate')) {
        o.cv_EndDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_EndDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }
      else if (GenUtil.eq(fieldName, 'WritedownDate')) {
        o.cv_WritedownDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_WritedownDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }
      else if (GenUtil.eq(fieldName, 'TermExtensionDate')) {
        o.cv_TermExtensionDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_TermExtensionDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }
      else if (GenUtil.eq(fieldName, 'UpdatedInterestDate')) {
        o.cv_UpdatedInterestDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_UpdatedInterestDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }

      return t;
    });
  }


  if (stateRows.length <= 0) {
    return (
      <>
        <MessageBar messageBarType={MessageBarType.warning} className='wts12'>
          {`No active loans selected.`}
        </MessageBar>
      </>
    )

  }
  else return (
    <>
      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3'>
          <tbody>
            {
              stateRows.map((o, i) => (
                <tr key={o.cv_RowNum} className='sep'>
                  <td>
                    <OtherNPLevelRow
                      data={o}
                      counter={i}
                      totCount={stateRows.length}
                      araDetails={props.araDetails}
                      updateFieldVal={updateFieldVal}
                      stateFormSubmitted={props.stateFormSubmitted}
                      isReadOnly={props.isReadOnly}
                    ></OtherNPLevelRow>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.xml: {JSON.stringify(props.xml, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};
