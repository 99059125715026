/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  Icon,
  Separator,
  PrimaryButton,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps
} from '@fluentui/react';
import { AjaxPicker } from '../Controls/AjaxPicker';

const stackTokens: IStackTokens = {
  childrenGap: 8
};

export const Test2: React.FunctionComponent = () => {

  const [curSelItem, setCurSelItem] = useState<ITag | null | undefined>(null);

  const [curSelItems, setCurSelItems] = useState<ITag[]>([]);
  // const [curSelItems, setCurSelItems] = useState<ITag[]>([{ key: '123', name: 'njitben' }]);

  const [curSearchResults, setCurSearchResults] = useState<any[]>([]);


  async function execAjaxSearch(filter: string): Promise<ITag[]> {

    let col: any[] = [];

    if (!!filter && filter.trim().length > 0) {
      let q = `https://api.github.com/search/users?q=${filter}+in:login&page=1&per_page=${50}`;
      let resp = await fetch(q);
      let data = await resp.json();
      col = (!data || !data.items) ? [] : data.items;
    }

    setCurSearchResults(col);

    let tags: ITag[] = col.map((o, i) => {
      return { key: o.id, name: o.login } as ITag;
    });

    return tags;
  }


  //================================== USING TAGPICKER COMPONENT


  // const testTags: ITag[] = [
  //   'black',
  //   'blue',
  //   'brown',
  //   'cyan',
  //   'green',
  //   'magenta',
  //   'mauve',
  //   'orange',
  //   'pink',
  //   'purple',
  //   'red',
  //   'rose',
  //   'violet',
  //   'white',
  //   'yellow',
  // ].map(item => ({ key: item, name: item }));


  // const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  //   if (!tagList || !tagList.length || tagList.length === 0) {
  //     return false;
  //   }
  //   return tagList.some(compareTag => compareTag.key === tag.key);
  // };


  function tagAlreadySelected(tag: ITag, tagList?: ITag[]) {
    if (!tagList || !tagList.length || tagList.length === 0) return false;
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
  }


  const handleResolveSuggestions = async (filterText: string, selectedItems?: ITag[]): Promise<ITag[]> => {

    console.log('handleResolveSuggestions called', filterText, selectedItems);

    const matches = await execAjaxSearch(filterText);

    //return matches;

    return matches.filter((tag: ITag) => !tagAlreadySelected(tag, selectedItems));

    // return filterText
    //   ? testTags.filter(
    //     tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
    //   )
    //   : [];
  };


  const getTextFromItem = (item: ITag) => item.name;


  function handleTagPickerChange(items?: ITag[]) {

    console.log('handleTagPickerChange called', items);

    setCurSelItem((!items || items.length <= 0) ? null : items[0]);
  }


  //================================== USING MY CONTROL


  async function execAjaxSearch2(filter: string): Promise<any[]> {

    let data: any[] = [];

    if (!!filter && filter.trim().length > 0) {
      let q = `https://api.github.com/search/users?q=${filter}+in:login&page=1&per_page=${50}`;
      let resp = await fetch(q);
      let json = await resp.json();
      data = (!json || !json.items) ? [] : json.items;
    }

    return data;
  }


  async function onGetSuggestionsGithubUsers(filterText: string): Promise<ITag[]> {

    console.log(' -- onGetSuggestionsGithubUsers called', filterText);

    if (!filterText || filterText.trim().length <= 0) {
      return [];
    }

    const col = await execAjaxSearch2(filterText);

    setCurSearchResults(col);

    return col.map((o, i) => {
      return { key: o.id, name: o.login } as ITag;
    });
  }


  function onTagsChangedGithubUsers(items?: ITag[]): void {

    console.log(' -- onTagsChangedGithubUsers called', items);

    setCurSelItems(!items ? [] : items);
  }


  //===========================
  return (
    <div className="w600">

      <Stack tokens={stackTokens}>

        <Separator>TagPicker</Separator>

        <TagPicker
          onResolveSuggestions={handleResolveSuggestions}
          getTextFromItem={getTextFromItem}
          // pickerSuggestionsProps={pickerSuggestionsProps}
          itemLimit={1}
          resolveDelay={400}
          onChange={handleTagPickerChange}
          pickerSuggestionsProps={{
            suggestionsHeaderText: '',
            noResultsFoundText: 'No matches found',
          }}
          searchingText="... searching"
        />

        <Separator>AjaxPicker</Separator>

        <AjaxPicker
          disabled={false}
          itemLimit={1}
          getSuggestedTags={onGetSuggestionsGithubUsers}
          onTagsChanged={onTagsChangedGithubUsers}
          selectedTags={curSelItems}
          // cssClassNames='w400'
          cssClassNames=''
          noResultsFoundText=''
          searchingText='Loading...'
          suggestionsHeaderText='Search for GitHub users'
        />

        <PrimaryButton className='w200' text="Get First User" onClick={() => {
          alert(curSelItems.length > 0 ? `${curSelItems[0].name} (${curSelItems[0].key})` : 'No user found')
        }} allowDisabledFocus disabled={false} />

        <Separator>State</Separator>

        <div>Sel Item: {JSON.stringify(curSelItem)}</div>

        <div>Sel Items:</div>
        <ul>{curSelItems.map(o => <li key={o.key}>{o.name} ({o.key})</li>)}</ul>

        <div>
          <textarea rows={15} style={{ width: '100%', whiteSpace: 'normal' }} value={JSON.stringify(curSearchResults, null, 2)} readOnly></textarea>
        </div>

      </Stack>
    </div>
  );
};
