import * as Consts from './Consts';
import * as GenUtil from './GenUtil2';


export default class GraphHelper {
  // use this for helpful intellisense, type "GraphHelper" and it comes up to import this file, then change it to "import * as GraphHelper from [...]"
}


// export async function getAsync(url: string, accessToken: string): Promise<any> {
//   // method = "GET", pass the URL to graph, return the regular expected JSON response

//   const headers = new Headers();
//   const bearer = `Bearer ${accessToken}`;

//   headers.append("Authorization", bearer);

//   const options = {
//     method: "GET",
//     headers: headers
//   };

//   return fetch(url, options)
//     .then(response => response.json())
//     .catch(error => console.error(error));
// }


export async function getAsync2(url: string, accessToken: string): Promise<any> {
  // custom function that merges the HTTP response status and statustext into the JSON body

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  let resp = await fetch(url, options);
  let data: any = await resp.json();

  return {
    ...data,
    httpStatus: resp.status,
    httpStatusText: resp.statusText
  };
}


export async function postAsync(url: string, accessToken: string, payload: any, method: string): Promise<any> {
  // method = 'POST' for insert, 'PATCH' for update
  // shape of payload is different for these methods
  // insert/POST expects payload wrapped:  { "fields": { "Title": "Widget", ... } }
  // update/PATCH expects playload flat: { "Title": "Widget", ... }

  if (Consts.disableSavingData) {
    console.warn('url', url);
    console.warn('accessToken', accessToken);
    console.warn('payload', JSON.stringify(payload, null, 2));
    console.warn('method', method);
    throw new Error(`Saving data to SharePoint using Graph is disabled via config options (Consts.disableSavingData=false)`);
  }
  else {
    let headers = new Headers();

    if (!GenUtil.isNull(accessToken)) {
      let bearer = `Bearer ${accessToken}`;
      headers.append("Authorization", bearer);
    }

    headers.append("Content-Type", "application/json");

    let options: any = {
      method: method,
      headers: headers
    };

    if (payload != null && typeof payload !== 'undefined') {
      // body is optional, for requests like "DELETE"
      options.body = JSON.stringify(payload);
    }

    let resp = await fetch(url, options);

    if (payload != null && typeof payload !== 'undefined') {
      // expecting data back, lets get that data, and wrap it with response data too
      let data: any = await resp.json();

      return {
        ...data,
        httpStatus: resp.status,
        httpStatusText: resp.statusText
      };
    }
    else {
      // if not posting a body, then don't expect anything back, like sending DELETE request, only expecting 204 back
      return {
        httpStatus: resp.status,
        httpStatusText: resp.statusText
      };
    }
  }
}
