/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as StaticData from "../../../StaticData/Link/StaticData";
import * as ConvertToXml from '../../../Services/Link/ConvertToXml';

import { ConnectionDetailsItem } from '../../../Models/Link/GridModels/ConnectionDetails1';
import { ANEntity } from '../../../Models/Link/ANEntity';


export interface IANConnectionDetailROProps {
  data: string | undefined;
  project: ANEntity | null;
  onTotalUpdated: (s: string, d: number) => void;
}

export const ANConnectionDetailRO: React.FunctionComponent<IANConnectionDetailROProps> = (props: React.PropsWithChildren<IANConnectionDetailROProps>) => {
  // ConnectionDetail component for readonly mode. Load the XML saved in SPItem, show this data, because the Connection item loaded from Connection lookup may fail if that Connection is eventually removed.
  // RO = ReadOnly

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANConnectionDetailRO]");
  }, []);


  const [stateRows, setStateRows] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    let col: ConnectionDetailsItem[] = ConvertToXml.cvtXml2ConnectionDetailsItem(GenUtil.safeTrim(props.data));
    setStateRows(col);
  }, [props.data]);


  const memoShowFullyAssigned = useMemo<boolean>(() => {
    if (props.project && GenUtil.safeToBool(props.project.fields.ShowConnFullyAssigned))
      return true;
    else
      return false;
  }, [props.project]);


  const memoSumTotalCollAmts = useMemo(() => {
    // rpt_TotalCollectionsAmount is already summed per connectionid(unique connection), so just need to sum all of these to get the actual total
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToNumber(o.rpt_TotalCollectionsAmount));
    return tot;
  }, [stateRows]);

  useEffect(() => {
    props.onTotalUpdated('ConnectionDetails', memoSumTotalCollAmts);
  }, [memoSumTotalCollAmts]);


  if (stateRows.length <= 0) {
    return null;
  }
  else {
    return (
      <>
        <table className='sub-table wbsss'>
          <thead>
            <tr>

              <th>{`Connection ID`}</th>

              <th>{`Connection Name`}</th>

              {/* <th>{`Connection Status`}</th> */}

              {memoShowFullyAssigned && <th>{`Fully Assigned?`}</th>}

              <th>{`Deal Life Collections (€)`}</th>

            </tr>
          </thead>
          <tbody>

            {
              stateRows.map((o, i) => {
                return (
                  <tr key={i}>

                    <td>{o.rpt_ConnectionID}</td>

                    <td>{o.rpt_ConnectionName}</td>

                    {/* <td>{o.cv_ConnectionStatus}</td> */}

                    {memoShowFullyAssigned && <td>{o.rpt_FullyAssigned}</td>}

                    <td>{o.rpt_TotalCollectionsAmount}</td>

                  </tr>
                );
              })
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>

                  <td>&nbsp;</td>

                  {memoShowFullyAssigned && <td>&nbsp;</td>}

                  <td style={{ textAlign: 'right' }}>
                    <Label>{`Total (€):`}</Label>
                  </td>

                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumTotalCollAmts)}</Label>
                  </td>

                </tr>
              )
            }

          </tbody>
        </table>
      </>
    );
  }
};