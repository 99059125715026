/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { ALM4Row } from './ALM4Row';
import { AssetLevelMatrixFour, AssetLevelMatrixFourItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixFour';
import { ANType } from '../../../Models/Cabot/ANType';


export interface IALM4BodyProps {
  xml: string | undefined;
  selANSubType: ANType | null;
  onDataUpdated: (s: string, d: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const ALM4Body: React.FunctionComponent<IALM4BodyProps> = (props: React.PropsWithChildren<IALM4BodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM4Body]");
  }, []);


  const [stateRows, setStateRows] = useState<AssetLevelMatrixFourItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('alm4', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object
    // NOTE: ALM4 will only ever have max:1 min:1 default:1 record saved in xml

    let col: AssetLevelMatrixFourItem[] = [];

    if (!!props.xml && !GenUtil.isNull(props.xml) && !GenUtil.isInt(props.xml)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ALM4', props.xml);

        if (!AppHelper.xmlHasMultipleItems(props.xml)) {
          let _t = (obj as AssetLevelMatrixFour).RepeaterData.Items.Item;

          let item: AssetLevelMatrixFourItem = {
            cv_RowNum: GenUtil.generateGuid(),
            cv_EOSFOS: GenUtil.safeTrim(AppHelper.getText(_t.cv_EOSFOS)),
            rpt_ALM4_EOS: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM4_EOS)),
            rpt_decimal_ALM4_SalesProceeds: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_SalesProceeds)),
            rpt_decimal_ALM4_RentalIncome: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_RentalIncome)),
            rpt_decimal_ALM4_TotalIncome: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_TotalIncome)),
            rpt_decimal_ALM4_ReceiversFee: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_ReceiversFee)),
            rpt_decimal_ALM4_ReceiverOutlays: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_ReceiverOutlays)),
            rpt_decimal_ALM4_SalesAgentFee: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_SalesAgentFee)),
            rpt_decimal_ALM4_SaleAgentMarketingCosts: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_SaleAgentMarketingCosts)),
            rpt_decimal_ALM4_LegalFeesConveyance: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_LegalFeesConveyance)),
            rpt_decimal_ALM4_LegalFeesLegalOutlay: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_LegalFeesLegalOutlay)),
            rpt_decimal_ALM4_TaxAdvise: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_TaxAdvise)),
            rpt_decimal_ALM4_BERCerts: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_BERCerts)),
            rpt_decimal_ALM4_Insurance: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_Insurance)),
            rpt_decimal_ALM4_ServiceCharges: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_ServiceCharges)),
            rpt_decimal_ALM4_Rates: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_Rates)),
            rpt_decimal_ALM4_RepairsMaintenanceCleanup: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_RepairsMaintenanceCleanup)),
            rpt_decimal_ALM4_CGT: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_CGT)),
            rpt_decimal_ALM4_HHC: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_HHC)),
            rpt_decimal_ALM4_LPT: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_LPT)),
            rpt_decimal_ALM4_NPPR: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_NPPR)),
            rpt_decimal_ALM4_BankCharge: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_BankCharge)),
            rpt_ALM4_UserField: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM4_UserField)),
            rpt_decimal_ALM4_UserValue: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_UserValue)),
            rpt_decimal_ALM4_TotalIncVAT: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_TotalIncVAT)),
            rpt_decimal_ALM4_NetProceeds: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM4_NetProceeds)),
          };

          col = [item];
        }

      } catch (error) { }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        cv_RowNum: GenUtil.generateGuid(),
        cv_EOSFOS: '',
        rpt_ALM4_EOS: '',
        rpt_decimal_ALM4_SalesProceeds: '0',
        rpt_decimal_ALM4_RentalIncome: '0',
        rpt_decimal_ALM4_TotalIncome: '0',
        rpt_decimal_ALM4_ReceiversFee: '0',
        rpt_decimal_ALM4_ReceiverOutlays: '0',
        rpt_decimal_ALM4_SalesAgentFee: '0',
        rpt_decimal_ALM4_SaleAgentMarketingCosts: '0',
        rpt_decimal_ALM4_LegalFeesConveyance: '0',
        rpt_decimal_ALM4_LegalFeesLegalOutlay: '0',
        rpt_decimal_ALM4_TaxAdvise: '0',
        rpt_decimal_ALM4_BERCerts: '0',
        rpt_decimal_ALM4_Insurance: '0',
        rpt_decimal_ALM4_ServiceCharges: '0',
        rpt_decimal_ALM4_Rates: '0',
        rpt_decimal_ALM4_RepairsMaintenanceCleanup: '0',
        rpt_decimal_ALM4_CGT: '0',
        rpt_decimal_ALM4_HHC: '0',
        rpt_decimal_ALM4_LPT: '0',
        rpt_decimal_ALM4_NPPR: '0',
        rpt_decimal_ALM4_BankCharge: '0',
        rpt_ALM4_UserField: '',
        rpt_decimal_ALM4_UserValue: '',
        rpt_decimal_ALM4_TotalIncVAT: '0',
        rpt_decimal_ALM4_NetProceeds: '0',
      }];
    }

    setStateRows(col);

  }, [props.xml])


  /**
   * NOTES:
   * -no need to support adding rows
   * -no need to support deleting rows
   * -will only ever have a single record
   */


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];
      if (GenUtil.eq(fieldName, 'cv_EOSFOS')) o.cv_EOSFOS = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'rpt_ALM4_EOS')) o.rpt_ALM4_EOS = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_SalesProceeds')) o.rpt_decimal_ALM4_SalesProceeds = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_RentalIncome')) o.rpt_decimal_ALM4_RentalIncome = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_TotalIncome')) o.rpt_decimal_ALM4_TotalIncome = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_ReceiversFee')) o.rpt_decimal_ALM4_ReceiversFee = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_ReceiverOutlays')) o.rpt_decimal_ALM4_ReceiverOutlays = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_SalesAgentFee')) o.rpt_decimal_ALM4_SalesAgentFee = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_SaleAgentMarketingCosts')) o.rpt_decimal_ALM4_SaleAgentMarketingCosts = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_LegalFeesConveyance')) o.rpt_decimal_ALM4_LegalFeesConveyance = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_LegalFeesLegalOutlay')) o.rpt_decimal_ALM4_LegalFeesLegalOutlay = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_TaxAdvise')) o.rpt_decimal_ALM4_TaxAdvise = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_BERCerts')) o.rpt_decimal_ALM4_BERCerts = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_Insurance')) o.rpt_decimal_ALM4_Insurance = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_ServiceCharges')) o.rpt_decimal_ALM4_ServiceCharges = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_Rates')) o.rpt_decimal_ALM4_Rates = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_RepairsMaintenanceCleanup')) o.rpt_decimal_ALM4_RepairsMaintenanceCleanup = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_CGT')) o.rpt_decimal_ALM4_CGT = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_HHC')) o.rpt_decimal_ALM4_HHC = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_LPT')) o.rpt_decimal_ALM4_LPT = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_NPPR')) o.rpt_decimal_ALM4_NPPR = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_BankCharge')) o.rpt_decimal_ALM4_BankCharge = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_ALM4_UserField')) o.rpt_ALM4_UserField = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_UserValue')) o.rpt_decimal_ALM4_UserValue = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_TotalIncVAT')) o.rpt_decimal_ALM4_TotalIncVAT = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'rpt_decimal_ALM4_NetProceeds')) o.rpt_decimal_ALM4_NetProceeds = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      return t;
    });
  }


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3 alm4-table'>
          <tbody>

            {
              stateRows.map((o, i) =>
                <ALM4Row
                  selANSubType={props.selANSubType}
                  key={o.cv_RowNum}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly} />)
            }

          </tbody>
        </table>

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.xml: {JSON.stringify(props.xml, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};