/* eslint-disable @typescript-eslint/no-unused-vars */
import * as AppHelper from "../../Helpers/AppHelper";
import * as GenUtil from "../../Helpers/GenUtil2";

import { AssetInfoItem } from "../../Models/Altamira/GridModels/AssetInfo1";
import { BorrowerInfoItem } from "../../Models/Altamira/GridModels/BorrowerInfo1";
import { LoanInfoItem } from "../../Models/Altamira/GridModels/LoanInfo1";
import { ProposedCostsDetailsItem } from "../../Models/Altamira/GridModels/ProposedCostsDetails1";
import { ConnectionDetails1, ConnectionDetailsItem } from "../../Models/Altamira/GridModels/ConnectionDetails1";
import { ConnectionDetailsN } from "../../Models/Altamira/GridModels/ConnectionDetailsN";


// either save '' or '<xml>...empty_defaults...</xml>'
const _SAVE_EMPTY_XML: boolean = false;


export function cvtXml2ConnectionDetailsItem(data: string): ConnectionDetailsItem[] {

  let col: ConnectionDetailsItem[] = [];

  if (!!data && !GenUtil.isNull(data) && !GenUtil.isInt(data)) {
    let obj = AppHelper.getJsonObjFromXmlStr('ConnectionDetails', data);

    if (!AppHelper.xmlHasMultipleItems(data)) {
      let _t = (obj as ConnectionDetails1).RepeaterData.Items.Item;

      let item: ConnectionDetailsItem = {
        cv_ConnId_Is_AssetId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId_Is_AssetId)),
        rpt_CESConnID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CESConnID)),
        cv_rpt_ConnecID: GenUtil.safeTrim(AppHelper.getText(_t.cv_rpt_ConnecID)),
        cv_rpt_ConnName: GenUtil.safeTrim(AppHelper.getText(_t.cv_rpt_ConnName)),
        rpt_ConnecID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnecID)),
        rpt_ConnectionName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionName)),
        rpt_ConnectionStatus: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionStatus)),
      };

      // only add if the object is not nintex empty xml record
      if (!GenUtil.isNull(item.rpt_ConnecID)) col = [item];

    }
    else {
      let _t = obj as ConnectionDetailsN;

      let _col: ConnectionDetailsItem[] = _t.RepeaterData.Items.Item.map(_t => {
        return {
          cv_ConnId_Is_AssetId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId_Is_AssetId)),
          rpt_CESConnID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_CESConnID)),
          cv_rpt_ConnecID: GenUtil.safeTrim(AppHelper.getText(_t.cv_rpt_ConnecID)),
          cv_rpt_ConnName: GenUtil.safeTrim(AppHelper.getText(_t.cv_rpt_ConnName)),
          rpt_ConnecID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnecID)),
          rpt_ConnectionName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionName)),
          rpt_ConnectionStatus: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionStatus)),
        }
      });

      // only add if the object is not nintex empty xml record
      col = _col.filter(o => !GenUtil.isNull(o.rpt_ConnecID));
    }
  }

  return col;
}


export function cvtConnectionDetailsItem2Xml(data: ConnectionDetailsItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_ConnId_Is_AssetId', '', _System_String);
    xml += makeNode('rpt_CESConnID', '', _System_String);
    xml += makeNode('cv_rpt_ConnecID', '', _System_String);
    xml += makeNode('rpt_ConnecID', '', _System_String);
    xml += makeNode('cv_rpt_ConnName', '', _System_String);
    xml += makeNode('rpt_ConnectionName', '', _System_String);
    xml += makeNode('rpt_ConnectionStatus', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_ConnId_Is_AssetId', o.cv_ConnId_Is_AssetId, _System_String);
      xml += makeNode('rpt_CESConnID', o.rpt_CESConnID, _System_String);
      xml += makeNode('cv_rpt_ConnecID', o.cv_rpt_ConnecID, _System_String);
      xml += makeNode('rpt_ConnecID', o.rpt_ConnecID, _System_String);
      xml += makeNode('cv_rpt_ConnName', o.cv_rpt_ConnName, _System_String);
      xml += makeNode('rpt_ConnectionName', o.rpt_ConnectionName, _System_String);
      xml += makeNode('rpt_ConnectionStatus', o.rpt_ConnectionStatus, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtBorrowerInfoItems2Xml(data: BorrowerInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_ConnId', '', _System_String);
    xml += makeNode('cv_BorrowerID', '', _System_String);
    xml += makeNode('cv_BorrID', '', _System_String);
    xml += makeNode('rpt_BorrowerName', '', _System_String);
    xml += makeNode('cv_BorrowerName', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);
      xml += makeNode('cv_BorrowerID', o.cv_BorrowerID, _System_String);
      xml += makeNode('cv_BorrID', o.cv_BorrID, _System_String);
      xml += makeNode('rpt_BorrowerName', o.rpt_BorrowerName, _System_String);
      xml += makeNode('cv_BorrowerName', o.cv_BorrowerName, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtLoanInfoItems2Xml(data: LoanInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;

    xml += makeNode('cv_ConnId', '', _System_String);

    xml += makeNode('rpt_ServicerLoanNumber', '', _System_String);

    xml += makeNode('rpt_LoanID', '', _System_String);
    // xml += makeNode('cv_LoanID', '', _System_String);

    xml += makeNode('rpt_ServicerLoanID', '', _System_String);
    // xml += makeNode('cv_SrvcLoanID', '', _System_String);

    xml += makeNode('cv_decimal_UPB', 0, _System_Decimal);
    // xml += makeNode('cv_upb', 0, _System_Decimal);

    xml += makeNode('cv_UPBCurrency', '', _System_String);
    // xml += makeNode('cv_UPBCurrency', '', _System_String);

    xml += makeNode('cv_OrigUPB', 0, _System_Decimal);
    xml += makeNode('cv_OrigCurr', '', _System_String);
    xml += makeNode('rpt_DispUPB', '', _System_String);
    xml += makeNode('rpt_DispCurr', '', _System_String);

    xml += makeNode('rpt_JrLiensEnc', '', _System_String);
    xml += makeNode('cv_RentalIncome', '', _System_String);

    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;

      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);

      xml += makeNode('rpt_ServicerLoanNumber', o.rpt_ServicerLoanNumber, _System_String);

      xml += makeNode('rpt_LoanID', o.rpt_LoanID, _System_String);
      // xml += makeNode('cv_LoanID', o.cv_LoanID, _System_String);

      xml += makeNode('rpt_ServicerLoanID', o.rpt_ServicerLoanID, _System_String);
      // xml += makeNode('cv_SrvcLoanID', o.cv_SrvcLoanID, _System_String);

      xml += makeNode('cv_decimal_UPB', o.cv_decimal_UPB, _System_Decimal);
      // xml += makeNode('cv_upb', o.cv_upb, _System_Decimal);

      xml += makeNode('cv_UPBCurrency', o.cv_UPBCurrency, _System_String);
      // xml += makeNode('cv_UPBCurrency', o.cv_UPBCurrency, _System_String);

      xml += makeNode('cv_OrigUPB', o.cv_OrigUPB, _System_Decimal);
      xml += makeNode('cv_OrigCurr', o.cv_OrigCurr, _System_String);
      xml += makeNode('rpt_DispUPB', o.rpt_DispUPB, _System_String);
      xml += makeNode('rpt_DispCurr', o.rpt_DispCurr, _System_String);

      xml += makeNode('rpt_JrLiensEnc', o.rpt_JrLiensEnc, _System_String);
      xml += makeNode('cv_RentalIncome', o.cv_RentalIncome, _System_String);

      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtAssetInfoItems2Xml(data: AssetInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_ConnId', '', _System_String);
    xml += makeNode('rpt_CESAssetID', '', _System_String);
    xml += makeNode('rpt_AssetID', '', _System_String);
    xml += makeNode('cv_AssetID', '', _System_String);
    xml += makeNode('rpt_AssetAddress', '', _System_String);
    xml += makeNode('cv_AssetAddr', '', _System_String);
    xml += makeNode('rpt_decimal_RealEstateValue', 0, _System_Decimal);
    xml += makeNode('cv_REValue', 0, _System_Decimal);
    xml += makeNode('rpt_District', '', _System_String);
    xml += makeNode('cv_District', '', _System_String);
    xml += makeNode('rpt_AssetType', '', _System_String);
    xml += makeNode('cv_AssetType', '', _System_String);
    xml += makeNode('rpt_SqmUnits', '', _System_String);
    xml += makeNode('rpt_Vacant', '', _System_String);
    xml += makeNode('rpt_PubDate', '', _System_String);
    xml += makeNode('cv_PubDate', '', _System_String);
    xml += makeNode('rpt_CommAct', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);
      xml += makeNode('rpt_CESAssetID', o.rpt_CESAssetID, _System_String);
      xml += makeNode('rpt_AssetID', o.rpt_AssetID, _System_String);
      xml += makeNode('cv_AssetID', o.cv_AssetID, _System_String);
      xml += makeNode('rpt_AssetAddress', o.rpt_AssetAddress, _System_String);
      xml += makeNode('cv_AssetAddr', o.cv_AssetAddr, _System_String);
      xml += makeNode('rpt_decimal_RealEstateValue', o.rpt_decimal_RealEstateValue, _System_Decimal);
      xml += makeNode('cv_REValue', o.cv_REValue, _System_Decimal);
      xml += makeNode('rpt_District', o.rpt_District, _System_String);
      xml += makeNode('cv_District', o.cv_District, _System_String);
      xml += makeNode('rpt_AssetType', o.rpt_AssetType, _System_String);
      xml += makeNode('cv_AssetType', o.cv_AssetType, _System_String);
      xml += makeNode('rpt_SqmUnits', o.rpt_SqmUnits, _System_String);
      xml += makeNode('rpt_Vacant', o.rpt_Vacant, _System_String);
      xml += makeNode('rpt_PubDate', o.rpt_PubDate, _System_String);
      xml += makeNode('cv_PubDate', o.cv_PubDate, _System_String);
      xml += makeNode('rpt_CommAct', o.rpt_CommAct, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtProposedCostsDetailsItems2Xml(data: ProposedCostsDetailsItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_PropCostRowNumber', '', _System_String);
    xml += makeNode('rpt_VendorLabel', '', _System_String);
    xml += makeNode('dd_Vendors', '', _System_String);
    xml += makeNode('cv_VendorsValue', '', _System_String);
    xml += makeNode('rpt_decimal_Costs', '', _System_String);
    xml += makeNode('rpt_CostsCurrency', '', _System_String);
    xml += makeNode('rpt_Description', '', _System_String);
    xml += makeNode('dd_TypeOfCosts', '', _System_String);
    xml += makeNode('cv_TypeOfCosts', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;

      xml += makeNode('rpt_PropCostRowNumber', i + 1, _System_String); // override rownumber with counter

      xml += makeNode('rpt_VendorLabel', o.rpt_VendorLabel, _System_String);

      xml += makeNode('dd_Vendors', o.dd_Vendors, _System_String);
      xml += makeNode('cv_VendorsValue', o.cv_VendorsValue, _System_String);

      // if vendor is NA, then type of costs is added, otherwise clear "type of costs"
      if (o.dd_Vendors === '-999') {
        xml += makeNode('dd_TypeOfCosts', o.dd_TypeOfCosts, _System_String);
        xml += makeNode('cv_TypeOfCosts', o.cv_TypeOfCosts, _System_String);
      }
      else {
        xml += makeNode('dd_TypeOfCosts', '', _System_String);
        xml += makeNode('cv_TypeOfCosts', '', _System_String);
      }


      xml += makeNode('rpt_decimal_Costs', o.rpt_decimal_Costs, _System_Decimal);
      xml += makeNode('rpt_CostsCurrency', o.rpt_CostsCurrency, _System_String);
      xml += makeNode('rpt_Description', o.rpt_Description, _System_String);

      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


// ------------------------------


function makeNode(name: string, val: any, type: string) {

  let v = GenUtil.safeTrim(val);

  if (type === _System_String) {

    v = GenUtil.normalizeEOL(val);
    v = v.replace(/\n/ig, ' '); // convert linebreaks to spaces
    v = v.replace(/\s+/ig, ' '); // consolidate spaces

    // replace xml reserved chars
    v = v.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;');
  }

  return `<${name} type='${type}'>${v}</${name}>`;
}


// ------------------------------


const _Xml_Start: string = '<?xml version="1.0" encoding="utf-8"?><RepeaterData><Version /><Items>';
const _Xml_End: string = '</Items></RepeaterData>';
const _Xml_Item_Start: string = `<Item>`;
const _Xml_Item_End: string = `</Item>`;

const _System_String: string = 'System.String';
const _System_Decimal: string = 'System.Decimal';
// const _System_Double: string = 'System.Double';
// const _System_DateTime: string = 'System.DateTime';
