/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Checkbox,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';

import * as JsonXmlHelper from '../../Helpers/JsonXmlConverter';

import './Test1.css';

const stackTokens: IStackTokens = {
  childrenGap: 8,
  // maxWidth: 400
};

export const Test1: React.FunctionComponent = () => {

  const [loading, setLoading] = useState(false);


  const [stateCheckbox1, setStateCheckbox1] = useState<boolean>(true);

  function onChangeCheckbox1() {
    setStateCheckbox1(p => {
      return !p;
    })
  }


  useEffect(() => {

    let xmlStr = "<?xml version=\"1.0\" encoding=\"utf-8\"?><RepeaterData><Version /><Items><Item><rpt_ConnecID type=\"System.String\">cns149592</rpt_ConnecID><cv_rpt_ConnecID type=\"System.String\">cns149592</cv_rpt_ConnecID><cv_rpt_ConnName type=\"System.String\">GILNA N &amp;amp; A</cv_rpt_ConnName><rpt_ConnectionName type=\"System.String\">GILNA N &amp;amp; A</rpt_ConnectionName><rpt_ConnectionStatus type=\"System.String\">Settled - Enforced</rpt_ConnectionStatus><rpt_FullyAssigned type=\"System.String\">Y</rpt_FullyAssigned><cv_rpt_FullyAssigned type=\"System.String\">Y</cv_rpt_FullyAssigned><cv_TotalCollectionsAmount type=\"System.Decimal\">73351.87</cv_TotalCollectionsAmount><rpt_TotalCollectionsAmount type=\"System.String\">73351.87</rpt_TotalCollectionsAmount><RowNumberConnectionDetails type=\"System.String\">1</RowNumberConnectionDetails></Item></Items></RepeaterData>";
    // console.log('xmlStr', xmlStr);

    // convert xml string to dom
    let dom = JsonXmlHelper.parseXml(xmlStr);
    // console.log('dom', dom);

    // convert to json string
    let jsonStr = JsonXmlHelper.xml2json(dom, "  ");
    // console.log('jsonStr', jsonStr); // ex: '{"RepeaterData":{"Version":null,"Items":{"Item":{"rpt_ConnecID":{"@type":"System.String","#text":"cns149592"},...'

    // convert to json object
    let obj = JSON.parse(jsonStr);
    // console.log('obj', obj);

    // convert json object back to xml string
    let xml2Str = JsonXmlHelper.json2xml(obj, "");
    // console.log('xml2Str', xml2Str); // ex: '<RepeaterData><Version/><Items><Item><rpt_ConnecID type="System.String">cns149592...'
    // NOTE: the xml string is missing the header '<?xml version=\"1.0\" encoding=\"utf-8\"?>'

  }, []);


  useEffect(() => {

    // let num = 1;
    let num = 1.55652525;

    let result = (Math.round(num * 100) / 100).toFixed(2);

    console.log('result', result);

  }, []);


  const [tb1, setTb1] = useState('');

  function _alertClicked(): void {
    alert(tb1);
  }

  const [pocDollar2, set_pocDollar2] = useState('');
  const onChange_pocDollar2 = useCallback((evt: any, v?: string) => { set_pocDollar2(v || ''); }, []);

  const [mlot1, set_mlot1] = useState('');
  const onChange_mlot1 = useCallback((evt: any, v?: string) => { set_mlot1(v || ''); }, []);

  const shimmerWithElementFirstRow = [
    { type: ShimmerElementType.circle },
    { type: ShimmerElementType.gap, width: '5%' },
    { type: ShimmerElementType.line },
    { type: ShimmerElementType.gap, width: '5%' },
    { type: ShimmerElementType.line },
  ];

  const [showMsg, setShowMsg] = useState(true);


  const onFormatDate = (date?: Date, format: string = 'MDY'): string => {

    if (!date) return '';

    return date.toLocaleDateString();

    // let M = date.getMonth() + 1;
    // let D = date.getDate();
    // let Y = date.getFullYear() % 100;

    // if (format === "MDY")
    //   return `${M}/${D}/${Y}`;
    // else if (format === "DMY")
    //   return `${D}/${M}/${Y}`;
    // else if (format === "YMD")
    //   return `${Y}/${M}/${D}`;
    // else
    //   return `${M}/${D}/${Y}`;
  };

  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(new Date('3/15/2015'));


  const optionsYesNo: IComboBoxOption[] = [
    { key: '-', text: 'Any' },
    { key: 'Y', text: 'Yes' },
    { key: 'N', text: 'No' },
  ];

  const [selectedKey, setSelectedKey] = React.useState<string | number | undefined>('-');


  useEffect(() => {
    let ignore = false;

    setLoading(true);

    setTimeout(async () => {

      let search = 'steinhauser'
      let perPage = 50;
      let q = `https://api.github.com/search/users?q=${search}+in:login&page=1&per_page=${perPage}`;
      let resp = await fetch(q);
      let json = await resp.json();

      // console.log(json);

      if (!ignore) {

        setLoading(false);
      }

    }, 100);

    return () => { ignore = true; }
  }, []);


  //===========================
  // dropdown testing

  const [stateSelItemDDL, setStateSelItemDDL] = useState<IDropdownOption>();
  const onChangeDDL = (event: any, option?: IDropdownOption, index?: number) => { setStateSelItemDDL(option); };


  //===========================

  return (
    <div>

      <Stack tokens={stackTokens}>

        <h2>
          <Stack horizontal tokens={{ childrenGap: 6 }} verticalAlign={'center'}>
            <Icon iconName="CompassNW" />
            <span>Test1.tsx</span>
          </Stack>
        </h2>

        <Separator>Font Size (shortcut)</Separator>

        <div className='ms-font-su'>The quick brown fox jumps over the lazy dog /ms-font-su</div>
        <div className='ms-font-xxl'>The quick brown fox jumps over the lazy dog /ms-font-xxl</div>
        <div className='ms-font-xl'>The quick brown fox jumps over the lazy dog /ms-font-xl</div>
        <div className='ms-font-l'>The quick brown fox jumps over the lazy dog /ms-font-l</div>
        <div className='ms-font-m-plus'>The quick brown fox jumps over the lazy dog /ms-font-m-plus</div>
        <div className='ms-font-s-plus'>The quick brown fox jumps over the lazy dog /ms-font-s-plus</div>
        <div className='ms-font-s'>The quick brown fox jumps over the lazy dog /ms-font-s</div>
        <div className='ms-font-xs'>The quick brown fox jumps over the lazy dog /ms-font-xs</div>
        <div className='ms-font-mi'>The quick brown fox jumps over the lazy dog /ms-font-mi</div>

        <Separator>Font Weight</Separator>

        <div className='ms-fontWeight-light'>The quick brown fox jumps over the lazy dog /ms-fontWeight-light</div>
        <div className='ms-fontWeight-semilight'>The quick brown fox jumps over the lazy dog /ms-fontWeight-semilight</div>
        <div className='ms-fontWeight-regular'>The quick brown fox jumps over the lazy dog /ms-fontWeight-regular</div>
        <div className='ms-fontWeight-semibold'>The quick brown fox jumps over the lazy dog /ms-fontWeight-semibold</div>
        <div className='ms-fontWeight-bold'>The quick brown fox jumps over the lazy dog /ms-fontWeight-bold</div>

        <Separator>Font Size (number)</Separator>

        <div className='ms-fontSize-68'>The quick brown fox jumps over the lazy dog /ms-fontSize-68</div>
        <div className='ms-fontSize-42'>The quick brown fox jumps over the lazy dog /ms-fontSize-42</div>
        <div className='ms-fontSize-32'>The quick brown fox jumps over the lazy dog /ms-fontSize-32</div>
        <div className='ms-fontSize-28'>The quick brown fox jumps over the lazy dog /ms-fontSize-28</div>
        <div className='ms-fontSize-24'>The quick brown fox jumps over the lazy dog /ms-fontSize-24</div>
        <div className='ms-fontSize-20'>The quick brown fox jumps over the lazy dog /ms-fontSize-20</div>
        <div className='ms-fontSize-18'>The quick brown fox jumps over the lazy dog /ms-fontSize-18</div>
        <div className='ms-fontSize-14'>The quick brown fox jumps over the lazy dog /ms-fontSize-14</div>
        <div className='ms-fontSize-12'>The quick brown fox jumps over the lazy dog /ms-fontSize-12</div>
        <div className='ms-fontSize-10'>The quick brown fox jumps over the lazy dog /ms-fontSize-10</div>

        <Separator>Headings</Separator>

        <h1 className="flu-page-title2 flu-bottomborder1">Best Practices flu-page-title2<span className="flu-page-title-sub2">React flu-page-title-sub2</span></h1>
        test

        <h2 className="flu-heading1 flu-bottomborder1">Heading flu-heading1</h2>
        test

        <h3 className="flu-heading2 flu-bottomborder1">Layout flu-heading2</h3>
        test

        <Separator>Headings</Separator>

        <div className="flu-page-title1 flu-bottomborder1">Big blue text - flu-page-title1</div>

        <p className="flu-heading1b flu-bottomborder1">Heading - flu-heading1b</p>

        <div className="flu-heading2b flu-bottomborder1">Sub Heading - flu-heading2b</div>

        {/* <Separator>Headings</Separator>

        <div className="ms-font-su ms-fontColor-themePrimary wbss">Big blue text</div>

        <p className="ms-font-xxl ms-fontColor-neutralSecondary ms-fontWeight-semilight wbss">Heading</p>

        <div className="ms-font-xl ms-fontColor-neutralTertiary wbss">Sub Heading</div> */}

        <Separator>Section</Separator>

        <div className='flu-section'>
          <h2 className="flu-heading1 wbss">Best practices</h2>
          <h3 className="flu-heading2 wbss">Layout</h3>
          <div className='flu-section-body'>Use a multiline text field when long entries are expected.</div>
          <ul className='flu-section-body'><li>Use a multiline text field when long entries are expected.</li><li>Don't place a text field in the middle of a sentence, because the sentence structure might not make sense in all languages. For example, "Remind me in [textfield] weeks" should instead read, "Remind me in this many weeks: [textfield]".</li><li>Format the text field for the expected entry. For example, when someone needs to enter a phone number, use an input mask to indicate that three sets of digits should be entered.</li></ul>

          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 "><Shimmer /></div>
              <div className="ms-Grid-col ms-sm6 "><Shimmer /></div>
            </div>
          </div>
        </div>

        <Separator>Grids</Separator>

        <div className="ms-Grid grid-test" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ">A</div>
            <div className="ms-Grid-col ms-sm6 ">B</div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm2 ms-smPush10 ">A</div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 "><Shimmer /></div>
            <div className="ms-Grid-col ms-sm6 "><Shimmer /></div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm4 "><Shimmer /></div>
            <div className="ms-Grid-col ms-sm4 "><Shimmer /></div>
            <div className="ms-Grid-col ms-sm4 "><Shimmer /></div>
          </div>
        </div>

        <Separator>Icon Buttons</Separator>

        <IconButton iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add"></IconButton>
        <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add">Add Item</ActionButton>

        <Separator>Message Bars</Separator>

        {
          showMsg && (
            <>
              <MessageBar>
                Info (default) MessageBar.
                <Link href="www.bing.com" target="_blank" underline>
                  Visit our website.
                </Link>
              </MessageBar>

              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={() => setShowMsg(false)}
                dismissButtonAriaLabel="Close"
              >
                Error MessageBar with single line, with dismiss button.
                <Link href="www.bing.com" target="_blank" underline>
                  Visit our website.
                </Link>
              </MessageBar>

              <MessageBar
                actions={
                  <div>
                    <MessageBarButton onClick={() => alert('Yes')}>Yes</MessageBarButton>
                    <MessageBarButton onClick={() => alert('No')}>No</MessageBarButton>
                  </div>
                }
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >
                Success MessageBar with single line and action buttons.
                <Link href="www.bing.com" target="_blank" underline>
                  Visit our website.
                </Link>
              </MessageBar>

              <MessageBar
                messageBarType={MessageBarType.blocked}
                isMultiline={false}
                onDismiss={() => setShowMsg(false)}
                dismissButtonAriaLabel="Close"
                truncated={true}
                overflowButtonAriaLabel="See more"
              >
                <b>Blocked MessageBar - single line, with dismiss button and truncated text.</b> Truncation is not available if you
                use action buttons or multiline and should be used sparingly. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Morbi luctus, purus a lobortis tristique, odio augue pharetra metus, ac placerat nunc mi nec dui. Vestibulum
                aliquam et nunc semper scelerisque. Curabitur vitae orci nec quam condimentum porttitor et sed lacus. Vivamus ac
                efficitur leo. Cras faucibus mauris libero, ac placerat erat euismod et. Donec pulvinar commodo odio sit amet
                faucibus. In hac habitasse platea dictumst. Duis eu ante commodo, condimentum nibh pellentesque, laoreet enim. Fusce
                massa lorem, ultrices eu mi a, fermentum suscipit magna. Integer porta purus pulvinar, hendrerit felis eget,
                condimentum mauris.
              </MessageBar>
            </>
          )
        }

        <Separator>Spinners</Separator>

        <div className=''>
          <Label>Spinner with label positioned below</Label>
          <Spinner label="I am definitely loading..." size={SpinnerSize.large} />
        </div>

        <Separator>Shimmer</Separator>

        <Shimmer />
        <Shimmer width="75%" />
        <Shimmer width="50%" />
        <Shimmer shimmerElements={shimmerWithElementFirstRow} />

        <div className='w600'>

          <Stack tokens={stackTokens}>

            <Separator>Form Controls</Separator>

            <Dropdown
              label='DDL Testing'
              className='w300'
              selectedKey={stateSelItemDDL ? stateSelItemDDL.key : undefined}
              onChange={onChangeDDL}
              placeholder="Select a Borrower"
              options={[
                { key: 'Test1', text: 'Test1' },
                { key: 'Test2', text: 'Test2' },
                { key: 'Test3', text: 'Test3' }
              ]}
            />
            <div>Sel Item: [{stateSelItemDDL ? stateSelItemDDL.key : 'nill'}]</div>

            <ComboBox
              selectedKey={selectedKey}
              label="Combobox with inline options list"
              options={optionsYesNo}
              // styles={comboBoxStyles}
              autoComplete={'on'}
              onChange={(e, o, i, v) => {
                console.log(o, i, v);
                let key = o?.key;
                setSelectedKey(key);
              }}
            />

            <div>
              key: [<b>{selectedKey + ''}</b>]
            </div>

            <DatePicker
              label="Date required (with label)"
              formatDate={onFormatDate}
              isRequired
              firstDayOfWeek={DayOfWeek.Sunday}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              // DatePicker uses English strings by default. For localized apps, you must override this prop.
              strings={defaultDatePickerStrings}
            />

            <DatePicker
              label="Date"
              formatDate={(d) => !d ? '' : d.toLocaleDateString()}
              placeholder="Select a date..."
              value={selectedDate}
              onSelectDate={setSelectedDate as (date: Date | null | undefined) => void}
              allowTextInput
            />

            <div>
              selectedDate: [<b>{selectedDate + ''}</b>]
            </div>

            <DatePicker
              label="Date"
              formatDate={onFormatDate}
              placeholder="Select a date..."
              allowTextInput
            />

            <TextField label='mlot1' onChange={onChange_mlot1} value={mlot1} placeholder='Please enter text here' className='w400' multiline rows={3} />

            <TextField label='pocDollar2' onChange={onChange_pocDollar2} value={pocDollar2} placeholder='Please enter text here' className='w400' />

            <TextField label="Standard" onChange={e => setTb1(e.currentTarget.value)} value={tb1} placeholder="Please enter text here" />

            <TextField label="Standard" onChange={e => setTb1(e.currentTarget.value)} value={tb1} placeholder="Please enter text here" errorMessage={'Always bad!'} />

            <TextField label="Standard" onChange={e => setTb1(e.currentTarget.value)} value={tb1} placeholder="Please enter text here" onGetErrorMessage={(v: string) => { return v === '222' ? '' : 'Must be 222!'; }} />

            <Checkbox label="Checkbox Testing" checked={stateCheckbox1} onChange={onChangeCheckbox1} />

            <div>tb1: [<b>{tb1}</b>]</div>
            <div>pocDollar2: [<b>{pocDollar2}</b>]</div>

            <Stack horizontal tokens={stackTokens}>
              <DefaultButton text="Standard" onClick={_alertClicked} allowDisabledFocus disabled={false} />
              <PrimaryButton text="Primary" onClick={_alertClicked} allowDisabledFocus disabled={false} />
            </Stack>

          </Stack>

        </div>

      </Stack>
    </div>
  );
};
