/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox, SpinButton, Position
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import { eq, inn, safeTrim, NVL, isNull, contains } from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { ProposedCostsDetailsItem } from '../../../Models/Link/GridModels/ProposedCostsDetails1';
import { ANVendor } from '../../../Models/Link/ANVendor';
import { AjaxPicker } from '../../Controls/AjaxPicker';
import { ANRateCardItem } from '../../../Models/Link/ANRateCardItem';
import { ANType } from '../../../Models/Link/ANType';


export interface IProposedCostsRowProps {
  data: ProposedCostsDetailsItem;
  vendors: ANVendor[];
  rateCardItems: ANRateCardItem[];
  selANSubType: ANType | null;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ProposedCostsRow: React.FunctionComponent<IProposedCostsRowProps> = (props: React.PropsWithChildren<IProposedCostsRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposedCostsRow]");
  }, []);


  // const [stateLoadCount, setStateLoadCount] = useState<number>(0);
  // useEffect(() => { setStateLoadCount(p => p + 1); }, [props.data]);


  // user controlled input

  //#region 'Vendor Picker'

  const [stateSelVendorPicker, setStateSelVendorPicker] = useState<ITag[]>(() => {
    // special init, lets lookup the value from the Vendors list, and sync the latest Vendor Name, this list is being synced from DW
    if (GenUtil.isNull(props.data.dd_Vendors))
      return [];
    else {
      let match = props.vendors.find(x => GenUtil.eq(x.fields.DWBrokerID, props.data.dd_Vendors));
      if (match) {
        return [{ key: props.data.dd_Vendors, name: GenUtil.safeTrim(match.fields.Title) }]; // use latest Title from Vendor list
      }
      else {
        return [{ key: props.data.dd_Vendors, name: props.data.rpt_VendorsValue }]; // use the data saved in XML
      }
    }
  });

  const onTagsChangedVendors = (items?: ITag[]) => { setStateSelVendorPicker(!items ? [] : items); }

  const memoVendorOptions = useMemo(() => {
    // convert vendors into picker tag options
    let col: { key: string, name: string }[] = props.vendors.map(o => { return { key: GenUtil.safeTrim(o.fields.DWBrokerID), name: GenUtil.safeTrim(o.fields.Title) }; });

    // NOTE: as of 7-18-23 no longer need n/a as an option
    // if (col.findIndex(x => GenUtil.eq(x.name, "n/a")) < 0) {
    //   // add n/a if not already found in splistitem collection
    //   col = [{ key: '0', name: 'N/A' }, ...col];
    // }

    return col;
  }, [props.vendors]);

  //#endregion

  const [stateCost, setStateCost] = useState(props.data.rpt_decimal_Costs);
  const onChangeCost = useCallback((evt: any, v?: string) => { setStateCost(v || ''); }, []);

  const [stateSelCostsCurr, setStateSelCostsCurr] = useState<string>(props.data.rpt_CostsCurrency);
  const onChangeCostsCurr = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelCostsCurr(option ? option.key + '' : ''); }

  const [stateDescr, setStateDescr] = useState(props.data.rpt_Description);
  const onChangeDescr = useCallback((evt: any, v?: string) => { setStateDescr(v || ''); }, []);

  const [stateSelRateCard, setStateSelRateCard] = useState<string>(props.data.rpt_RateCard);
  const onChangeRateCard = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelRateCard(option ? option.key + '' : ''); }

  const [stateRCQuantity, setStateRCQuantity] = useState<string>(GenUtil.NVL(props.data.rpt_RCQuantity, "1"));
  const onRCQuantityChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => { setStateRCQuantity(value || ''); };

  const [stateSelPaymentMethod, setStateSelPaymentMethod] = useState<string>(props.data.rpt_PaymentMethod);
  const onChangePaymentMethod = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelPaymentMethod(option ? option.key + '' : ''); }

  const [stateSelPaidByReceiver, setStateSelPaidByReceiver] = useState<string>(props.data.rpt_PaidByReceiver);
  const onChangePaidByReceiver = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelPaidByReceiver(option ? option.key + '' : ''); }

  const [stateAmount, setStateAmount] = useState(props.data.rpt_RCAmount);
  const onChangeAmount = useCallback((evt: any, v?: string) => { setStateAmount(v || ''); }, []);


  // 'rate card type and instruction type cascading lists'

  const [stateRCTypeOptions, setStateRCTypeOptions] = useState<any[]>([]);
  const [stateSelRCType, setStateSelRCType] = useState<string>(props.data.rpt_RCType);
  const [stateSelRCTypeObject, setStateSelRCTypeObject] = useState<ANRateCardItem | null>(null);
  const onRCTypeChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelRCType(option ? option.key + '' : '');
    setStateSelRCInstrType(''); // clear the selected subtype
  };

  const [stateRCInstrTypeOptions, setStateRCInstrTypeOptions] = useState<any[]>([]);
  const [stateSelRCInstrType, setStateSelRCInstrType] = useState<string>(props.data.rpt_RCInstrType);
  const [stateSelRCInstrTypeId, setStateSelRCInstrTypeId] = useState<string>(props.data.cv_RCInstrTypeId);
  const [stateSelRCInstrTypeObject, setStateSelRCInstrTypeObject] = useState<ANRateCardItem | null>(null);
  const onRCInstrTypeChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelRCInstrType(option ? option.text + '' : '');
    setStateSelRCInstrTypeId(option ? option.key + '' : '');
  };


  useEffect(() => {
    // get selected listitem: RCType
    let obj = null;
    if (stateSelRCType !== '') {
      let objs = props.rateCardItems.filter(o => GenUtil.eq(o.fields.Title, stateSelRCType));
      if (objs.length > 0) obj = objs[0];
    }
    setStateSelRCTypeObject(obj);
  }, [props.rateCardItems, stateSelRCType]);


  useEffect(() => {
    // get selected listitem: RCInstrType
    let obj = null;
    if (stateSelRCType !== '' && stateSelRCInstrType !== '') {
      let objs = props.rateCardItems.filter(o => GenUtil.eq(o.fields.Title, stateSelRCType) && GenUtil.eq(o.fields.id, stateSelRCInstrTypeId)); // use RCInstrType itemid instead of text
      if (objs.length > 0) obj = objs[0];
    }
    setStateSelRCInstrTypeObject(obj);
  }, [props.rateCardItems, stateSelRCType, stateSelRCInstrType, stateSelRCInstrTypeId]);


  useEffect(() => {
    // generate RCType options
    let col: string[] = props.rateCardItems.map((o) => { return GenUtil.safeTrim(o.fields.Title); });
    col = _.uniq(col);
    col = _.sortBy(col);

    setStateRCTypeOptions(col.map(s => { return { key: s, text: s }; }));

  }, [props.rateCardItems]);


  useEffect(() => {
    // generate RCInstrType options
    let col: any[] = props.rateCardItems.filter(o => GenUtil.eq(o.fields.Title, stateSelRCType)).map(o => { return { key: o.fields.id, text: GenUtil.safeTrim(o.fields.InstructionType) } });
    col = _.uniqBy(col, 'key');
    col = _.sortBy(col, ['text']);

    setStateRCInstrTypeOptions(col);

  }, [props.rateCardItems, stateSelRCType]);


  // memos for rate card quantity, amount, currency fields

  const memoRCAmountIsNumber = useMemo<boolean>(() => {
    // determine if Rate Card Item amount(price) is a number
    // if its not a number in the listitem, then allow user to enter a number in a textbox
    if (!stateSelRCInstrTypeObject)
      return false;
    else {
      let amt = stateSelRCInstrTypeObject.fields.Amount;
      return GenUtil.safeToNumberOrNull(amt) != null;
    }
  }, [stateSelRCInstrTypeObject]);


  const memoRCAmount = useMemo<string>(() => {
    if (!stateSelRCInstrTypeObject) {
      return '';
    }
    else {
      if (!memoRCAmountIsNumber) {
        return GenUtil.numberToCurrency(GenUtil.safeToNumber(stateAmount));
      }
      else {
        return GenUtil.numberToCurrency(GenUtil.safeToNumber(stateSelRCInstrTypeObject.fields.Amount));
      }
    }
  }, [stateSelRCInstrTypeObject, memoRCAmountIsNumber, stateAmount]);


  const memoRCTotal = useMemo<string>(() => {
    if (!stateSelRCInstrTypeObject)
      return '';
    else {
      let qty = GenUtil.safeToNumber(stateRCQuantity);
      let amt = GenUtil.safeToNumber(memoRCAmount);
      let tot = qty * amt;
      return GenUtil.numberToCurrency(tot);
    }
  }, [stateSelRCInstrTypeObject, memoRCAmount, stateRCQuantity]);


  const memoRCCurrency = useMemo<string>(() => {
    if (!stateSelRCInstrTypeObject)
      return '';
    else {
      return GenUtil.safeTrim(stateSelRCInstrTypeObject.fields.Currency);
    }
  }, [stateSelRCInstrTypeObject]);


  // show hide fields in this section

  const memoIsRequired = useMemo<boolean>(() => {
    return GenUtil.safeToBool(props.selANSubType?.fields.MandatoryCostTable);;
  }, [props.selANSubType]);

  const memoShowPaidByReceiver = useMemo<boolean>(() => {
    if (Consts.admOvrShowAllSectionsFields()) return true;
    else return props.selANSubType ? GenUtil.safeToBool(props.selANSubType.fields.PaidByReceiver) : false;
  }, [props.selANSubType]);

  const memoShowRateCardSection = useMemo<boolean>(() => {
    return GenUtil.safeToBool(stateSelRateCard);
  }, [stateSelRateCard]);


  // send field updates back to parent

  useEffect(() => {
    // sending ITag object back, not a string
    props.updateFieldVal(props.counter, 'Vendor', stateSelVendorPicker.length > 0 ? stateSelVendorPicker[0] : null);
  }, [stateSelVendorPicker]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'Cost', GenUtil.safeTrim(stateCost));
  }, [stateCost]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'CostsCurr', GenUtil.safeTrim(stateSelCostsCurr));
  }, [stateSelCostsCurr]);

  useEffect(() => {
    // sending string value (yes/no)
    props.updateFieldVal(props.counter, 'RateCard', GenUtil.safeTrim(stateSelRateCard));
  }, [stateSelRateCard]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'Descr', GenUtil.safeTrim(stateDescr));
  }, [stateDescr]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'RCType', GenUtil.safeTrim(stateSelRCType));
    props.updateFieldVal(props.counter, 'RCTypeId', stateSelRCTypeObject?.id || '');
    props.updateFieldVal(props.counter, 'RCInstrType', GenUtil.safeTrim(stateSelRCInstrType));
    props.updateFieldVal(props.counter, 'RCInstrTypeId', GenUtil.safeTrim(stateSelRCInstrTypeId));
  }, [stateSelRCType, stateSelRCTypeObject, stateSelRCInstrType, stateSelRCInstrTypeId]);

  // useEffect(() => {
  //   props.updateFieldVal(props.counter, 'RCInstrType', GenUtil.safeTrim(stateSelRCInstrType));
  //   props.updateFieldVal(props.counter, 'RCInstrTypeId', stateSelRCInstrTypeObject?.id || '');
  // }, [stateSelRCInstrType]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'RCQuantity', GenUtil.safeTrim(stateRCQuantity));
    props.updateFieldVal(props.counter, 'RCAmount', GenUtil.safeTrim(memoRCAmount));
    props.updateFieldVal(props.counter, 'RCTotal', GenUtil.safeTrim(memoRCTotal));
    props.updateFieldVal(props.counter, 'RCCurrency', GenUtil.safeTrim(memoRCCurrency));
  }, [stateRCQuantity, memoRCAmount, memoRCTotal, memoRCCurrency]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'PaymentMethod', GenUtil.safeTrim(stateSelPaymentMethod));
  }, [stateSelPaymentMethod]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'PaidByReceiver', GenUtil.safeTrim(stateSelPaidByReceiver));
  }, [stateSelPaidByReceiver]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  // NOTE: as of 7-18-23 no longer need n/a as an option, therefore this effect is not needed
  // useEffect(() => {
  //   // when the user chooses "N/A" as the vendor, default all the fields to 0, n/a, etc.
  //   // this should not happen to prior saved data loaded into component
  //   // this happens regardless of the ANType mandatory costs field requirement
  //   // console.log('TESTTESTTESTTESTTESTTESTTESTTEST', props.counter, props.totCount, stateLoadCount, JSON.stringify(stateSelVendorPicker));
  //   let match = stateSelVendorPicker.find(x => x.key === "0");
  //   if (match) {
  //     // if choice is N/A and the selection is AFTER the form already loaded with saved data, then set these default values
  //     // this will help if the prior saved data had N/A as a choice, and fields were left blank
  //     if (stateLoadCount > 0) {
  //       if (isNull(stateCost))
  //         setStateCost("0");
  //       if (isNull(stateSelCostsCurr))
  //         setStateSelCostsCurr("EUR");
  //       if (isNull(stateDescr))
  //         setStateDescr("N/A");
  //       if (isNull(stateSelPaymentMethod))
  //         setStateSelPaymentMethod("N/A");
  //       if (isNull(stateSelPaidByReceiver))
  //         setStateSelPaidByReceiver("N/A");
  //     }
  //   }

  // }, [stateSelVendorPicker, stateLoadCount]);


  //#region 'validation'
  //-------------------------

  // NOTE: entire section is always shown, and either all required, or all optional
  //   based on ANType field "MandatoryCostTable"


  // vendor picker
  // NOTE: lets make vendor always required, since its the first field and important, user can choose "n/a" if needed to continue
  const memoIsErrVendor = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrVendor();
  }, [props.stateFormSubmitted, stateSelVendorPicker]);

  function isErrVendor() {
    // if (!memoIsRequired) return false;
    if (stateSelVendorPicker.length <= 0) return true;
    return false;
  }


  // rate card choice
  const memoIsErrRateCardDDL = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRateCardDDL();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard]);

  function isErrRateCardDDL() {
    if (!memoIsRequired) return false;
    return GenUtil.isNull(stateSelRateCard);
  }


  // amount slot
  const memoIsErrAmount = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrAmount();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, stateCost]);

  function isErrAmount() {
    if (!memoIsRequired) return false;
    if (GenUtil.safeToBool(stateSelRateCard)) return false;
    return GenUtil.safeToNumberOrNull(stateCost) == null;
  }


  // costs currency ddl
  const memoIsErrCurrency = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrCurrency();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, stateSelCostsCurr]);

  function isErrCurrency() {
    if (!memoIsRequired) return false;
    if (GenUtil.safeToBool(stateSelRateCard)) return false;
    return GenUtil.isNull(stateSelCostsCurr);
  }

  //--------------------
  // rate card related:

  // rate card type ddl
  const memoIsErrRateCardType = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRateCardType();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, stateSelRCType]);

  function isErrRateCardType() {
    if (!memoIsRequired) return false;
    if (!GenUtil.safeToBool(stateSelRateCard)) return false;
    return GenUtil.isNull(stateSelRCType);
  }

  // instruction type ddl
  const memoIsErrRateCardInstrType = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRateCardInstrType();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, stateSelRCType, stateSelRCInstrType, stateSelRCInstrTypeId]);

  function isErrRateCardInstrType() {
    if (!memoIsRequired) return false;
    if (!GenUtil.safeToBool(stateSelRateCard)) return false;
    return GenUtil.isNull(stateSelRCInstrType);
  }

  // quantity slot (number) [stateRCQuantity]
  const memoIsErrRateCardQty = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRateCardQty();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, stateRCQuantity]);

  function isErrRateCardQty() {
    if (!memoIsRequired) return false;
    if (!GenUtil.safeToBool(stateSelRateCard)) return false;
    return GenUtil.safeToNumberOrNull(stateRCQuantity) == null;
  }

  // amount slot (number) (2 fields: user input, readonly from lookup) [stateAmount]
  const memoIsErrRCAmount = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRCAmount();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelRateCard, memoRCAmountIsNumber, stateAmount]);

  function isErrRCAmount() {
    if (!memoIsRequired) return false;
    if (!GenUtil.safeToBool(stateSelRateCard)) return false;
    if (memoRCAmountIsNumber) return false;
    return GenUtil.safeToNumberOrNull(stateAmount) == null;
  }

  //--------------------

  // payment method ddl
  const memoIsErrPaymentMethod = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrPaymentMethod();
  }, [props.stateFormSubmitted, memoIsRequired, stateSelPaymentMethod]);

  function isErrPaymentMethod() {
    if (!memoIsRequired) return false;
    return GenUtil.isNull(stateSelPaymentMethod);
  }


  // paid by receiver ddl (if shown)
  const memoIsErrPaidByReceiver = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrPaidByReceiver();
  }, [props.stateFormSubmitted, memoIsRequired, memoShowPaidByReceiver, stateSelPaidByReceiver]);

  function isErrPaidByReceiver() {
    if (!memoIsRequired) return false;
    if (!memoShowPaidByReceiver) return false;
    return GenUtil.isNull(stateSelPaidByReceiver);
  }


  // description
  const memoIsErrDescr = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrDescr();
  }, [props.stateFormSubmitted, memoIsRequired, stateDescr]);

  function isErrDescr() {
    if (!memoIsRequired) return false;
    return GenUtil.isNull(stateDescr);
  }


  //#endregion


  return (
    <>

      <tr className='sep'>

        {/* <td style={{ minWidth: 8, textAlign: 'left' }}>
          <Label style={{ marginTop: '28px', display: 'inline-block' }}>{props.counter + 1}</Label>
        </td> */}

        <td>

          <div className="ms-Grid" dir="ltr">

            <div className="ms-Grid-row">

              <div className="ms-Grid-col ms-sm6">

                {!props.isReadOnly && (
                  <Stack tokens={{ childrenGap: 0 }}>
                    <Label>{`Vendor:`}</Label>
                    <AjaxPicker
                      disabled={false}
                      itemLimit={1}
                      suggestedTags={memoVendorOptions}
                      onTagsChanged={onTagsChangedVendors}
                      selectedTags={stateSelVendorPicker}
                      noResultsFoundText=''
                      suggestionsHeaderText={`Search for Vendors`}
                      searchingText='Loading...'
                      placeholder={`Enter Vendor Name'`}
                      cssClassNames={`w100p ${(memoIsErrVendor ? "invalid-datepicker" : "")}`}
                    />
                    {
                      memoIsErrVendor && (
                        <Label className='red-msg-text clr' style={{ margin: '4px 0 0 0' }}>Vendor is required</Label>
                      )
                    }
                  </Stack>
                )}
                {props.isReadOnly && (<>
                  <Label>Vendor:</Label>
                  <Label className='ms-fontWeight-regular'>{props.data.rpt_VendorsValue}</Label>
                </>)}

              </div>

              <div className="ms-Grid-col ms-sm2">

                {!props.isReadOnly && (
                  <ComboBox
                    label='Rate Card'
                    // className='w100p'
                    disabled={false}
                    selectedKey={stateSelRateCard}
                    placeholder='Choose...'
                    options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                    onChange={onChangeRateCard}
                    errorMessage={memoIsErrRateCardDDL ? 'Value is required.' : ''}
                  />
                )}
                {props.isReadOnly && (<>
                  <Label>Rate Card:</Label>
                  <Label className='ms-fontWeight-regular'>{stateSelRateCard}</Label>
                </>)}

              </div>

              {
                props.totCount > 1 && !props.isReadOnly && (
                  <div className="ms-Grid-col ms-sm4" style={{ textAlign: 'right' }}>
                    <Label style={{ visibility: 'hidden' }}>DELETE</Label>
                    <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
                  </div>
                )
              }


            </div>

            {
              !GenUtil.safeToBool(stateSelRateCard) && (
                <>
                  <div className="ms-Grid-row">

                    <div className="ms-Grid-col ms-sm6">

                      {!props.isReadOnly && (
                        <TextField
                          label='Amount'
                          onChange={onChangeCost}
                          value={stateCost}
                          placeholder='Enter cost'
                          className='w100p'
                          errorMessage={memoIsErrAmount ? 'Number is required.' : ''}
                          maxLength={255} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Amount:</Label>
                        <Label className='ms-fontWeight-regular'>{stateCost}</Label>
                      </>)}

                    </div>

                    <div className="ms-Grid-col ms-sm6">

                      {!props.isReadOnly && (
                        <ComboBox
                          label='Currency'
                          className='w100p'
                          disabled={false}
                          selectedKey={stateSelCostsCurr}
                          placeholder='Choose...'
                          options={StaticData.costsCurrency3.split(',').map(o => { return { key: o, text: o }; })}
                          onChange={onChangeCostsCurr}
                          errorMessage={memoIsErrCurrency ? 'Value is required.' : ''}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Currency:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelCostsCurr}</Label>
                      </>)}

                    </div>

                  </div>
                </>
              )
            }

          </div>

          {/* </td> */}

          {/* <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            !props.isReadOnly && (
              <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          } */}
          {/* </td>

      </tr>

      <tr>
        <td colSpan={1} style={{ paddingTop: '0' }}> */}

          <div className="ms-Grid" dir="ltr">

            {
              memoShowRateCardSection && (
                <>

                  <div className="ms-Grid-row">

                    <div className="ms-Grid-col ms-sm6">

                      {!props.isReadOnly && (
                        <ComboBox
                          disabled={false}
                          selectedKey={stateSelRCType}
                          label="Rate Card Type:"
                          placeholder='Choose...'
                          options={stateRCTypeOptions}
                          // autoComplete={'on'}
                          onChange={onRCTypeChange}
                          errorMessage={memoIsErrRateCardType ? "Value is required." : ""}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Rate Card Type:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelRCType}</Label>
                      </>)}

                    </div>

                    <div className="ms-Grid-col ms-sm6">

                      {!props.isReadOnly && (
                        <ComboBox
                          disabled={stateSelRCTypeObject == null}
                          selectedKey={stateSelRCInstrTypeId}
                          label="Instruction Type:"
                          placeholder='Choose...'
                          options={stateRCInstrTypeOptions}
                          // autoComplete={'on'}
                          onChange={onRCInstrTypeChange}
                          errorMessage={memoIsErrRateCardInstrType ? "Value is required." : ""}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Instruction Type:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelRCInstrType}</Label>
                      </>)}

                    </div>

                  </div>

                  <div className="ms-Grid-row">

                    <div className="ms-Grid-col ms-sm3">

                      {!props.isReadOnly && (
                        <>
                          {
                            memoRCAmountIsNumber ? (
                              <TextField label='Amount:' onChange={() => { }} readOnly value={memoRCAmount} className='bgGrey' />
                            ) : (
                              <TextField label='Amount:' onChange={onChangeAmount} value={stateAmount} placeholder='Enter price' className='' errorMessage={memoIsErrRCAmount ? "Number is required." : ""} maxLength={255} />
                            )
                          }
                        </>
                      )}
                      {props.isReadOnly && (<>
                        <Label>Amount:</Label>
                        <Label className='ms-fontWeight-regular'>{memoRCAmount}</Label>
                      </>)}

                    </div>

                    <div className="ms-Grid-col ms-sm3">

                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <SpinButton
                            label="Quantity:"
                            labelPosition={Position.top}
                            min={1}
                            max={10}
                            step={1}
                            incrementButtonAriaLabel="Increase value by 1"
                            decrementButtonAriaLabel="Decrease value by 1"
                            onChange={onRCQuantityChange}
                            value={GenUtil.isNull(stateRCQuantity) ? '1' : stateRCQuantity}
                            className={`${(memoIsErrRateCardQty ? "invalid-datepicker" : "")}`}
                          />
                          {
                            memoIsErrRateCardQty && (
                              <Label className='red-msg-text clr'>Number is required.</Label>
                            )
                          }
                        </Stack>
                      )}
                      {props.isReadOnly && (<>
                        <Label>Quantity:</Label>
                        <Label className='ms-fontWeight-regular'>{stateRCQuantity}</Label>
                      </>)}

                    </div>

                    <div className="ms-Grid-col ms-sm3">

                      {!props.isReadOnly && (
                        <TextField label='Currency:' onChange={() => { }} readOnly value={memoRCCurrency} className='bgGrey' />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Currency:</Label>
                        <Label className='ms-fontWeight-regular'>{memoRCCurrency}</Label>
                      </>)}

                    </div>

                    <div className="ms-Grid-col ms-sm3">

                      {!props.isReadOnly && (
                        <TextField label={`Total:`} onChange={() => { }} readOnly value={memoRCTotal} className='bgGrey' />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Total:</Label>
                        <Label className='ms-fontWeight-regular'>{memoRCTotal}</Label>
                      </>)}
                    </div>



                  </div>

                </>
              )
            }

            <div className="ms-Grid-row">

              <div className="ms-Grid-col ms-sm6">

                {!props.isReadOnly && (
                  <ComboBox
                    label='Payment Method:'
                    className=''
                    disabled={false}
                    selectedKey={stateSelPaymentMethod}
                    placeholder='Choose...'
                    options={StaticData.paymentMethodOptions.split(',').map(o => { return { key: o, text: o }; })}
                    onChange={onChangePaymentMethod}
                    errorMessage={memoIsErrPaymentMethod ? "Value is required." : ""}
                  />
                )}
                {props.isReadOnly && (<>
                  <Label>Payment Method:</Label>
                  <Label className='ms-fontWeight-regular'>{stateSelPaymentMethod}</Label>
                </>)}
              </div>

              <div className="ms-Grid-col ms-sm6">

                {
                  memoShowPaidByReceiver && (
                    <>
                      {!props.isReadOnly && (
                        <ComboBox
                          label='Paid by Receiver:'
                          className=''
                          disabled={false}
                          selectedKey={stateSelPaidByReceiver}
                          placeholder='Choose...'
                          options={StaticData.luYesNoNA.split(',').map(o => { return { key: o, text: o }; })}
                          onChange={onChangePaidByReceiver}
                          errorMessage={memoIsErrPaidByReceiver ? "Value is required." : ""}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Paid by Receiver:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelPaidByReceiver}</Label>
                      </>)}
                    </>
                  )
                }

              </div>

            </div>

            <div className="ms-Grid-row">

              <div className="ms-Grid-col ms-sm6">
                {!props.isReadOnly && (
                  <TextField label='Description:' onChange={onChangeDescr} value={stateDescr} placeholder='Please enter text here' className='' maxLength={255} errorMessage={memoIsErrDescr ? "Value is required." : ""} />
                )}
                {props.isReadOnly && (<>
                  <Label>Description:</Label>
                  <Label className='ms-fontWeight-regular'>{stateDescr}</Label>
                </>)}
              </div>

            </div>

          </div>

        </td>
      </tr>

    </>
  );
};