/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';

import * as Consts from "../../../Helpers/Consts";
import { AssetConnDetail } from '../../../Models/TDX/GridModels/AssetConnDetail';


export interface IANAssetConnDetailROProps {
  data: AssetConnDetail[];
}

export const ANAssetConnDetailRO: React.FunctionComponent<IANAssetConnDetailROProps> = (props: React.PropsWithChildren<IANAssetConnDetailROProps>) => {
  // RO = ReadOnly

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANAssetConnDetailRO]");
  }, []);


  if (props.data.length <= 0) {
    return null;
  }
  else {
    return (
      <>
        <table className='sub-table wbsss'>
          <thead>
            <tr>
              <th>{`CES Connection ID`}</th>
              <th>{`Servicer Connection ID`}</th>
              <th>{`Connection Name`}</th>
              <th>{`Borrower ID`}</th>
              <th>{`Borrower Name`}</th>
            </tr>
          </thead>
          <tbody>

            {
              props.data.map((o, i) => {
                return (
                  <tr key={i}>
                    <td>{o.connIdCes}</td>
                    <td>{o.connIdSvr}</td>
                    <td>{o.connName}</td>
                    <td>{o.borId}</td>
                    <td>{o.borName}</td>
                  </tr>
                );
              })
            }

          </tbody>
        </table>
      </>
    );
  }
};