/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalConfig, msalScopes } from '../Helpers/AuthConfig';
import { Home } from './Home';
import { useEffect } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

import * as Consts from "../Helpers/Consts";
import * as GenUtil from '../Helpers/GenUtil2';


export interface IAuthLauncherProps { }

export const AuthLauncher: React.FunctionComponent<IAuthLauncherProps> = (props: React.PropsWithChildren<IAuthLauncherProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: AuthLauncher]");
  }, []);


  // make the 'redirectUri' dynamic, so it works the same in workbench/localhost:3000 and deployed in Azure, without having to save it as configData
  let _msal = msalConfig();
  _msal.auth.redirectUri = (window as any).location.protocol + '//' + (window as any).location.host + '/'; // this would pretty much exact match the Redirect URIs in the AAD app registration
  // _msal.auth.redirectUri = (window as any).location.href; // trying something new 9/27/23, found in MS msal research (this causes an error, about mismatch of the Redirect URIs)

  const msalInstance = new PublicClientApplication(_msal);

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <SingleSignOn></SingleSignOn>
      </MsalProvider>
    </>
  );
};


interface ISingleSignOnProps { }

const SingleSignOn: React.FunctionComponent<ISingleSignOnProps> = (props: React.PropsWithChildren<ISingleSignOnProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANConnectionDetail]");
  }, []);


  const { instance } = useMsal();


  useEffect(() => {
    let ignore = false;

    setTimeout(async () => {

      try {
        let tokenResponse = await instance.handleRedirectPromise();

        let accountObj;
        if (tokenResponse) {
          accountObj = tokenResponse.account;
        } else {
          accountObj = instance.getAllAccounts()[0];
        }

        if (accountObj && tokenResponse) {
          console.log("[AuthService.init] Got valid accountObj and tokenResponse");
        } else if (accountObj) {
          console.log("[AuthService.init] User has logged in, but no tokens.");
          try {
            tokenResponse = await instance.acquireTokenSilent({
              account: instance.getAllAccounts()[0],
              ...msalScopes()
            });
          } catch (err) {
            await instance.acquireTokenRedirect({ ...msalScopes() });
          }
        } else {
          console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
          await instance.loginRedirect({ ...msalScopes() });
        }
      } catch (error) {
        console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
      }

    }, Consts.sleepMsSignIn);

    return () => { ignore = true; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <AuthenticatedTemplate>
        <Home useAuth={true}></Home>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className='wrapper25'>
          <Spinner label="Signing In User..." size={SpinnerSize.large} />
        </div>
      </UnauthenticatedTemplate>

    </>
  );
};
