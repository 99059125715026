/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Finsolutia/GraphDataService';
import * as StaticData from "../../../StaticData/Finsolutia/StaticData";

import { BorrowerInfoRow } from './BorrowerInfoRow';
import { BorrowerInfo1, BorrowerInfoItem } from '../../../Models/Finsolutia/GridModels/BorrowerInfo1';
import { BorrowerInfoN } from '../../../Models/Finsolutia/GridModels/BorrowerInfoN';
import { ANConnection } from '../../../Models/Finsolutia/ANConnection';


export interface IBorrowerInfoBodyProps {
  data: string | undefined;
  options: IDropdownOption[];
  connections: ANConnection[];
  onDataUpdated: (s: string, d: any) => void;
  isReadOnly: boolean;
  removeConnId: string;
}

export const BorrowerInfoBody: React.FunctionComponent<IBorrowerInfoBodyProps> = (props: React.PropsWithChildren<IBorrowerInfoBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorrowerInfoBody]");
  }, []);


  const [stateRows, setStateRows] = useState<BorrowerInfoItem[]>([]);

  const [stateSelItem, setStateSelItem] = useState<IDropdownOption>();
  const onChange = (event: any, option?: IDropdownOption, index?: number) => { setStateSelItem(option); };

  const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);


  useEffect(() => {
    props.onDataUpdated('borrowers', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // use local state for options, since we have to remove options that are already added to the list
    // remove from options any item that is added to the Grid
    let ids = stateRows.map(o => o.cv_BorrowerID);
    let col = [...props.options].filter(o => ids.indexOf(o.key + '') < 0);
    setStateOptions(col);
  }, [props.options, stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: BorrowerInfoItem[] = [];

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('BorrowerInfo', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as BorrowerInfo1).RepeaterData.Items.Item;

          let item: BorrowerInfoItem = {
            cv_ConnId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId)),
            cv_BorrID: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrID)),
            cv_BorrowerID: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrowerID)),
            cv_BorrowerName: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrowerName)),
            rpt_BorrowerName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BorrowerName)),
            // rpt_LegalStatus: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LegalStatus)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.cv_BorrowerID).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as BorrowerInfoN;

          let _col: BorrowerInfoItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {
              cv_ConnId: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnId)),
              cv_BorrID: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrID)),
              cv_BorrowerID: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrowerID)),
              cv_BorrowerName: GenUtil.safeTrim(AppHelper.getText(_t.cv_BorrowerName)),
              rpt_BorrowerName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BorrowerName)),
              // rpt_LegalStatus: GenUtil.safeTrim(AppHelper.getText(_t.rpt_LegalStatus)),
            };
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.cv_BorrowerID));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in BorrowerInfoBody`, props.data, error); }
    }
    else {
      col = [];
    }

    setStateRows(col);

  }, [props.data]);


  function onClickAddID() {
    // add specific item from DDL to grid

    if (!stateSelItem) {
      return;
    }

    let selKey = GenUtil.safeTrim(stateSelItem.key);

    if (GenUtil.isNull(selKey)) {
      return;
    }

    // prevent same item adding more than once
    if (stateRows.filter(x => GenUtil.eq(x.cv_BorrowerID, selKey)).length > 0) return;

    let col = props.connections.filter(o => GenUtil.eq(o.fields.Borrower_x0020_ID, selKey)).map((o, i) => {
      return {
        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),
        cv_BorrID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        cv_BorrowerID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        cv_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        rpt_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        // user controlled
        // rpt_LegalStatus: '',
      };
    });

    if (col.length <= 0) {
      return;
    }

    let obj = col[0];

    setStateRows(p => {
      return [...p, obj];
    });
  }


  function onClickAddAll() {
    // add all items from DDL to grid

    let col = [...stateRows];

    let col2 = props.connections.filter(a => {
      return col.findIndex(b => b.cv_BorrowerID === a.fields.Borrower_x0020_ID) < 0 && !GenUtil.isNull(a.fields.Borrower_x0020_ID);
    }).map(o => {
      return {
        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),
        cv_BorrID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        cv_BorrowerID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        cv_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        rpt_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        // user controlled
        // rpt_LegalStatus: '',
      };
    });

    col = [...col, ...col2];

    setStateRows(col);
  }


  function handleDeleteItem(id: string) {
    // remove the item from the grid

    setStateRows(p => {
      return p.filter(o => !GenUtil.eq(o.cv_BorrowerID, id));
    });
  }


  useEffect(() => {
    // delete all rows with the connId sent from parent
    let id = GenUtil.safeTrim(props.removeConnId);

    if (!GenUtil.isNull(id)) {
      setStateRows(p => {
        return p.filter(o => !GenUtil.eq(o.cv_ConnId, id));
      });
    }
  }, [props.removeConnId]);


  function onClickReset() {
    setStateRows([]);
    setStateOptions([...props.options]);
    if (props.options.length > 1) setStateSelItem(undefined);
    // setStateSelItem(undefined); // do not reset this, single value DDLs get stuck, cannot trigger an onchange
  }


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        {
          !props.isReadOnly && (
            <>
              <Stack tokens={Consts.stackTokens} horizontal className='wbss' verticalAlign='baseline'>
                <div>{`Borrower ID:`}</div>
                <Dropdown
                  className='w400'
                  selectedKey={stateSelItem ? stateSelItem.key : undefined}
                  onChange={onChange}
                  placeholder={stateOptions.length > 0 ? "Select a Borrower" : 'No Borrowers Found'}
                  options={stateOptions}
                />
                <PrimaryButton text="Add Borrower ID" allowDisabledFocus onClick={onClickAddID} disabled={stateOptions.length <= 0} />
                <PrimaryButton text="Add All" allowDisabledFocus onClick={onClickAddAll} disabled={stateOptions.length <= 0} />
                {stateRows.length > 0 && <PrimaryButton text="Reset" allowDisabledFocus onClick={onClickReset} />}
              </Stack>
            </>
          )
        }

        <table className='sub-table3'>
          <thead>

            <tr>
              {
                Consts.isWorkbench() && (
                  <th style={{ color: 'purple' }}>
                    {`Connection ID`}
                  </th>
                )
              }
              {
                Consts.isWorkbench() && (
                  <th style={{ color: 'purple' }}>
                    {`Borrower ID`}
                  </th>
                )
              }
              <th>{`Borrower Name`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>
            {
              stateRows.map((o, i) => <BorrowerInfoRow
                key={i}
                data={o}
                handleDeleteItem={handleDeleteItem}
                isReadOnly={props.isReadOnly} />)
            }
          </tbody>
        </table>

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>props.options: {JSON.stringify(props.options, null, 2)}</li>
              <li>stateOptions: {JSON.stringify(stateOptions, null, 2)}</li>
              <li>stateSelItem: {JSON.stringify(stateSelItem, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};