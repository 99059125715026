/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Consts from "./Consts";
import * as GenUtil from './GenUtil2';
import * as JsonXmlHelper from './JsonXmlConverter';
import { toast } from 'react-toastify';

import { ANCurrency as ANCurrencyFinSol } from "../Models/Finsolutia/ANCurrency";
import { ANCurrency as ANCurrencyLink } from "../Models/Link/ANCurrency";
import * as StaticDataFinSol from "../StaticData/Finsolutia/StaticData";


export default class AppHelper {
  // use this for helpful intellisense, type "AppHelper" and it comes up to import this file, then change it to "import * as AppHelper from [...]"
}


export function aiTrackEvent(svc: string, anId: any, msg: string): void {
  if (!!(window as any).appInsights === false) return;
  try {
    (window as any).appInsights.trackEvent({
      name: `ADVISORY NOTES DATA 180527 (svc=${svc}) (anId=${anId}) (anUser=${(window as any).curUserEmail})`,
      properties: {
        userEmail: (window as any).curUserEmail,
        userDispName: (window as any).curUserDispName,
        pageUrl: window.location.href,
        msg
      }
    });
  } catch (error) {
    console.error("*** ERROR @ aiTrackEvent", error);
  }
}


export function aiTrackData(svc: string, anId: any, msg: string, data: any): void {
  if (!!(window as any).appInsights === false) return;
  try {
    (window as any).appInsights.trackEvent({
      name: `ADVISORY NOTES DATA 180686 (svc=${svc}) (anId=${anId}) (anUser=${(window as any).curUserEmail})`,
      properties: {
        userEmail: (window as any).curUserEmail,
        userDispName: (window as any).curUserDispName,
        pageUrl: window.location.href,
        msg,
        ...data
      }
    });
  } catch (error) {
    console.error("*** ERROR @ aiTrackData", error);
  }
}


export function aiTrackErr(svc: string, anId: any, msg: string, data: any = null): void {
  if (!!(window as any).appInsights === false) return;
  try {
    let hs = data == null ? "" : GenUtil.safeTrim(data.httpStatus);
    hs = GenUtil.isNull(hs) ? "" : `(httpStatus=${hs})`;
    (window as any).appInsights.trackEvent({
      name: [`ADVISORY NOTES ERROR 180765`, `(svc=${svc})`, `(anId=${anId})`, `(anUser=${(window as any).curUserEmail})`, hs].map(x => GenUtil.safeTrim(x)).filter(x => !GenUtil.isNull(x)).join(" "),
      properties: {
        userEmail: (window as any).curUserEmail,
        userDispName: (window as any).curUserDispName,
        pageUrl: window.location.href,
        msg,
        data: data ? JSON.stringify(data, null, 2) : '',
        ...data
      }
    });
  } catch (error) {
    console.error("*** ERROR @ aiTrackErr", error);
  }
}


export function aiTrackDataErr(svc: string, anId: any, msg: string, data: any = null): void {
  if (!!(window as any).appInsights === false) return;
  try {
    let hs = data == null ? "" : GenUtil.safeTrim(data.httpStatus);
    hs = GenUtil.isNull(hs) ? "" : `(httpStatus=${hs})`;
    (window as any).appInsights.trackEvent({
      name: [`ADVISORY NOTES ERROR 180927`, `(svc=${svc})`, `(anId=${anId})`, `(anUser=${(window as any).curUserEmail})`, hs].map(x => GenUtil.safeTrim(x)).filter(x => !GenUtil.isNull(x)).join(" "),
      properties: {
        userEmail: (window as any).curUserEmail,
        userDispName: (window as any).curUserDispName,
        pageUrl: window.location.href,
        msg,
        data: data ? JSON.stringify(data, null, 2) : '',
        ...data
      }
    });
  } catch (error) {
    console.error("*** ERROR @ aiTrackDataErr", error);
  }
}


/** get filename from url, ex. "somelongurl/filename.txt" will return "filename.txt", uses safeTrim, if no forward slash found returns param safeTrimmed. */
export function getFilename(url: string | null | undefined) {
  let s = GenUtil.safeTrim(url).replace(/\\/ig, "/"); // normalize forward slashes "\" to back slashes "/"
  if (GenUtil.isNull(s) || s.indexOf("/") < 0)
    return s;
  else
    return s.substring(s.lastIndexOf("/") + 1);
}


export function toastError(msg: string) {
  toast(msg, {
    position: 'top-right',
    theme: 'colored',
    type: 'error',
    closeOnClick: true,
    closeButton: true,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: Consts.toastDismissLong * 1000,
    onClick: (e) => { console.log(e); }
  });
}

export function toastWarn(msg: string) {
  toast(msg, {
    position: 'top-right',
    theme: 'colored',
    type: 'warning',
    closeOnClick: true,
    closeButton: true,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: Consts.toastDismissMedium * 1000,
    onClick: (e) => { console.log(e); }
  });
}

export function toastSuccess(msg: string) {
  toast(msg, {
    position: 'top-right',
    theme: 'colored',
    type: 'success',
    closeOnClick: true,
    closeButton: true,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: Consts.toastDismissLong * 1000,
    onClick: (e) => { console.log(e); }
  });
}

export function toastInfo(msg: string) {
  toast(msg, {
    position: 'top-right',
    theme: 'colored',
    type: 'info',
    closeOnClick: true,
    closeButton: true,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: Consts.toastDismissShort * 1000,
    onClick: (e) => { console.log(e); }
  });
}


export function getText(o: any) {
  // used when parsing xml to JSON, and accessing a text property that may be missing
  // especially can happen if the old xml string is missing a newer(added later) property that this code is expecting to read
  // ex. dd_TypeOfCosts: GenUtil.safeTrim(_t.dd_TypeOfCosts['#text']),
  // this field 'dd_TypeOfCosts' is not in the old XML, but is expected in the latest UI
  // accessing _t.dd_TypeOfCosts['#text'] when its missing generates an error: TypeError: Cannot read properties of undefined (reading '#text')
  // because the property 'dd_TypeOfCosts' is MyTuple and would be undefined

  // NOTE: rich text html is NOT stored in XML in this app, they are mutually exclusive
  return !!o ? GenUtil.replaceHtmlSpecialChars(o['#text']) : '';
}


export function renderDSIH(html: string): any {
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}


export function getJsonObjFromXmlStr(_name: string, _xml: string): any | null {

  let name = GenUtil.safeTrim(_name);
  let xml = GenUtil.safeTrim(_xml);

  try {
    // console.log('Processing XML...', name, xml);

    let dom = JsonXmlHelper.parseXml(xml);

    // console.log('DOM', dom);

    let jsonStr = JsonXmlHelper.xml2json(dom, "  ");

    // console.log('JSON', jsonStr);

    let obj = JSON.parse(jsonStr);

    return obj;

  } catch (error) {
    console.error('ERROR parsing XML from String', name, xml, error);
    return null;
  }
}


export function xmlHasMultipleItems(xml: string): boolean {

  if (!!xml === false || xml.trim().length === 0) return false;

  return GenUtil.countStrInStr(xml, '<Item>') > 1 ? true : false;
}


export function isVendorOther(name: string): boolean {
  // NOTE: as of 2-16-23
  // trim the name, remove the spaces, and compare (case matters!) with Constant value
  // this is a special Other value that triggers loading a textbox in Nintex forms
  // in this app the Other textbox is NOT needed, instead Type of Costs is used
  return GenUtil.safeTrim(name).replace(/\s/ig, '').indexOf(Consts.vendorOtherLabel) >= 0;
}


export function convertCurrency(amount: number, currency: string, currencys: ANCurrencyFinSol[] | ANCurrencyLink[]): number {
  // given the original amount, and the original currency, return the converted amount using the lookup table (converting to EUR)
  // #todo if original curr is unavail, then what should happen? default to "EUR", skip converting, return "0"?

  console.log("Converting", amount, currency, currencys.map(o => o.fields));

  if (amount === 0)
    return 0; // nothing to convert, quit
  else if (GenUtil.isNull(currency))
    return 0; // currency unavail, quit
  else if (GenUtil.eq(currency, StaticDataFinSol.costsCurrency))
    return amount; // already EUR, nothing to convert, quit

  // find matching currency from props, if nothing found quit
  let currs = currencys.filter(o => GenUtil.eq(o.fields.Title, currency));

  if (currs.length > 0) {
    let curr = currs[0];
    let rate = GenUtil.safeToNumber(curr.fields.Value);
    if (rate <= 0) {
      // rate must be greater than 0
      console.log("Currency rate <= 0");
      return 0;
    }
    else {
      // actual conversion from other currency to EUR
      let calc: number = amount * (1 / rate);
      console.log("Converting", amount, currency, currencys.map(o => o.fields), calc);
      return +calc.toFixed(2);
    }
  }
  else {
    console.log("Currency match not found");
    return 0;
  }
}


/**
 * can return "" if either a or b is null, or a real currency calc from a-b
 */
export function calcDelta(a: string, b: string): string {
  if (GenUtil.isNull(a) || GenUtil.isNull(b)) return '';
  else if (GenUtil.safeToNumberOrNull(a) == null || GenUtil.safeToNumberOrNull(b) == null) return '';
  else return GenUtil.numberToCurrency(GenUtil.safeToNumber(a) - GenUtil.safeToNumber(b));
}
