/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as ConvertToXml from '../../../Services/Intrum/ConvertToXml';

import { ConnectionDetailsItem } from '../../../Models/Intrum/GridModels/ConnectionDetails1';


export interface IANConnectionDetailROProps {
  data: string | undefined;
}

export const ANConnectionDetailRO: React.FunctionComponent<IANConnectionDetailROProps> = (props: React.PropsWithChildren<IANConnectionDetailROProps>) => {
  // ConnectionDetail component for readonly mode. Load the XML saved in SPItem, show this data, because the Connection item loaded from Connection lookup may fail if that Connection is eventually removed.
  // RO = ReadOnly

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANConnectionDetailRO]");
  }, []);


  const [stateConnCache, setStateConnCache] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    let col: ConnectionDetailsItem[] = ConvertToXml.cvtXml2ConnectionDetailsItem(GenUtil.safeTrim(props.data));
    setStateConnCache(col);
  }, [props.data]);


  if (stateConnCache.length <= 0) {
    return null;
  }
  else {
    return (
      <>
        <table className='sub-table wbsss'>
          <thead>
            <tr>
              <th>{`CES Connection ID`}</th>
              <th>{`Servicer Connection ID`}</th>
              <th>{`Connection Name`}</th>
              <th>{`Connection Status`}</th>
            </tr>
          </thead>
          <tbody>

            {
              stateConnCache.map((o, i) => {
                return (
                  <tr key={i}>
                    <td>{o.rpt_CESConnID}</td>
                    <td>{o.rpt_ConnecID}</td>
                    <td>{o.rpt_ConnectionName}</td>
                    <td>{o.rpt_ConnectionStatus}</td>
                  </tr>
                );
              })
            }

          </tbody>
        </table>
      </>
    );
  }
};