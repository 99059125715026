import { LogLevel } from "@azure/msal-browser";
import * as Config from './ConfigData';
import * as Consts from "./Consts";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */


export function msalConfig() {
  return {
    auth: {
      clientId: Config.Settings().AppRegClientId,
      authority: Consts.msalAuthorityUri + Config.Settings().AppRegTenantId,
      redirectUri: '' // this will be dynamic, read from JS window.location information
      // redirectUri: Config.AppRegRedirectUri
    },
    cache: {
      cacheLocation: "localStorage", // Location of token cache in browser.	String value that must be one of the following: "sessionStorage", "localStorage", "memoryStorage"	
      // cacheLocation: "sessionStorage", // This is the Default value.
      storeAuthStateInCookie: true, // If true, stores cache items in cookies as well as browser cache. Should be set to true for use cases using IE.	
      // secureCookies: true, // If true and storeAuthStateInCookies is also enabled, MSAL adds the Secure flag to the browser cookie so it can only be sent over HTTPS.	
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              Consts.enableMsalLogging && console.error(message);
              return;
            case LogLevel.Info:
              Consts.enableMsalLogging && console.info(message);
              return;
            case LogLevel.Verbose:
              Consts.enableMsalLogging && console.debug(message);
              return;
            case LogLevel.Warning:
              Consts.enableMsalLogging && console.warn(message);
              return;
          }
        }
      }
    }
  };
}


export function msalScopes() {
  return {
    scopes: Config.Settings().AppRegScopes.split('|')
  };
}


export function msalScopesDefault() {
  return {
    scopes: [`${Config.Settings().AppRegClientId}/.default`]
  };
}
