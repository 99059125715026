/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';

export interface IANHomeProps { }

export const ANHome: React.FunctionComponent<IANHomeProps> = (props: React.PropsWithChildren<IANHomeProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANHome]");
  }, []);


  let navigate = useNavigate();


  return (
    <>
      <Stack tokens={Consts.stackTokens}>

        <h1 className="flu-page-title2 flu-bottomborder1 wbss">Advisory Notes<span className="flu-page-title-sub2">Copernicus</span></h1>

        <div className='flu-section'>

          <h2 className="flu-heading1 wbss">Actions</h2>

          {/* <div className='flu-section-body wbss'>Helloworld.</div> */}

          <Stack tokens={Consts.stackTokens} horizontal>

            <PrimaryButton text="Create New" onClick={() => {
              navigate(`new`);
            }} allowDisabledFocus />

          </Stack>

        </div>

      </Stack>
    </>
  );
};