/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";

import { BorLoanLoanItem } from '../../../Models/Link/GridModels/BorLoan';


export interface IBorLoanLoanBodyProps {
  loans: BorLoanLoanItem[];
  loansAsJson: string; // NOTE: used to force a render when the deep collection changes (loan col in bor col)
  onUpdateLoanActive: (loanId: string) => void;
  isReadOnly: boolean;
  saving: boolean;
}

export const BorLoanLoanBody: React.FunctionComponent<IBorLoanLoanBodyProps> = (props: React.PropsWithChildren<IBorLoanLoanBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorLoanLoanBody]");
  }, []);


  function onClickSwitchItem(o: BorLoanLoanItem) {
    props.onUpdateLoanActive(o.rpt_ServicerLoanID);
  }


  function onClickCheckAll() {
    props.onUpdateLoanActive(''); // pass empty string to update all
  }


  function getCheckAllState(): boolean {
    let countAllActive = props.loans.filter(x => x.cv_IsActive).length;
    if (countAllActive === props.loans.length && props.loans.length > 0)
      return true;
    else return false;
  }


  // useEffect(() => {
  //   console.log('CHANGE');
  // }, [props.data, props.dataAsJson]);


  return (
    <>

      <table className='sub-table3 wbss'>
        <thead>

          <tr className='sep'>
            <th>{`Servicer Loan ID`}</th>
            <th>{`Loan ID`}</th>
            <th>{`Regulatory Status`}</th>
            <th>{`UPB`}</th>
            <th>{`UPB Currency`}</th>
            <th>{`Collections (€)`}</th>
            <th>
              <Checkbox checked={getCheckAllState()} onChange={onClickCheckAll} disabled={props.isReadOnly || props.saving} />
            </th>
          </tr>

        </thead>
        <tbody>

          {
            props.loans.map((loan, i) =>
              <tr key={i} className={`sep ${loan.cv_IsActive ? 'loaninfo-tr-active-y' : 'loaninfo-tr-active-n'}`}>
                {/* <tr key={i} className={`sep ${o.cv_IsActive ? 'loaninfo-tr-active-y' : 'loaninfo-tr-active-n'}`} style={i < props.data.length - 1 ? undefined : { borderBottom: '0 none' }}> */}

                <td>
                  {loan.rpt_ServicerLoanID}
                </td>

                <td>
                  {loan.rpt_LoanID}
                </td>

                <td>
                  {loan.rpt_RegulatoryStatus}
                </td>

                <td>
                  {loan.rpt_UPBDisp}
                </td>

                <td>
                  {loan.rpt_UPBCurrencyDisp}
                </td>

                <td>
                  {GenUtil.numberToCurrency(GenUtil.safeToNumber(loan.rpt_Collections))}
                </td>

                <td style={{ maxWidth: 25, textAlign: 'right' }}>
                  <Checkbox checked={loan.cv_IsActive} onChange={() => onClickSwitchItem(loan)} disabled={props.isReadOnly || props.saving} />
                </td>

              </tr>
            )
          }

        </tbody>
      </table>

    </>
  );
};
