/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { OtherNPLevelItem } from '../../../Models/Link/GridModels/OtherNPLevel1';
import { ARADetailItem } from '../../../Models/Link/ARADetail';
import { Space } from '../../Controls/Space';


export interface IOtherNPLevelRowProps {
  data: OtherNPLevelItem;
  counter: number;
  totCount: number;
  araDetails: ARADetailItem[];
  updateFieldVal: (id: string, fieldName: string, fieldVal: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const OtherNPLevelRow: React.FunctionComponent<IOtherNPLevelRowProps> = (props: React.PropsWithChildren<IOtherNPLevelRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: OtherNPLevelRow]");
  }, []);


  // user controlled input
  const [stateIsNA, setStateIsNA] = React.useState(GenUtil.safeToBool(props.data.cv_NotApplicableChecked));
  const onChangeIsNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateIsNA(!!checked); }, []);

  const [stateCommittedOption, setStateCommittedOption] = useState(props.data.rpt_CommittedOption);
  const onChangeCommittedOption = useCallback((evt: any, v?: string) => { setStateCommittedOption(v || ''); }, []);

  const [stateMonthlyARAPayment, setStateMonthlyARAPayment] = useState(props.data.rpt_MonthlyARAPayment);
  const onChangeMonthlyARAPayment = useCallback((evt: any, v?: string) => { setStateMonthlyARAPayment(v || ''); }, []);

  const [stateWarehouseSplitAmt, setStateWarehouseSplitAmt] = useState(props.data.rpt_WarehouseSplitAmt);
  const onChangeWarehouseSplitAmt = useCallback((evt: any, v?: string) => { setStateWarehouseSplitAmt(v || ''); }, []);

  const [stateWritedownAmt, setStateWritedownAmt] = useState(props.data.rpt_WritedownAmt);
  const onChangeWritedownAmt = useCallback((evt: any, v?: string) => { setStateWritedownAmt(v || ''); }, []);

  const [stateUpdatedInterestRate, setStateUpdatedInterestRate] = useState(props.data.rpt_UpdatedInterestRate);
  const onChangeUpdatedInterestRate = useCallback((evt: any, v?: string) => { setStateUpdatedInterestRate(v || ''); }, []);

  const [stateInterestRateType, setStateInterestRateType] = useState(props.data.rpt_InterestRateType);
  const onChangeInterestRateType = useCallback((evt: any, v?: string) => { setStateInterestRateType(v || ''); }, []);

  const [stateSelCapatilised, setStateSelCapatilised] = useState<string>(props.data.rpt_Capatilised);
  const onChangeCapatilised = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelCapatilised(option ? option.key + '' : ''); }

  const [stateSelARABroken, setStateSelARABroken] = useState<string>(props.data.rpt_ARABroken);
  const onChangeARABroken = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelARABroken(option ? option.key + '' : ''); }

  const [stateSelDetailOnARA, setStateSelDetailOnARA] = useState<string>(props.data.rpt_DetailOnARA);
  const onChangeDetailOnARA = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelDetailOnARA(option ? option.key + '' : ''); }

  const [stateStartDate, setStateStartDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_StartDate));
  const [stateEndDate, setStateEndDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_EndDate));
  const [stateWritedownDate, setStateWritedownDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_WritedownDate));
  const [stateTermExtensionDate, setStateTermExtensionDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_TermExtensionDate));
  const [stateUpdatedInterestDate, setStateUpdatedInterestDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_UpdatedInterestDate));

  const [stateWritedownDateNA, setStateWritedownDateNA] = useState<boolean>(false);
  const onChangeWriteDowndateNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateWritedownDateNA(!!checked); }, []);

  const [stateTermExtensionDateNA, setStateTermExtensionDateNA] = useState<boolean>(false);
  const onChangeTermExtensionDateNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateTermExtensionDateNA(!!checked); }, []);

  const [stateUpdatedInterestDateNA, setStateUpdatedInterestDateNA] = useState<boolean>(false);
  const onChangeUpdatedInterestDateNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateUpdatedInterestDateNA(!!checked); }, []);


  useEffect(() => {
    if (!!props.data) {

      setStateCommittedOption(GenUtil.safeTrim(props.data.rpt_CommittedOption));
      setStateMonthlyARAPayment(GenUtil.safeTrim(props.data.rpt_MonthlyARAPayment));
      setStateWarehouseSplitAmt(GenUtil.safeTrim(props.data.rpt_WarehouseSplitAmt));
      setStateWritedownAmt(GenUtil.safeTrim(props.data.rpt_WritedownAmt));
      setStateUpdatedInterestRate(GenUtil.safeTrim(props.data.rpt_UpdatedInterestRate));
      setStateInterestRateType(GenUtil.safeTrim(props.data.rpt_InterestRateType));

      setStateSelCapatilised(GenUtil.safeTrim(props.data.rpt_Capatilised));
      setStateSelARABroken(GenUtil.safeTrim(props.data.rpt_ARABroken));
      setStateSelDetailOnARA(GenUtil.safeTrim(props.data.rpt_DetailOnARA));

      setStateStartDate(GenUtil.safeToDateOrUndefined(props.data.cv_StartDate));
      setStateEndDate(GenUtil.safeToDateOrUndefined(props.data.cv_EndDate));
      setStateWritedownDate(GenUtil.safeToDateOrUndefined(props.data.cv_WritedownDate));
      setStateTermExtensionDate(GenUtil.safeToDateOrUndefined(props.data.cv_TermExtensionDate));
      setStateUpdatedInterestDate(GenUtil.safeToDateOrUndefined(props.data.cv_UpdatedInterestDate));

      setStateWritedownDateNA(GenUtil.isNull(props.data.cv_WritedownDate) ? true : false);
      setStateTermExtensionDateNA(GenUtil.isNull(props.data.cv_TermExtensionDate) ? true : false);
      setStateUpdatedInterestDateNA(GenUtil.isNull(props.data.cv_UpdatedInterestDate) ? true : false);

    }
  }, [props.data]);


  // special NA checkboxes for "Where Applicable" datepickers

  useEffect(() => {
    setStateWritedownDateNA(!stateWritedownDate ? true : false);
  }, [stateWritedownDate]);

  useEffect(() => {
    if (stateWritedownDateNA) setStateWritedownDate(undefined);
  }, [stateWritedownDateNA]);


  useEffect(() => {
    setStateTermExtensionDateNA(!stateTermExtensionDate ? true : false);
  }, [stateTermExtensionDate]);

  useEffect(() => {
    if (stateTermExtensionDateNA) setStateTermExtensionDate(undefined);
  }, [stateTermExtensionDateNA]);


  useEffect(() => {
    setStateUpdatedInterestDateNA(!stateUpdatedInterestDate ? true : false);
  }, [stateUpdatedInterestDate]);

  useEffect(() => {
    if (stateUpdatedInterestDateNA) setStateUpdatedInterestDate(undefined);
  }, [stateUpdatedInterestDateNA]);


  // send field updates back to parent

  useEffect(() => {
    // sending boolean value
    props.updateFieldVal(props.data.rpt_LoanID, 'NotApplicable', stateIsNA);
  }, [stateIsNA]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'CommittedOption', GenUtil.safeTrim(stateCommittedOption));
  }, [stateCommittedOption]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'MonthlyARAPayment', GenUtil.safeTrim(stateMonthlyARAPayment));
  }, [stateMonthlyARAPayment]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'WarehouseSplitAmt', GenUtil.safeTrim(stateWarehouseSplitAmt));
  }, [stateWarehouseSplitAmt]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'WritedownAmt', GenUtil.safeTrim(stateWritedownAmt));
  }, [stateWritedownAmt]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'UpdatedInterestRate', GenUtil.safeTrim(stateUpdatedInterestRate));
  }, [stateUpdatedInterestRate]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_LoanID, 'InterestRateType', GenUtil.safeTrim(stateInterestRateType));
  }, [stateInterestRateType]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.data.rpt_LoanID, 'Capitalised', GenUtil.safeTrim(stateSelCapatilised));
  }, [stateSelCapatilised]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.data.rpt_LoanID, 'ARABroken', GenUtil.safeTrim(stateSelARABroken));
  }, [stateSelARABroken]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.data.rpt_LoanID, 'DetailOnARA', GenUtil.safeTrim(stateSelDetailOnARA));
  }, [stateSelDetailOnARA]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_LoanID, 'StartDate', stateStartDate);
  }, [stateStartDate]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_LoanID, 'EndDate', stateEndDate);
  }, [stateEndDate]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_LoanID, 'WritedownDate', stateWritedownDate);
  }, [stateWritedownDate]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_LoanID, 'TermExtensionDate', stateTermExtensionDate);
  }, [stateTermExtensionDate]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.data.rpt_LoanID, 'UpdatedInterestDate', stateUpdatedInterestDate);
  }, [stateUpdatedInterestDate]);


  function fnResetFields() {
    setStateCommittedOption('');
    setStateMonthlyARAPayment('');
    setStateWarehouseSplitAmt('');
    setStateWritedownAmt('');
    setStateUpdatedInterestRate('');
    setStateInterestRateType('');
    setStateSelCapatilised('');
    setStateSelARABroken('');
    setStateSelDetailOnARA('');

    setStateStartDate(undefined);
    setStateEndDate(undefined);
    setStateWritedownDate(undefined);
    setStateTermExtensionDate(undefined);
    setStateUpdatedInterestDate(undefined);
  }


  const memoARADetails = useMemo<string>(() => {
    return props.araDetails.map(x => x.fields.Title).join('|');
  }, [props.araDetails]);


  //#region 'validation'
  //-------------------------

  // other fields

  const memoIsErrCommittedOption = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrCommittedOption();
  }, [props.stateFormSubmitted, stateCommittedOption]);

  function isErrCommittedOption() {
    return GenUtil.isNull(stateCommittedOption);
  }


  const memoIsErrInterestRateType = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrInterestRateType();
  }, [props.stateFormSubmitted, stateInterestRateType]);

  function isErrInterestRateType() {
    return GenUtil.isNull(stateInterestRateType);
  }


  const memoIsErrMonthlyARAPayment = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrMonthlyARAPayment();
  }, [props.stateFormSubmitted, stateMonthlyARAPayment]);

  function isErrMonthlyARAPayment() {
    return GenUtil.isNull(stateMonthlyARAPayment);
  }


  const memoIsErrSelARABroken = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrSelARABroken();
  }, [props.stateFormSubmitted, stateSelARABroken]);

  function isErrSelARABroken() {
    return GenUtil.isNull(stateSelARABroken);
  }


  const memoIsErrSelCapatilised = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrSelCapatilised();
  }, [props.stateFormSubmitted, stateSelCapatilised]);

  function isErrSelCapatilised() {
    return GenUtil.isNull(stateSelCapatilised);
  }


  const memoIsErrSelDetailOnARA = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrSelDetailOnARA();
  }, [props.stateFormSubmitted, stateSelDetailOnARA]);

  function isErrSelDetailOnARA() {
    return GenUtil.isNull(stateSelDetailOnARA);
  }


  const memoIsErrUpdatedInterestRate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrUpdatedInterestRate();
  }, [props.stateFormSubmitted, stateUpdatedInterestRate]);

  function isErrUpdatedInterestRate() {
    return GenUtil.isNull(stateUpdatedInterestRate);
  }


  const memoIsErrWarehouseSplitAmt = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrWarehouseSplitAmt();
  }, [props.stateFormSubmitted, stateWarehouseSplitAmt]);

  function isErrWarehouseSplitAmt() {
    return GenUtil.isNull(stateWarehouseSplitAmt);
  }


  const memoIsErrWritedownAmt = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrWritedownAmt();
  }, [props.stateFormSubmitted, stateWritedownAmt]);

  function isErrWritedownAmt() {
    return GenUtil.isNull(stateWritedownAmt);
  }


  // date fields

  const memoIsErrStartDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrStartDate();
  }, [props.stateFormSubmitted, stateStartDate]);

  function isErrStartDate() {
    return !stateStartDate;
  }


  const memoIsErrEndDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrEndDate();
  }, [props.stateFormSubmitted, stateEndDate]);

  function isErrEndDate() {
    return !stateEndDate;
  }


  const memoIsErrWritedownDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrWritedownDate();
  }, [props.stateFormSubmitted, stateWritedownDate, stateWritedownDateNA]);

  function isErrWritedownDate() {
    return stateWritedownDateNA === true ? false : !stateWritedownDate;
  }


  const memoIsErrTermExtensionDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrTermExtensionDate();
  }, [props.stateFormSubmitted, stateTermExtensionDate, stateTermExtensionDateNA]);

  function isErrTermExtensionDate() {
    return stateTermExtensionDateNA === true ? false : !stateTermExtensionDate;
  }


  const memoIsErrUpdatedInterestDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrUpdatedInterestDate();
  }, [props.stateFormSubmitted, stateUpdatedInterestDate, stateUpdatedInterestDateNA]);

  function isErrUpdatedInterestDate() {
    return stateUpdatedInterestDateNA === true ? false : !stateUpdatedInterestDate;
  }


  //#endregion


  return (
    <>

      <div className="ms-Grid" dir="ltr">

        <h2 className="flu-heading1 wbs" style={{ fontSize: '16px' }}>

          <table className='w100p'>
            <tbody>
              <tr>
                <td className='w10p nowrap'>
                  {`LOAN ID:`} {props.data.rpt_LoanID}
                </td>
                <td className='w10p nowrap' style={{ paddingLeft: 50 }}>
                  <Checkbox label="Not Applicable" checked={stateIsNA} onChange={props.isReadOnly ? () => { } : onChangeIsNA} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  {
                    !props.isReadOnly && (
                      <Link onClick={fnResetFields} className='btnResetFields'>
                        Reset fields
                      </Link>
                    )
                  }
                </td>
              </tr>
            </tbody>
          </table>

        </h2>

        {
          !stateIsNA && (
            <>
              <table className='wbss w98p' style={{ paddingLeft: 16 }}>
                <tbody>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Committed Option:`} onChange={onChangeCommittedOption} value={stateCommittedOption} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrCommittedOption ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Committed Option:</Label>
                        <Label className='ms-fontWeight-regular'>{stateCommittedOption}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Warehouse/Split Amount (Where Applicable):`} onChange={onChangeWarehouseSplitAmt} value={stateWarehouseSplitAmt} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrWarehouseSplitAmt ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Warehouse/Split Amount (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{stateWarehouseSplitAmt}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <DatePicker
                            label='Start Date:'
                            formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                            placeholder="Select a date"
                            value={stateStartDate}
                            onSelectDate={setStateStartDate as (date: Date | null | undefined) => void}
                            allowTextInput
                            className={`w90p ${(memoIsErrStartDate ? "invalid-datepicker" : "")}`}
                          />
                          {
                            memoIsErrStartDate && (
                              <Label className='red-msg-text clr'>Date is required</Label>
                            )
                          }
                        </Stack>
                      )}
                      {props.isReadOnly && (<>
                        <Label>Start Date:</Label>
                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(stateStartDate)}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Writedown Amount (Where Applicable):`} onChange={onChangeWritedownAmt} value={stateWritedownAmt} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrWritedownAmt ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Writedown Amount (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{stateWritedownAmt}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <DatePicker
                            label='End Date:'
                            formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                            placeholder="Select a date"
                            value={stateEndDate}
                            onSelectDate={setStateEndDate as (date: Date | null | undefined) => void}
                            allowTextInput
                            className={`w90p ${(memoIsErrEndDate ? "invalid-datepicker" : "")}`}
                          />
                          {
                            memoIsErrEndDate && (
                              <Label className='red-msg-text clr'>Date is required</Label>
                            )
                          }
                        </Stack>
                      )}
                      {props.isReadOnly && (<>
                        <Label>End Date:</Label>
                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(stateEndDate)}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <Label>{`Writedown Date (Where Applicable):`}</Label>
                          <Space horizontal sizeIsSmall>
                            <Checkbox
                              label="N/A"
                              checked={stateWritedownDateNA}
                              onChange={onChangeWriteDowndateNA} />
                            <DatePicker
                              formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                              placeholder="Select a date"
                              value={stateWritedownDate}
                              onSelectDate={setStateWritedownDate as (date: Date | null | undefined) => void}
                              allowTextInput
                              className={`w70p ${(memoIsErrWritedownDate ? "invalid-datepicker" : "")}`}
                            />
                          </Space>
                          {
                            memoIsErrWritedownDate && (
                              <Label className='red-msg-text clr'>Date is required</Label>
                            )
                          }
                        </Stack>
                      )}
                      {props.isReadOnly && (<>
                        <Label>Writedown Date (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{GenUtil.NVL(GenUtil.getCalDate(stateWritedownDate), "N/A")}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <ComboBox
                          label='Capitalised:'
                          className='w90p'
                          disabled={false}
                          selectedKey={stateSelCapatilised}
                          placeholder='Choose...'
                          options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                          onChange={onChangeCapatilised}
                          errorMessage={memoIsErrSelCapatilised ? 'Value is required.' : ''}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Capitalised:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelCapatilised}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <Label>{`Term Extension Date (Where Applicable):`}</Label>
                          <Space horizontal sizeIsSmall>
                            <Checkbox
                              label="N/A"
                              checked={stateTermExtensionDateNA}
                              onChange={onChangeTermExtensionDateNA} />
                            <DatePicker
                              formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                              placeholder="Select a date"
                              value={stateTermExtensionDate}
                              onSelectDate={setStateTermExtensionDate as (date: Date | null | undefined) => void}
                              allowTextInput
                              className={`w70p ${(memoIsErrTermExtensionDate ? "invalid-datepicker" : "")}`}
                            />
                          </Space>
                          {
                            memoIsErrTermExtensionDate && (
                              <Label className='red-msg-text clr'>Date is required</Label>
                            )
                          }
                        </Stack>

                      )}
                      {props.isReadOnly && (<>
                        <Label>Term Extension Date (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{GenUtil.NVL(GenUtil.getCalDate(stateTermExtensionDate), "N/A")}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <ComboBox
                          label='ARA Broken:'
                          className='w90p'
                          disabled={false}
                          selectedKey={stateSelARABroken}
                          placeholder='Choose...'
                          options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                          onChange={onChangeARABroken}
                          errorMessage={memoIsErrSelARABroken ? 'Value is required.' : ''}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>ARA Broken:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelARABroken}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <Stack tokens={{ childrenGap: 4 }}>
                          <Label>{`Updated Interest Date (Where Applicable):`}</Label>
                          <Space horizontal sizeIsSmall>
                            <Checkbox
                              label="N/A"
                              checked={stateUpdatedInterestDateNA}
                              onChange={onChangeUpdatedInterestDateNA} />
                            <DatePicker
                              formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                              placeholder="Select a date"
                              value={stateUpdatedInterestDate}
                              onSelectDate={setStateUpdatedInterestDate as (date: Date | null | undefined) => void}
                              allowTextInput
                              className={`w70p ${(memoIsErrUpdatedInterestDate ? "invalid-datepicker" : "")}`}
                            />
                          </Space>
                          {
                            memoIsErrUpdatedInterestDate && (
                              <Label className='red-msg-text clr'>Date is required</Label>
                            )
                          }
                        </Stack>
                      )}
                      {props.isReadOnly && (<>
                        <Label>Updated Interest Date (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{GenUtil.NVL(GenUtil.getCalDate(stateUpdatedInterestDate), "N/A")}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Monthly ARA Payment:`} onChange={onChangeMonthlyARAPayment} value={stateMonthlyARAPayment} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrMonthlyARAPayment ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Monthly ARA Payment:</Label>
                        <Label className='ms-fontWeight-regular'>{stateMonthlyARAPayment}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Updated Interest Rate (Where Applicable):`} onChange={onChangeUpdatedInterestRate} value={stateUpdatedInterestRate} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrUpdatedInterestRate ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Updated Interest Rate (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{stateUpdatedInterestRate}</Label>
                      </>)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {!props.isReadOnly && (
                        <ComboBox
                          label='Detail on ARA:'
                          className='w90p'
                          disabled={false}
                          selectedKey={stateSelDetailOnARA}
                          placeholder='Choose...'
                          options={memoARADetails.split('|').map(o => { return { key: o, text: o }; })}
                          onChange={onChangeDetailOnARA}
                          errorMessage={memoIsErrSelDetailOnARA ? 'Value is required.' : ''}
                        />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Detail on ARA:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelDetailOnARA}</Label>
                      </>)}
                    </td>
                    <td>
                      {!props.isReadOnly && (
                        <TextField label={`Interest Rate Type (Where Applicable):`} onChange={onChangeInterestRateType} value={stateInterestRateType} placeholder='Enter text here' maxLength={255} className='w90p' errorMessage={memoIsErrInterestRateType ? 'Value is required.' : ''} />
                      )}
                      {props.isReadOnly && (<>
                        <Label>Interest Rate Type (Where Applicable):</Label>
                        <Label className='ms-fontWeight-regular'>{stateInterestRateType}</Label>
                      </>)}
                    </td>
                  </tr>

                </tbody>
              </table>
            </>
          )}

      </div>

    </>
  );
};
