/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, PrimaryButton } from "@fluentui/react";

import * as Consts from "../../../Helpers/Consts";

export interface IANHomeProps {}

export const ANHome: React.FunctionComponent<IANHomeProps> = (
  props: React.PropsWithChildren<IANHomeProps>
) => {
  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANHome]");
  }, []);

  let navigate = useNavigate();

  return (
    <>
      <Stack tokens={Consts.stackTokens}>
        <h1 className="flu-page-title2 flu-bottomborder1 wbss">
          Advisory Notes<span className="flu-page-title-sub2">TDX</span>
        </h1>

        <div className="flu-section">
          <h2 className="flu-heading1 wbss">Actions</h2>

          <Stack tokens={Consts.stackTokens} horizontal>
            <PrimaryButton
              text="Create New"
              onClick={() => {
                navigate(`new`);
              }}
              allowDisabledFocus
            />
          </Stack>
        </div>
      </Stack>
    </>
  );
};
