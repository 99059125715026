/* eslint-disable @typescript-eslint/no-unused-vars */

import * as GraphHelper from '../../Helpers/GraphHelper';
import * as GenUtil from '../../Helpers/GenUtil2';
import * as Consts from '../../Helpers/Consts';
// import { msalScopes } from '../../Helpers/AuthConfig';
import * as Config from '../../Helpers/ConfigData';

import { GraphBaseResp } from '../../Models/GraphBaseResp';
import { ANEntity, ANEntities } from '../../Models/Copernicus/ANEntity';
import { ANType, ANTypes } from '../../Models/Copernicus/ANType';
import { ANVendor, ANVendors } from '../../Models/Copernicus/ANVendor';
import { ANCurrency, ANCurrencys } from '../../Models/Copernicus/ANCurrency';
import { ANUser, ANUsers } from '../../Models/Copernicus/ANUser';
import { ANConnection, ANConnections } from '../../Models/Copernicus/ANConnection';
import { ANNote } from '../../Models/Copernicus/ANNote';
import { ANTypeOfCost, ANTypeOfCosts } from '../../Models/Copernicus/ANTypeOfCost';
import * as AppModels from '../../Models/AppModels';

import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { getToken } from '../GraphDataService';


/*
NOTES:
sample: https://graph.microsoft.com/v1.0/sites/cerberus.sharepoint.com:/sites/CerberusPOC1:/lists/Vendors/items?$expand=fields&$orderBy=fields/Title
-if using "orderby", then the field in the list MUST be indexed
-if expecting to return all the items from the list on page load, then can sort them using clientside (lodash)

-when returning a single record, "/item/222", if the item is not found, Graph returns an error, it is NOT OK to try to return an object that does not exist
-when filtering records, Graph will return 0 or more records in a "value": []" property, it is OK to filter and find no matching results
-invalid Graph requests return with different HTTP codes, but DO return a JSON body that can be used to determine what happenned
-i added a special GraphHelper.getAsync method to merge the HTTP status code and message to the returned json payload from Graph.
  this should make it easier to detect issues
  like searching for Connections and getting a 422 result
*/


export function buildGraphBaseUri() {

  let a = `https://graph.microsoft.com/v1.0/sites/${Config.Settings().HostName}`;

  let b = Config.Settings().SiteRelPathCopernicus.trim().length === 0 ?
    '' :
    `:${encodeURIComponent(Config.Settings().SiteRelPathCopernicus)}:`;

  return `${a}${b}`;
}


// =============================================================


export async function getANNotesList(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<GraphBaseResp> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANNotesCopernicus}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANEntities(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANEntities> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleEntitiesCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANTypes(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANTypes> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANTypesCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANVendors(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANVendors> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleVendorsCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANCurrencys(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANCurrencys> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleCurrencyCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANTypeOfCosts(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANTypeOfCosts> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleTypeOfCostsCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANUsers(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANUsers> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANUsersCopernicus}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANConnectionsForPicker(accounts: AccountInfo[], instance: IPublicClientApplication,
  text: string,
  isREO: boolean,
  isConnGroupId: boolean,
  projName: string,
  top: number = Consts.graphMaxAllItemsCount,
  token?: string
): Promise<ANConnections> {

  /**
   * Connections list must have the following fields indexed:
   *   Asset_x0020_ID
   *   Connection_x0020_ID
   *   ConnectionGroupID
   *   Project
   */

  let tok = await getToken(accounts, instance, token);

  let url = '';
  url += `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleConnectionsCopernicus}/items?`;
  url += `&$top=${top}`;

  if (isREO) {
    if (GenUtil.eq(projName, 'INDIAN_REO')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'INDIAN' or fields/Project eq 'INDIAN_REO')`;
    }
    else if (GenUtil.eq(projName, 'MARS_REO')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'MARS' or fields/Project eq 'MARS_REO')`;
    }
    else if (GenUtil.eq(projName, 'PRO - LEZAMA')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'LEZAMA' or fields/Project eq 'PRO - LEZAMA')`;
    }
    else if (GenUtil.eq(projName, 'PRO - JAIPUR')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'JAIPUR' or fields/Project eq 'PRO - JAIPUR')`;
    }
    else if (GenUtil.eq(projName, 'PRO - AGORA')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'AGORA' or fields/Project eq 'PRO - AGORA')`;
    }
    else if (GenUtil.eq(projName, 'PRO - EGEO')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'EGEO' or fields/Project eq 'PRO - EGEO')`;
    }
    else if (GenUtil.eq(projName, 'PRO - YOSEMITE')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'YELLOWSTONE' or fields/Project eq 'PRO - YOSEMITE')`;
    }
    else if (GenUtil.eq(projName, 'PRO - BACKGAMMON - COP')) {
      // search Asset_x0020_ID, expand Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and (fields/Project eq 'BACKGAMMON - COP' or fields/Project eq 'PRO - BACKGAMMON - COP')`;
    }

    else if (isConnGroupId) {
      // deprecated, not used in latest q1/q2 2023 Copernicus release
      // search ConnectionGroupID, regular Project query, select ConnectionGroupID
      url += `&$expand=fields($select=ID,ConnectionGroupID)`;
      url += `&$orderBy=fields/ConnectionGroupID`;
      url += `&$filter=startsWith(fields/ConnectionGroupID, '${encodeURIComponent(text.trim())}') and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
    }

    else {
      // search Asset_x0020_ID, regular Project query, select Asset_x0020_ID
      url += `&$expand=fields($select=ID,Asset_x0020_ID)`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=startsWith(fields/Asset_x0020_ID, '${encodeURIComponent(text.trim())}') and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
    }

  }
  else {
    // search Connection_x0020_ID, regular Project query, select Connection_x0020_ID
    url += `&$expand=fields($select=ID,Connection_x0020_ID)`;
    url += `&$orderBy=fields/Connection_x0020_ID`;
    url += `&$filter=startsWith(fields/Connection_x0020_ID, '${encodeURIComponent(text.trim())}') and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
  }

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, that has HTTP codes in it too, this endpoint may fail on searches that are too general
  return data;
}


export async function getANConnections(accounts: AccountInfo[], instance: IPublicClientApplication,
  text: string,
  isREO: boolean,
  isConnGroupId: boolean,
  projName: string,
  top: number = Consts.graphMaxAllItemsCount,
  token?: string
): Promise<ANConnections> {

  let tok = await getToken(accounts, instance, token);

  let url = '';
  url += `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleConnectionsCopernicus}/items?`;
  url += `&$top=${top}`;

  if (isREO) {
    // search using asset id or connection group
    // NOTE: we are not sure if the below queries need to use project at all, but the code from the old project DOES do this, so lets keep this here too
    if (GenUtil.eq(projName, 'INDIAN_REO')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'INDIAN' or fields/Project eq 'INDIAN_REO')`;
    }
    else if (GenUtil.eq(projName, 'MARS_REO')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'MARS' or fields/Project eq 'MARS_REO')`;
    }
    else if (GenUtil.eq(projName, 'PRO - LEZAMA')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'LEZAMA' or fields/Project eq 'PRO - LEZAMA')`;
    }
    else if (GenUtil.eq(projName, 'PRO - JAIPUR')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'JAIPUR' or fields/Project eq 'PRO - JAIPUR')`;
    }
    else if (GenUtil.eq(projName, 'PRO - AGORA')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'AGORA' or fields/Project eq 'PRO - AGORA')`;
    }
    else if (GenUtil.eq(projName, 'PRO - EGEO')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'EGEO' or fields/Project eq 'PRO - EGEO')`;
    }
    else if (GenUtil.eq(projName, 'PRO - YOSEMITE')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'YELLOWSTONE' or fields/Project eq 'PRO - YOSEMITE')`;
    }
    else if (GenUtil.eq(projName, 'PRO - BACKGAMMON - COP')) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and (fields/Project eq 'BACKGAMMON - COP' or fields/Project eq 'PRO - BACKGAMMON - COP')`;
    }

    else if (isConnGroupId) {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/ConnectionGroupID eq '${encodeURIComponent(text.trim())}' and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
    }

    else {
      url += `&$expand=fields`;
      url += `&$orderBy=fields/Asset_x0020_ID`;
      url += `&$filter=fields/Asset_x0020_ID eq '${encodeURIComponent(text.trim())}' and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
    }

  }
  else {
    // search using connection id
    // NOTE: old code was using substring match due to leading spaces, but this data issue was likely resovled, and not needed anymore, eq match should be OK
    // NOTE: on 5/10/23 we found a data issue were the conn id in prod Copernicus connections list was "[id]; [id]", so the eq match failed, and the asset type conns did not come back, but the loan type rows did come back, causing a connection loader issue where no unique conn was found
    //   but consider this a data issue and do not code against it
    url += `&$expand=fields`;
    url += `&$orderBy=fields/Asset_x0020_ID`;
    url += `&$filter=fields/Connection_x0020_ID eq '${encodeURIComponent(text.trim())}'`;
  }

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, that has HTTP codes in it too, this endpoint may fail on searches that are too general
  return data;
}


export async function getANNote(accounts: AccountInfo[], instance: IPublicClientApplication,
  id: number,
  token?: string
): Promise<ANNote> {
  // NOTE: if the item is not found (i.e. item id is no longer in the list), Graph will return an error code

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANNotesCopernicus}/items/${id}?$expand=fields`;

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, as this endpoint is volatile (items that do not exist cause graph return errors), also the "/items/222" response is not an array of objects, its a single object
  return data;
}
