/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { ProposalBreakdownItem } from '../../../Models/Cabot/GridModels/ProposalBreakdown1';


export interface IProposalBreakdownRowProps {
  data: ProposalBreakdownItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ProposalBreakdownRow: React.FunctionComponent<IProposalBreakdownRowProps> = (props: React.PropsWithChildren<IProposalBreakdownRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposalBreakdownRow]");
  }, []);


  // user controlled input

  // EarliestExpectedDate - datepicker
  const [stateEarliestExpectedDate, setStateEarliestExpectedDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_EarliestExpectedDate));

  // ExpectedMinAmt - slot
  const [stateExpectedMinAmt, setStateExpectedMinAmt] = useState(props.data.cv_ExpMinAmtOrig);
  const onChangeExpectedMinAmt = useCallback((evt: any, v?: string) => { setStateExpectedMinAmt(v || ''); }, []);

  // Currency - ddl
  const [stateSelCurrency, setStateSelCurrency] = useState<string>(props.data.cv_ExpMinAmtCurOrig);
  const onChangeCurrency = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelCurrency(option ? option.key + '' : ''); }

  // Source - ddl
  const [stateSelSource, setStateSelSource] = useState<string>(props.data.rpt_Source);
  const onChangeSource = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSource(option ? option.key + '' : ''); }


  // send field updates back to parent

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.counter, 'EarliestExpectedDate', stateEarliestExpectedDate);
  }, [stateEarliestExpectedDate]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'ExpectedMinAmt', GenUtil.safeTrim(stateExpectedMinAmt));
  }, [stateExpectedMinAmt]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.counter, 'Currency', GenUtil.safeTrim(stateSelCurrency));
  }, [stateSelCurrency]);

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.counter, 'Source', GenUtil.safeTrim(stateSelSource));
  }, [stateSelSource]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //#region 'validation'
  //-------------------------

  const memoIsErrExpectedMinAmt = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrExpectedMinAmt();
  }, [props.stateFormSubmitted, stateExpectedMinAmt]);

  function isErrExpectedMinAmt() {
    return GenUtil.safeToNumberOrNull(stateExpectedMinAmt) == null ? true : false;
  }


  const memoIsErrSelCurrency = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrSelCurrency();
  }, [props.stateFormSubmitted, stateSelCurrency]);

  function isErrSelCurrency() {
    return GenUtil.isNull(stateSelCurrency);
  }


  const memoIsErrSelSource = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrSelSource();
  }, [props.stateFormSubmitted, stateSelSource]);

  function isErrSelSource() {
    return GenUtil.isNull(stateSelSource);
  }


  const memoIsErrEarliestExpectedDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrEarliestExpectedDate();
  }, [props.stateFormSubmitted, stateEarliestExpectedDate]);

  function isErrEarliestExpectedDate() {
    return !(stateEarliestExpectedDate);
  }

  //#endregion


  return (
    <>

      <tr className='sep'>

        <td>
          {
            !props.isReadOnly ? (
              <Stack tokens={{ childrenGap: 4 }}>
                <DatePicker
                  formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                  placeholder="Select a date"
                  value={stateEarliestExpectedDate}
                  onSelectDate={setStateEarliestExpectedDate as (date: Date | null | undefined) => void}
                  allowTextInput
                  className={`w175 ${(memoIsErrEarliestExpectedDate ? "invalid-datepicker" : "")}`}
                />
                {
                  memoIsErrEarliestExpectedDate && (
                    <Label className='red-msg-text clr'>Date is required</Label>
                  )
                }
              </Stack>
            ) : (
              GenUtil.getCalDate(stateEarliestExpectedDate)
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeExpectedMinAmt} value={stateExpectedMinAmt} placeholder='Please enter text here' className='' errorMessage={memoIsErrExpectedMinAmt ? 'Number is required' : ''} maxLength={255} />
            ) : (
              GenUtil.numberToCurrency(GenUtil.safeToNumber(stateExpectedMinAmt))
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <ComboBox
                className='w125'
                disabled={false}
                selectedKey={stateSelCurrency}
                placeholder='Choose...'
                options={StaticData.costsCurrency2.split(',').map(o => { return { key: o, text: o }; })}
                onChange={onChangeCurrency}
                errorMessage={memoIsErrSelCurrency ? 'Value is required' : ''}
              />
            ) : (
              stateSelCurrency
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <ComboBox
                className='w250'
                disabled={false}
                selectedKey={stateSelSource}
                placeholder='Choose...'
                options={StaticData.propBrdSourceOptions.split(',').map(o => { return { key: o, text: o }; })}
                onChange={onChangeSource}
                errorMessage={memoIsErrSelSource ? 'Value is required' : ''}
              />
            ) : (
              stateSelSource
            )
          }
        </td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            props.totCount > 1 && !props.isReadOnly && (
              <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>

      </tr>

    </>
  );
};