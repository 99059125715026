/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Copernicus/GraphDataService';
import * as StaticData from "../../../StaticData/Copernicus/StaticData";
import { BorrowerInfoItem } from '../../../Models/Copernicus/GridModels/BorrowerInfo1';

export interface IBorrowerInfoRowProps {
  data: BorrowerInfoItem;
  handleDeleteItem: (a: string) => void;
  isReadOnly: boolean;
}

export const BorrowerInfoRow: React.FunctionComponent<IBorrowerInfoRowProps> = (props: React.PropsWithChildren<IBorrowerInfoRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorrowerInfoRow]");
  }, []);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.data.cv_BorrowerID);
  }


  return (
    <>

      <tr className='sep'>

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_ConnId}
            </td>
          )
        }

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_BorrowerID}
            </td>
          )
        }

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.data.rpt_BorrowerName}
          </Label>
        </td>

        {
          !props.isReadOnly && (
            <>
              <td style={{ maxWidth: 25, textAlign: 'right' }}>
                <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
              </td>
            </>
          )
        }

      </tr>

    </>
  );
};