/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Checkbox,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';

// import _ from 'lodash';

// import * as Consts from "../../Helpers/Consts";
// import * as GenUtil from '../../Helpers/GenUtil2';
// import * as AppHelper from '../../Helpers/AppHelper';
// import * as GraphDataService from '../../Services/Finsolutia/GraphDataService';
// import * as JsonXmlHelper from '../../Helpers/JsonXmlConverter';
// import * as StaticData from "../../StaticData/Finsolutia/StaticData";

// import { ConnectionDetailsItem } from '../../Models/Finsolutia/GridModels/ConnectionDetails1';
// import { BorrowerInfo1 } from '../../Models/Finsolutia/GridModels/BorrowerInfo1';
// import { BorrowerInfoN } from '../../Models/Finsolutia/GridModels/BorrowerInfoN';
// import { LoanInfo1 } from '../../Models/Finsolutia/GridModels/LoanInfo1';
// import { LoanInfoN } from '../../Models/Finsolutia/GridModels/LoanInfoN';
// import { AssetInfo1 } from '../../Models/Finsolutia/GridModels/AssetInfo1';
// import { AssetInfoN } from '../../Models/Finsolutia/GridModels/AssetInfoN';




export interface ITest5Props { }

export const Test5: React.FunctionComponent<ITest5Props> = (props: React.PropsWithChildren<ITest5Props>) => {
  return (
    <>

    </>
  );
};




// export interface ITest5Props { }

// export const Test5: React.FunctionComponent<ITest5Props> = (props: React.PropsWithChildren<ITest5Props>) => {


//   const [stateOutput, setStateOutput] = useState<string>('');


//   let xmlAssetInfo = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_AssetID type="System.String">159283</cv_AssetID>
// <rpt_AssetID type="System.String">159283</rpt_AssetID>
// <cv_AssetAddr type="System.String">URBANIZACI&amp;#211;N RESIDENCIAL JARDINES DE HERCULES SOT - GJE 423</cv_AssetAddr>
// <cv_REValue type="System.Double">13000</cv_REValue>
// <cv_District type="System.String">ANDALUCIA</cv_District>
// <cv_County type="System.String">SEVILLA</cv_County>
// <rpt_decimal_RealEstateValue type="System.Double">13000</rpt_decimal_RealEstateValue>
// <rpt_AssetAddress type="System.String">URBANIZACI&amp;#211;N RESIDENCIAL JARDINES DE HERCULES SOT - GJE 423</rpt_AssetAddress>
// <rpt_County type="System.String">SEVILLA</rpt_County>
// <rpt_District type="System.String">ANDALUCIA</rpt_District>
// <cv_AssetStatus type="System.String">No</cv_AssetStatus>
// <rpt_AssetStatus type="System.String">No</rpt_AssetStatus>
// <cv_AssetType type="System.String">Parking</cv_AssetType>
// <rpt_Occupied type="System.String">Unknown</rpt_Occupied>
// <rpt_AssetType type="System.String">Parking</rpt_AssetType>
// <rpt_CappedAmount type="System.Decimal">0</rpt_CappedAmount>
// <rept_Capping type="System.String">No</rept_Capping>
// </Item>
// <Item>
// <cv_AssetID type="System.String">159466</cv_AssetID>
// <rpt_AssetID type="System.String">159466</rpt_AssetID>
// <cv_AssetAddr type="System.String">URBANIZACI&amp;#211;N JARDINES DE HERCULES PRIMERA FASE S/N SSOT - TR 1</cv_AssetAddr>
// <cv_REValue type="System.Double">3300</cv_REValue>
// <cv_District type="System.String">ANDALUCIA</cv_District>
// <cv_County type="System.String">SEVILLA</cv_County>
// <rpt_decimal_RealEstateValue type="System.Double">3300</rpt_decimal_RealEstateValue>
// <rpt_AssetAddress type="System.String">URBANIZACI&amp;#211;N JARDINES DE HERCULES PRIMERA FASE S/N SSOT - TR 1</rpt_AssetAddress>
// <rpt_County type="System.String">SEVILLA</rpt_County>
// <rpt_District type="System.String">ANDALUCIA</rpt_District>
// <cv_AssetStatus type="System.String">No</cv_AssetStatus>
// <rpt_AssetStatus type="System.String">No</rpt_AssetStatus>
// <cv_AssetType type="System.String">Trastero</cv_AssetType>
// <rpt_Occupied type="System.String">Unknown</rpt_Occupied>
// <rpt_AssetType type="System.String">Trastero</rpt_AssetType>
// <rpt_CappedAmount type="System.Decimal">0</rpt_CappedAmount>
// <rept_Capping type="System.String">No</rept_Capping>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlAssetSale = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_AS_Description type="System.String">ID - 1425</rpt_AS_Description>
// <rpt_AS_SalesProceeds type="System.Double">25000</rpt_AS_SalesProceeds>
// <rpt_AS_Broker type="System.String">2</rpt_AS_Broker>
// <rpt_AS_BrokerFee type="System.String">500</rpt_AS_BrokerFee>
// <rpt_AS_NotaryFee type="System.String">200</rpt_AS_NotaryFee>
// <rpt_AS_Condo type="System.String">0</rpt_AS_Condo>
// <rpt_AS_VAT type="System.String">161</rpt_AS_VAT>
// <rpt_AS_NetProceeds type="System.Decimal">24139.00</rpt_AS_NetProceeds>
// </Item>
// <Item>
// <rpt_AS_Description type="System.String">ID - 1511</rpt_AS_Description>
// <rpt_AS_SalesProceeds type="System.Double">46000</rpt_AS_SalesProceeds>
// <rpt_AS_Broker type="System.String">3</rpt_AS_Broker>
// <rpt_AS_BrokerFee type="System.String">1,380</rpt_AS_BrokerFee>
// <rpt_AS_NotaryFee type="System.String">200</rpt_AS_NotaryFee>
// <rpt_AS_Condo type="System.String">0</rpt_AS_Condo>
// <rpt_AS_VAT type="System.String">363.40</rpt_AS_VAT>
// <rpt_AS_NetProceeds type="System.Decimal">44056.60</rpt_AS_NetProceeds>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlBorrowerInfo1 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_BorrID type="System.String">208089; 208090</cv_BorrID>
// <rpt_BorrowerID type="System.String">208089; 208090</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">MANUEL CAMPOS VILLODRES; PABLO GONZALEZ ARAUJO</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">MANUEL CAMPOS VILLODRES; PABLO GONZALEZ ARAUJO</rpt_BorrowerName>
// <rpt_LegalStatus type="System.String">Foreclosure</rpt_LegalStatus>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlBorrowerInfo2 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_BorrID type="System.String">208090</cv_BorrID>
// <rpt_BorrowerID type="System.String">208090</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">PABLO GONZALEZ ARAUJO</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">PABLO GONZALEZ ARAUJO</rpt_BorrowerName>
// <rpt_LegalStatus type="System.String">Foreclosure</rpt_LegalStatus>
// </Item>
// <Item>
// <cv_BorrID type="System.String">208089</cv_BorrID>
// <rpt_BorrowerID type="System.String">208089</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">MANUEL CAMPOS VILLODRES</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">MANUEL CAMPOS VILLODRES</rpt_BorrowerName>
// <rpt_LegalStatus type="System.String">Foreclosure</rpt_LegalStatus>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlCashflowTable = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_ASP_AssetID type="System.String">122261</rpt_ASP_AssetID>
// <cv_ASP_OptionRowNumber type="System.String">1</cv_ASP_OptionRowNumber>
// <rpt_ASP_CB1Opt1JudSaleAmt type="System.Decimal">250000</rpt_ASP_CB1Opt1JudSaleAmt>
// <rpt_ASP_CB1AgentFeePct type="System.Decimal">5.00</rpt_ASP_CB1AgentFeePct>
// <rpt_CB1AgentFeeValue type="System.Decimal">12500.00</rpt_CB1AgentFeeValue>
// <rpt_ASP_CBOpt1CostFree type="System.String">Sale Charge</rpt_ASP_CBOpt1CostFree>
// <rpt_ASP_CBOpt1DescFree type="System.String">5%</rpt_ASP_CBOpt1DescFree>
// <rpt_ASP_CBOpt1EurosFreeValue type="System.Decimal">12500</rpt_ASP_CBOpt1EurosFreeValue>
// <rpt_ASP_CBJudicialSaleTotal type="System.Decimal">225000.00</rpt_ASP_CBJudicialSaleTotal>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlConnectionDetails = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_ConnecID type="System.String">LEZ_679</rpt_ConnecID>
// <cv_rpt_ConnecID type="System.String">LEZ_679</cv_rpt_ConnecID>
// <cv_rpt_ConnName type="System.String">ADAN LIZARDO URGILES GUTAMA</cv_rpt_ConnName>
// <rpt_ConnectionName type="System.String">ADAN LIZARDO URGILES GUTAMA</rpt_ConnectionName>
// <rpt_TotalCollectionsAmount type="System.String">0</rpt_TotalCollectionsAmount>
// <rpt_ConnectionStatus type="System.String">Performing</rpt_ConnectionStatus>
// <cv_TotalCollectionsAmount type="System.String">0</cv_TotalCollectionsAmount>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlCreditBidTable = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_REA_AssetID type="System.String">122298</rpt_REA_AssetID>
// <cv_REA_OptionRowNumber type="System.String">1</cv_REA_OptionRowNumber>
// <rpt_REA_CBValue type="System.Decimal">65000</rpt_REA_CBValue>
// <rpt_REA_AgentFeePercent type="System.Decimal">5.00</rpt_REA_AgentFeePercent>
// <rpt_REA_AgentFeeValue type="System.Decimal">3250.00</rpt_REA_AgentFeeValue>
// <rpt_REA_TransCosts type="System.Decimal">1000</rpt_REA_TransCosts>
// <rpt_REA_StampDutyPercent type="System.Decimal">0.80</rpt_REA_StampDutyPercent>
// <rpt_REA_StampDutyValue type="System.Decimal">520.00</rpt_REA_StampDutyValue>
// <rpt_REA_Variable1 type="System.String"></rpt_REA_Variable1>
// <rpt_REA_Variable2 type="System.String"></rpt_REA_Variable2>
// <rpt_REA_Variable3 type="System.Decimal">0</rpt_REA_Variable3>
// <rpt_REA_CreditBidTotalValue type="System.Decimal">60230.00</rpt_REA_CreditBidTotalValue>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlDILTable = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_DIL_rowNumber type="System.String">1</cv_DIL_rowNumber>
// <rpt_DIL_REV type="System.Decimal">60100</rpt_DIL_REV>
// <rpt_DIL_JudicialFees type="System.Decimal">0</rpt_DIL_JudicialFees>
// <rpt_DIL_DeedRegistration type="System.Decimal">0</rpt_DIL_DeedRegistration>
// <rpt_DIL_MortgageCancellation type="System.Decimal">0</rpt_DIL_MortgageCancellation>
// <rpt_DIL_StampDuty type="System.Decimal">480.80</rpt_DIL_StampDuty>
// <rpt_DIL_PrepForSale type="System.Decimal">0</rpt_DIL_PrepForSale>
// <rpt_DIL_Other type="System.Decimal">0</rpt_DIL_Other>
// <rpt_DIL_EstimatedTotalCosts type="System.Decimal">480.80</rpt_DIL_EstimatedTotalCosts>
// <rpt_DIL_EstimatedNetCashflow type="System.Decimal">59619.20</rpt_DIL_EstimatedNetCashflow>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlLoanInfo = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_LoanID type="System.String">129555</cv_LoanID>
// <rpt_LoanID type="System.String">129555</rpt_LoanID>
// <cv_SrvcLoanID type="System.String">PT 0035027700040676092</cv_SrvcLoanID>
// <rpt_ServicerLoanID type="System.String">PT 0035027700040676092</rpt_ServicerLoanID>
// <rpt_decimal_UPB type="System.Double">72277.22</rpt_decimal_UPB>
// <rpt_UPBCurrency type="System.String">EUR</rpt_UPBCurrency>
// <cv_upb type="System.Double">72277.22</cv_upb>
// <cv_UPBCurrency type="System.String">EUR</cv_UPBCurrency>
// </Item>
// <Item>
// <cv_LoanID type="System.String">129556</cv_LoanID>
// <rpt_LoanID type="System.String">129556</rpt_LoanID>
// <cv_SrvcLoanID type="System.String">PT 0035027700048249091</cv_SrvcLoanID>
// <rpt_ServicerLoanID type="System.String">PT 0035027700048249091</rpt_ServicerLoanID>
// <rpt_decimal_UPB type="System.Double">33714.9</rpt_decimal_UPB>
// <rpt_UPBCurrency type="System.String">EUR</rpt_UPBCurrency>
// <cv_upb type="System.Double">33714.9</cv_upb>
// <cv_UPBCurrency type="System.String">EUR</cv_UPBCurrency>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlProposedCostsDetails = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_PropCostRowNumber type="System.String">1</rpt_PropCostRowNumber>
// <dd_Vendors type="System.String">127</dd_Vendors>
// <rpt_decimal_Costs type="System.Decimal">3315.00</rpt_decimal_Costs>
// <rpt_decimal_VAT type="System.Decimal">0.00</rpt_decimal_VAT>
// <rpt_decimal_TotalCosts type="System.Decimal">3315.00</rpt_decimal_TotalCosts>
// <rpt_CostsCurrency type="System.String">EUR</rpt_CostsCurrency>
// <rpt_Description type="System.String">3% ITP</rpt_Description>
// <rpt_VendorsValue type="System.String">--      Other      --</rpt_VendorsValue>
// <rpt_Other type="System.String">Taxation</rpt_Other>
// </Item>
// <Item>
// <rpt_PropCostRowNumber type="System.String">2</rpt_PropCostRowNumber>
// <dd_Vendors type="System.String">127</dd_Vendors>
// <rpt_decimal_Costs type="System.Decimal">2068.00</rpt_decimal_Costs>
// <rpt_decimal_VAT type="System.Decimal">0.00</rpt_decimal_VAT>
// <rpt_decimal_TotalCosts type="System.Decimal">2068.00</rpt_decimal_TotalCosts>
// <rpt_CostsCurrency type="System.String">EUR</rpt_CostsCurrency>
// <rpt_Description type="System.String">
// </rpt_Description>
// <rpt_VendorsValue type="System.String">--      Other      --</rpt_VendorsValue>
// <rpt_Other type="System.String">Land registry and notary</rpt_Other>
// </Item>
// </Items>
// </RepeaterData>`;








//   function fnGetJsonObjFromXmlStr(name: string, xml: string): any | null {

//     try {
//       console.log('------------------------');

//       console.log('Processing XML...', name, xml);

//       let dom = JsonXmlHelper.parseXml(xml);

//       let jsonStr = JsonXmlHelper.xml2json(dom, "  ");
//       console.log('JSON String:');
//       console.log(jsonStr);

//       setStateOutput(`${name}:\r\n${jsonStr}`);

//       let obj = JSON.parse(jsonStr);
//       console.log('JSON Object', obj);

//       return obj;
//     } catch (error) {
//       console.error('ERROR PARSING XML', name, error, xml);
//       return null;
//     }
//   }


//   function fnXmlHasMultipleItems(xml: string) {
//     return GenUtil.countStrInStr(xml, '<Item>') > 1 ? true : false;
//   }


//   useEffect(function onComponentDidMount() {

//     console.clear();

//     console.log('ComponentDidMount [Test5]');


//     //------------------------------


//     // if (false) {

//     //   let a = fnGetJsonObjFromXmlStr('xmlConnectionDetails', xmlConnectionDetails) as ConnectionDetailsItem;

//     //   console.log(a.RepeaterData.Items.Item.rpt_ConnecID['#text']);
//     //   // TIP: will only ever have a single Item
//     // }


//     //------------------------------
//     /*

//     https://app.quicktype.io/
//     https://nimbletext.com/Live/


//         PROBLEM:
//           there is XML ambiguity with the data that is saved in the SP listitem
//           the tool i'm using to convert XML to JSON cannot figure out if 'Items.Item' is an object or a collection of objects
//           if only a single Item is in the XML, it looks like 'Items.Item' => is an object
//           if more than 1 Items is in the XML, it looks like 'Items.Item' => is an array of objects
//           see here:
  
//             export interface Items {
//                 Item: Item;
//             }
  
//             export interface Items {
//                 Item: Item[];
//             }
  
//           I can do this 2 ways:

//           1. using a single inteface where:
//                 Item: Item | Item[]
//                 i can check object.hasOwnProperty to determine if Item is an object or an array
//                 But, i lose intellisense and things start getting crazy
//                 plus, i'm not sure how the toXML conversion later will be handled, probably OK

//           2. using 2 different interfaces
//                 one for each scenario above
//                 i get much better typing/intellisense
//                 i have to add a helper function to check how many "<Item>" are found in the XML string
//                 helper function is XmlHasMultipleItems(s)
//                 seems to be the better way to get the Item(s) from the xml=>json object

//         */


//     if (false) {

//       // console.log(GenUtil.countStrInStr(xmlBorrowerInfo1, '<Item>'));
//       // console.log(GenUtil.countStrInStr(xmlBorrowerInfo2, '<Item>'));

//       // console.log(XmlHasMultipleItems(xmlBorrowerInfo1));
//       // console.log(XmlHasMultipleItems(xmlBorrowerInfo2));

//       // has single Item
//       let c1 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo1', xmlBorrowerInfo1);
//       // has multiple Items
//       let c2 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo2', xmlBorrowerInfo2);

//       if (!fnXmlHasMultipleItems(xmlBorrowerInfo1)) {
//         let t = c1 as BorrowerInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_BorrID);
//       }
//       else {
//         let t = c1 as BorrowerInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_BorrID));
//       }

//       if (!fnXmlHasMultipleItems(xmlBorrowerInfo2)) {
//         let t = c2 as BorrowerInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_BorrID);
//       }
//       else {
//         let t = c2 as BorrowerInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_BorrID));
//       }
//     }


//     if (false) {

//       let d = fnGetJsonObjFromXmlStr('xmlAssetInfo', xmlAssetInfo);

//       // if (!fnXmlHasMultipleItems(xmlAssetInfo)) {
//       //   let t = d as LoanInfo1;
//       //   console.log("Single:", t.RepeaterData.Items.Item.cv_LoanID);
//       // }
//       // else {
//       //   let t = d as LoanInfoN;
//       //   console.log("Multiple:");
//       //   t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_LoanID));
//       // }

//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlAssetSale', xmlAssetSale);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlBorrowerInfo1', xmlBorrowerInfo1);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlBorrowerInfo2', xmlBorrowerInfo2);
//     }

//     if (true) {
//       let d = fnGetJsonObjFromXmlStr('xmlCashflowTable', xmlCashflowTable);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlConnectionDetails', xmlConnectionDetails);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlCreditBidTable', xmlCreditBidTable);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlDILTable', xmlDILTable);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlLoanInfo', xmlLoanInfo);
//     }

//     if (false) {
//       let d = fnGetJsonObjFromXmlStr('xmlProposedCostsDetails', xmlProposedCostsDetails);
//     }






//     if (false) {

//       // let d = fnGetJsonObjFromXmlStr('xmlLoanInfo', xmlLoanInfo);

//       // if (!fnXmlHasMultipleItems(xmlLoanInfo)) {
//       //   let t = d as LoanInfo1;
//       //   console.log("Single:", t.RepeaterData.Items.Item.cv_LoanID);
//       // }
//       // else {
//       //   let t = d as LoanInfoN;
//       //   console.log("Multiple:");
//       //   t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_LoanID));
//       // }
//     }


//     if (false) {

//       let e = fnGetJsonObjFromXmlStr('xmlAssetInfo', xmlAssetInfo);

//       if (!fnXmlHasMultipleItems(xmlLoanInfo)) {
//         let t = e as AssetInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_AssetID);
//       }
//       else {
//         let t = e as AssetInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_AssetID));
//       }
//     }







//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);


//   return (
//     <>
//       <h3>Check the browser console for Testing results.</h3>

//       <div>

//         <TextField label="OUTPUT:" value={stateOutput} multiline rows={10} />

//       </div>

//     </>
//   );
// };
