/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import {
  TagPicker,
  ITag
} from '@fluentui/react';

import * as GenUtil from '../../Helpers/GenUtil2';
import * as Consts from "../../Helpers/Consts";

export interface IAjaxPickerProps {
  cssClassNames?: string;
  disabled: boolean;
  itemLimit: number;
  suggestedTags?: ITag[];
  getSuggestedTags?: (filterText: string) => Promise<ITag[]>;
  onTagsChanged: (items?: ITag[]) => void;
  selectedTags: ITag[];
  suggestionsHeaderText?: string;
  noResultsFoundText?: string;
  searchingText?: string;
  placeholder?: string;
}

export const AjaxPicker: React.FunctionComponent<IAjaxPickerProps> = (props: React.PropsWithChildren<IAjaxPickerProps>) => {

  // useEffect(() => {
  //   Consts.showMounted && console.log("[MOUNTED: AjaxPicker]");
  // }, []);


  function getTextFromItem(item: ITag) {
    return item.name;
  }


  function tagAlreadySelected(tag: ITag, tagList?: ITag[]): boolean {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.filter(compareTag => compareTag.key === tag.key).length > 0;
  }


  const onResolveSuggestions = async (filterText: string, selectedItems?: ITag[]): Promise<ITag[]> => {

    if (typeof props.getSuggestedTags !== 'undefined') {
      // handle using ajax lookup from parent
      const matches = await props.getSuggestedTags(filterText);
      return matches.filter(tag => !tagAlreadySelected(tag, selectedItems));
    }
    else if (typeof props.suggestedTags !== 'undefined') {
      // handle using static tags from parent
      if (GenUtil.isNull(filterText)) return props.suggestedTags.slice(0, Consts.graphMaxForPickerCount);
      return props.suggestedTags.filter((o, i) => {
        return GenUtil.contains(o.name, filterText);
      }).slice(0, Consts.graphMaxForPickerCount);
    }
    else {
      return [];
    }
  };


  let _cssClassNames = GenUtil.safeTrim(props.cssClassNames);
  let _noResultsFoundText = GenUtil.NVL(GenUtil.safeTrim(props.noResultsFoundText), 'No matches found');
  let _suggestionsHeaderText = GenUtil.NVL(GenUtil.safeTrim(props.suggestionsHeaderText), '');
  let _searchingText = GenUtil.NVL(GenUtil.safeTrim(props.searchingText), '');
  let _inputProps = props.placeholder ? { placeholder: props.placeholder } : undefined;


  return (
    <>
      <TagPicker
        className={_cssClassNames}
        disabled={props.disabled}
        getTextFromItem={getTextFromItem}
        itemLimit={props.itemLimit}
        onChange={props.onTagsChanged}
        onResolveSuggestions={onResolveSuggestions}
        pickerSuggestionsProps={{
          noResultsFoundText: _noResultsFoundText,
          suggestionsHeaderText: _suggestionsHeaderText,
        }}
        searchingText={_searchingText}
        selectedItems={props.selectedTags}
        resolveDelay={400}
        inputProps={_inputProps}
      />
    </>
  );
};