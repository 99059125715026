/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { TextField, IconButton } from '@fluentui/react';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import { LoanInfoItem } from '../../../Models/Intrum/GridModels/LoanInfo1';

export interface ILoanInfoRowProps {
  data: LoanInfoItem;
  updateFieldVal: (id: string, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (a: string) => void;
  isReadOnly: boolean;
}

export const LoanInfoRow: React.FunctionComponent<ILoanInfoRowProps> = (props: React.PropsWithChildren<ILoanInfoRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: LoanInfoRow]");
  }, []);


  // user controlled input

  const [stateJrLiens, setStateJrLiens] = useState(props.data.rpt_JrLiensEnc);
  const onChangeJrLiens = useCallback((evt: any, v?: string) => { setStateJrLiens(v || ''); }, []);

  // const [stateRentalIncome, setStateRentalIncome] = useState(props.data.cv_RentalIncome);
  // const onChangeRentalIncome = useCallback((evt: any, v?: string) => { setStateRentalIncome(v || ''); }, []);


  // send field updates back to parent

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.data.rpt_ServicerLoanID, 'jrLiensEnc', GenUtil.safeTrim(stateJrLiens));
  }, [stateJrLiens]);


  // useEffect(() => {
  //   // sending string value
  //   props.updateFieldVal(props.data.rpt_ServicerLoanID, 'rentalIncome', GenUtil.safeTrim(stateRentalIncome));
  // }, [stateRentalIncome]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.data.rpt_ServicerLoanID);
  }


  return (
    <>

      <tr className='sep'>

        {
          Consts.isWorkbench() && (
            <td style={{ color: 'purple' }}>
              {props.data.cv_ConnId}
            </td>
          )
        }

        <td>
          {props.data.rpt_LoanID}
        </td>
        <td>
          {props.data.rpt_ServicerLoanNumber}
        </td>
        <td>
          {props.data.rpt_DispUPB}
        </td>
        <td>
          {props.data.rpt_DispCurr}
        </td>
        {/* <td>
          {
            props.isReadOnly ? (
              <>{props.data.cv_RentalIncome}</>
            ) : (
              <><TextField onChange={onChangeRentalIncome} value={stateRentalIncome} placeholder='Please enter text here' className='w300' maxLength={255} /></>
            )
          }
        </td> */}
        <td>
          {
            props.isReadOnly ? (
              <>{props.data.rpt_JrLiensEnc}</>
            ) : (
              <><TextField onChange={onChangeJrLiens} value={stateJrLiens} placeholder='Please enter text here' className='w250' maxLength={255} /></>
            )
          }
        </td>
        <td style={{ maxWidth: 25, textAlign: 'right' }}>
          {
            !props.isReadOnly && (
              <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>
      </tr>

    </>
  );
};