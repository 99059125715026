/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import { eq } from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/TDX/GraphDataService';
import * as StaticData from "../../../StaticData/TDX/StaticData";


export interface IANDetailLoaderProps { }


/**
 *
 * The purpose of this component is to load the TDX AN record to determine its contenttype (Secured vs Unsecured).
 * Clicking New works fine using the SPLoader.aspx.
 * Using the builtin doubleclick or SP edit option works fine using the SPLoader.aspx.
 * ***BUT, using the custom editurl column will only be able to redirect without knowing the path should be /Secured or /Unsecured.
 * This component will quickly load the AN record and determine its contenttype, and then redirect to the proper secured or unsecured path.
 * HOWEVER, the EditUrl button in SP could be changed to filter on "ANWorkflowType" field, and then this redirect component would not be needed.
 * LATEST: i edited the EditUrl button in SP and formatted it to use the "ANWorkflowType", which holds the value 'Secured' or 'Unsecured', this works fine, so this component is NOT needed.
 *
 */
export const ANDetailLoader: React.FunctionComponent<IANDetailLoaderProps> = (props: React.PropsWithChildren<IANDetailLoaderProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANDetailLoader]");
  }, []);


  let params = useParams();

  let navigate = useNavigate();

  const { instance, accounts } = useMsal();

  let stateANItemId: number = GenUtil.safeToNumber(params.id);


  useEffect(function loadANItemFromSP() {
    // load AN Note SPListItem from SP
    let ignore = false;

    let _id = stateANItemId;

    setTimeout(async () => {

      let an = await GraphDataService.getANNote(accounts, instance, _id);

      if (an.httpStatus >= 400) {
        AppHelper.toastError(`Error loading Advisory Note from SharePoint: ItemId=${_id}; Msg=${an.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Advisory Note in ANDetailLoader`, an);
        return;
      }

      console.log('Loaded AN from SharePoint', an);

      if (!ignore) {

        if (eq(an.fields.ANWorkflowType, "Secured")) {
          navigate(`/TDX/Secured/${_id}`);
        }
        else {
          navigate(`/TDX/Unsecured/${_id}`);
        }
      }

    }, Consts.sleepMsAjax);

    return () => { ignore = true; }
  }, [stateANItemId]);


  return (
    <>
    </>
  );
};