/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { AssetLevelMatrixFourItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixFour';
import { ANType } from '../../../Models/Cabot/ANType';


export interface IALM4RowProps {
  selANSubType: ANType | null;
  data: AssetLevelMatrixFourItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const ALM4Row: React.FunctionComponent<IALM4RowProps> = (props: React.PropsWithChildren<IALM4RowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM4Row]");
  }, []);


  // user controlled input
  const [state_cv_EOSFOS, setState_cv_EOSFOS] = useState(''); // do not set this here, this is set in a different hook, which depends on ansubtype

  const [state_rpt_ALM4_EOS, setstate_rpt_ALM4_EOS] = useState(props.data.rpt_ALM4_EOS);
  const onChange_rpt_ALM4_EOS = useCallback((evt: any, v?: string) => { setstate_rpt_ALM4_EOS(v || ''); }, []);

  const [state_rpt_decimal_ALM4_SalesProceeds, setstate_rpt_decimal_ALM4_SalesProceeds] = useState(props.data.rpt_decimal_ALM4_SalesProceeds);
  const onChange_rpt_decimal_ALM4_SalesProceeds = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_SalesProceeds(v || ''); }, []);

  const [state_rpt_decimal_ALM4_RentalIncome, setstate_rpt_decimal_ALM4_RentalIncome] = useState(props.data.rpt_decimal_ALM4_RentalIncome);
  const onChange_rpt_decimal_ALM4_RentalIncome = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_RentalIncome(v || ''); }, []);

  const [state_rpt_decimal_ALM4_TotalIncome, setstate_rpt_decimal_ALM4_TotalIncome] = useState(props.data.rpt_decimal_ALM4_TotalIncome);
  const onChange_rpt_decimal_ALM4_TotalIncome = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_TotalIncome(v || ''); }, []);

  const [state_rpt_decimal_ALM4_ReceiversFee, setstate_rpt_decimal_ALM4_ReceiversFee] = useState(props.data.rpt_decimal_ALM4_ReceiversFee);
  const onChange_rpt_decimal_ALM4_ReceiversFee = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_ReceiversFee(v || ''); }, []);

  const [state_rpt_decimal_ALM4_ReceiverOutlays, setstate_rpt_decimal_ALM4_ReceiverOutlays] = useState(props.data.rpt_decimal_ALM4_ReceiverOutlays);
  const onChange_rpt_decimal_ALM4_ReceiverOutlays = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_ReceiverOutlays(v || ''); }, []);

  const [state_rpt_decimal_ALM4_SalesAgentFee, setstate_rpt_decimal_ALM4_SalesAgentFee] = useState(props.data.rpt_decimal_ALM4_SalesAgentFee);
  const onChange_rpt_decimal_ALM4_SalesAgentFee = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_SalesAgentFee(v || ''); }, []);

  const [state_rpt_decimal_ALM4_SaleAgentMarketingCosts, setstate_rpt_decimal_ALM4_SaleAgentMarketingCosts] = useState(props.data.rpt_decimal_ALM4_SaleAgentMarketingCosts);
  const onChange_rpt_decimal_ALM4_SaleAgentMarketingCosts = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_SaleAgentMarketingCosts(v || ''); }, []);

  const [state_rpt_decimal_ALM4_LegalFeesConveyance, setstate_rpt_decimal_ALM4_LegalFeesConveyance] = useState(props.data.rpt_decimal_ALM4_LegalFeesConveyance);
  const onChange_rpt_decimal_ALM4_LegalFeesConveyance = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_LegalFeesConveyance(v || ''); }, []);

  const [state_rpt_decimal_ALM4_LegalFeesLegalOutlay, setstate_rpt_decimal_ALM4_LegalFeesLegalOutlay] = useState(props.data.rpt_decimal_ALM4_LegalFeesLegalOutlay);
  const onChange_rpt_decimal_ALM4_LegalFeesLegalOutlay = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_LegalFeesLegalOutlay(v || ''); }, []);

  const [state_rpt_decimal_ALM4_TaxAdvise, setstate_rpt_decimal_ALM4_TaxAdvise] = useState(props.data.rpt_decimal_ALM4_TaxAdvise);
  const onChange_rpt_decimal_ALM4_TaxAdvise = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_TaxAdvise(v || ''); }, []);

  const [state_rpt_decimal_ALM4_BERCerts, setstate_rpt_decimal_ALM4_BERCerts] = useState(props.data.rpt_decimal_ALM4_BERCerts);
  const onChange_rpt_decimal_ALM4_BERCerts = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_BERCerts(v || ''); }, []);

  const [state_rpt_decimal_ALM4_Insurance, setstate_rpt_decimal_ALM4_Insurance] = useState(props.data.rpt_decimal_ALM4_Insurance);
  const onChange_rpt_decimal_ALM4_Insurance = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_Insurance(v || ''); }, []);

  const [state_rpt_decimal_ALM4_ServiceCharges, setstate_rpt_decimal_ALM4_ServiceCharges] = useState(props.data.rpt_decimal_ALM4_ServiceCharges);
  const onChange_rpt_decimal_ALM4_ServiceCharges = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_ServiceCharges(v || ''); }, []);

  const [state_rpt_decimal_ALM4_Rates, setstate_rpt_decimal_ALM4_Rates] = useState(props.data.rpt_decimal_ALM4_Rates);
  const onChange_rpt_decimal_ALM4_Rates = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_Rates(v || ''); }, []);

  const [state_rpt_decimal_ALM4_RepairsMaintenanceCleanup, setstate_rpt_decimal_ALM4_RepairsMaintenanceCleanup] = useState(props.data.rpt_decimal_ALM4_RepairsMaintenanceCleanup);
  const onChange_rpt_decimal_ALM4_RepairsMaintenanceCleanup = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_RepairsMaintenanceCleanup(v || ''); }, []);

  const [state_rpt_decimal_ALM4_CGT, setstate_rpt_decimal_ALM4_CGT] = useState(props.data.rpt_decimal_ALM4_CGT);
  const onChange_rpt_decimal_ALM4_CGT = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_CGT(v || ''); }, []);

  const [state_rpt_decimal_ALM4_HHC, setstate_rpt_decimal_ALM4_HHC] = useState(props.data.rpt_decimal_ALM4_HHC);
  const onChange_rpt_decimal_ALM4_HHC = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_HHC(v || ''); }, []);

  const [state_rpt_decimal_ALM4_LPT, setstate_rpt_decimal_ALM4_LPT] = useState(props.data.rpt_decimal_ALM4_LPT);
  const onChange_rpt_decimal_ALM4_LPT = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_LPT(v || ''); }, []);

  const [state_rpt_decimal_ALM4_NPPR, setstate_rpt_decimal_ALM4_NPPR] = useState(props.data.rpt_decimal_ALM4_NPPR);
  const onChange_rpt_decimal_ALM4_NPPR = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_NPPR(v || ''); }, []);

  const [state_rpt_decimal_ALM4_BankCharge, setstate_rpt_decimal_ALM4_BankCharge] = useState(props.data.rpt_decimal_ALM4_BankCharge);
  const onChange_rpt_decimal_ALM4_BankCharge = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_BankCharge(v || ''); }, []);

  const [state_rpt_ALM4_UserField, setstate_rpt_ALM4_UserField] = useState(props.data.rpt_ALM4_UserField);
  const onChange_rpt_ALM4_UserField = useCallback((evt: any, v?: string) => { setstate_rpt_ALM4_UserField(v || ''); }, []);

  const [state_rpt_decimal_ALM4_UserValue, setstate_rpt_decimal_ALM4_UserValue] = useState(props.data.rpt_decimal_ALM4_UserValue);
  const onChange_rpt_decimal_ALM4_UserValue = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_UserValue(v || ''); }, []);

  const [state_rpt_decimal_ALM4_TotalIncVAT, setstate_rpt_decimal_ALM4_TotalIncVAT] = useState(props.data.rpt_decimal_ALM4_TotalIncVAT);
  const onChange_rpt_decimal_ALM4_TotalIncVAT = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_TotalIncVAT(v || ''); }, []);

  const [state_rpt_decimal_ALM4_NetProceeds, setstate_rpt_decimal_ALM4_NetProceeds] = useState(props.data.rpt_decimal_ALM4_NetProceeds);
  const onChange_rpt_decimal_ALM4_NetProceeds = useCallback((evt: any, v?: string) => { setstate_rpt_decimal_ALM4_NetProceeds(v || ''); }, []);


  useEffect(() => {
    let lbl = !props.selANSubType ? 'Loading' :
      GenUtil.contains(props.selANSubType.fields.Secondaryoptions, 'FOS', true) ? 'FOS' : 'EOS';
    setState_cv_EOSFOS(lbl);
  }, [props.selANSubType]);


  // send field updates back to parent
  useEffect(() => {
    props.updateFieldVal(props.counter, 'cv_EOSFOS', GenUtil.safeTrim(state_cv_EOSFOS));
  }, [state_cv_EOSFOS]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_ALM4_EOS', GenUtil.safeTrim(state_rpt_ALM4_EOS));
  }, [state_rpt_ALM4_EOS]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_SalesProceeds', GenUtil.safeTrim(state_rpt_decimal_ALM4_SalesProceeds));
  }, [state_rpt_decimal_ALM4_SalesProceeds]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_RentalIncome', GenUtil.safeTrim(state_rpt_decimal_ALM4_RentalIncome));
  }, [state_rpt_decimal_ALM4_RentalIncome]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_TotalIncome', GenUtil.safeTrim(state_rpt_decimal_ALM4_TotalIncome));
  }, [state_rpt_decimal_ALM4_TotalIncome]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_ReceiversFee', GenUtil.safeTrim(state_rpt_decimal_ALM4_ReceiversFee));
  }, [state_rpt_decimal_ALM4_ReceiversFee]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_ReceiverOutlays', GenUtil.safeTrim(state_rpt_decimal_ALM4_ReceiverOutlays));
  }, [state_rpt_decimal_ALM4_ReceiverOutlays]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_SalesAgentFee', GenUtil.safeTrim(state_rpt_decimal_ALM4_SalesAgentFee));
  }, [state_rpt_decimal_ALM4_SalesAgentFee]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_SaleAgentMarketingCosts', GenUtil.safeTrim(state_rpt_decimal_ALM4_SaleAgentMarketingCosts));
  }, [state_rpt_decimal_ALM4_SaleAgentMarketingCosts]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_LegalFeesConveyance', GenUtil.safeTrim(state_rpt_decimal_ALM4_LegalFeesConveyance));
  }, [state_rpt_decimal_ALM4_LegalFeesConveyance]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_LegalFeesLegalOutlay', GenUtil.safeTrim(state_rpt_decimal_ALM4_LegalFeesLegalOutlay));
  }, [state_rpt_decimal_ALM4_LegalFeesLegalOutlay]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_TaxAdvise', GenUtil.safeTrim(state_rpt_decimal_ALM4_TaxAdvise));
  }, [state_rpt_decimal_ALM4_TaxAdvise]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_BERCerts', GenUtil.safeTrim(state_rpt_decimal_ALM4_BERCerts));
  }, [state_rpt_decimal_ALM4_BERCerts]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_Insurance', GenUtil.safeTrim(state_rpt_decimal_ALM4_Insurance));
  }, [state_rpt_decimal_ALM4_Insurance]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_ServiceCharges', GenUtil.safeTrim(state_rpt_decimal_ALM4_ServiceCharges));
  }, [state_rpt_decimal_ALM4_ServiceCharges]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_Rates', GenUtil.safeTrim(state_rpt_decimal_ALM4_Rates));
  }, [state_rpt_decimal_ALM4_Rates]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_RepairsMaintenanceCleanup', GenUtil.safeTrim(state_rpt_decimal_ALM4_RepairsMaintenanceCleanup));
  }, [state_rpt_decimal_ALM4_RepairsMaintenanceCleanup]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_CGT', GenUtil.safeTrim(state_rpt_decimal_ALM4_CGT));
  }, [state_rpt_decimal_ALM4_CGT]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_HHC', GenUtil.safeTrim(state_rpt_decimal_ALM4_HHC));
  }, [state_rpt_decimal_ALM4_HHC]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_LPT', GenUtil.safeTrim(state_rpt_decimal_ALM4_LPT));
  }, [state_rpt_decimal_ALM4_LPT]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_NPPR', GenUtil.safeTrim(state_rpt_decimal_ALM4_NPPR));
  }, [state_rpt_decimal_ALM4_NPPR]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_BankCharge', GenUtil.safeTrim(state_rpt_decimal_ALM4_BankCharge));
  }, [state_rpt_decimal_ALM4_BankCharge]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_ALM4_UserField', GenUtil.safeTrim(state_rpt_ALM4_UserField));
  }, [state_rpt_ALM4_UserField]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_UserValue', GenUtil.safeTrim(state_rpt_decimal_ALM4_UserValue));
  }, [state_rpt_decimal_ALM4_UserValue]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_TotalIncVAT', GenUtil.safeTrim(state_rpt_decimal_ALM4_TotalIncVAT));
  }, [state_rpt_decimal_ALM4_TotalIncVAT]);
  useEffect(() => {
    props.updateFieldVal(props.counter, 'rpt_decimal_ALM4_NetProceeds', GenUtil.safeTrim(state_rpt_decimal_ALM4_NetProceeds));
  }, [state_rpt_decimal_ALM4_NetProceeds]);


  //#region 'validation'
  //-------------------------

  const memoIsErr_rpt_ALM4_EOS = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_ALM4_EOS();
  }, [props.stateFormSubmitted, state_rpt_ALM4_EOS]);

  function isErr_rpt_ALM4_EOS() {
    return GenUtil.isNull(state_rpt_ALM4_EOS);
  }


  const memoIsErr_rpt_decimal_ALM4_BERCerts = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_BERCerts();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_BERCerts]);

  function isErr_rpt_decimal_ALM4_BERCerts() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_BERCerts) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_BankCharge = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_BankCharge();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_BankCharge]);

  function isErr_rpt_decimal_ALM4_BankCharge() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_BankCharge) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_CGT = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_CGT();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_CGT]);

  function isErr_rpt_decimal_ALM4_CGT() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_CGT) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_HHC = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_HHC();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_HHC]);

  function isErr_rpt_decimal_ALM4_HHC() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_HHC) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_Insurance = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_Insurance();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_Insurance]);

  function isErr_rpt_decimal_ALM4_Insurance() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_Insurance) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_LPT = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_LPT();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_LPT]);

  function isErr_rpt_decimal_ALM4_LPT() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_LPT) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_LegalFeesConveyance = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_LegalFeesConveyance();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_LegalFeesConveyance]);

  function isErr_rpt_decimal_ALM4_LegalFeesConveyance() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_LegalFeesConveyance) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_LegalFeesLegalOutlay = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_LegalFeesLegalOutlay();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_LegalFeesLegalOutlay]);

  function isErr_rpt_decimal_ALM4_LegalFeesLegalOutlay() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_LegalFeesLegalOutlay) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_NPPR = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_NPPR();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_NPPR]);

  function isErr_rpt_decimal_ALM4_NPPR() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_NPPR) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_NetProceeds = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_NetProceeds();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_NetProceeds]);

  function isErr_rpt_decimal_ALM4_NetProceeds() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_NetProceeds) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_Rates = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_Rates();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_Rates]);

  function isErr_rpt_decimal_ALM4_Rates() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_Rates) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_ReceiverOutlays = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_ReceiverOutlays();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_ReceiverOutlays]);

  function isErr_rpt_decimal_ALM4_ReceiverOutlays() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_ReceiverOutlays) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_ReceiversFee = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_ReceiversFee();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_ReceiversFee]);

  function isErr_rpt_decimal_ALM4_ReceiversFee() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_ReceiversFee) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_RentalIncome = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_RentalIncome();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_RentalIncome]);

  function isErr_rpt_decimal_ALM4_RentalIncome() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_RentalIncome) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_RepairsMaintenanceCleanup = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_RepairsMaintenanceCleanup();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_RepairsMaintenanceCleanup]);

  function isErr_rpt_decimal_ALM4_RepairsMaintenanceCleanup() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_RepairsMaintenanceCleanup) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_SaleAgentMarketingCosts = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_SaleAgentMarketingCosts();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_SaleAgentMarketingCosts]);

  function isErr_rpt_decimal_ALM4_SaleAgentMarketingCosts() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_SaleAgentMarketingCosts) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_SalesAgentFee = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_SalesAgentFee();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_SalesAgentFee]);

  function isErr_rpt_decimal_ALM4_SalesAgentFee() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_SalesAgentFee) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_SalesProceeds = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_SalesProceeds();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_SalesProceeds]);

  function isErr_rpt_decimal_ALM4_SalesProceeds() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_SalesProceeds) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_ServiceCharges = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_ServiceCharges();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_ServiceCharges]);

  function isErr_rpt_decimal_ALM4_ServiceCharges() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_ServiceCharges) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_TaxAdvise = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_TaxAdvise();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_TaxAdvise]);

  function isErr_rpt_decimal_ALM4_TaxAdvise() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_TaxAdvise) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_TotalIncVAT = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_TotalIncVAT();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_TotalIncVAT]);

  function isErr_rpt_decimal_ALM4_TotalIncVAT() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_TotalIncVAT) == null;
  }


  const memoIsErr_rpt_decimal_ALM4_TotalIncome = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErr_rpt_decimal_ALM4_TotalIncome();
  }, [props.stateFormSubmitted, state_rpt_decimal_ALM4_TotalIncome]);

  function isErr_rpt_decimal_ALM4_TotalIncome() {
    return GenUtil.safeToNumberOrNull(state_rpt_decimal_ALM4_TotalIncome) == null;
  }

  //#endregion


  return (
    <>

      <tr className='sep'>
        <td className='ms-fontWeight-bold ms-fontSize-20'>
          {state_cv_EOSFOS}{(state_cv_EOSFOS.indexOf(':') < 0 ? ':' : '')}
        </td>
        <td>          {
          !props.isReadOnly ? (
            <TextField onChange={onChange_rpt_ALM4_EOS} value={state_rpt_ALM4_EOS} placeholder='Please enter text here' className='w450' errorMessage={memoIsErr_rpt_ALM4_EOS ? 'Value is required.' : ''} maxLength={255} />
          ) : (
            <div>{state_rpt_ALM4_EOS}</div>
          )
        }
        </td>
      </tr>

      <tr className='sep'>
        <td className='ms-fontWeight-bold ms-fontSize-20'>
          Income
        </td>
        <td>
        </td>
      </tr>

      <tr className='sep'>
        <td>
          <Label>Sales Proceeds:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_SalesProceeds} value={state_rpt_decimal_ALM4_SalesProceeds} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_SalesProceeds ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_SalesProceeds}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Rental Income:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_RentalIncome} value={state_rpt_decimal_ALM4_RentalIncome} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_RentalIncome ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_RentalIncome}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Total Income:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_TotalIncome} value={state_rpt_decimal_ALM4_TotalIncome} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_TotalIncome ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_TotalIncome}</div>
            )
          }
        </td>
      </tr>

      <tr className='sep'>
        <td className='ms-fontWeight-bold ms-fontSize-20'>
          Expenditure
        </td>
        <td>
        </td>
      </tr>

      <tr className='sep'>
        <td>
          <Label>Receivers Fee:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_ReceiversFee} value={state_rpt_decimal_ALM4_ReceiversFee} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_ReceiversFee ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_ReceiversFee}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Receiver Outlays:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_ReceiverOutlays} value={state_rpt_decimal_ALM4_ReceiverOutlays} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_ReceiverOutlays ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_ReceiverOutlays}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Sales Agent Fee:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_SalesAgentFee} value={state_rpt_decimal_ALM4_SalesAgentFee} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_SalesAgentFee ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_SalesAgentFee}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Sale Agent Marketing Costs:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_SaleAgentMarketingCosts} value={state_rpt_decimal_ALM4_SaleAgentMarketingCosts} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_SaleAgentMarketingCosts ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_SaleAgentMarketingCosts}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Legal Fees - Conveyance:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_LegalFeesConveyance} value={state_rpt_decimal_ALM4_LegalFeesConveyance} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_LegalFeesConveyance ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_LegalFeesConveyance}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Legal Fees - Legal Outlay:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_LegalFeesLegalOutlay} value={state_rpt_decimal_ALM4_LegalFeesLegalOutlay} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_LegalFeesLegalOutlay ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_LegalFeesLegalOutlay}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Tax Advise:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_TaxAdvise} value={state_rpt_decimal_ALM4_TaxAdvise} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_TaxAdvise ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_TaxAdvise}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>BER Certs:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_BERCerts} value={state_rpt_decimal_ALM4_BERCerts} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_BERCerts ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_BERCerts}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Insurance:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_Insurance} value={state_rpt_decimal_ALM4_Insurance} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_Insurance ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_Insurance}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Service Charges:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_ServiceCharges} value={state_rpt_decimal_ALM4_ServiceCharges} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_ServiceCharges ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_ServiceCharges}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Rates:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_Rates} value={state_rpt_decimal_ALM4_Rates} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_Rates ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_Rates}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Repairs/Maintenance/Clean-up:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_RepairsMaintenanceCleanup} value={state_rpt_decimal_ALM4_RepairsMaintenanceCleanup} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_RepairsMaintenanceCleanup ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_RepairsMaintenanceCleanup}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>CGT/VAT/CGS/Income Tax:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_CGT} value={state_rpt_decimal_ALM4_CGT} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_CGT ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_CGT}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>HHC:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_HHC} value={state_rpt_decimal_ALM4_HHC} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_HHC ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_HHC}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>LPT:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_LPT} value={state_rpt_decimal_ALM4_LPT} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_LPT ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_LPT}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>NPPR:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_NPPR} value={state_rpt_decimal_ALM4_NPPR} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_NPPR ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_NPPR}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Bank Charge:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_BankCharge} value={state_rpt_decimal_ALM4_BankCharge} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_BankCharge ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_BankCharge}</div>
            )
          }
        </td>
      </tr>

      <tr className='sep'>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_ALM4_UserField} value={state_rpt_ALM4_UserField} placeholder='Please enter label here' className='' maxLength={255} />
            ) : (
              <div>{state_rpt_ALM4_UserField}</div>
            )
          }
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_UserValue} value={state_rpt_decimal_ALM4_UserValue} placeholder='Please enter value here' className='' maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_UserValue}</div>
            )
          }
        </td>
      </tr>

      <tr className='sep'>
        <td>
          <Label>Total Inc VAT:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_TotalIncVAT} value={state_rpt_decimal_ALM4_TotalIncVAT} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_TotalIncVAT ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_TotalIncVAT}</div>
            )
          }
        </td>
      </tr>
      <tr className='sep'>
        <td>
          <Label>Net Proceeds Available for Distribution:</Label>
        </td>
        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChange_rpt_decimal_ALM4_NetProceeds} value={state_rpt_decimal_ALM4_NetProceeds} placeholder='Please enter value here' className='' errorMessage={memoIsErr_rpt_decimal_ALM4_NetProceeds ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <div>{state_rpt_decimal_ALM4_NetProceeds}</div>
            )
          }
        </td>
      </tr>

    </>
  );
};