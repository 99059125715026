import { useState } from "react";




export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  // ex: const [name, setName] = useLocalStorage("name", "Bob");
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue] as const;
}


export function useSessionStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  // ex: const [name, setName] = useSessionStorage("name", "Bob");
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that persists the new value to sessionStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to session storage
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue] as const;
}


export function useStorageExpires<T>(key: string, initialValue: T, isSession: boolean = true, minutes: number = 5) {
  // customized hook to use session/local storage with expiration
  // default is using session storage that expires in 5 mins
  // NOTE: initial value should be empty, eg: null, [], '', 0
  // when isSession = true, uses sessionStorage, otherwise uses localStorage
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      let item: string | null;

      if (isSession) item = window.sessionStorage.getItem(key);
      else item = window.localStorage.getItem(key);

      if (item) {
        // unwrap data, check the datetime, return initial value if not found or expired
        let expData: ExpiringData = JSON.parse(item);

        let dtNow = new Date();
        let dtExp = new Date(expData.dt);

        let data = expData.data;

        console.log(`[useStorageExpires] found cache, will expire at '${dtExp.toLocaleString()}', isExpired=${!(dtNow < dtExp)}`);

        if (dtNow < dtExp) {
          return data;
        }
        else {
          return initialValue;
        }
      }
      else {
        return initialValue;
      }

    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      // wrap data with datetime in the future when data will expire
      let dtExp = getFutureDate(minutes);
      let expData: ExpiringData = { dt: dtExp, data: valueToStore };

      if (isSession) window.sessionStorage.setItem(key, JSON.stringify(expData));
      else window.localStorage.setItem(key, JSON.stringify(expData));

      console.log(`[useStorageExpires] saving cache, will expire at '${dtExp.toLocaleString()}'`);

    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as const;
}


function getFutureDate(minutes: number): Date {
  let d1 = new Date();
  let d2 = new Date();
  d2.setTime(d1.getTime() + (minutes * 60 * 1000));
  return d2;
}


interface ExpiringData {
  dt: Date;
  data: any;
};
