/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Checkbox,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';
import { useAccount, useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../Helpers/Consts";
import * as GenUtil from '../../Helpers/GenUtil2';
import * as AppHelper from '../../Helpers/AppHelper';
// import * as JsonXmlHelper from '../../Helpers/JsonXmlConverter';
import * as StaticData from "../../StaticData/Finsolutia/StaticData";
import * as AppModels from '../../Models/AppModels';

import * as GraphDataServiceShared from '../../Services/GraphDataService';
import * as GraphDataService from '../../Services/Finsolutia/GraphDataService';
import * as GraphHelper from '../../Helpers/GraphHelper';

import { Space } from '../Controls/Space';


export interface ITest6Props { }


export const Test6: React.FunctionComponent<ITest6Props> = (props: React.PropsWithChildren<ITest6Props>) => {
  return (<></>);
};


// export const Test6: React.FunctionComponent<ITest6Props> = (props: React.PropsWithChildren<ITest6Props>) => {

//   const { instance, accounts } = useMsal();

//   const [stateOutput, setStateOutput] = useState<string>('');

//   const [stateLoading, setStateLoading] = useState<boolean>(false);

//   const [stateDriveId, setStateDriveId] = useState('');
//   const onChangeDriveId = useCallback((evt: any, v?: string) => { setStateDriveId(v || ''); }, []);

//   const [stateFolderId, setStateFolderId] = useState('');
//   const onChangeFolderId = useCallback((evt: any, v?: string) => { setStateFolderId(v || ''); }, []);

//   const [stateSelFile, setStateSelFile] = useState<AppModels.FileUpload | null>(null);


//   useEffect(function onComponentDidMount() {
//     // console.clear();
//     console.log('ComponentDidMount [Test6]');
//   }, []);


//   function handleFileChange(event: any) {
//     let f = event.target.files[0];
//     // console.log(f);
//     setStateSelFile(f);
//     // console.log(
//     //   f.name, // "fire officers listing 2023.txt"
//     //   f.size, // 693
//     //   f.type, // "text/plain"
//     // )

//     // setStateSelFileUI(event.target.files[0].name);

//     event.target.value = null;
//   }




//   async function handleUploadClick() {

//     if (stateSelFile == null) {
//       return;
//     }

//     setStateLoading(true);

//     setTimeout(async () => {

//       console.log("UPLOADING", stateSelFile, stateSelFile.name, stateSelFile.size, stateSelFile.type);

//       let defDriveId = "b!PmC4208Tr0yDWfPbd2nnp4MmjAghdJVDl7igW4omQbw7rXrECe_9RaEiB18qdgQl"; // doc library drive id
//       // let folderId = "01EPVIH4V6Y2GOVW7725BZO354PWSELRRZ"; // root folder drive id
//       let defFolderId = '01EPVIH4RNGDI64GODXRBL26GQ4STCD5ZS'; // sub folder in the library works too!
//       // NOTE: folderId is required, it will be either root folder id, or some other folder id in the library/drive

//       let driveId = defDriveId;
//       let folderId = defFolderId;

//       if (!GenUtil.isNull(stateDriveId)) {
//         driveId = GenUtil.safeTrim(stateDriveId);
//       }
//       if (!GenUtil.isNull(stateFolderId)) {
//         folderId = GenUtil.safeTrim(stateFolderId);
//       }

//       let fname = stateSelFile.name;

//       let changeFilename = false;

//       if (changeFilename) {
//         // convert the filename, add "datetime.now.ticks" to file before extension
//         let ts = (new Date()).getTime() + '';
//         fname = `${fname.substring(0, fname.lastIndexOf('.'))}_${ts}${fname.substring(fname.lastIndexOf('.'))}`;
//       }

//       // let createUploadSessionUrl = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${rootFolderId}:/${encodeURIComponent(fileName)}:/createUploadSession`;

//       let data = await GraphDataServiceShared.createUploadSession(accounts, instance, driveId, folderId, fname);

//       console.log("createUploadSession Resp", data);

//       let uploadUrl = (data as any).uploadUrl;


//       // let b64 = await toBase64(stateSelFile);
//       // console.log("BASE64", b64);
//       // #todo: may need to cut off the following prefix in the returned base64 string, up to first comma found: "data:image/jpeg;base64,"

//       // this URL comes from a prior POST request to Create the Upload Session (POST .../createUploadSession)
//       // let uploadUrl = `https://test.sharepoint.com/sites/CerberusPOC1/finsolutia1/_api/v2.0/drives/b!PmC4208Tr0yDWfPbd2nnp4MmjAghdJVDl7igW4omQbw7rXrECe_9RaEiB18qdgQl/items/01EPVIH4SC4ATLVJQVK5DYTAU2YLWOCQOV/uploadSession?guid='c5fff766-2c99-4b86-bc24-fdfd651cfe5e'&overwrite=True&rename=False&dc=0&tempauth=eyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvYmFuZHJkZXYyMDE3LnNoYXJlcG9pbnQuY29tQDkxMWVjNjk1LTUyOGUtNGNkZC04MzBhLWQ2Zjg4MmQ5YzkzZSIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2NzY1MzEyNjQiLCJleHAiOiIxNjc2NjE3NjY0IiwiZW5kcG9pbnR1cmwiOiIvN2lPOTJ3aDlvVzBWM0laS05Qd1hzN3Q2UmgydXZQeFEzc0FQckdXTkdFPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMjgyIiwiaXNsb29wYmFjayI6IlRydWUiLCJjaWQiOiJaVFpoTlRFeU5tTXRaalZrTmkxak9XVXlMV0k0WVdNdE5tWXdOakJpTlRjNFlqUTQiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiWkdKaU9EWXdNMlV0TVRNMFppMDBZMkZtTFRnek5Ua3Raak5rWWpjM05qbGxOMkUzIiwiYXBwX2Rpc3BsYXluYW1lIjoiR3JhcGggRXhwbG9yZXIiLCJnaXZlbl9uYW1lIjoiQmVuamFtaW4iLCJmYW1pbHlfbmFtZSI6IlN0ZWluaGF1c2VyIiwic2lnbmluX3N0YXRlIjoiW1wia21zaVwiXSIsImFwcGlkIjoiZGU4YmM4YjUtZDlmOS00OGIxLWE4YWQtYjc0OGRhNzI1MDY0IiwidGlkIjoiOTExZWM2OTUtNTI4ZS00Y2RkLTgzMGEtZDZmODgyZDljOTNlIiwidXBuIjoiYnN0ZWluaGF1c2VyQGJhbmRyZGV2MjAxNy5vbm1pY3Jvc29mdC5jb20iLCJwdWlkIjoiMTAwM0JGRkRBMTBCRUNCRCIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfDEwMDNiZmZkYTEwYmVjYmRAbGl2ZS5jb20iLCJzY3AiOiJhbGxzaXRlcy5yZWFkIGFsbHNpdGVzLndyaXRlIGFsbHByb2ZpbGVzLnJlYWQgYWxscHJvZmlsZXMud3JpdGUiLCJ0dCI6IjIiLCJ1c2VQZXJzaXN0ZW50Q29va2llIjpudWxsLCJpcGFkZHIiOiIyMC4xOTAuMTM1LjQ3In0.UEFYRDV2VVo0WmlBNUdOL0toVVg2Tk11K1F3ek00Nk5OcVZoOHRpdFdoZz0`;


//       // const resp = await fetch(uploadUrl, {
//       //   "method": "PUT",
//       //   "headers": {
//       //     "Content-Length": `${stateSelFile.size}`,
//       //     "Content-Range": `bytes 0-${stateSelFile.size - 1}/${stateSelFile.size}`
//       //   },
//       //   // "body": b64 + '' // NOTE: never tried using base64, the direct File object below worked OK
//       //   "body": stateSelFile as any // NOTE: this worked!
//       // });

//       let resp1 = await GraphDataServiceShared.uploadFile(uploadUrl, stateSelFile);

//       console.log("uploadFile resp", resp1);

//       let newDriveItemId = resp1.id || '';


//       // at this point the file IS uploaded
//       // get the response, get the new driveItemId
//       // make a new request to get the listitem associated with the new driveItem just uploaded
//       // when we get back the listitem ID from that request
//       // we can make a POST request to update the listitem and set a field value associating the new file with the current AN ID

//       let resp2 = await GraphDataServiceShared.getDriveItemListItem(accounts, instance, driveId, newDriveItemId);

//       console.log("getDriveItemListItem resp", resp2);

//       let newItemId = parseInt(resp2.id || '');





//       let resp3 = await GraphDataService.updateItem(accounts, instance, 'Documents', newItemId, { AdvisoryNoteId: '12345' });

//       console.log("GraphDataService.updateItem", resp3);







//       // alert('Upload Success!');
//       AppHelper.toastSuccess(`Upload Success!`);

//       setStateSelFile(null); // this helps, but the file upload control on page is still locked in with the last file selected
//       // setStateSelFileUI('');

//       setStateLoading(false);
//     }, 100);
//   }


//   // const toBase64 = (file: any) => new Promise((resolve, reject) => {
//   //   // not needed! can just attach the File object from File Upload control directly to the fetch body, fetch supports it!
//   //   const reader = new FileReader();
//   //   reader.readAsDataURL(file);
//   //   reader.onload = () => resolve(reader.result);
//   //   reader.onerror = error => reject(error);
//   // });


//   return (
//     <>

//       <h3>Testing</h3>

//       <Space sizeIsMed className='w400'>

//         <TextField label='DriveId' onChange={onChangeDriveId} value={stateDriveId} placeholder='Please enter text here' className='' disabled={stateLoading} />

//         <TextField label='FolderId' onChange={onChangeFolderId} value={stateFolderId} placeholder='Please enter text here' className='' disabled={stateLoading} />

//         {
//           !stateSelFile && (
//             <input type="file" onChange={handleFileChange} />
//           )
//         }
//         {
//           stateSelFile && (
//             <Space horizontal>
//               <div>File Selected: {stateSelFile && stateSelFile.name}</div>
//               <div><DefaultButton className='fluSmallButton' text="Clear" onClick={() => { setStateSelFile(null); }} disabled={stateLoading} /></div>
//             </Space>
//           )
//         }

//         <PrimaryButton text="Upload" onClick={handleUploadClick} disabled={stateLoading} />

//         {
//           stateLoading && (
//             <Spinner label="Saving file..." size={SpinnerSize.large} />
//           )
//         }

//       </Space>

//       <Stack tokens={Consts.stackTokens} className='w400'>

//       </Stack>

//     </>
//   );
// };
