/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */


export default class GenUtil {
  // use this for helpful intellisense, type "GenUtil" and it comes up to import this file, then change it to "import * as GenUtil from [...]"
}


const __CHECK_NULL_STRING_VAL = true; // if string value is "NULL" (exact match only, no trimming or case changes), convert to "" in the trim and check functions throughout


/** Trim all JS things, remove all whitespace, coalesce any null like things to empty strings. */
export function safeTrim(o: object | string | number | boolean | undefined | null): string {
  // prior version would output number 0 as '', and false to ''
  // latest: try to fix above, and help auto convert objects and arrays output to JSON
  if (typeof o === 'undefined' || o == null || o === '' || o === ' ' || o === '\t' || o === '\n' || o === '\r' || o === '\r\n' || (__CHECK_NULL_STRING_VAL && o === "NULL"))
    return '';
  else {
    if (typeof o == 'string') {
      return (o as string).trim();
    }
    else if (typeof o == 'number') {
      return (o as number).toString().trim();
    }
    else if (typeof o == 'boolean') {
      return (o as boolean).toString().trim();
    }
    else if (typeof o == 'object') {
      return JSON.stringify(o);
    }
    else {
      return (o + '').trim();
    }
  }
}


/** Using safeTrim, check if empty string/length. */
export function isNull(o: object | string | number | boolean | undefined | null) {
  if (__CHECK_NULL_STRING_VAL && o === "NULL") return true;
  return safeTrim(o).length <= 0;
}


/** Compare A to B, using safeTrim or safeToUpper. MatchCase is false by default. */
export function isEqual(o1: any, o2: any, matchCase: boolean = false): boolean {
  if (matchCase)
    return safeTrim(o1) === safeTrim(o2);
  else
    return safeToUpper(o1) === safeToUpper(o2);
}


/**
 * Compare A to B, using safeTrim or safeToUpper. MatchCase is false by default.
 */
// export function eqORIG(o1: any, o2: any, matchCase: boolean = false): boolean {
//   // alias to "isEqual"
//   return isEqual(o1, o2, matchCase);
// }


/** Compare a1 to a2, using safeTrim, case does not matter. */
// export function eqOLD2(a1: any, a2: any): boolean {
//   // alias to "isEqual"
//   let matchCase: boolean = false;
//   return isEqual(a1, a2, matchCase);
// }


/**
 * Compare the first arg with all additional args, if any match between arg0 and arg1..n return true. 
 * If 2 params then uses standard eq/isEqual match. 
 * If 3 or more params then uses inn/isMatch. 
 * All entries safeTrim/safeToUpper, case does not matter, but whole word comparison, not contains/indexof match.
 */
export function eq(...args: any[]): boolean {
  if (args.length <= 1) {
    return true; // something is always equal to itself
  }
  else if (args.length === 2) {
    return isEqual(args[0], args[1]);
  }
  else {
    return isMatch(...args);
  }
}


/**
 * Given a string S, check if 'match' is found in it. Safe to call on null/undefined S and 'match'. MatchCase is false by default.
 */
export function contains(s: string | null | undefined, match: string | null | undefined, matchCase: boolean = false): boolean {
  if (isNull(s) || isNull(match))
    return false;
  let a = matchCase ? safeTrim(s) : safeToUpper(s);
  let b = matchCase ? safeTrim(match) : safeToUpper(match);
  return a.indexOf(b) >= 0;
}


/**
 * Similar to SQL "In" clause. Given any set of args(converted to strings), match the first arg with any other arg in col (using safetrim/safetoupper/isequal comp).
 */
export function isMatch(...args: any[]): boolean {

  if (!args || args.length <= 1)
    return false;

  let match = safeTrim(args[0]); // first el
  let col = args.slice(1); // the other els

  if (isNull(match)) return false;

  for (let i = 0; i < col.length; i++) {
    const tmp = safeTrim(col[i]);
    if (isEqual(match, tmp)) return true;
  }

  return false;
}


/**
 * Similar to SQL "In" clause. Given any set of args(converted to strings), match the first arg with any other arg in col (using safetrim/safetoupper/isequal comp).
 */
export function inn(...args: any[]): boolean {
  // alias to "isMatch"
  return isMatch(...args);
}


// export function NVL(s1: string, s2: string) {
//   if (safeTrim(s1).length > 0) {
//     return s1;
//   }
//   else {
//     return s2;
//   }
// }


// export function NVL(s1: any, s2: any): any {
//   if (typeof s1 === 'string') {
//     return !isNull(s1) ? safeTrim(s1) : safeTrim(s2);
//   }
//   else {
//     return !!s1 ? s1 : s2;
//   }
// }


/**
 * Given any set of args, return the first "not null/empty" in args. If arg is string will safeTrim() it. Based on Oracle NVL function.
 */
export function NVL(...args: any[]): any {
  if (args && args.length > 0) {
    for (let i = 0; i < args.length; i++) {
      const el = args[i];

      if (typeof el === 'string') {
        // string type
        if (!isNull(el)) {
          return safeTrim(el);
        }
      }
      else {
        // any other type
        if (!!el) {
          return el;
        }
      }
    }
  }

  return null;
}


export function safeToUpper(o: object | string | number | boolean | undefined | null): string {
  return safeTrim(o).toUpperCase();
}


export function safeToBool(o: boolean | string | undefined | null): boolean {
  if (o == null || typeof o === 'undefined' || o === '' || o === ' ') {
    return false;
  }
  else if (typeof o === 'string') {
    if (o.trim().toLowerCase() === "true" || o.trim().toLowerCase() === "yes" || o.trim().toLowerCase() === "y" || o.trim().toLowerCase() === "1") {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return o;
  }
}


// export function safeToNumberOLD(x: string | number | undefined | null): number {
//   // return 0 or the number (int/float)

//   if (x == null || typeof x === 'undefined' || x === '' || x === ' ') {
//     return 0;
//   }
//   else if (typeof x === 'string') {
//     let t = 0;

//     if (safeTrim(x) === '') {
//       t = 0;
//     }
//     else if (
//       (x + '').indexOf('.') >= 0 ||
//       (x + '').indexOf(',') >= 0 ||
//       (x + '').indexOf('$') >= 0 ||
//       (x + '').indexOf('%') >= 0) {
//       t = parseFloat((x + '').replace(/\,/ig, '').replace(/\$/ig, '').replace(/\%/ig, ''));
//     }
//     else {
//       t = parseInt(x);
//     }

//     return isNaN(t) ? 0 : t;
//   }
//   else {
//     return x;
//   }
// }


/**
 * return null if not a number
 */
export function safeToNumberOrNull(o: string | number | undefined | null): number | null {
  // new way: attempts to find a number in a poorly entered string
  // return that number, or return null

  if (o == null || typeof o === 'undefined' || o === '' || o === ' ') {
    return null;
  }
  else if (typeof o === 'string') {
    let s = o.replace(/[^0-9\.-]+/g, "").trim(); // remove all non-numbers, keep "." and "-"
    if (s.length <= 0) return null; // after removing chars, check if nothing is left
    let n = Number(s); // convert to number
    return isNaN(n) ? null : n; // if not a number, return null
  }
  else {
    return o; // return the number
  }
}


/**
 * return 0 if not a number
 */
export function safeToNumber(o: string | number | undefined | null, decPlaces: number = 0): number {
  // using the new way: convert a null to a zero
  let n = safeToNumberOrNull(o);
  return n == null ? 0 :
    decPlaces <= 0 ? n :
      +n.toFixed(decPlaces);
}


// export function safeToMomentOrNullFromMoment(x: moment.Moment | undefined | null): moment.Moment | null {
//   if (x == null || typeof x === 'undefined') {
//     return null;
//   }
//   else {
//     return x;
//   }
// }


// export function safeToDateOrNullFromMoment(x: moment.Moment | undefined | null): Date | null {
//   if (x == null || typeof x === 'undefined') {
//     return null;
//   }
//   else {
//     return x.startOf('day').toDate();
//   }
// }


export function safeToDateOrUndefined(s: null | undefined | string) {
  // #todo needs testing (as of 4-27-23 seems ok)
  let t = safeTrim(s);
  if (t === '') return undefined;
  else {
    try {
      let d = new Date(t);
      return d;
    } catch (error) {
      return undefined;
    }
  }
}


/** Normalize/convert all instances of \r\n to just \n. */
export function normalizeEOL(s: string | undefined | null): string {
  if (!isNull(s)) {
    return safeTrim(s).replace(/\r\n|\n\r|\n|\r/g, '\n');
  }
  else {
    return '';
  }
}


export function getUrlParameter(name: string): string {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);
  return results === null || results.length < 2 ? '' : safeTrim(decodeURIComponent(results[1].replace(/\+/g, ' ')));
}


export function urlHasText(match: string): boolean {
  if ((window as any).location.href.indexOf((match + '').trim().toLowerCase()) > 0) return true;
  else return false;
}


export function getWindowHeight(): number {
  let w: any = window,
    d: any = document,
    e: any = d.documentElement,
    g: any = d.getElementsByTagName('body')[0],
    y: any = w.innerHeight || e.clientHeight || g.clientHeight;

  return parseInt(y);
}


export function getWindowWidth(): number {
  let w: any = window, d: any = document, e: any = d.documentElement, g: any = d.getElementsByTagName('body')[0], x: any = w.innerWidth || e.clientWidth || g.clientWidth;

  return parseInt(x);
}


export function isBrowserIE(): boolean {
  let bd: any = (window as any).BrowserDetection; // comes from SharePoint init.js

  if (typeof bd != 'undefined') {
    if (bd.userAgent.ie || bd.userAgent.ie5up || bd.userAgent.ie7up || bd.userAgent.ie8standardUp || bd.userAgent.ie9standardUp || bd.userAgent.ie10standardUp || parseInt(bd.userAgent.iever) > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}


export function isInt(n: any) {
  let tmp = safeTrim(n);
  let isvalid = /^\d+$/gi.test(tmp);
  return isvalid;
}


export function hasNumber(s: string) {
  let tmp = safeTrim(s);
  let r = /\d+/gi.test(tmp);
  return r;
}


export function isGuid(s: string): boolean {
  if (!!s === false) {
    return false;
  }
  else if (s.trim().length < 10) {
    return false;
  }

  s = s.trim();

  if (s[0] === '{') {
    s = s.substring(1, s.length - 1);
  }

  let regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return regexGuid.test(s);
}


export function cacheBust(): string {
  return 'tid=_' + Date.now().toString();
}


export function escapeApos(s: string) {
  return s.replace(/'/g, '%2527')
}


export function validateEmail(email: string) {
  //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let re = /\S+@\S+\.\S+/;
  // simple expression: /\S+@\S+\.\S+/
  return re.test(String(email).toLowerCase());
}


export function replaceHtmlSpecialChars(s: string | undefined | null) {

  let x = safeTrim(s);

  if (x === '') return '';

  // regular replacement
  // do these first, fixing "&amp;" may actually fix other encodings below
  x = x.replace(/&amp;/ig, '&');
  x = x.replace(/&nbsp;/ig, ' ');
  x = x.replace(/&lt;/ig, '<');
  x = x.replace(/&gt;/ig, '>');
  x = x.replace(/&quot;/ig, '"');
  x = x.replace(/&apos;/ig, "'");
  x = x.replace(/&cent;/ig, '¢');
  x = x.replace(/&pound;/ig, '£');
  x = x.replace(/&yen;/ig, '¥');
  x = x.replace(/&euro;/ig, '€');
  x = x.replace(/&copy;/ig, '©');
  x = x.replace(/&reg;/ig, '®');

  // numeric replacement
  x = x.replace(/&#160;/ig, ' ');
  x = x.replace(/&#60;/ig, '<');
  x = x.replace(/&#62;/ig, '>');
  x = x.replace(/&#38;/ig, '&');
  x = x.replace(/&#34;/ig, '"');
  x = x.replace(/&#39;/ig, "'");
  x = x.replace(/&#8364;/ig, '€');

  // numeric replacement, more
  x = x.replace(/&#130;/ig, '');
  x = x.replace(/&#131;/ig, '');
  x = x.replace(/&#132;/ig, '');
  x = x.replace(/&#133;/ig, '');
  x = x.replace(/&#134;/ig, '');
  x = x.replace(/&#135;/ig, '');
  x = x.replace(/&#136;/ig, '');
  x = x.replace(/&#137;/ig, '');
  x = x.replace(/&#138;/ig, '');
  x = x.replace(/&#139;/ig, '');
  x = x.replace(/&#140;/ig, '');
  x = x.replace(/&#145;/ig, '');
  x = x.replace(/&#146;/ig, '');
  x = x.replace(/&#147;/ig, '');
  x = x.replace(/&#148;/ig, '');
  x = x.replace(/&#150;/ig, '');
  x = x.replace(/&#151;/ig, '');
  x = x.replace(/&#152;/ig, '');
  x = x.replace(/&#153;/ig, '');
  x = x.replace(/&#154;/ig, '');
  x = x.replace(/&#155;/ig, '');
  x = x.replace(/&#156;/ig, '');
  x = x.replace(/&#159;/ig, '');
  x = x.replace(/&#161;/ig, '¡');
  x = x.replace(/&#162;/ig, '¢');
  x = x.replace(/&#163;/ig, '£');
  x = x.replace(/&#164;/ig, '¤');
  x = x.replace(/&#165;/ig, '¥');
  x = x.replace(/&#166;/ig, '¦');
  x = x.replace(/&#167;/ig, '§');
  x = x.replace(/&#168;/ig, '¨');
  x = x.replace(/&#169;/ig, '©');
  x = x.replace(/&#170;/ig, 'ª');
  x = x.replace(/&#171;/ig, '«');
  x = x.replace(/&#172;/ig, '¬');
  x = x.replace(/&#174;/ig, '®');
  x = x.replace(/&#175;/ig, '¯');
  x = x.replace(/&#176;/ig, '°');
  x = x.replace(/&#177;/ig, '±');
  x = x.replace(/&#178;/ig, '²');
  x = x.replace(/&#179;/ig, '³');
  x = x.replace(/&#180;/ig, '´');
  x = x.replace(/&#181;/ig, 'µ');
  x = x.replace(/&#182;/ig, '¶');
  x = x.replace(/&#183;/ig, '·');
  x = x.replace(/&#184;/ig, '¸');
  x = x.replace(/&#185;/ig, '¹');
  x = x.replace(/&#186;/ig, 'º');
  x = x.replace(/&#187;/ig, '»');
  x = x.replace(/&#188;/ig, '¼');
  x = x.replace(/&#189;/ig, '½');
  x = x.replace(/&#190;/ig, '¾');
  x = x.replace(/&#191;/ig, '¿');
  x = x.replace(/&#192;/ig, 'À');
  x = x.replace(/&#193;/ig, 'Á');
  x = x.replace(/&#194;/ig, 'Â');
  x = x.replace(/&#195;/ig, 'Ã');
  x = x.replace(/&#196;/ig, 'Ä');
  x = x.replace(/&#197;/ig, 'Å');
  x = x.replace(/&#198;/ig, 'Æ');
  x = x.replace(/&#199;/ig, 'Ç');
  x = x.replace(/&#200;/ig, 'È');
  x = x.replace(/&#201;/ig, 'É');
  x = x.replace(/&#202;/ig, 'Ê');
  x = x.replace(/&#203;/ig, 'Ë');
  x = x.replace(/&#204;/ig, 'Ì');
  x = x.replace(/&#205;/ig, 'Í');
  x = x.replace(/&#206;/ig, 'Î');
  x = x.replace(/&#207;/ig, 'Ï');
  x = x.replace(/&#208;/ig, 'Ð');
  x = x.replace(/&#209;/ig, 'Ñ');
  x = x.replace(/&#210;/ig, 'Ò');
  x = x.replace(/&#211;/ig, 'Ó');
  x = x.replace(/&#212;/ig, 'Ô');
  x = x.replace(/&#213;/ig, 'Õ');
  x = x.replace(/&#214;/ig, 'Ö');
  x = x.replace(/&#215;/ig, '×');
  x = x.replace(/&#216;/ig, 'Ø');
  x = x.replace(/&#217;/ig, 'Ù');
  x = x.replace(/&#218;/ig, 'Ú');
  x = x.replace(/&#219;/ig, 'Û');
  x = x.replace(/&#220;/ig, 'Ü');
  x = x.replace(/&#221;/ig, 'Ý');
  x = x.replace(/&#222;/ig, 'Þ');
  x = x.replace(/&#223;/ig, 'ß');
  x = x.replace(/&#224;/ig, 'à');
  x = x.replace(/&#225;/ig, 'á');
  x = x.replace(/&#226;/ig, 'â');
  x = x.replace(/&#227;/ig, 'ã');
  x = x.replace(/&#228;/ig, 'ä');
  x = x.replace(/&#229;/ig, 'å');
  x = x.replace(/&#230;/ig, 'æ');
  x = x.replace(/&#231;/ig, 'ç');
  x = x.replace(/&#232;/ig, 'è');
  x = x.replace(/&#233;/ig, 'é');
  x = x.replace(/&#234;/ig, 'ê');
  x = x.replace(/&#235;/ig, 'ë');
  x = x.replace(/&#236;/ig, 'ì');
  x = x.replace(/&#237;/ig, 'í');
  x = x.replace(/&#238;/ig, 'î');
  x = x.replace(/&#239;/ig, 'ï');
  x = x.replace(/&#240;/ig, 'ð');
  x = x.replace(/&#241;/ig, 'ñ');
  x = x.replace(/&#242;/ig, 'ò');
  x = x.replace(/&#243;/ig, 'ó');
  x = x.replace(/&#244;/ig, 'ô');
  x = x.replace(/&#245;/ig, 'õ');
  x = x.replace(/&#246;/ig, 'ö');
  x = x.replace(/&#247;/ig, '÷');
  x = x.replace(/&#248;/ig, 'ø');
  x = x.replace(/&#249;/ig, 'ù');
  x = x.replace(/&#250;/ig, 'ú');
  x = x.replace(/&#251;/ig, 'û');
  x = x.replace(/&#252;/ig, 'ü');
  x = x.replace(/&#253;/ig, 'ý');
  x = x.replace(/&#254;/ig, 'þ');
  x = x.replace(/&#255;/ig, 'ÿ');

  // remove any left over special chars that were not converted above
  x = x.replace(/&#?[\w\d]{2,};/ig, ''); // starts with '&', may have '#' following
  x = x.replace(/#[\w\d]{2,};/ig, ''); // left over missing '&'

  // remove html encoded control chars: &#x0D, 0x0a = LF, 0x0d = CR
  x = x.replace(/&#x0[a-z0-9];?/ig, '');

  x = mergeSpaces(x);

  return x.trim();
}


export function makePlainText(s: string) {

  let x = mergeSpaces(stripControlChars(stripHtml(s))).trim();

  x = x.replace(/&[\w\d]+;/ig, ''); // will match '&amp;' or '&quot;'
  x = x.replace(/#[\w\d]+;/ig, ''); // will match '#39;'  
  x = x.replace(/amp;/ig, '');
  x = x.replace(/quot;/ig, '');

  return x;
}


export function stripHtml(s: string) {
  return safeTrim(s).replace(/(<([^>]+)>)/ig, "");
}


export function stripHtml2(s: string) {
  return safeTrim(s).replace(/<\/?[^>]+(>|$)/ig, "");
}


export function stripControlChars(s: string) {
  return s.replace(/\r|\n|\t/ig, "");
}


export function mergeSpaces(s: string) {
  return s.replace(/\s+/ig, " ");
}


export function combinePaths(...args: any[]) {
  return args.map((part, i) => {
    if (i === 0) {
      return part.trim().replace(/[\/]*$/g, '');
    } else {
      return part.trim().replace(/(^[\/]*|[\/]*$)/g, '');
    }
  }).filter(x => x.length).join('/');
}


export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}


export function capitalizeFirstLetterEachWord(s: string) {
  let splitStr = s
    //.toLowerCase()
    //.replace(/-/ig, ' ')
    .replace(/_/ig, ' ')
    .split(' ');

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
}


export function generateGuid() {
  // uuidv4
  // NOTE: when tested in window.load console.log loop, they were all unique call after call.
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-mixed-operators
    return v.toString(16);
  });
}


export function genShortGuid() {
  // I generate the UID from two parts here to ensure the random number provide enough bits
  // https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-js
  // NOTE: when tested in window.load console.log loop, they were all unique call after call.
  let firstPart: number = (Math.random() * 46656) | 0;
  let secondPart: number = (Math.random() * 46656) | 0;
  let fp: string = ("000" + firstPart.toString(36)).slice(-3);
  let sp: string = ("000" + secondPart.toString(36)).slice(-3);
  return fp + sp;
}


/**
 * From: https://github.com/ai/nanoid/.
 * By default, Nano ID uses URL-friendly symbols (A-Za-z0-9_-) and returns an ID with 21 characters (to have a collision probability similar to UUID v4).
 * Ex: model.id = nanoid() //=> "V1StGXR8_Z5jdHi6B-myT".
 * Entropy, 21 is default and very safe, 10 is likely OK for apps.
 * @param t optional, ID size to return, 21 is default, 10 is likely safe.
 * @returns string nanoid result (similar to uuidv4)
 */
export function genNanoGuid(size: number = 21) {
  // only return a nanoid when it doesn't start or end with "-_".
  let id = '';
  do {
    id = _genNanoGuid(size);
  } while (id.startsWith('-') || id.startsWith('_') || id.endsWith('-') || id.endsWith('_'));
  return id;
}

let _genNanoGuid = (t: number = 21): string => crypto.getRandomValues(new Uint8Array(t)).reduce(((t, e) => t += (e &= 63) < 36 ? e.toString(36) : e < 62 ? (e - 26).toString(36).toUpperCase() : e > 62 ? "-" : "_"), "");


export function rot13Transform(s: string): string {

  let input1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
  let output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm0963852741%$#@!)(*&^';
  let index = (x: any) => input1.indexOf(x);
  let translate = (x: any) => index(x) > -1 ? output[index(x)] : x;
  return s.split('').map(translate).join('');
}


export function boolToHtmlYesNo(o: boolean): any {
  return o ? 'Yes' : 'No'
}


export function boolToHtmlYesNoNa(o: boolean | null | undefined): any {
  return (typeof o === 'undefined' || o == null) ? 'N/A' : o ? 'Yes' : 'No';
}


export function plainTextToHtml(s: string | null | undefined): string {
  let x = safeTrim(s);
  x = normalizeEOL(x);
  x = x.replace(/\n/ig, '<br />');
  return x;
}


export function dateToString(o: Date | null | undefined): string {
  // toLocaleString (on my PC) shows '1/5/2023, 10:15:03 PM'
  if (!!o) {
    return (new Date(o).toLocaleString().replace(/,/ig, ''));
  }
  else {
    return '';
  }
}


export function dateOnlyToString(o: Date | null | undefined): string {
  // toLocaleString (on my PC) shows '1/5/2023'
  if (!!o) {
    return (new Date(o).toLocaleDateString().replace(/,/ig, ''));
  }
  else {
    return '';
  }
}


/** rounds to 2 decimal places, include comma separators, and will keep ".00" */
export function numberToCurrency(o: number | null | undefined, curSymb: string = ''): string {

  if (o === null || typeof o === 'undefined') {
    return '';
  }
  else {
    return (curSymb.length > 0 ? curSymb : '') + o.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  }
}


/** rounds to 2 decimal places, include comma separators, can also round to remove decimal, and will remove ".00" if found */
export function numberToCurrency00(o: number | null | undefined, curSymb: string = '', round: boolean = true): string {

  if (o == null || typeof o === 'undefined') {
    return '';
  }
  else {
    let y = round ? Math.round(o) : o;
    return (curSymb.length > 0 ? curSymb : '') + y.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00', '');
  }
}


export function getFormLayoutDirection(): any {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1200 ? 'horizontal' : 'vertical';
}


export function parseJwt(token: string) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}


export function countStrInStr(str: string, pattern: string): number {
  // using regex, count how many times a pattern occur within another string
  if (isNull(str) || isNull(pattern)) return 0;
  let re = new RegExp(pattern, 'ig');
  return (str.match(re) || []).length;
}


export function safeToFluOption(key: string, text: string = '') {
  // return a FluentUI combobox or dropdownlist option object, or undefined

  let k = safeTrim(key);
  let t = safeTrim(text);

  k = NVL(k, t);
  t = NVL(t, k);

  if (k === '' && t === '')
    return undefined;
  else
    return { key: k, text: t };
}


/** generate string 'dd/MM/yyyy' */
export function getEuroDate(d: Date | string | undefined | null): string {
  // generate standard euro date string in format: 'dd/MM/yyyy'
  if (d == null || typeof d === 'undefined' || d === '' || d === ' ')
    return '';
  else {
    let d2: Date;

    if (typeof d === 'string') {
      let t = safeToDateOrUndefined(d);
      if (!t)
        return '';
      d2 = t;
    }
    else {
      d2 = d;
    }

    return `${d2.getDate()}/${d2.getMonth() + 1}/${d2.getFullYear()}`;
  }
}


/** generate string 'Dec 16, 2022' */
export function getCalDate(d: Date | string | undefined | null): string {
  // 
  // let d = new Date() => d.toString() => 'Thu Jan 12 2023 13:56:50 GMT-0500 (Eastern Standard Time)'
  if (d == null || typeof d === 'undefined' || d === '' || d === ' ')
    return '';
  else {
    let d2: Date;

    if (typeof d === 'string') {
      let t = safeToDateOrUndefined(d);
      if (!t)
        return '';
      d2 = t;
    }
    else {
      d2 = d;
    }

    let x = d2.toString().split(' ');
    return x[1] + ' ' + x[2] + ', ' + x[3];
  }
}


/**
 * part is string in '[href|origin|protocol|host|hostname|port|pathname|search|hash]'.
 * default is location.href
 */
export function getLocationInfo(part: string = ""): string {
  /*
  sample:
    href: 'https://domain.sharepoint.com/sites/CerberusPOC1/finsolutia1/Lists/ConnectionsIndian304/Allitemsg.aspx?isAscending=false&FilterField1=Connection%5Fx0020%5FID&FilterValue1=IND%2DCR%2D01%2D0901&FilterType1=Text&sortField=Connection%5Fx0020%5FName&viewid=e61a42b5%2D4af2%2D47f5%2D970c%2D8cae0fd89fb6', 'http://localhost:3000/Finsolutia/2750#param1'
    origin: 'https://domain.sharepoint.com', 'http://localhost:3000'
    protocol: 'https:', 'http:'
    host: 'domain.sharepoint.com', 'localhost:3000'
    hostname: 'domain.sharepoint.com', 'localhost'
    port: '', '3000'
    pathname: '/sites/CerberusPOC1/finsolutia1/Lists/ConnectionsIndian304/Allitemsg.aspx', '/Finsolutia/2750'
    search: '?isAscending=false&FilterField1=Connection%5Fx0020…ewid=e61a42b5%2D4af2%2D47f5%2D970c%2D8cae0fd89fb6'
    hash: '#param1' // used a diff root url for this
  */

  let p = safeTrim(part);

  if (p === '') return (window as any).location.href;
  else if (p === 'href') return (window as any).location.href;
  else if (p === 'origin') return (window as any).location.origin;
  else if (p === 'protocol') return (window as any).location.protocol;
  else if (p === 'host') return (window as any).location.host;
  else if (p === 'hostname') return (window as any).location.hostname;
  else if (p === 'port') return (window as any).location.port;
  else if (p === 'pathname') return (window as any).location.pathname;
  else if (p === 'search') return (window as any).location.search;
  else if (p === 'hash') return (window as any).location.hash;
  return '';
}


/**
 * Using JSON.parse(JSON.stringify(x)) technique, creates deap copy of object (limited, plain object please)
 * (https://stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript)
 * NOTE: this may be dangerous, how it handles 'undefined', dates also becomed broken (unless originally saved as string)
 */
export function cloneObj_DONOTUSE(orig: any): any | null {
  if (orig == null || typeof orig === "undefined")
    return null;
  else
    return JSON.parse(JSON.stringify(orig));
}


/**
 * 
 * @param s Original string, will be SafeTrimmed.
 * @param sep Optional separator string like ";".
 * @param commaIsSep Default is true, to replace commas with separator param, since commans are common as sep (ie. CSV files).
 * @returns Array of strings, each is trimmed, empty/null are removed, order is preserved, duplicates are NOT removed.
 */
export function strToList(s: string, sep: string = ";", commaIsSep: boolean = true): string[] {
  if (isNull(s)) return [];
  let _sep = NVL(sep, ";"); // default will be ";"
  let parts: string[] = [];
  if (commaIsSep)
    parts = safeTrim(s).replace(/,/ig, _sep).split(_sep); // comma is common separator, replace that with sep param
  else
    parts = safeTrim(s).split(_sep);
  parts = parts.map(x => safeTrim(x)).filter(x => x.length > 0);
  return parts;
}


/**
 * Given array of strings, return the unique elements, using isEqual function which compares by safeTrim and optionally matching case.
 * Returned array entries will not be trimmed unless requested.
 * @param stringsArray Array of strings as input.
 * @param matchCase Param to determine if comparison should be case sensitive.
 * @param trimStrings Set to true to safeTrim the returned entries.
 * @returns 
 */
function distinct(stringsArray: string[], matchCase: boolean = false, trimStrings: boolean = false): string[] {
  let col: string[] = [];
  [...stringsArray].forEach(s => {
    let i = col.findIndex(x => isEqual(s, x, matchCase)); // find a match using isEqual
    if (i < 0 && !isNull(s)) { // match not found, but do not add "null" entries
      col = [...col, (trimStrings ? safeTrim(s) : s)];
    }
  });
  return col;
}
