/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactDOM from 'react-dom';
import { Launcher } from './Components/Launcher';
// import { mergeStyles } from '@fluentui/react';
// import reportWebVitals from './reportWebVitals';
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from './Helpers/AuthConfig';

// Inject some global styles
// mergeStyles({
//   ':global(body,html,#root)': {
//     margin: 0,
//     padding: 0,
//     height: '100vh',
//   },
// });

// ReactDOM.render(<Launcher />, document.getElementById('root'));

// const msalInstance = new PublicClientApplication(msalConfig);

// ReactDOM.render(
//   <React.StrictMode>
//     <MsalProvider instance={msalInstance}>
//       <Launcher />
//     </MsalProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

ReactDOM.render(
  <Launcher />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
