import * as React from 'react';

export interface ITest4Props {}

export const Test4: React.FunctionComponent<ITest4Props> = (props: React.PropsWithChildren<ITest4Props>) => {
  return (
    <>

    </>
  );
};



// /* eslint-disable @typescript-eslint/no-unused-vars */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useCallback, useEffect, useState } from 'react';

// import {
//   Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
//   TextField,
//   DefaultButton, PrimaryButton, IconButton, ActionButton,
//   Spinner, SpinnerSize,
//   Shimmer, ShimmerElementType, IShimmerElement,
//   MessageBar, MessageBarType, MessageBarButton,
//   Icon,
//   Separator,
//   DatePicker, DayOfWeek, defaultDatePickerStrings,
//   ComboBox, IComboBoxOption, IComboBoxStyles,
//   TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
//   Checkbox,
//   Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
// } from '@fluentui/react';

// import _ from 'lodash';

// import * as Consts from "../../Helpers/Consts";
// import * as GenUtil from '../../Helpers/GenUtil2';
// import * as AppHelper from '../../Helpers/AppHelper';
// import * as GraphDataService from '../../Services/Link/GraphDataService';
// import * as JsonXmlHelper from '../../Helpers/JsonXmlConverter';
// import * as StaticData from "../../StaticData/Link/StaticData";

// import { ConnectionDetails } from '../../Models/Link/GridModels/ConnectionDetails';
// import { BorrowerInfo, IsSingleBorrowerInfo } from '../../Models/Link/GridModels/BorrowerInfo';
// import { BorrowerInfo1 } from '../../Models/Link/GridModels/BorrowerInfo1';
// import { BorrowerInfoN } from '../../Models/Link/GridModels/BorrowerInfoN';
// import { LoanInfo1 } from '../../Models/Link/GridModels/LoanInfo1';
// import { LoanInfoN } from '../../Models/Link/GridModels/LoanInfoN';
// import { AssetInfo1 } from '../../Models/Link/GridModels/AssetInfo1';
// import { AssetInfoN } from '../../Models/Link/GridModels/AssetInfoN';
// import { SupportingDetails1 } from '../../Models/Link/GridModels/SupportingDetails1';
// import { SupportingDetailsN } from '../../Models/Link/GridModels/SupportingDetailsN';
// import { ProposedCostsDetails1 } from '../../Models/Link/GridModels/ProposedCostsDetails1';
// import { ProposedCostsDetailsN } from '../../Models/Link/GridModels/ProposedCostsDetailsN';
// import { LitigationMatrix1 } from '../../Models/Link/GridModels/LitigationMatrix1';
// import { LitigationMatrixN } from '../../Models/Link/GridModels/LitigationMatrixN';
// import { AssetLevelMatrixOne1 } from '../../Models/Link/GridModels/AssetLevelMatrixOne1';
// import { AssetLevelMatrixOneN } from '../../Models/Link/GridModels/AssetLevelMatrixOneN';
// import { AssetLevelMatrixTwo1 } from '../../Models/Link/GridModels/AssetLevelMatrixTwo1';
// import { AssetLevelMatrixTwoN } from '../../Models/Link/GridModels/AssetLevelMatrixTwoN';
// import { AssetLevelMatrixThree1 } from '../../Models/Link/GridModels/AssetLevelMatrixThree1';
// import { AssetLevelMatrixThreeN } from '../../Models/Link/GridModels/AssetLevelMatrixThreeN';
// import { AssetLevelMatrixFour } from '../../Models/Link/GridModels/AssetLevelMatrixFour';


// export interface ITest4Props { }

// export const Test4: React.FunctionComponent<ITest4Props> = (props: React.PropsWithChildren<ITest4Props>) => {

//   const [value, setValue] = React.useState('');


//   let xmlConnectionDetails = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_ConnecID type="System.String">cns149592</rpt_ConnecID>
// <cv_rpt_ConnecID type="System.String">cns149592</cv_rpt_ConnecID>
// <cv_rpt_ConnName type="System.String">GILNA N &amp;amp; A</cv_rpt_ConnName>
// <rpt_ConnectionName type="System.String">GILNA N &amp;amp; A</rpt_ConnectionName>
// <rpt_ConnectionStatus type="System.String">Settled - Enforced</rpt_ConnectionStatus>
// <rpt_FullyAssigned type="System.String">Y</rpt_FullyAssigned>
// <cv_rpt_FullyAssigned type="System.String">Y</cv_rpt_FullyAssigned>
// <cv_TotalCollectionsAmount type="System.Decimal">73351.87</cv_TotalCollectionsAmount>
// <rpt_TotalCollectionsAmount type="System.String">73351.87</rpt_TotalCollectionsAmount>
// <RowNumberConnectionDetails type="System.String">1</RowNumberConnectionDetails>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlBorrowerInfo1 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_BorrID type="System.String">7690282</cv_BorrID>
// <rpt_BorrowerID type="System.String">7690282</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">MR NIALL GILNA</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">MR NIALL GILNA</rpt_BorrowerName>
// <RowNumberBorrowerDetails type="System.String">1</RowNumberBorrowerDetails>
// <rpt_RecourseValue type="System.String">Yes</rpt_RecourseValue>
// <cv_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</cv_JointBorrowerNames>
// <rpt_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</rpt_JointBorrowerNames>
// </Item>
// </Items>
// </RepeaterData>`;

//   let xmlBorrowerInfo2 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_BorrID type="System.String">7690282-1</cv_BorrID>
// <rpt_BorrowerID type="System.String">7690282-1</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">MR NIALL GILNA</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">MR NIALL GILNA</rpt_BorrowerName>
// <RowNumberBorrowerDetails type="System.String">1</RowNumberBorrowerDetails>
// <rpt_RecourseValue type="System.String">Yes</rpt_RecourseValue>
// <cv_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</cv_JointBorrowerNames>
// <rpt_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</rpt_JointBorrowerNames>
// </Item>
// <Item>
// <cv_BorrID type="System.String">7690282-2</cv_BorrID>
// <rpt_BorrowerID type="System.String">7690282-2</rpt_BorrowerID>
// <cv_BorrowerName type="System.String">MR NIALL GILNA</cv_BorrowerName>
// <rpt_BorrowerName type="System.String">MR NIALL GILNA</rpt_BorrowerName>
// <RowNumberBorrowerDetails type="System.String">1</RowNumberBorrowerDetails>
// <rpt_RecourseValue type="System.String">Yes</rpt_RecourseValue>
// <cv_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</cv_JointBorrowerNames>
// <rpt_JointBorrowerNames type="System.String">MRS ANNMARIE DOWNES GILNA</rpt_JointBorrowerNames>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlLoanInfo = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</cv_LoanBorrowerName>
// <rpt_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</rpt_LoanBorrowerName>
// <cv_LoanID type="System.String">50000933</cv_LoanID>
// <rpt_LoanID type="System.String">50000933</rpt_LoanID>
// <RowNumberLoanDetails type="System.String">1</RowNumberLoanDetails>
// <rpt_ServicerLoanID type="System.String">98666810216649</rpt_ServicerLoanID>
// <cv_SrvcLoanID type="System.String">98666810216649</cv_SrvcLoanID>
// <rpt_decimal_UPB type="System.Double">1674373.27</rpt_decimal_UPB>
// <rpt_UPBCurrency type="System.String">EUR</rpt_UPBCurrency>
// <cv_upb type="System.Double">1674373.27</cv_upb>
// <cv_UPBCurrency type="System.String">EUR</cv_UPBCurrency>
// <cv_RegulatoryStatus type="System.String">SME1</cv_RegulatoryStatus>
// <rpt_RegulatoryStatus type="System.String">SME1</rpt_RegulatoryStatus>
// </Item>
// <Item>
// <cv_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</cv_LoanBorrowerName>
// <rpt_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</rpt_LoanBorrowerName>
// <cv_LoanID type="System.String">50000934</cv_LoanID>
// <rpt_LoanID type="System.String">50000934</rpt_LoanID>
// <RowNumberLoanDetails type="System.String">2</RowNumberLoanDetails>
// <rpt_ServicerLoanID type="System.String">98666810216995</rpt_ServicerLoanID>
// <cv_SrvcLoanID type="System.String">98666810216995</cv_SrvcLoanID>
// <rpt_decimal_UPB type="System.Double">0</rpt_decimal_UPB>
// <rpt_UPBCurrency type="System.String">EUR</rpt_UPBCurrency>
// <cv_upb type="System.Double">0</cv_upb>
// <cv_UPBCurrency type="System.String">EUR</cv_UPBCurrency>
// <cv_RegulatoryStatus type="System.String">SME1</cv_RegulatoryStatus>
// <rpt_RegulatoryStatus type="System.String">SME1</rpt_RegulatoryStatus>
// </Item>
// <Item>
// <cv_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</cv_LoanBorrowerName>
// <rpt_LoanBorrowerName type="System.String">P &amp;amp; M PROPERTIES LIMITED - 7000317</rpt_LoanBorrowerName>
// <cv_LoanID type="System.String">50000935</cv_LoanID>
// <rpt_LoanID type="System.String">50000935</rpt_LoanID>
// <RowNumberLoanDetails type="System.String">3</RowNumberLoanDetails>
// <rpt_ServicerLoanID type="System.String">98666810248337</rpt_ServicerLoanID>
// <cv_SrvcLoanID type="System.String">98666810248337</cv_SrvcLoanID>
// <rpt_decimal_UPB type="System.Double">1965915.07</rpt_decimal_UPB>
// <rpt_UPBCurrency type="System.String">EUR</rpt_UPBCurrency>
// <cv_upb type="System.Double">1965915.07</cv_upb>
// <cv_UPBCurrency type="System.String">EUR</cv_UPBCurrency>
// <cv_RegulatoryStatus type="System.String">SME1</cv_RegulatoryStatus>
// <rpt_RegulatoryStatus type="System.String">SME1</rpt_RegulatoryStatus>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlAssetInfo = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_AssetID type="System.String">719217200_2</cv_AssetID>
// <rpt_AssetID type="System.String">719217200_2</rpt_AssetID>
// <cv_AssetName type="System.String">5 units Palmer Avenue, Rush, Co Dublin.</cv_AssetName>
// <rpt_AssetName type="System.String">5 units Palmer Avenue, Rush, Co Dublin.</rpt_AssetName>
// <cv_AssetAddr type="System.String">5 unfinished residential units Palmer Avenue, (Nos 26, 27, 28, 29 &amp;amp; 30), Rush, Co Dublin</cv_AssetAddr>
// <rpt_AssetAddress type="System.String">5 unfinished residential units Palmer Avenue, (Nos 26, 27, 28, 29 &amp;amp; 30), Rush, Co Dublin</rpt_AssetAddress>
// <RowNumberAssetDetails type="System.String">1</RowNumberAssetDetails>
// <rpt_decimal_RealEstateValue type="System.Double">524847.21</rpt_decimal_RealEstateValue>
// <cv_REValue type="System.Double">524847.21</cv_REValue>
// <rpt_RealEstateCurrency type="System.String">EUR</rpt_RealEstateCurrency>
// <cv_RECurrency type="System.String">EUR</cv_RECurrency>
// <cv_AssetStatus type="System.String">N</cv_AssetStatus>
// <rpt_AssetStatus type="System.String">N</rpt_AssetStatus>
// <cv_AssetStrategy type="System.String">Enforced</cv_AssetStrategy>
// <rpt_AssetStrategy type="System.String">Enforced</rpt_AssetStrategy>
// </Item>
// <Item>
// <cv_AssetID type="System.String">719217200_27</cv_AssetID>
// <rpt_AssetID type="System.String">719217200_27</rpt_AssetID>
// <cv_AssetName type="System.String">1 acre site situated at Palmer Road South,</cv_AssetName>
// <rpt_AssetName type="System.String">1 acre site situated at Palmer Road South,</rpt_AssetName>
// <cv_AssetAddr type="System.String">Palmer Road South,  Rush, Co Dublin</cv_AssetAddr>
// <rpt_AssetAddress type="System.String">Palmer Road South,  Rush, Co Dublin</rpt_AssetAddress>
// <RowNumberAssetDetails type="System.String">2</RowNumberAssetDetails>
// <rpt_decimal_RealEstateValue type="System.Double">400000</rpt_decimal_RealEstateValue>
// <cv_REValue type="System.Double">400000</cv_REValue>
// <rpt_RealEstateCurrency type="System.String">EUR</rpt_RealEstateCurrency>
// <cv_RECurrency type="System.String">EUR</cv_RECurrency>
// <cv_AssetStatus type="System.String">N</cv_AssetStatus>
// <rpt_AssetStatus type="System.String">N</rpt_AssetStatus>
// <cv_AssetStrategy type="System.String">Enforced</cv_AssetStrategy>
// <rpt_AssetStrategy type="System.String">Enforced</rpt_AssetStrategy>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlSupportingDetails = `<?xml version="1.0" encoding="utf-8"?><RepeaterData><Version /><Items><Item><cv_SDLoanID type="System.String">55000739</cv_SDLoanID><rpt_SDLoanID type="System.String">55000739</rpt_SDLoanID><cv_Coll type="System.Double">250</cv_Coll><rpt_decimal_Collections type="System.Double">250</rpt_decimal_Collections><cv_CollCurr type="System.String">EUR</cv_CollCurr><rpt_CollectionsCurrency type="System.String">EUR</rpt_CollectionsCurrency><cv_LoanTypeValue type="System.String">CCMA</cv_LoanTypeValue><rpt_LoanType type="System.String">CCMA</rpt_LoanType></Item></Items></RepeaterData>`;


//   let xmlProposedCostsDetails = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_Description type="System.String">N/A</rpt_Description>
// <rpt_PropCostRowNumber type="System.String">1</rpt_PropCostRowNumber>
// <dd_Vendors type="System.String">25</dd_Vendors>
// <rpt_decimal_Costs type="System.Double">0</rpt_decimal_Costs>
// <rpt_CostsCurrency type="System.String">EUR</rpt_CostsCurrency>
// <rpt_VendorsValue type="System.String">BDO - Receivership (Beech)</rpt_VendorsValue>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlLitigationMatrix = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_LMProceedings type="System.String">Charles McGuinness (Plaintiff) V Kevin McGarry (Defendant) and Stephen Tennant (Third Party)</rpt_LMProceedings>
// <rpt_LMLitigationReference type="System.String">TBC</rpt_LMLitigationReference>
// <rpt_LMRecordNumber type="System.String">2022 123</rpt_LMRecordNumber>
// <rpt_LMLegalBudget type="System.String">5000</rpt_LMLegalBudget>
// </Item>
// <Item>
// <rpt_LMProceedings type="System.String">Charles McGuinness (Plaintiff) V Kevin McGarry (Defendant) and Stephen Tennant (Third Party)</rpt_LMProceedings>
// <rpt_LMLitigationReference type="System.String">TBC</rpt_LMLitigationReference>
// <rpt_LMRecordNumber type="System.String">2019 1850 P</rpt_LMRecordNumber>
// <rpt_LMLegalBudget type="System.String">TBC</rpt_LMLegalBudget>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlAssetLevelMatrix1 = `<?xml version="1.0" encoding="utf-8"?><RepeaterData><Version /><Items><Item><rpt_ALM1_AssetID type="System.String">1000299850</rpt_ALM1_AssetID><rpt_ALM1_Guide type="System.String">565000</rpt_ALM1_Guide><rpt_decimal_ALM1_BPGDP type="System.Double">517000</rpt_decimal_ALM1_BPGDP><rpt_ALM1_BPExitDate type="System.DateTime">06/30/2022 00:00:00</rpt_ALM1_BPExitDate></Item></Items></RepeaterData>`;

//   let xmlAssetLevelMatrix1Empty = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_ALM1_AssetID type="System.String">
// </rpt_ALM1_AssetID>
// <rpt_ALM1_Guide type="System.String">
// </rpt_ALM1_Guide>
// <rpt_decimal_ALM1_BPGDP type="System.String">
// </rpt_decimal_ALM1_BPGDP>
// <rpt_ALM1_BPExitDate type="System.DateTime">01/01/1900 00:00:00</rpt_ALM1_BPExitDate>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlAssetLevelMatrix2 = `<?xml version="1.0" encoding="utf-8"?><RepeaterData><Version /><Items><Item><rpt_ALM2_AssetID type="System.String">624007200_4</rpt_ALM2_AssetID><rpt_decimal_ALM2_Reserve type="System.Double">209815</rpt_decimal_ALM2_Reserve><rpt_decimal_ALM2_EstNetSalesProceeds type="System.Double">192330</rpt_decimal_ALM2_EstNetSalesProceeds><rpt_decimal_ALM2_BPGDP type="System.Double">270680</rpt_decimal_ALM2_BPGDP><rpt_decimal_ALM2_BPNet type="System.Double">263236</rpt_decimal_ALM2_BPNet><rpt_ALM2_BPExitDate type="System.DateTime">01/05/2017 00:00:00</rpt_ALM2_BPExitDate><rpt_ALM2_Comments type="System.String">Sales comps provided by agent (both properties sold in 2021); 

// No. 705 was a two-bedroom holiday home for &amp;#163;140,000
// No. 708 was a three-bedroom holiday home for &amp;#163;190,000
// </rpt_ALM2_Comments></Item></Items></RepeaterData>`;


//   let xmlAssetLevelMatrix3 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <rpt_Actual2Gross type="System.Double">40802.28</rpt_Actual2Gross>
// <rpt_Actual2Net type="System.Double">22195.21</rpt_Actual2Net>
// <rpt_dt_ALAct2Exit type="System.DateTime">09/17/2020 00:00:00</rpt_dt_ALAct2Exit>
// <rpt_BP2Gross type="System.Double">49500</rpt_BP2Gross>
// <rpt_BP2Net type="System.Double">46530</rpt_BP2Net>
// <rpt_dt_ALBP2Exit type="System.DateTime">12/31/2020 00:00:00</rpt_dt_ALBP2Exit>
// <rpt_cv_Del2Gross type="System.Double">-8697.72</rpt_cv_Del2Gross>
// <rpt_cv_ALDel2Net type="System.Double">-24334.79</rpt_cv_ALDel2Net>
// </Item>
// </Items>
// </RepeaterData>`;


//   let xmlAssetLevelMatrix4 = `<?xml version="1.0" encoding="utf-8"?>
// <RepeaterData>
// <Version />
// <Items>
// <Item>
// <cv_EOSFOS type="System.String">FOS:</cv_EOSFOS>
// <rpt_ALM4_EOS type="System.String">No.34 (Q8d), East Clare Golf Village, Bodyke, Co. Clare</rpt_ALM4_EOS>
// <rpt_decimal_ALM4_SalesProceeds type="System.Double">37000</rpt_decimal_ALM4_SalesProceeds>
// <rpt_decimal_ALM4_RentalIncome type="System.Double">3802.28</rpt_decimal_ALM4_RentalIncome>
// <rpt_decimal_ALM4_TotalIncome type="System.Double">40802.28</rpt_decimal_ALM4_TotalIncome>
// <rpt_decimal_ALM4_ReceiversFee type="System.Double">2521.5</rpt_decimal_ALM4_ReceiversFee>
// <rpt_decimal_ALM4_ReceiverOutlays type="System.Double">184.5</rpt_decimal_ALM4_ReceiverOutlays>
// <rpt_decimal_ALM4_SalesAgentFee type="System.Double">2460</rpt_decimal_ALM4_SalesAgentFee>
// <rpt_decimal_ALM4_SaleAgentMarketingCosts type="System.Double">1230</rpt_decimal_ALM4_SaleAgentMarketingCosts>
// <rpt_decimal_ALM4_LegalFeesConveyance type="System.Double">2130</rpt_decimal_ALM4_LegalFeesConveyance>
// <rpt_decimal_ALM4_LegalFeesLegalOutlay type="System.Double">163</rpt_decimal_ALM4_LegalFeesLegalOutlay>
// <rpt_decimal_ALM4_TaxAdvise type="System.Double">584.25</rpt_decimal_ALM4_TaxAdvise>
// <rpt_decimal_ALM4_BERCerts type="System.Double">0</rpt_decimal_ALM4_BERCerts>
// <rpt_decimal_ALM4_Insurance type="System.Double">367.5</rpt_decimal_ALM4_Insurance>
// <rpt_decimal_ALM4_ServiceCharges type="System.Double">0</rpt_decimal_ALM4_ServiceCharges>
// <rpt_decimal_ALM4_Rates type="System.Double">0</rpt_decimal_ALM4_Rates>
// <rpt_decimal_ALM4_RepairsMaintenanceCleanup type="System.Double">1633.33</rpt_decimal_ALM4_RepairsMaintenanceCleanup>
// <rpt_decimal_ALM4_CGT type="System.Double">0</rpt_decimal_ALM4_CGT>
// <rpt_decimal_ALM4_HHC type="System.Double">0</rpt_decimal_ALM4_HHC>
// <rpt_decimal_ALM4_LPT type="System.Double">103</rpt_decimal_ALM4_LPT>
// <rpt_decimal_ALM4_NPPR type="System.Double">7230</rpt_decimal_ALM4_NPPR>
// <rpt_decimal_ALM4_BankCharge type="System.Double">0</rpt_decimal_ALM4_BankCharge>
// <rpt_decimal_ALM4_TotalIncVAT type="System.Double">18607.08</rpt_decimal_ALM4_TotalIncVAT>
// <rpt_decimal_ALM4_NetProceeds type="System.Double">22195.21</rpt_decimal_ALM4_NetProceeds>
// </Item>
// </Items>
// </RepeaterData>`;


//   function fnGetJsonObjFromXmlStr(name: string, xml: string): any | null {

//     try {
//       console.log('------------------------');

//       console.log('Processing XML...', name, xml);

//       let dom = JsonXmlHelper.parseXml(xml);

//       let jsonStr = JsonXmlHelper.xml2json(dom, "  ");
//       console.log('JSON String:');
//       console.log(jsonStr);

//       let obj = JSON.parse(jsonStr);
//       console.log('JSON Object', obj);

//       return obj;
//     } catch (error) {
//       console.error('ERROR PARSING XML', name, error, xml);
//       return null;
//     }
//   }


//   function fnXmlHasMultipleItems(xml: string) {
//     return GenUtil.countStrInStr(xml, '<Item>') > 1 ? true : false;
//   }


//   useEffect(function onComponentDidMount() {

//     console.clear();

//     console.log('ComponentDidMount [Test4]');


//     //------------------------------


//     if (false) {

//       let a = fnGetJsonObjFromXmlStr('xmlConnectionDetails', xmlConnectionDetails) as ConnectionDetails;

//       console.log(a.RepeaterData.Items.Item.rpt_ConnecID['#text']);
//       // TIP: will only ever have a single Item
//     }


//     //------------------------------
//     /*
//         PROBLEM:
//           there is XML ambiguity with the data that is saved in the SP listitem
//           the tool i'm using to convert XML to JSON cannot figure out if 'Items.Item' is an object or a collection of objects
//           if only a single Item is in the XML, it looks like 'Items.Item' => is an object
//           if more than 1 Items is in the XML, it looks like 'Items.Item' => is an array of objects
//           see here:
  
//             export interface Items {
//                 Item: Item;
//             }
  
//             export interface Items {
//                 Item: Item[];
//             }
  
//           I can do this 2 ways:

//           1. using a single inteface where:
//                 Item: Item | Item[]
//                 i can check object.hasOwnProperty to determine if Item is an object or an array
//                 But, i lose intellisense and things start getting crazy
//                 plus, i'm not sure how the toXML conversion later will be handled, probably OK

//           2. using 2 different interfaces
//                 one for each scenario above
//                 i get much better typing/intellisense
//                 i have to add a helper function to check how many "<Item>" are found in the XML string
//                 helper function is XmlHasMultipleItems(s)
//                 seems to be the better way to get the Item(s) from the xml=>json object

//         */

//     if (false) {

//       // has single Item
//       let b1 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo1', xmlBorrowerInfo1) as BorrowerInfo;
//       // has multiple Items
//       let b2 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo2', xmlBorrowerInfo2) as BorrowerInfo;

//       console.log(b1.RepeaterData.Items.Item);
//       console.log(b1.RepeaterData.Items.Item.hasOwnProperty('cv_BorrID'));
//       console.log(IsSingleBorrowerInfo(b1));
//       console.log(b2.RepeaterData.Items.Item);
//       console.log(b2.RepeaterData.Items.Item.hasOwnProperty('cv_BorrID'));
//       console.log(IsSingleBorrowerInfo(b2));

//       if (IsSingleBorrowerInfo(b1)) {
//         let t = b1.RepeaterData.Items.Item as any;
//         console.log(t.cv_BorrID);
//       }
//       else {
//         let t = b1.RepeaterData.Items.Item as any[];
//         t.forEach(o => {
//           console.log(o.cv_BorrID);
//         })
//       }

//       if (IsSingleBorrowerInfo(b2)) {
//         let t = b2.RepeaterData.Items.Item as any;
//         console.log(t.cv_BorrID);
//       }
//       else {
//         let t = b2.RepeaterData.Items.Item as any[];
//         t.forEach(o => {
//           console.log(o.cv_BorrID);
//         })
//       }
//     }


//     if (false) {

//       // console.log(GenUtil.countStrInStr(xmlBorrowerInfo1, '<Item>'));
//       // console.log(GenUtil.countStrInStr(xmlBorrowerInfo2, '<Item>'));

//       // console.log(XmlHasMultipleItems(xmlBorrowerInfo1));
//       // console.log(XmlHasMultipleItems(xmlBorrowerInfo2));

//       // has single Item
//       let c1 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo1', xmlBorrowerInfo1);
//       // has multiple Items
//       let c2 = fnGetJsonObjFromXmlStr('xmlBorrowerInfo2', xmlBorrowerInfo2);

//       if (!fnXmlHasMultipleItems(xmlBorrowerInfo1)) {
//         let t = c1 as BorrowerInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_BorrID);
//       }
//       else {
//         let t = c1 as BorrowerInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_BorrID));
//       }

//       if (!fnXmlHasMultipleItems(xmlBorrowerInfo2)) {
//         let t = c2 as BorrowerInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_BorrID);
//       }
//       else {
//         let t = c2 as BorrowerInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_BorrID));
//       }
//     }


//     if (false) {

//       let d = fnGetJsonObjFromXmlStr('xmlLoanInfo', xmlLoanInfo);

//       if (!fnXmlHasMultipleItems(xmlLoanInfo)) {
//         let t = d as LoanInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_LoanID);
//       }
//       else {
//         let t = d as LoanInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_LoanID));
//       }
//     }


//     if (false) {

//       let e = fnGetJsonObjFromXmlStr('xmlAssetInfo', xmlAssetInfo);

//       if (!fnXmlHasMultipleItems(xmlLoanInfo)) {
//         let t = e as AssetInfo1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_AssetID);
//       }
//       else {
//         let t = e as AssetInfoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_AssetID));
//       }
//     }


//     if (false) {

//       let f = fnGetJsonObjFromXmlStr('xmlSupportingDetails', xmlSupportingDetails);

//       if (!fnXmlHasMultipleItems(xmlSupportingDetails)) {
//         let t = f as SupportingDetails1;
//         console.log("Single:", t.RepeaterData.Items.Item.cv_SDLoanID);
//       }
//       else {
//         let t = f as SupportingDetailsN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.cv_SDLoanID));
//       }
//     }


//     if (false) {

//       let f = fnGetJsonObjFromXmlStr('xmlProposedCostsDetails', xmlProposedCostsDetails);

//       if (!fnXmlHasMultipleItems(xmlProposedCostsDetails)) {
//         let t = f as ProposedCostsDetails1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_VendorsValue);
//       }
//       else {
//         let t = f as ProposedCostsDetailsN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_VendorsValue));
//       }
//     }


//     if (false) {

//       let f = fnGetJsonObjFromXmlStr('xmlLitigationMatrix', xmlLitigationMatrix);

//       if (!fnXmlHasMultipleItems(xmlLitigationMatrix)) {
//         let t = f as LitigationMatrix1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_LMRecordNumber);
//       }
//       else {
//         let t = f as LitigationMatrixN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_LMRecordNumber));
//       }
//     }


//     if (false) {

//       let f = fnGetJsonObjFromXmlStr('xmlAssetLevelMatrix1', xmlAssetLevelMatrix1);

//       if (!fnXmlHasMultipleItems(xmlAssetLevelMatrix1)) {
//         let t = f as AssetLevelMatrixOne1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_ALM1_AssetID['#text']);
//       }
//       else {
//         let t = f as AssetLevelMatrixOneN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_ALM1_AssetID['#text']));
//       }
//     }

//     if (false) {
//       // checking the empty version
//       // STILL OK, the "if" block below exports this: 'Single undefined'

//       let f = fnGetJsonObjFromXmlStr('xmlAssetLevelMatrix1Empty', xmlAssetLevelMatrix1Empty);

//       if (!fnXmlHasMultipleItems(xmlAssetLevelMatrix1Empty)) {
//         let t = f as AssetLevelMatrixOne1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_ALM1_AssetID['#text']);
//       }
//       else {
//         let t = f as AssetLevelMatrixOneN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_ALM1_AssetID['#text']));
//       }
//     }


//     if (false) {

//       let f = fnGetJsonObjFromXmlStr('xmlAssetLevelMatrix2', xmlAssetLevelMatrix2);

//       if (!fnXmlHasMultipleItems(xmlAssetLevelMatrix2)) {
//         let t = f as AssetLevelMatrixTwo1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_ALM2_AssetID['#text']);
//       }
//       else {
//         let t = f as AssetLevelMatrixTwoN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_ALM2_AssetID['#text']));
//       }
//     }


//     if (true) {

//       let f = AppHelper.getJsonObjFromXmlStr('xmlAssetLevelMatrix3', xmlAssetLevelMatrix3);

//       if (!AppHelper.xmlHasMultipleItems(xmlAssetLevelMatrix3)) {
//         let t = f as AssetLevelMatrixThree1;
//         console.log("Single:", t.RepeaterData.Items.Item.rpt_BP2Gross['#text']);
//       }
//       else {
//         let t = f as AssetLevelMatrixThreeN;
//         console.log("Multiple:");
//         t.RepeaterData.Items.Item.forEach(o => console.log(o.rpt_BP2Gross['#text']));
//       }
//     }


//     if (false) {

//       let a = fnGetJsonObjFromXmlStr('xmlAssetLevelMatrix4', xmlAssetLevelMatrix4) as AssetLevelMatrixFour;

//       console.log(a.RepeaterData.Items.Item.rpt_ALM4_EOS['#text']);

//       // TIP: will only ever have a single Item
//     }


//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);


//   return (
//     <>
//       <h3>Check the browser console for Testing results.</h3>
//     </>
//   );
// };
