/* eslint-disable @typescript-eslint/no-unused-vars */
import * as AppHelper from "../../Helpers/AppHelper";
import * as GenUtil from "../../Helpers/GenUtil2";
import { AssetInfoItem } from "../../Models/Cabot/GridModels/AssetInfo1";
import { AssetLevelMatrixFourItem } from "../../Models/Cabot/GridModels/AssetLevelMatrixFour";
import { AssetLevelMatrixOneItem } from "../../Models/Cabot/GridModels/AssetLevelMatrixOne1";
import { AssetLevelMatrixThreeItem } from "../../Models/Cabot/GridModels/AssetLevelMatrixThree1";
import { AssetLevelMatrixTwoItem } from "../../Models/Cabot/GridModels/AssetLevelMatrixTwo1";
import { BorLoanBorItem } from "../../Models/Cabot/GridModels/BorLoan";
import { BorrowerInfoItem } from "../../Models/Cabot/GridModels/BorrowerInfo1";

import { ConnectionDetails1, ConnectionDetailsItem } from "../../Models/Cabot/GridModels/ConnectionDetails1";
import { ConnectionDetailsN } from "../../Models/Cabot/GridModels/ConnectionDetailsN";
import { LitigationMatrixItem } from "../../Models/Cabot/GridModels/LitigationMatrix1";
import { LoanInfoItem } from "../../Models/Cabot/GridModels/LoanInfo1";
import { OtherNPLevelItem } from "../../Models/Cabot/GridModels/OtherNPLevel1";
import { ProposalBreakdownItem } from "../../Models/Cabot/GridModels/ProposalBreakdown1";
import { ProposedCostsDetailsItem } from "../../Models/Cabot/GridModels/ProposedCostsDetails1";
import { SupportingDetailsItem } from "../../Models/Cabot/GridModels/SupportingDetails1";


export function cvtXml2ConnectionDetailsItem(data: string): ConnectionDetailsItem[] {

  let col: ConnectionDetailsItem[] = [];

  if (!!data && !GenUtil.isNull(data) && !GenUtil.isInt(data)) {
    let obj = AppHelper.getJsonObjFromXmlStr('ConnectionDetails', data);

    if (!AppHelper.xmlHasMultipleItems(data)) {
      let _t = (obj as ConnectionDetails1).RepeaterData.Items.Item;

      let item: ConnectionDetailsItem = {
        rpt_ConnectionID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionID)),

        rpt_ConnectionName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionName)),

        cv_ConnectionStatus: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnectionStatus)),

        rpt_FullyAssigned: GenUtil.safeTrim(AppHelper.getText(_t.rpt_FullyAssigned)),

        rpt_TotalCollectionsAmount: GenUtil.safeTrim(AppHelper.getText(_t.rpt_TotalCollectionsAmount)),
        cv_TotalCollectionsAmount: GenUtil.safeTrim(AppHelper.getText(_t.cv_TotalCollectionsAmount)),

        RowNumberConnectionDetails: (1) + '',
      };

      // only add if the object is not nintex empty xml record
      if (!GenUtil.isNull(item.rpt_ConnectionID)) col = [item];

    }
    else {
      let _t = obj as ConnectionDetailsN;

      let _col: ConnectionDetailsItem[] = _t.RepeaterData.Items.Item.map((_t, i) => {
        return {
          rpt_ConnectionID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionID)),

          rpt_ConnectionName: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ConnectionName)),

          cv_ConnectionStatus: GenUtil.safeTrim(AppHelper.getText(_t.cv_ConnectionStatus)),

          rpt_FullyAssigned: GenUtil.safeTrim(AppHelper.getText(_t.rpt_FullyAssigned)),

          rpt_TotalCollectionsAmount: GenUtil.safeTrim(AppHelper.getText(_t.rpt_TotalCollectionsAmount)),
          cv_TotalCollectionsAmount: GenUtil.safeTrim(AppHelper.getText(_t.cv_TotalCollectionsAmount)),

          RowNumberConnectionDetails: (i + 1) + '',
        }
      });

      // only add if the object is not nintex empty xml record
      col = _col.filter(o => !GenUtil.isNull(o.rpt_ConnectionID));
    }
  }

  return col;
}


export function cvtConnectionDetailsItem2Xml(data: ConnectionDetailsItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_ConnectionID', '', _System_String);

    xml += makeNode('rpt_ConnectionName', '', _System_String);

    xml += makeNode('cv_ConnectionStatus', '', _System_String);

    xml += makeNode('rpt_FullyAssigned', '', _System_String);

    xml += makeNode('rpt_TotalCollectionsAmount', '', _System_String);
    xml += makeNode('cv_TotalCollectionsAmount', '', _System_String);

    xml += makeNode('RowNumberConnectionDetails', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_ConnectionID', o.rpt_ConnectionID, _System_String);

      xml += makeNode('rpt_ConnectionName', o.rpt_ConnectionName, _System_String);

      xml += makeNode('cv_ConnectionStatus', o.cv_ConnectionStatus, _System_String);

      xml += makeNode('rpt_FullyAssigned', o.rpt_FullyAssigned, _System_String);

      xml += makeNode('rpt_TotalCollectionsAmount', o.rpt_TotalCollectionsAmount, _System_String);
      xml += makeNode('cv_TotalCollectionsAmount', o.cv_TotalCollectionsAmount, _System_String);

      xml += makeNode('RowNumberConnectionDetails', (i + 1) + '', _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtBorLoanItems2Xml(data: BorLoanBorItem[]): string {
  // #todo will this remain as JSON, does Kent need XML? does this need to be unnested?
  return JSON.stringify(data);
}


export function cvtBorrowerInfoItems2Xml(data: BorrowerInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_ConnId', '', _System_String);
    xml += makeNode('rpt_BorrowerID', '', _System_String);
    xml += makeNode('rpt_BorrowerName', '', _System_String);
    xml += makeNode('RowNumberBorrowerDetails', '', _System_String);
    xml += makeNode('rpt_RecourseValue', '', _System_String);
    // xml += makeNode('rpt_JointBorrowerNames', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);
      xml += makeNode('rpt_BorrowerID', o.rpt_BorrowerID, _System_String);
      xml += makeNode('rpt_BorrowerName', o.rpt_BorrowerName, _System_String);
      xml += makeNode('RowNumberBorrowerDetails', (i + 1) + '', _System_String);
      xml += makeNode('rpt_RecourseValue', o.rpt_RecourseValue, _System_String);
      // xml += makeNode('rpt_JointBorrowerNames', o.rpt_JointBorrowerNames, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtLoanInfoItems2Xml(data: LoanInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;

    xml += makeNode('cv_ConnId', '', _System_String);
    xml += makeNode('cv_BorrId', '', _System_String);
    xml += makeNode('cv_IsActive', '', _System_String);
    xml += makeNode('rpt_LoanBorrowerName', '', _System_String);
    xml += makeNode('rpt_LoanID', '', _System_String);
    xml += makeNode('RowNumberLoanDetails', '', _System_String);
    xml += makeNode('rpt_ServicerLoanID', '', _System_String);

    xml += makeNode('cv_UPBActual', '', _System_String);
    xml += makeNode('cv_UPBCurrencyActual', '', _System_String);
    xml += makeNode('cv_UPBOrig', '', _System_String);
    xml += makeNode('cv_UPBCurrencyOrig', '', _System_String);
    xml += makeNode('rpt_UPBDisp', '', _System_String);
    xml += makeNode('rpt_UPBCurrencyDisp', '', _System_String);

    xml += makeNode('rpt_RegulatoryStatus', '', _System_String);
    xml += makeNode('rpt_Collections', '', _System_String);
    xml += makeNode('cv_CollectionsCurrency', '', _System_String);

    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;

      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);
      xml += makeNode('cv_BorrId', o.cv_BorrId, _System_String);
      xml += makeNode('cv_IsActive', o.cv_IsActive, _System_String);
      xml += makeNode('rpt_LoanBorrowerName', o.rpt_LoanBorrowerName, _System_String);
      xml += makeNode('rpt_LoanID', o.rpt_LoanID, _System_String);
      xml += makeNode('RowNumberLoanDetails', (i + 1) + '', _System_String);
      xml += makeNode('rpt_ServicerLoanID', o.rpt_ServicerLoanID, _System_String);

      xml += makeNode('cv_UPBActual', o.cv_UPBActual, _System_String);
      xml += makeNode('cv_UPBCurrencyActual', o.cv_UPBCurrencyActual, _System_String);
      xml += makeNode('cv_UPBOrig', o.cv_UPBOrig, _System_String);
      xml += makeNode('cv_UPBCurrencyOrig', o.cv_UPBCurrencyOrig, _System_String);
      xml += makeNode('rpt_UPBDisp', o.rpt_UPBDisp, _System_String);
      xml += makeNode('rpt_UPBCurrencyDisp', o.rpt_UPBCurrencyDisp, _System_String);

      xml += makeNode('rpt_RegulatoryStatus', o.rpt_RegulatoryStatus, _System_String);
      xml += makeNode('rpt_Collections', o.rpt_Collections, _System_String);
      xml += makeNode('cv_CollectionsCurrency', o.cv_CollectionsCurrency, _System_String);

      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtAssetInfoItems2Xml(data: AssetInfoItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_ConnId', '', _System_String);
    xml += makeNode('rpt_AssetID', '', _System_String);
    xml += makeNode('rpt_AssetName', '', _System_String);
    xml += makeNode('rpt_AssetAddress', '', _System_String);
    xml += makeNode('RowNumberAssetDetails', '', _System_String);
    xml += makeNode('cv_REValueActual', '', _System_String);
    xml += makeNode('cv_RECurrencyActual', '', _System_String);
    xml += makeNode('cv_REValueOrig', '', _System_String);
    xml += makeNode('cv_RECurrencyOrig', '', _System_String);
    xml += makeNode('rpt_REValueDisp', '', _System_String);
    xml += makeNode('rpt_RECurrencyDisp', '', _System_String);
    xml += makeNode('rpt_AssetStatus', '', _System_String);
    xml += makeNode('rpt_AssetStrategy', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_ConnId', o.cv_ConnId, _System_String);
      xml += makeNode('rpt_AssetID', o.rpt_AssetID, _System_String);
      xml += makeNode('rpt_AssetName', o.rpt_AssetName, _System_String);
      xml += makeNode('rpt_AssetAddress', o.rpt_AssetAddress, _System_String);
      xml += makeNode('RowNumberAssetDetails', (i + 1) + '', _System_String);
      xml += makeNode('cv_REValueActual', o.cv_REValueActual, _System_String);
      xml += makeNode('cv_RECurrencyActual', o.cv_RECurrencyActual, _System_String);
      xml += makeNode('cv_REValueOrig', o.cv_REValueOrig, _System_String);
      xml += makeNode('cv_RECurrencyOrig', o.cv_RECurrencyOrig, _System_String);
      xml += makeNode('rpt_REValueDisp', o.rpt_REValueDisp, _System_String);
      xml += makeNode('rpt_RECurrencyDisp', o.rpt_RECurrencyDisp, _System_String);
      xml += makeNode('rpt_AssetStatus', o.rpt_AssetStatus, _System_String);
      xml += makeNode('rpt_AssetStrategy', o.rpt_AssetStrategy, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtSupportingDetailsItems2Xml(data: SupportingDetailsItem[]): string {

  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_SDLoanID', '', _System_String);
    xml += makeNode('cv_SDLoanID', '', _System_String);
    xml += makeNode('rpt_decimal_Collections', '', _System_String);
    xml += makeNode('cv_Coll', '', _System_String);
    xml += makeNode('rpt_CollectionsCurrency', '', _System_String);
    xml += makeNode('cv_CollCurr', '', _System_String);
    xml += makeNode('rpt_LoanType', '', _System_String);
    xml += makeNode('cv_LoanTypeValue', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_SDLoanID', o.rpt_SDLoanID, _System_String);
      xml += makeNode('cv_SDLoanID', o.cv_SDLoanID, _System_String);
      xml += makeNode('rpt_decimal_Collections', o.rpt_decimal_Collections, _System_String);
      xml += makeNode('cv_Coll', o.cv_Coll, _System_String);
      xml += makeNode('rpt_CollectionsCurrency', o.rpt_CollectionsCurrency, _System_String);
      xml += makeNode('cv_CollCurr', o.cv_CollCurr, _System_String);
      xml += makeNode('rpt_LoanType', o.rpt_LoanType, _System_String);
      xml += makeNode('cv_LoanTypeValue', o.cv_LoanTypeValue, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtProposedCostsDetailsItems2Xml(data: ProposedCostsDetailsItem[]): string {
  let xml = _Xml_Start;
  let count = 0;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;

    xml += makeNode('rpt_PropCostRowNumber', '', _System_String);
    xml += makeNode('dd_Vendors', '', _System_String);
    xml += makeNode('rpt_VendorsValue', '', _System_String);
    xml += makeNode('rpt_decimal_Costs', '', _System_String);
    xml += makeNode('rpt_CostsCurrency', '', _System_String);
    xml += makeNode('rpt_Description', '', _System_String);
    xml += makeNode('rpt_RateCard', '', _System_String);
    xml += makeNode('rpt_RCType', '', _System_String);
    xml += makeNode('cv_RCTypeId', '', _System_String);
    xml += makeNode('rpt_RCInstrType', '', _System_String);
    xml += makeNode('cv_RCInstrTypeId', '', _System_String);
    xml += makeNode('rpt_RCAmount', '', _System_String);
    xml += makeNode('rpt_RCQuantity', '', _System_String);
    xml += makeNode('rpt_RCTotal', '', _System_String);
    xml += makeNode('rpt_RCCurrency', '', _System_String);
    xml += makeNode('rpt_PaymentMethod', '', _System_String);
    xml += makeNode('rpt_PaidByReceiver', '', _System_String);

    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      // must have at least vendor selected to save the Proposed Cost row
      if (!GenUtil.isNull(o.dd_Vendors)) {
        count++;
        xml += _Xml_Item_Start;

        xml += makeNode('rpt_PropCostRowNumber', (i + 1) + '', _System_String);
        xml += makeNode('dd_Vendors', o.dd_Vendors, _System_String);
        xml += makeNode('rpt_VendorsValue', o.rpt_VendorsValue, _System_String);
        xml += makeNode('rpt_decimal_Costs', o.rpt_decimal_Costs, _System_String);
        xml += makeNode('rpt_CostsCurrency', o.rpt_CostsCurrency, _System_String);
        xml += makeNode('rpt_Description', o.rpt_Description, _System_String);
        xml += makeNode('rpt_RateCard', o.rpt_RateCard, _System_String);
        xml += makeNode('rpt_RCType', o.rpt_RCType, _System_String);
        xml += makeNode('cv_RCTypeId', o.cv_RCTypeId, _System_String);
        xml += makeNode('rpt_RCInstrType', o.rpt_RCInstrType, _System_String);
        xml += makeNode('cv_RCInstrTypeId', o.cv_RCInstrTypeId, _System_String);
        xml += makeNode('rpt_RCAmount', o.rpt_RCAmount, _System_String);
        xml += makeNode('rpt_RCQuantity', o.rpt_RCQuantity, _System_String);
        xml += makeNode('rpt_RCTotal', o.rpt_RCTotal, _System_String);
        xml += makeNode('rpt_RCCurrency', o.rpt_RCCurrency, _System_String);
        xml += makeNode('rpt_PaymentMethod', o.rpt_PaymentMethod, _System_String);
        xml += makeNode('rpt_PaidByReceiver', o.rpt_PaidByReceiver, _System_String);

        xml += _Xml_Item_End;
      }
    });
  }

  xml += _Xml_End;

  if ((data.length <= 0 || count <= 0) && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtLitigationMatrixDetailsItems2Xml(data: LitigationMatrixItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_LMProceedings', '', _System_String);
    xml += makeNode('rpt_LMLitigationReference', '', _System_String);
    xml += makeNode('rpt_LMRecordNumber', '', _System_String);
    xml += makeNode('rpt_LMLegalBudget', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_LMProceedings', o.rpt_LMProceedings, _System_String);
      xml += makeNode('rpt_LMLitigationReference', o.rpt_LMLitigationReference, _System_String);
      xml += makeNode('rpt_LMRecordNumber', o.rpt_LMRecordNumber, _System_String);
      xml += makeNode('rpt_LMLegalBudget', o.rpt_LMLegalBudget, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtALM1DetailsItems2Xml(data: AssetLevelMatrixOneItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_ALM1_AssetID', '', _System_String);
    xml += makeNode('rpt_ALM1_Guide', '', _System_String);
    xml += makeNode('rpt_decimal_ALM1_BPGDP', '', _System_String);
    xml += makeNode('rpt_ALM1_BPExitDate', '', _System_String);
    xml += makeNode('cv_ALM1_BPExitDate', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_ALM1_AssetID', o.rpt_ALM1_AssetID, _System_String);
      xml += makeNode('rpt_ALM1_Guide', o.rpt_ALM1_Guide, _System_String);
      xml += makeNode('rpt_decimal_ALM1_BPGDP', o.rpt_decimal_ALM1_BPGDP, _System_String);
      xml += makeNode('rpt_ALM1_BPExitDate', o.rpt_ALM1_BPExitDate, _System_String);
      xml += makeNode('cv_ALM1_BPExitDate', o.cv_ALM1_BPExitDate, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtALM2DetailsItems2Xml(data: AssetLevelMatrixTwoItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_ALM2_AssetID', '', _System_String);
    xml += makeNode('rpt_decimal_ALM2_Reserve', '', _System_String);
    xml += makeNode('rpt_decimal_ALM2_EstNetSalesProceeds', '', _System_String);
    xml += makeNode('rpt_decimal_ALM2_BPGDP', '', _System_String);
    xml += makeNode('rpt_decimal_ALM2_BPNet', '', _System_String);
    xml += makeNode('rpt_ALM2_BPExitDate', '', _System_String);
    xml += makeNode('cv_ALM2_BPExitDate', '', _System_String);
    xml += makeNode('rpt_ALM2_Comments', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_ALM2_AssetID', o.rpt_ALM2_AssetID, _System_String);
      xml += makeNode('rpt_decimal_ALM2_Reserve', o.rpt_decimal_ALM2_Reserve, _System_String);
      xml += makeNode('rpt_decimal_ALM2_EstNetSalesProceeds', o.rpt_decimal_ALM2_EstNetSalesProceeds, _System_String);
      xml += makeNode('rpt_decimal_ALM2_BPGDP', o.rpt_decimal_ALM2_BPGDP, _System_String);
      xml += makeNode('rpt_decimal_ALM2_BPNet', o.rpt_decimal_ALM2_BPNet, _System_String);
      xml += makeNode('rpt_ALM2_BPExitDate', o.rpt_ALM2_BPExitDate, _System_String);
      xml += makeNode('cv_ALM2_BPExitDate', o.cv_ALM2_BPExitDate, _System_String);
      xml += makeNode('rpt_ALM2_Comments', o.rpt_ALM2_Comments, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtALM3DetailsItems2Xml(data: AssetLevelMatrixThreeItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_Actual2Gross', '', _System_String);
    xml += makeNode('rpt_Actual2Net', '', _System_String);
    xml += makeNode('rpt_dt_ALAct2Exit', '', _System_String);
    xml += makeNode('cv_dt_ALAct2Exit', '', _System_String);
    xml += makeNode('rpt_BP2Gross', '', _System_String);
    xml += makeNode('rpt_BP2Net', '', _System_String);
    xml += makeNode('rpt_dt_ALBP2Exit', '', _System_String);
    xml += makeNode('cv_dt_ALBP2Exit', '', _System_String);
    xml += makeNode('rpt_cv_Del2Gross', '', _System_String);
    xml += makeNode('rpt_cv_ALDel2Net', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_Actual2Gross', o.rpt_Actual2Gross, _System_String);
      xml += makeNode('rpt_Actual2Net', o.rpt_Actual2Net, _System_String);
      xml += makeNode('rpt_dt_ALAct2Exit', o.rpt_dt_ALAct2Exit, _System_String);
      xml += makeNode('cv_dt_ALAct2Exit', o.cv_dt_ALAct2Exit, _System_String);
      xml += makeNode('rpt_BP2Gross', o.rpt_BP2Gross, _System_String);
      xml += makeNode('rpt_BP2Net', o.rpt_BP2Net, _System_String);
      xml += makeNode('rpt_dt_ALBP2Exit', o.rpt_dt_ALBP2Exit, _System_String);
      xml += makeNode('cv_dt_ALBP2Exit', o.cv_dt_ALBP2Exit, _System_String);
      xml += makeNode('rpt_cv_Del2Gross', o.rpt_cv_Del2Gross, _System_String);
      xml += makeNode('rpt_cv_ALDel2Net', o.rpt_cv_ALDel2Net, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtALM4DetailsItems2Xml(data: AssetLevelMatrixFourItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_EOSFOS', '', _System_String);
    xml += makeNode('rpt_ALM4_EOS', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_SalesProceeds', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_RentalIncome', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_TotalIncome', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_ReceiversFee', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_ReceiverOutlays', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_SalesAgentFee', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_SaleAgentMarketingCosts', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_LegalFeesConveyance', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_LegalFeesLegalOutlay', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_TaxAdvise', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_BERCerts', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_Insurance', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_ServiceCharges', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_Rates', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_RepairsMaintenanceCleanup', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_CGT', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_HHC', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_LPT', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_NPPR', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_BankCharge', '', _System_String);
    xml += makeNode('rpt_ALM4_UserField', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_UserValue', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_TotalIncVAT', '', _System_String);
    xml += makeNode('rpt_decimal_ALM4_NetProceeds', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_EOSFOS', o.cv_EOSFOS, _System_String);
      xml += makeNode('rpt_ALM4_EOS', o.rpt_ALM4_EOS, _System_String);
      xml += makeNode('rpt_decimal_ALM4_SalesProceeds', o.rpt_decimal_ALM4_SalesProceeds, _System_String);
      xml += makeNode('rpt_decimal_ALM4_RentalIncome', o.rpt_decimal_ALM4_RentalIncome, _System_String);
      xml += makeNode('rpt_decimal_ALM4_TotalIncome', o.rpt_decimal_ALM4_TotalIncome, _System_String);
      xml += makeNode('rpt_decimal_ALM4_ReceiversFee', o.rpt_decimal_ALM4_ReceiversFee, _System_String);
      xml += makeNode('rpt_decimal_ALM4_ReceiverOutlays', o.rpt_decimal_ALM4_ReceiverOutlays, _System_String);
      xml += makeNode('rpt_decimal_ALM4_SalesAgentFee', o.rpt_decimal_ALM4_SalesAgentFee, _System_String);
      xml += makeNode('rpt_decimal_ALM4_SaleAgentMarketingCosts', o.rpt_decimal_ALM4_SaleAgentMarketingCosts, _System_String);
      xml += makeNode('rpt_decimal_ALM4_LegalFeesConveyance', o.rpt_decimal_ALM4_LegalFeesConveyance, _System_String);
      xml += makeNode('rpt_decimal_ALM4_LegalFeesLegalOutlay', o.rpt_decimal_ALM4_LegalFeesLegalOutlay, _System_String);
      xml += makeNode('rpt_decimal_ALM4_TaxAdvise', o.rpt_decimal_ALM4_TaxAdvise, _System_String);
      xml += makeNode('rpt_decimal_ALM4_BERCerts', o.rpt_decimal_ALM4_BERCerts, _System_String);
      xml += makeNode('rpt_decimal_ALM4_Insurance', o.rpt_decimal_ALM4_Insurance, _System_String);
      xml += makeNode('rpt_decimal_ALM4_ServiceCharges', o.rpt_decimal_ALM4_ServiceCharges, _System_String);
      xml += makeNode('rpt_decimal_ALM4_Rates', o.rpt_decimal_ALM4_Rates, _System_String);
      xml += makeNode('rpt_decimal_ALM4_RepairsMaintenanceCleanup', o.rpt_decimal_ALM4_RepairsMaintenanceCleanup, _System_String);
      xml += makeNode('rpt_decimal_ALM4_CGT', o.rpt_decimal_ALM4_CGT, _System_String);
      xml += makeNode('rpt_decimal_ALM4_HHC', o.rpt_decimal_ALM4_HHC, _System_String);
      xml += makeNode('rpt_decimal_ALM4_LPT', o.rpt_decimal_ALM4_LPT, _System_String);
      xml += makeNode('rpt_decimal_ALM4_NPPR', o.rpt_decimal_ALM4_NPPR, _System_String);
      xml += makeNode('rpt_decimal_ALM4_BankCharge', o.rpt_decimal_ALM4_BankCharge, _System_String);
      xml += makeNode('rpt_ALM4_UserField', o.rpt_ALM4_UserField, _System_String);
      xml += makeNode('rpt_decimal_ALM4_UserValue', o.rpt_decimal_ALM4_UserValue, _System_String);
      xml += makeNode('rpt_decimal_ALM4_TotalIncVAT', o.rpt_decimal_ALM4_TotalIncVAT, _System_String);
      xml += makeNode('rpt_decimal_ALM4_NetProceeds', o.rpt_decimal_ALM4_NetProceeds, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtProposalBreakdownItems2Xml(data: ProposalBreakdownItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('cv_RowNum', '', _System_String);
    xml += makeNode('rpt_EarliestExpectedDate', '', _System_String);
    xml += makeNode('cv_EarliestExpectedDate', '', _System_String);

    xml += makeNode('cv_ExpMinAmtAct', '', _System_String);
    xml += makeNode('cv_ExpMinAmtCurAct', '', _System_String);
    xml += makeNode('cv_ExpMinAmtOrig', '', _System_String);
    xml += makeNode('cv_ExpMinAmtCurOrig', '', _System_String);
    xml += makeNode('rpt_ExpMinAmtDisp', '', _System_String);
    xml += makeNode('rpt_ExpMinAmtCurDisp', '', _System_String);

    xml += makeNode('rpt_Source', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('cv_RowNum', (i + 1) + '', _System_String);
      xml += makeNode('rpt_EarliestExpectedDate', o.rpt_EarliestExpectedDate, _System_String);
      xml += makeNode('cv_EarliestExpectedDate', o.cv_EarliestExpectedDate, _System_String);

      xml += makeNode('cv_ExpMinAmtAct', o.cv_ExpMinAmtAct, _System_String);
      xml += makeNode('cv_ExpMinAmtCurAct', o.cv_ExpMinAmtCurAct, _System_String);
      xml += makeNode('cv_ExpMinAmtOrig', o.cv_ExpMinAmtOrig, _System_String);
      xml += makeNode('cv_ExpMinAmtCurOrig', o.cv_ExpMinAmtCurOrig, _System_String);
      xml += makeNode('rpt_ExpMinAmtDisp', o.rpt_ExpMinAmtDisp, _System_String);
      xml += makeNode('rpt_ExpMinAmtCurDisp', o.rpt_ExpMinAmtCurDisp, _System_String);

      xml += makeNode('rpt_Source', o.rpt_Source, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


export function cvtOtherNPLevelItems2Xml(data: OtherNPLevelItem[]): string {
  let xml = _Xml_Start;

  if (data.length <= 0) {
    // empty element
    xml += _Xml_Item_Start;
    xml += makeNode('rpt_LoanID', '', _System_String);
    xml += makeNode('cv_NotApplicableChecked', '', _System_String);
    xml += makeNode('rpt_CommittedOption', '', _System_String);
    xml += makeNode('rpt_StartDate', '', _System_String);
    xml += makeNode('cv_StartDate', '', _System_String);
    xml += makeNode('rpt_EndDate', '', _System_String);
    xml += makeNode('cv_EndDate', '', _System_String);
    xml += makeNode('rpt_Capatilised', '', _System_String);
    xml += makeNode('rpt_ARABroken', '', _System_String);
    xml += makeNode('rpt_MonthlyARAPayment', '', _System_String);
    xml += makeNode('rpt_DetailOnARA', '', _System_String);
    xml += makeNode('rpt_WarehouseSplitAmt', '', _System_String);
    xml += makeNode('rpt_WritedownAmt', '', _System_String);
    xml += makeNode('rpt_WritedownDate', '', _System_String);
    xml += makeNode('cv_WritedownDate', '', _System_String);
    xml += makeNode('rpt_TermExtensionDate', '', _System_String);
    xml += makeNode('cv_TermExtensionDate', '', _System_String);
    xml += makeNode('rpt_UpdatedInterestDate', '', _System_String);
    xml += makeNode('cv_UpdatedInterestDate', '', _System_String);
    xml += makeNode('rpt_UpdatedInterestRate', '', _System_String);
    xml += makeNode('rpt_InterestRateType', '', _System_String);
    xml += _Xml_Item_End;
  }
  else {
    data.forEach((o, i) => {
      xml += _Xml_Item_Start;
      xml += makeNode('rpt_LoanID', o.rpt_LoanID, _System_String);
      xml += makeNode('cv_NotApplicableChecked', o.cv_NotApplicableChecked, _System_String);
      xml += makeNode('rpt_CommittedOption', o.rpt_CommittedOption, _System_String);
      xml += makeNode('rpt_StartDate', o.rpt_StartDate, _System_String);
      xml += makeNode('cv_StartDate', o.cv_StartDate, _System_String);
      xml += makeNode('rpt_EndDate', o.rpt_EndDate, _System_String);
      xml += makeNode('cv_EndDate', o.cv_EndDate, _System_String);
      xml += makeNode('rpt_Capatilised', o.rpt_Capatilised, _System_String);
      xml += makeNode('rpt_ARABroken', o.rpt_ARABroken, _System_String);
      xml += makeNode('rpt_MonthlyARAPayment', o.rpt_MonthlyARAPayment, _System_String);
      xml += makeNode('rpt_DetailOnARA', o.rpt_DetailOnARA, _System_String);
      xml += makeNode('rpt_WarehouseSplitAmt', o.rpt_WarehouseSplitAmt, _System_String);
      xml += makeNode('rpt_WritedownAmt', o.rpt_WritedownAmt, _System_String);
      xml += makeNode('rpt_WritedownDate', o.rpt_WritedownDate, _System_String);
      xml += makeNode('cv_WritedownDate', o.cv_WritedownDate, _System_String);
      xml += makeNode('rpt_TermExtensionDate', o.rpt_TermExtensionDate, _System_String);
      xml += makeNode('cv_TermExtensionDate', o.cv_TermExtensionDate, _System_String);
      xml += makeNode('rpt_UpdatedInterestDate', o.rpt_UpdatedInterestDate, _System_String);
      xml += makeNode('cv_UpdatedInterestDate', o.cv_UpdatedInterestDate, _System_String);
      xml += makeNode('rpt_UpdatedInterestRate', o.rpt_UpdatedInterestRate, _System_String);
      xml += makeNode('rpt_InterestRateType', o.rpt_InterestRateType, _System_String);
      xml += _Xml_Item_End;
    });
  }

  xml += _Xml_End;

  if (data.length <= 0 && !_SAVE_EMPTY_XML) {
    return '';
  }

  return xml;
}


//#region 'Utility'
//-------------------------

// either save '' or '<xml>...empty_defaults...</xml>'
const _SAVE_EMPTY_XML: boolean = false;


function makeNode(name: string, val: any, type: string) {

  let v = GenUtil.safeTrim(val);

  if (type === _System_String) {

    v = GenUtil.normalizeEOL(val);
    v = v.replace(/\n/ig, ' '); // convert linebreaks to spaces
    v = v.replace(/\s+/ig, ' '); // consolidate spaces

    // replace xml reserved chars
    v = v.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;');
  }

  return `<${name} type='${type}'>${v}</${name}>`;
}


const _Xml_Start: string = '<?xml version="1.0" encoding="utf-8"?><RepeaterData><Version /><Items>';
const _Xml_End: string = '</Items></RepeaterData>';
const _Xml_Item_Start: string = `<Item>`;
const _Xml_Item_End: string = `</Item>`;

const _System_String: string = 'System.String';
const _System_Decimal: string = 'System.Decimal';
// const _System_Double: string = 'System.Double';
// const _System_DateTime: string = 'System.DateTime';


//#endregion
