/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { resolvePath, useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox, getWindow, Toggle,
  Dialog, DialogType, DialogFooter
} from '@fluentui/react';
import { useAccount, useMsal } from '@azure/msal-react';
import _ from 'lodash';
//import { useBoolean } from '@fluentui/react-hooks';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import { eq, inn, safeTrim, NVL, isNull, contains } from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import { getListItemAttachments, addListItemAttachment, deleteListItemAttachment, getTokenForAAD } from '../../../Services/LogicAppAttsService';
import { insertItem, updateItem, getDriveInfo } from '../../../Services/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import * as Config from '../../../Helpers/ConfigData';
import { useSessionStorage, useStorageExpires } from '../../../Helpers/UseStorage';
import * as ConvertToXml from '../../../Services/Cabot/ConvertToXml';
import * as BigConsts from "../../../Helpers/BigConsts";

import { RichTextArea } from '../../Controls/RichTextArea';
import { AjaxPicker } from '../../Controls/AjaxPicker';
//import { Space } from '../../Controls/Space';

import { ANEntity } from '../../../Models/Cabot/ANEntity';

import { ANType } from '../../../Models/Cabot/ANType';

//import { ANIrishBankHoliday } from '../../../Models/Cabot/ANIrishBankHoliday';

import { ANVendor } from '../../../Models/Cabot/ANVendor';

import { ANUser } from '../../../Models/Cabot/ANUser';

import { ANConnection, ANConnections } from '../../../Models/Cabot/ANConnection';

import { ANNote, Fields as ANNoteFields, anNoteFieldNames } from '../../../Models/Cabot/ANNote';

//import { ANLegalTitleHolderMap } from '../../../Models/Cabot/ANLegalTitleHolderMap';

//import { ANLinkApprover } from '../../../Models/Cabot/ANLinkApprover';

// import { ANServicerSubmitter } from '../../../Models/Cabot/ANServicerSubmitters';

import { ANCurrency } from '../../../Models/Cabot/ANCurrency';

//import { ANCGIDirector } from '../../../Models/Cabot/ANCGIDirector';

import { AssetInfoItem } from '../../../Models/Cabot/GridModels/AssetInfo1';

//import { BorrowerInfoItem } from '../../../Models/Cabot/GridModels/BorrowerInfo1';

import { ConnectionDetailsItem } from '../../../Models/Cabot/GridModels/ConnectionDetails1';

//import { LoanInfoItem } from '../../../Models/Cabot/GridModels/LoanInfo1';

import { ProposedCostsDetailsItem } from '../../../Models/Cabot/GridModels/ProposedCostsDetails1';

//import { SupportingDetailsItem } from '../../../Models/Cabot/GridModels/SupportingDetails1';

import { LitigationMatrixItem } from '../../../Models/Cabot/GridModels/LitigationMatrix1';

import { AssetLevelMatrixOneItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixOne1';

import { AssetLevelMatrixTwoItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixTwo1';

import { AssetLevelMatrixThreeItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixThree1';

import { AssetLevelMatrixFourItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixFour';

import { ANRateCardItem } from '../../../Models/Cabot/ANRateCardItem';

import { ProposalBreakdownItem } from '../../../Models/Cabot/GridModels/ProposalBreakdown1';

import { OtherNPLevelItem } from '../../../Models/Cabot/GridModels/OtherNPLevel1';

import { BorLoanBorItem } from '../../../Models/Cabot/GridModels/BorLoan';

import { ANLegalTrackerItem } from '../../../Models/Cabot/ANLegalTracker';

import { CompletedAN } from '../../../Models/Cabot/CompletedAN';

import { AppSectionTotals } from '../../../Models/Cabot/AppModels';

import { FileUpload, FileUploadWithType, FluOption, SavedFile, SimpleUserInfo } from '../../../Models/AppModels';

import { ARADetailItem } from '../../../Models/Cabot/ARADetail';

import { ANConnectionDetail } from './ANConnectionDetail';

import { ANConnectionDetailRO } from './ANConnectionDetailRO';

//import { BorrowerInfoBody } from './BorrowerInfoBody';

//import { LoanInfoBody } from './LoanInfoBody';

import { AssetInfoBody } from './AssetInfoBody';

import { ProposedCostsBody } from './ProposedCostsBody';

import { LitigationMatrixBody } from './LitigationMatrixBody';

import { ALM3Body } from './ALM3Body';

import { ALM2Body } from './ALM2Body';

import { ALM1Body } from './ALM1Body';

import { ALM4Body } from './ALM4Body';

//import { SupportingDetailsRO } from './SupportingDetailsRO';

import { ProposalBreakdownBody } from './ProposalBreakdownBody';

import { OtherNPLevelBody } from './OtherNPLevelBody';

import { BorLoanBorBody } from './BorLoanBorBody';

import { LegalTrackerRO } from './LegalTrackerRO';

import { CompletedANsRO } from './CompletedANsRO';

import { ANAttachments } from '../ANAttachments';


export interface IANDetailProps { }


export const ANDetail: React.FunctionComponent<IANDetailProps> = (props: React.PropsWithChildren<IANDetailProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANDetail]");
  }, []);


  const today = new Date();
  const todayISO = today.toISOString();

  let __tracking: string = '';
  let __badFields = "";

  const params = useParams();

  const { instance, accounts } = useMsal();


  //#region 'msal instance/accounts/identity related'
  //-------------------------

  useEffect(() => {
    // welcome user message
    if (accounts && accounts.length > 0) {
      console.log(`Welcome [${accounts[0].username}]`, accounts, accounts[0]);
    }
  }, [accounts]);


  useEffect(() => {
    // msal instance info
    if (instance != null) {
      console.log("Msal_instance", instance);
    }
  }, [instance]);


  let memoAdmOvrAvail = (() => {
    // admin overrides should only be available for certain users
    if (accounts && accounts.length > 0) {
      let parts = Consts.admNamesWhiteList;
      let curUsername = safeTrim(accounts[0].username);
      for (let i = 0; i < parts.length; i++) {
        const el = safeTrim(parts[i]);
        if (contains(curUsername, el)) return true;
      }
    }
    return false;
  })();


  /** current user display name */
  let memoCurUserDispName = (() => {
    // should return display name, or username
    if (memoAdmOvrAvail && Consts.admOvrUsername())
      return Consts.admOvrUsername();
    else
      return accounts.length > 0 ? (accounts[0].name ? accounts[0].name : accounts[0].username) : '';
  })();


  /** current user email address */
  let memoCurUsername = (() => {
    // should return email address
    if (memoAdmOvrAvail && Consts.admOvrUsername())
      return Consts.admOvrUsername();
    else
      return accounts.length > 0 ? accounts[0].username : '';
  })();

  //#endregion


  //#region 'state for ANNote ID'
  //-------------------------

  // const [stateANItemId, setStateANItemId] = useState<number>(0); // will be 0 or real item integer

  // useEffect(() => {
  //   // get the url "id" from the route, using safeToNumber will return 0 when "new", otherwise the integer passed
  //   setStateANItemId(GenUtil.safeToNumber(params.id));
  // }, [params]);

  const stateANItemId: number = GenUtil.safeToNumber(params.id);

  const memoIsNewItem: boolean = stateANItemId <= 0;

  //#endregion


  //#region 'state for loading'
  //-------------------------

  const [statePageLoading, setStatePageLoading] = useState<boolean>(true);
  const [stateLoadingANItem, setStateLoadingANItem] = useState<boolean>(true);
  const [stateLoadingConnection, setStateLoadingConnection] = useState<boolean>(true);
  const [stateSaving, setStateSaving] = useState<boolean>(false);

  // useEffect(() => {
  //   // scroll to top of page when loading
  //   window.scrollTo(0, 0);
  // }, [statePageLoading, stateLoadingANItem]);

  //#endregion


  //#region 'setup prevent "leave" warnings'
  //-------------------------

  const setupNavAwayWarning = () => {
    // make sure user cannot navigate away easily losing all their form changes
    resetWinDirty();
    if (Consts.disableNavAwayWarning) {
      return;
    }
    else {
      (window as any).addEventListener("beforeunload", function (e: any) {
        if (getWinDirty()) {
          // default message if left unset: 'Changes you made may not be saved.'
          let confirmationMessage = '';
          (e || window.event).returnValue = confirmationMessage; // Gecko + IE
          return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        }
      });
    }
  }

  useEffect(() => {
    // setup the warning on page load
    setupNavAwayWarning();

    // set the form as dirty after N secs
    // #todo maybe we should only set the form as dirty if the fields are actually touched?
    setTimeout(() => {
      console.log('Timer: Setting form as touched');
      setWinDirty();
    }, 10 * 1000);
  }, []);

  function setWinDirty() {
    (window as any).dirty = true;
  }

  function resetWinDirty() {
    (window as any).dirty = false;
  }

  function getWinDirty() {
    if (Consts.disableNavAwayWarning) return false;
    else return !!((window as any).dirty);
  }

  //#endregion


  //#region 'get lookup data (from cache or Graph/SharePoint)'
  //-------------------------

  const [cacheANListData, setCacheANListData] = useStorageExpires<any[]>('cacheANListDataCabotv2', [], true, Consts.cacheExpiresMinutes);

  const [stateAllANEntities, setStateAllANEntities] = useState<ANEntity[]>([]);
  const [stateAllANTypes, setStateAllANTypes] = useState<ANType[]>([]);
  const [stateAllANVendors, setStateAllANVendors] = useState<ANVendor[]>([]);
  const [stateAllANUsers, setStateAllANUsers] = useState<ANUser[]>([]);
  // const [stateAllANServicerSubmitters, setStateAllANServicerSubmitters] = useState<ANServicerSubmitter[]>([]);
  const [stateAllANCurrencys, setStateAllANCurrencys] = useState<ANCurrency[]>([]);
  const [stateAllANRateCardItems, setStateAllANRateCardItems] = useState<ANRateCardItem[]>([]);
  const [stateAllARADetailtems, setStateAllARADetailtems] = useState<ARADetailItem[]>([]);
  const [stateANNotesListWebUrl, setStateANNotesListWebUrl] = useState<string>('');
  const [stateANLegalTrackerListWebUrl, setStateANLegalTrackerListWebUrl] = useState<string>('');


  const memoLegalTrackerEnabled = useMemo<boolean>(() => {
    return isNull(stateANLegalTrackerListWebUrl) ? false : true;
  }, [stateANLegalTrackerListWebUrl]);


  useEffect(function loadLookupDataFromSP() {
    // load all Lookup Data from SP
    let ignore = false;

    if (Consts.cacheEnabled && cacheANListData.length > 0) {
      console.log('Cache enabled and prior data was saved, use cache', cacheANListData);

      let [ents, ants, ven, usr, currs, rcards, ara, anListANFramework, anListLegalTracker] = cacheANListData;

      setStateAllANEntities(ents);
      setStateAllANTypes(ants);
      setStateAllANVendors(ven);
      setStateAllANUsers(usr);
      // setStateAllANServicerSubmitters(ssub);
      setStateAllANCurrencys(currs);
      setStateAllANRateCardItems(rcards);
      setStateAllARADetailtems(ara);
      setStateANNotesListWebUrl(anListANFramework);
      setStateANLegalTrackerListWebUrl(anListLegalTracker);

      setStatePageLoading(false);

    }
    else {
      console.log('Cache is not enabled, or cache is expired, or list data missing, load lists using graph');

      setTimeout(async () => {

        let [ents, ants, ven, usr, currs, rcards, ara, anListANFramework, anListLegalTracker] = await Promise.all([
          GraphDataService.getANEntities(accounts, instance),
          GraphDataService.getANTypes(accounts, instance),
          GraphDataService.getANVendors(accounts, instance),
          GraphDataService.getANUsers(accounts, instance),
          // GraphDataService.getANServicerSubmitters(accounts, instance),
          GraphDataService.getANCurrencys(accounts, instance),
          GraphDataService.getANRateCardItems(accounts, instance),
          GraphDataService.getARADetails(accounts, instance),
          GraphDataService.getANNotesList(accounts, instance),
          GraphDataService.getANLegalTrackerList(accounts, instance)
        ]);

        let err = false;

        if (ents.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Entities from SharePoint: Msg=${ents.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Entities', ents);
          err = true;
        }

        if (ants.httpStatus >= 400) {
          AppHelper.toastError(`Error loading ANTypes from SharePoint: Msg=${ants.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading ANTypes', ants);
          err = true;
        }

        if (ven.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Vendors from SharePoint: Msg=${ven.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Vendors', ven);
          err = true;
        }

        if (usr.httpStatus >= 400) {
          AppHelper.toastError(`Error loading ANUsers from SharePoint: Msg=${usr.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading ANUsers', usr);
          err = true;
        }

        // if (linka.httpStatus >= 400) {
        //   AppHelper.toastError(`Error loading LinkApprovers from SharePoint: Msg=${linka.httpStatusText}`);
        //   err = true;
        // }

        // if (ssub.httpStatus >= 400) {
        //   AppHelper.toastError(`Error loading ServicerSubmitters from SharePoint: Msg=${ssub.httpStatusText}`);
        //   AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading ServicerSubmitters', ssub);
        //   err = true;
        // }

        if (currs.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Currencys from SharePoint: Msg=${currs.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Currencys', currs);
          err = true;
        }

        if (rcards.httpStatus >= 400) {
          AppHelper.toastError(`Error loading RateCardItems from SharePoint: Msg=${rcards.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading RateCardItems', rcards);
          err = true;
        }

        if (ara.httpStatus >= 400) {
          AppHelper.toastError(`Error loading ARADetails from SharePoint: Msg=${ara.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading ARADetails', ara);
          err = true;
        }

        if (anListANFramework.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Advisory Notes list from SharePoint: Msg=${anListANFramework.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Advisory Notes list', anListANFramework);
          err = true;
        }

        if (anListLegalTracker.httpStatus >= 400) {
          // NOTE: some users are expected to not have access to this list, so hide error messages
          // AppHelper.toastError(`Error loading Legal Tracker list from SharePoint: Msg=${anList2.httpStatusText}`);
          // err = true;
          console.warn(`Error loading Legal Tracker list from SharePoint: Msg=${anListLegalTracker.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Legal Tracker list', anListLegalTracker);
        }

        if (err) return;

        // sorting (optional)
        ents.value = _.sortBy(ents.value, o => { return o.fields.Title });
        ants.value = _.sortBy(ants.value, o => { return o.fields.Title });
        ven.value = _.sortBy(ven.value, o => { return o.fields.Title });
        usr.value = _.sortBy(usr.value, o => { return o.fields.Title });
        // ssub.value = _.sortBy(ssub.value, o => { return o.fields.Title }); // "Name" is internal "Title"
        currs.value = _.sortBy(currs.value, o => { return o.fields.Title });
        // rcards.value = TBD // do RateCardItems needs to be manually sorted? has cascading data: Title = Rate Card Type (simple text), Instruction Type (text starts with codes that may not sort like numbers, ex "T1", "T10", "T2" )
        ara.value = _.sortBy(ara.value, o => { return o.fields.Title }); // sort by Title

        if (!ignore) {

          Consts.cacheEnabled && setCacheANListData([
            ents.value, ants.value, ven.value, usr.value, currs.value, rcards.value, ara.value,
            anListANFramework.webUrl || '',
            anListLegalTracker.httpStatus < 400 ? anListLegalTracker.webUrl || '' : ''
          ]);

          setStateAllANEntities(ents.value);
          setStateAllANTypes(ants.value);
          setStateAllANVendors(ven.value);
          setStateAllANUsers(usr.value);
          // setStateAllANServicerSubmitters(ssub.value);
          setStateAllANCurrencys(currs.value);
          setStateAllANRateCardItems(rcards.value);
          setStateAllARADetailtems(ara.value);
          setStateANNotesListWebUrl(anListANFramework.webUrl || ''); // save the list URL, it may differ from list Title (ex: title='Advisory Notes Framework' vs url='ANFramework')
          setStateANLegalTrackerListWebUrl(anListLegalTracker.httpStatus < 400 ? anListLegalTracker.webUrl || '' : '');

          if (statePageLoading) {
            setStatePageLoading(false);
          }
        }

      }, Consts.sleepMsAjax);
    }
    return () => { ignore = true; }
  }, []);

  //#endregion


  //#region 'get AN from SharePoint list if provided by querystring'
  //-------------------------

  const [stateANNote, setStateANNote] = useState<ANNote | null | undefined>();


  // can use this memo without worrying if its null/undefined, making every field value optional (ex. x[col] = string | undefined)
  const memoANNote = useMemo<Partial<ANNoteFields>>(() => {
    return stateANNote ? stateANNote.fields : {};
  }, [stateANNote]);


  // use this memo for Form Status, since we use it everywhere, plus we can provide an admin override to force edit mode
  // further, always convert Saved to Draft.  "Saved" will only be set when the save button is used, otherwise consider it Draft
  const memoFormStatus = useMemo<string>(() => {
    if (memoAdmOvrAvail && Consts.admOvrEditMode())
      return StaticData.wfStatusDraft; // admin override for Draft mode
    else {
      if (eq(memoANNote.FormStatus, StaticData.wfStatusSaved))
        return StaticData.wfStatusDraft; // if Saved switch to Draft
      else
        return NVL(memoANNote.FormStatus, StaticData.wfStatusDraft); // return status or Draft if empty
    };
  }, [memoANNote]);


  // convert formstatus to a number matching the app#
  const memoFormStatusNum = useMemo<number>(() => {
    if (eq(memoFormStatus, StaticData.wfStatusCABOT1)) return 17; // must be a number, "L1" looks like 17 backwards
    else if (eq(memoFormStatus, StaticData.wfStatusCES1)) return 6;
    else if (eq(memoFormStatus, StaticData.wfStatusServicer)) return 3;
    else if (eq(memoFormStatus, StaticData.wfStatusRPE1)) return 8;
    else if (eq(memoFormStatus, StaticData.wfStatusPSI)) return 4;
    else if (eq(memoFormStatus, StaticData.wfStatusCES2)) return 1;
    else if (eq(memoFormStatus, StaticData.wfStatusCGI1)) return 7;
    else if (eq(memoFormStatus, StaticData.wfStatusCGI2)) return 2;
    else if (eq(memoFormStatus, StaticData.wfStatusLTH)) return 5;
    else if (eq(memoFormStatus, StaticData.wfStatusEO)) return 9;
    else if (eq(memoFormStatus, StaticData.wfStatusRPE2)) return 10;
    else return 0;
  }, [memoANNote, memoFormStatus]);


  // determine if form is readonly (needed up here because used in connection fetch)
  const memoFormIsReadOnly = useMemo<boolean>(() => {
    // form is only enabled when: Is_New_Mode, or the formstatus is DRAFT, SAVED, or CABOT1
    if (memoIsNewItem || inn(memoFormStatus, StaticData.wfStatusDraft, StaticData.wfStatusSaved, StaticData.wfStatusCABOT1))
      return false;
    else
      return true;
  }, [memoIsNewItem, memoFormStatus]);


  useEffect(function loadANItemFromSP() {
    // load AN Note SPListItem from SP
    let ignore = false;

    let _id = stateANItemId;

    if (_id <= 0) {
      setStateLoadingANItem(false);
      setStateLoadingConnection(false);
    }
    else {
      setStateLoadingANItem(true);
      setStateLoadingConnection(true);

      setTimeout(async () => {

        // load AN from SP
        let an = await GraphDataService.getANNote(accounts, instance, _id);

        if (an.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Advisory Note from SharePoint: ItemId=${_id}; Msg=${an.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Advisory Note`, an);
          return;
        }

        console.log('Loaded AN from SharePoint', an);


        if (!ignore) {
          // order of saving state is important, as it will trigger other hooks before this hook may finish, first set the simple fields, then set the overall ANNote, then load connections, finally other 1:N sections



          // top section

          if (!isNull(an.fields.Project))
            setStateSelProject(an.fields.Project);

          if (!isNull(an.fields.AdvisoryNoteType))
            setStateSelANType(an.fields.AdvisoryNoteType);
          if (!isNull(an.fields.AdvisoryNoteSubtype))
            setStateSelANSubType(an.fields.AdvisoryNoteSubtype);

          if (!isNull(an.fields.SFS_Info_Available))
            setStateSelSFSInfoAvailable(an.fields.SFS_Info_Available);

          // DEPRECATED - not used anymore
          // if (!isNull(an.fields.Lender_approval_Security_release))
          //   setStateSelLenderApprovalSecurityRelease(an.fields.Lender_approval_Security_release);


          setStateProposedCostIsNA(GenUtil.safeToBool(an.fields.PropCostNA));
          setStateLitigationMatrixIsNA(GenUtil.safeToBool(an.fields.LitigationMatrixNA));


          // rich text mlots (if they are blank/null, then set them to the templates)

          setStatePropertyDetailsBgInfo(safeTrim(an.fields.Property_Details)); // html
          setStateProposal(safeTrim(an.fields.Proposal)); // html
          setStateDetailsTOC(safeTrim(an.fields.Details)); // html
          setStatePros(safeTrim(an.fields.Pros)); // html
          setStateSupportingTables(safeTrim(an.fields.SupportingTables)); // html
          setStateRecommendation(NVL(an.fields.Recommendation, StaticData.htmlDefaultRecComments)); // plain text (now html)
          setStateRecComments(safeTrim(an.fields.Comments)); // plain text (now html)


          // sfs section
          setStateSFSComments(an.fields.SFS_Assessment);
          setStateSFSReasonsWhyStmt(an.fields.SFS_ReasonsWhy);

          setStateSelSfsAssessmentIAFCA(safeTrim(an.fields.SFS_IFC));
          setStateSelSfsAssessmentIO(safeTrim(an.fields.SFS_IO));
          setStateSelSfsAssessmentLTIO(safeTrim(an.fields.SFS_LTIO));
          setStateSelSfsAssessmentMFPB(safeTrim(an.fields.SFS_Moratorium));
          setStateSelSfsAssessmentSettle(safeTrim(an.fields.SFS_Settlement));
          setStateSelSfsAssessmentCOA(safeTrim(an.fields.SFS_CapOfArrears));
          setStateSelSfsAssessmentEOLT(safeTrim(an.fields.SFS_ExtLoanTerm));
          setStateSelSfsAssessmentRA(safeTrim(an.fields.SFS_RA));
          setStateSelSfsAssessmentDWD(safeTrim(an.fields.SFS_DebtWriteDown));
          setStateSelSfsAssessmentSM(safeTrim(an.fields.SFS_SplitMortgage));
          setStateSelSfsAssessmentLIO(safeTrim(an.fields.SFS_LongTIO));
          setStateSelSfsAssessmentMTR(safeTrim(an.fields.SFS_MTR));
          setStateSelSfsAssessmentVSale(safeTrim(an.fields.SFS_VS));
          setStateSelSfsAssessmentVSurr(safeTrim(an.fields.SFS_VoluntarySurrender));
          setStateSelSfsAssessmentATR(safeTrim(an.fields.SFS_AbilityToRepay));
          setStateSelSfsAssessmentSARA(safeTrim(an.fields.SFS_SustainableARA));






          // connection metrics (flat)
          // setStateRequestMetricsFromCES(GenUtil.safeToBool(an.fields.ReqConnMetricsCES));
          setStateRequestMetricsFromCES(false); // this should always be false, as of 10-30-23, as per Jesse for Cabot

          setStateCMCollectionsUW(safeTrim(an.fields.CMGrossUW));
          setStateCMCollectionsRevBP(safeTrim(an.fields.CMGrossBP));
          setStateCMMultipleUW(safeTrim(an.fields.CMMultUW));
          setStateCMMultipleRevBP(safeTrim(an.fields.CMMultBP));
          setStateCMIRRUW(safeTrim(an.fields.CMIRRUW));
          setStateCMIRRRevBP(safeTrim(an.fields.CMIRRBP));
          setStateCMWALUW(safeTrim(an.fields.CMWALUM));
          setStateCMWALRevBP(safeTrim(an.fields.CMWALBP));




          // approvers
          if (!isNull(an.fields.CaseManager))
            setStateSelCaseManagerUser(an.fields.CaseManager);

          if (!isNull(an.fields.Link_x0020_ReviewerLookupId))
            setStateSelServicerSubmitter(an.fields.Link_x0020_ReviewerLookupId);





          // workflow sections:
          // NOTE: restore BBP choices, and comments, but actions are NOT restored, they are used to determine submit action

          // BBP
          // as of 7-11-23 both bbp will be set to No by default when submitted to CES1
          setStateSelApp6BBP(safeTrim(an.fields.App6BBP));
          setStateSelApp7BBP(safeTrim(an.fields.App7BBP));

          if (eq(an.fields.FormStatus, StaticData.wfStatusCGI1)) {
            // NOTE: when form is openned at step app7, set bbp7 to match bbp6 to help user avoid confusion; they can still override at app7 the bbp7 value, but lets set value on load here
            setStateSelApp7BBP(safeTrim(an.fields.App6BBP));
          }

          // special field: RPE1 override
          // as of 7-11-23 this will be set to "Yes" so the workflow will proceed RPE1 and RPE2 both needed
          setStateSelApp8OVR(safeTrim(an.fields.App8OVR));

          // special field: key decision override, as of 7-7-23 use this instead of app8ovr
          // this will be set to match the keydecision lookup and ccma override when submitted to CES1, and later can be overriden by RPE1
          setStateSelApp8KeyDecOvr(safeTrim(an.fields.App8KeyDecOvr));



          // Comments
          setStateAppL1Comment(safeTrim(an.fields.AppL1Comments));
          // setStateAppL2Comment(safeTrim(an.fields.AppL2Comments));
          setStateApp6Comment(safeTrim(an.fields.App6Comments));
          setStateApp3Comment(safeTrim(an.fields.App3Comments));
          setStateApp8Comment(safeTrim(an.fields.App8Comments));
          setStateApp4Comment(safeTrim(an.fields.App4Comments));
          setStateApp1Comment(safeTrim(an.fields.App1Comments));
          setStateApp7Comment(safeTrim(an.fields.App7Comments));
          setStateApp2Comment(safeTrim(an.fields.App2Comments));
          setStateApp5Comment(safeTrim(an.fields.App5Comments));
          setStateApp9Comment(safeTrim(an.fields.App9Comments));
          setStateApp10Comment(safeTrim(an.fields.App10Comments));




          // now set and trigger loading the main ANNote
          setStateANNote(an);



          // then load connections
          let conns = ConvertToXml.cvtXml2ConnectionDetailsItem(safeTrim(an.fields.ConnectionDetails));
          let connIds = conns
            .filter(x => !isNull(x.rpt_ConnectionID))
            .map(x => safeTrim(x.rpt_ConnectionID));
          setStateANSavedConnDetails(conns); // set this first so each status can be updated
          setStateCurANConnIDs(connIds); // setting these connectionIds will trigger the load of these connections in a useEffect below



          // then load 1:N sections
          setStateBorLoanInfo(NVL(an.fields.BorrowerInfo, new Date().getTime().toString()));
          // setStateBorrowerInfo(NVL(an.fields.BorrowerInfo, new Date().getTime().toString()));
          // setStateLoanInfo(NVL(an.fields.LoanInfo, new Date().getTime().toString()));
          setStateAssetInfo(NVL(an.fields.AssetInfo, new Date().getTime().toString()));
          // setStateSupportingDetailsInfo(NVL(an.fields.SupportingDetails, new Date().getTime().toString())); // do not set this, this is driven from loans saved/selected, its one way (loans change, generates supporting details)
          setStateOtherNPLevel(NVL(an.fields.OtherNPLevel, new Date().getTime().toString()));

          // independent xml sections (not affiliated with connections)
          setStateProposedCostsDetailsInfo(NVL(an.fields.ProposedCostsDetails, new Date().getTime().toString()));
          setStateLitigationMatrixDetailsInfo(NVL(an.fields.LitigationMatrix, new Date().getTime().toString()));
          setStateALM1DetailsInfo(NVL(an.fields.AssetLevelMatrix1, new Date().getTime().toString()));
          setStateALM2DetailsInfo(NVL(an.fields.AssetLevelMatrix2, new Date().getTime().toString()));
          setStateALM3DetailsInfo(NVL(an.fields.AssetLevelMatrix3, new Date().getTime().toString()));
          setStateALM4DetailsInfo(NVL(an.fields.AssetLevelMatrix4, new Date().getTime().toString()));
          setStateProposalBreakdownDetailsInfo(NVL(an.fields.ProposalBreakdown, new Date().getTime().toString()));




          // reset loaders
          setStateLoadingANItem(false);

          if (connIds.length <= 0)
            setStateLoadingConnection(false); // if no connections to load, hide the spinner, otherwise defer this while conns are loaded

        }
      }, Consts.sleepMsAjax);
    }

    return () => { ignore = true; }
  }, [stateANItemId]);

  //#endregion


  //#region 'Load AN Attachments'
  //-------------------------

  const [stateANAtts, setStateANAtts] = useState<SavedFile[]>([]);
  const [stateANAttsFromQuery, setStateANAttsFromQuery] = useState<SavedFile[]>([]);

  const [stateAttsEnabled, setStateAttsEnabled] = useState<boolean>(false);


  useEffect(function pingANAttachmentsSvc() {
    // #testing: lets try to keep the logic app alive by calling it every 60 seconds
    let enabled = false;

    let secs = 60; // interval in seconds
    let duration = 15; // max number of runs

    async function callSvc() {
      let t = GenUtil.safeToNumber((window as any).iv1c) + 1;
      (window as any).iv1c = t;
      if (t > duration) {
        return;
      }
      console.log("***pingANAttachmentsSvc", t);
      await getListItemAttachments(accounts, instance,
        Config.Settings().AttSvcLogicAppUrlCabot,
        Config.Settings().AttSvcSiteAbsUrlCabot,
        Config.Settings().ListTitleANNotesCabot,
        -999); // can send -999 to logic app to have it skip making a SP listitem attachment query, Kent logic app updated 10-18-23
    }

    if (enabled) {
      if ((window as any).iv1) {
        clearInterval((window as any).iv1);
      }

      callSvc();

      (window as any).iv1 = setInterval(() => {
        callSvc();
      }, secs * 1000);
    }
  }, []); // onpageload


  useEffect(function loadANAttachmentsFromSvc() {
    // NOTE: we have noticed that this could take long if logicapps is slow, so lets do this whole attachments loading after the main AN is loaded
    // after they are loaded we can enable the atts section
    let ignore = false;

    let _id = stateANItemId;

    if (_id <= 0) {
      // lets assume attachments are enabled for new AN Form entries
      setStateAttsEnabled(true);
    }
    else if (_id > 0 && stateANNote != null) {
      // only load attachments when ID is passed to page, and the ANNote was already successfully loaded
      setTimeout(async () => {

        let an: ANNote = stateANNote;
        let attachmentData: string = an.fields.AttachmentData;

        // load AN attachments from SP
        // NOTE: this should be a safe call, only loading items from a document library/list (no onedrive calls)
        let atts: any = await getListItemAttachments(accounts, instance,
          Config.Settings().AttSvcLogicAppUrlCabot,
          Config.Settings().AttSvcSiteAbsUrlCabot,
          Config.Settings().ListTitleANNotesCabot,
          _id);

        if (atts.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Attachments from SharePoint: ItemId=${_id}; Msg=${atts.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Attachments`, atts);
        }
        else {
          // saved attachments:

          // get the actual listitem attachments from logicapp, this data will NOT have category info
          let itemAtts: SavedFile[] = [];
          if (atts && atts.data && atts.data.length > 0) {
            // map the return listitems attachments to custom object type
            itemAtts = (atts.data as any[]).map((o, i) => {
              return {
                fullUrl: safeTrim(o.AbsoluteUri),
                attType: StaticData.defaultListAttType,
                deleteMe: false
              } as SavedFile;
            });
          }

          // get the saved listitem attachment data from AN listitem field, this data WILL have category info
          let savedAtts: SavedFile[] = [];
          if (!isNull(attachmentData)) {
            let tmp: SavedFile[] = JSON.parse(attachmentData);
            tmp.forEach(x => x.deleteMe = false); // this is for UI state, default to false
            savedAtts = tmp;
          }

          // add any orphaned list item attachments found in listitem, but not saved in item:AttachmentData
          for (const itemAtt of itemAtts) {
            let url = itemAtt.fullUrl;
            let match = savedAtts.find(x => eq(x.fullUrl, url));

            if (!match) {
              // match not found, add the listitem attachment to saved attachment collection with default type/category
              console.warn(`List item attachment found but does not have match in AttachmentData, adding...`, itemAtt);
              savedAtts.push({
                fullUrl: url,
                attType: StaticData.defaultListAttType,
                deleteMe: false
              });
            }
          }

          // remove any saved atts that do not really exist as list item attachments (someone could have edited listitem attachments outside of the AN Form)
          savedAtts = savedAtts.filter(x => {
            let url = x.fullUrl;
            let match = itemAtts.find(y => eq(y.fullUrl, url));
            if (!match) {
              console.warn(`Attachment found in AttachmentData does not have match in actual list item attachments, removing...`, x);
            }
            return !!match;
          });

          !ignore && setStateANAttsFromQuery(itemAtts);

          !ignore && setStateANAtts(savedAtts);

          !ignore && setStateAttsEnabled(true);
        }
      }, Consts.sleepMsAjax);
    }

    return () => { ignore = true; }
  }, [stateANItemId, stateANNote]);

  //#endregion


  //#region 'Field: Project Name (lookup)'
  //-------------------------

  const [stateSelProject, setStateSelProject] = useState<string>(''); // from lookup data Entities
  const onProjectNameChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelProject(option ? option.key + '' : ''));

  const [stateSelProjectItem, setStateSelProjectItem] = useState<ANEntity | null>(null);


  useEffect(() => {
    // get selected Project listitem (the combobox only selects the key/name)
    if (stateAllANEntities.length > 0 && !isNull(stateSelProject)) {
      let obj = stateAllANEntities.find(o => eq(o.fields.Title, stateSelProject));
      if (!!obj) {
        setStateSelProjectItem({ ...obj });
      }
    }
  }, [stateAllANEntities, stateSelProject]);


  // #testing always set selProject to "Scariff"
  useEffect(() => {
    if (Consts.isDevTenant() && stateAllANEntities.length > 0) {
      setStateSelProject("Scariff");
    }
  }, [stateAllANEntities]);


  // helper memo objects for mapping selected Entity/Project list item lookup fields

  const memoProjLUEntity = useMemo<string>(() => {
    // ex: "Promontoria (Redwood) Designated Activity Company"
    if (stateSelProjectItem) return safeTrim(stateSelProjectItem.fields.Projects);
    else return '';
  }, [stateSelProjectItem]);

  const memoProjLULTHName = useMemo<string>(() => {
    // ex: "Everyday Finance"
    if (stateSelProjectItem) return safeTrim(stateSelProjectItem.fields.wh8i); // i.e. LegalTitleHolder
    else return '';
  }, [stateSelProjectItem]);

  const memoProjLURegulatedEntity = useMemo<string>(() => {
    // ex: "Everyday Finance"
    if (stateSelProjectItem) return safeTrim(stateSelProjectItem.fields.c79r); // i.e. RegulatedEntity
    else return '';
  }, [stateSelProjectItem]);

  const memoProjLUAssetManager = useMemo<string>(() => {
    // ex: "Everyday Finance"
    if (stateSelProjectItem) return safeTrim(stateSelProjectItem.fields.vs0j); // i.e. AssetManager
    else return '';
  }, [stateSelProjectItem]);

  const memoProjLUIntExt = useMemo<string>(() => {
    // ex: External, Internal
    if (stateSelProjectItem) return safeTrim(stateSelProjectItem.fields.IntExt);
    else return '';
  }, [stateSelProjectItem]);

  const memoProjLURegulated = useMemo<boolean>(() => {
    // ex: Yes, No
    if (stateSelProjectItem) return GenUtil.safeToBool(stateSelProjectItem.fields.Regulated);
    else return false;
  }, [stateSelProjectItem]);


  //#endregion


  //#region 'Fields: AN Type (lookup)'
  //-------------------------

  const [stateANTypeOptions, setStateANTypeOptions] = useState<any[]>([]);

  const [stateSelANType, setStateSelANType] = useState<string>('');
  const onANTypeChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelANType(option ? option.key + '' : '');
    setStateSelANSubType(''); // clear the selected AN Sub-Type
  };
  const [stateSelANTypeObject, setStateSelANTypeObject] = useState<ANType | null>(null);


  const [stateANSubTypeOptions, setStateANSubTypeOptions] = useState<any[]>([]);

  const [stateSelANSubType, setStateSelANSubType] = useState<string>('');
  const onANSubTypeChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelANSubType(option ? option.key + '' : '');
  };
  const [stateSelANSubTypeObject, setStateSelANSubTypeObject] = useState<ANType | null>(null);


  const memoIsOtherPortfolioLevel = useMemo<boolean>(() => {
    // determine if selected ANType is "portfolio level" related
    return contains(stateSelANType, 'OTHER - PORTFOLIO LEVEL');
  }, [stateSelANType]);


  useEffect(() => {
    // get selected AN Type listitem
    let obj = null;
    if (stateSelANType !== '') {
      let objs = stateAllANTypes.filter(o => eq(o.fields.Title, stateSelANType));
      if (objs.length > 0) obj = objs[0];
    }
    setStateSelANTypeObject(obj);
  }, [stateAllANTypes, stateSelANType]);


  useEffect(() => {
    // get selected AN Sub-Type listitem
    let obj = null;
    if (stateSelANType !== '' && stateSelANSubType !== '') {
      let objs = stateAllANTypes.filter(o => eq(o.fields.Title, stateSelANType) && eq(o.fields.Secondaryoptions, stateSelANSubType));
      if (objs.length > 0) obj = objs[0];
    }
    setStateSelANSubTypeObject(obj);
  }, [stateAllANTypes, stateSelANType, stateSelANSubType]);


  useEffect(() => {
    // generate AN Type options
    let col = stateAllANTypes.filter(o => !isNull(o.fields.Title)).map((o) => { return o.fields.Title; });
    col = _.uniq(col);
    col = _.sortBy(col);

    setStateANTypeOptions(col.map(o => { return { key: o, text: o }; }));

  }, [stateAllANTypes]);


  useEffect(() => {
    // generate AN Sub-Type options (dependant on selected AN Type)
    let col = stateAllANTypes.filter(o => eq(o.fields.Title, stateSelANType) && !isNull(o.fields.Secondaryoptions)).map(o => o.fields.Secondaryoptions);
    col = _.uniq(col);
    col = _.sortBy(col);

    setStateANSubTypeOptions(col.map(o => { return { key: o, text: o }; }));

  }, [stateAllANTypes, stateSelANType]);


  // // #testing always set ANType
  // useEffect(() => {
  //   if (Consts.isDevTenant() && stateAllANTypes.length > 0) {
  //     setStateSelANType("DPO");
  //   }
  // }, [stateAllANTypes, stateANTypeOptions]);

  // // #testing always set ANSubType
  // useEffect(() => {
  //   if (Consts.isDevTenant() && stateAllANTypes.length > 0) {
  //     setStateSelANSubType("Accept Borrower Request");
  //   }
  // }, [stateAllANTypes, stateANTypeOptions, stateANSubTypeOptions, stateSelANType, stateSelANTypeObject]);


  // ANType/ANSubType helpers for showing sections/fields based on ANTypes list

  const memoANSubTypeLUKeyDecision = useMemo<boolean>(() => {
    // Title: "Key decision"; InternalName: "Keydecision"
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.Keydecision);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUConnectionMetrics = useMemo<boolean>(() => {
    // Title: "Matrix Required - Connection Metrics"; InternalName: "_x006e_kx2"/"nkx2"
    // this is: flat section, new form header "Connection Metrics (Flat)", fields: UW, Rev-BP, Delta, checkbox: Request Metrics from CES?
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields._x006e_kx2);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUMandatoryCostTable = useMemo<boolean>(() => {
    // Title: "Mandatory Cost Table"; InternalName: "MandatoryCostTable"
    // this is Proposed Costs table
    // this property/fieldvalue determines if the section is mandatory, otherwise the section is always visible
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.MandatoryCostTable);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLULitigationMatrix = useMemo<boolean>(() => {
    // Title: "Litigation Matrix"; InternalName: "zq3g"
    // this is Litigation Matrix table
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.zq3g);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUAlm1 = useMemo<boolean>(() => {
    // Title: "Asset Level Matrix 1"; InternalName: "AssetLevelMatrix1"
    // this is: grid, new form header "Metrics 1", fields: Asset ID,Guide,BP GDP,BP Exit Date
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.AssetLevelMatrix1);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUAlm2 = useMemo<boolean>(() => {
    // Title: "Asset Level Matrix 2"; InternalName: "AssetLevelMatrix2"
    // this is: grid, new form header "Metrics 2", fields: Asset ID, Reserve, Est net Sales Proceeds, BP GDP, BP Net, BP Exit Date
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.AssetLevelMatrix2);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUAlm4 = useMemo<boolean>(() => {
    // Title: "Asset Level Matrix 4"; InternalName: "AssetLevelMatrix4"
    // this is: long vertical table at bottom, EOS/FOS and lots of number fields
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.AssetLevelMatrix4);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUAlm03 = useMemo<boolean>(() => {
    // naming it "03" on purpose, since its both "0" and "3"
    // Title: "Asset Level Metrics"; InternalName: "c6jb"
    // this is: flat metrics section, deprecated in this form, hidden after a certain date in nintex
    // also, this is: grid, new form header "Metrics 3", fields: Gross Proceeds, Net Proceeds, Exit Date
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.c6jb);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUOtherNPLevel = useMemo<boolean>(() => {
    // Title: "ARA.."; InternalName: "OtherNPLevel"
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.OtherNPLevel);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUProposalBreakdown = useMemo<boolean>(() => {
    // Title: "ProposalBreakdown"; InternalName: "ProposalBreakdown"
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.ProposalBreakdown);
  }, [stateSelANSubTypeObject]);

  const memoANSubTypeLUBelowBpCheck = useMemo<boolean>(() => {
    // Title: "BelowBpCheck"; InternalName: "BelowBpCheck"
    // determines show/hide
    return GenUtil.safeToBool(stateSelANSubTypeObject?.fields.BelowBpCheck);
  }, [stateSelANSubTypeObject]);

  //#endregion


  //#region 'Fields: SFS Assessment Information & Senior Lending Approval Release (both choices)'
  //-------------------------

  const [stateSelSFSInfoAvailable, setStateSelSFSInfoAvailable] = useState<string>('');
  const onChangeSFSInfoAvailable = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelSFSInfoAvailable(option ? option.key + '' : '');
  };

  const [stateSelLenderApprovalSecurityRelease, setStateSelLenderApprovalSecurityRelease] = useState<string>(''); // DEPRECATED - not used anymore
  const onChangeLenderApprovalSecurityRelease = (event: any, option?: IComboBoxOption, index?: number, value?: string) => {
    setStateSelLenderApprovalSecurityRelease(option ? option.key + '' : '');
  };

  //#endregion


  //#region 'Field: Connection picker (ajax lookup)'
  //-------------------------

  const [stateSelANConnectionPicker, setStateSelANConnectionPicker] = useState<ITag[]>([]); // connection picker result

  const memoConnPickerId = useMemo<string>(() => {
    return stateSelANConnectionPicker == null ? '' :
      stateSelANConnectionPicker.length <= 0 ? '' : stateSelANConnectionPicker[0].name;
  }, [stateSelANConnectionPicker]);

  const [stateCurANConnIDs, setStateCurANConnIDs] = useState<string[]>([]);
  const [stateSelUniqueConns, setStateSelUniqueConns] = useState<ConnectionDetailsItem[]>([]);
  const [stateSelAllConns, setStateSelAllConns] = useState<ANConnection[]>([]);
  const [stateANSavedConnDetails, setStateANSavedConnDetails] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    // reset the connection picker only when project changes
    setStateSelANConnectionPicker([]);
  }, [stateSelProject]);


  const memoShowConnectionPickerSection = useMemo<boolean>(() => {
    // only show when Project is selected
    if (stateSelProjectItem == null)
      return false;
    else
      return true;
  }, [stateSelProjectItem]);


  async function getSuggestedANConnections(filterText: string): Promise<ITag[]> {
    // handler to start searching using the filter text, for Tag Picker

    if (stateSelProjectItem == null) {
      // don't allow search if no selected project
      return [];
    }

    let filter = safeTrim(filterText);

    // #testing quick filters
    if (eq(filter, 'test') || eq(filter, 'test1'))
      filter = `CNS606681`; // when project="Sycamore B", has 15 entries (7 loans, 8 assets)
    else if (eq(filter, 'test2'))
      filter = `CNS184742`; // when project="Sycamore B", has 12 entries
    else if (eq(filter, 'test3'))
      filter = `CNS100505`; // when PROJ: Beech, ANT: DPO, ANST: Accept..., CONNID: CNS100505
    else if (eq(filter, 'test4'))
      filter = `WHA6162960-2`; // when project="Sycamore B", has 2 loans, and 0 assets

    //#NOTE: as of 8-15-23, Cabot PROD min length is 5 chars
    if (filter.length <= Consts.graphMinCharsForSearch) {
      return [];
    }

    const data = await GraphDataService.getANConnections(accounts, instance, filter, stateSelProject, Consts.graphMaxForPickerCount);

    if (data.httpStatus >= 400) {
      AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error searching for Connection: Project='${stateSelProject}'; Filter='${filter}'`, data);
    }

    if (data.httpStatus >= 400 && data.httpStatus !== 422) {
      AppHelper.toastError(`Error searching for Connection in SharePoint: Project='${stateSelProject}'; Filter='${filter}'; Msg=${data.httpStatusText}`);
      return [];
    }

    // NOTE: can handle throttling issues here
    // if (data.httpStatus === 422){
    //   toastWarn(`TBD`);
    // }

    if (data == null || data.value == null || data.value.length <= 0) {
      return [];
    }

    let col = data.value;

    // only need connection ID back from service, which is not unique
    // there may also be more than record with same connection id per project name
    // get the unique connection ids
    let tmp = col.map(o => {
      return safeTrim(o.fields.Connection_x0020_ID);
    }).filter(s => !isNull(s));

    tmp = _.uniq(tmp);

    return tmp.map(s => {
      return { key: s, name: s } as ITag; // connection id is both key and name
    });
  }


  function onTagsChangedANConnections(items?: ITag[]): void {
    // handler when the connection id ajaxpicker has a result selected
    setStateSelANConnectionPicker(!items ? [] : items);
  }


  function handleAddConnectionId() {
    // assuming a connection is selected in the picker, this further "selects" it and locks that choice in

    // quit if project and picker are not loaded
    if (stateSelProjectItem == null || memoConnPickerId === '') {
      return;
    }

    // only add connections if not already added
    if (stateCurANConnIDs.findIndex(x => eq(x, memoConnPickerId)) < 0) {

      setStateLoadingConnection(true);

      // set selected connection id
      setStateCurANConnIDs(p => [...p, memoConnPickerId]);

      setStateSelANConnectionPicker([]); // clear picker
    }
  }


  useEffect(function loadConnections() {
    // when a Connection ID is selected by user and the add button is clicked,
    // OR, when the Connection ID is set from a loaded AN record from SP
    // get that Connection from SP
    let ignore = false;

    setTimeout(async () => {

      if (isNull(stateSelProject) || stateSelProjectItem == null) {
        return;
      }

      // get current ConnIds, make sure they are unique (from user input in picker this is guaranteed, but SP item data may be unreliable)
      let connIds: string[] = _.uniq(stateCurANConnIDs.map(x => safeTrim(x)).filter(x => x.length > 0));

      connIds = _.sortBy(connIds);

      // foreach unique ConnId found, search the Connections List for matches
      // save them all, and also save a unique set of Connection records (based on ConnectionId) that will become ConnectionDetails xml
      // NOTE: searching SP for a single connection at a time
      // #todo optimize this to combine connection ids into OR clause, up to N at a time to make the URL not too long
      let allConns: ANConnection[] = [];

      const getConns = connIds.map((connId: string, idx: number) => {
        return GraphDataService.getANConnections(accounts, instance, connId, '', Consts.graphMaxAllItemsCount).then((data) => {

          if (data.httpStatus >= 400) {
            AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Connection: idx=${idx},connId='${connId}'`, data);
          }

          let col = data.value;

          if (col.length <= 0) {
            /**
             * SPECIAL CASE:
             * this cannot really be <=0, because the picker found the connection, so this must happen from a prior saved AN loading a connection no longer found
             * this is needed in case a prior saved AN is loaded and the connection query does not return any results
             * this could happen if the prior connection is deleted (manually or due to sync), or if the connection id changed
             * it shouldn't be important when the form is in readonly mode, but IS really important when in draft mode
             * this is edge case protection, should not be common
             */

            if (!memoFormIsReadOnly) {
              AppHelper.toastError(`Cannot load prior saved Connection, no matching Connections found for Connection ID='${connId}'.`);
            }

            console.warn(`Cannot load prior saved Connection, no matching Connections found for Connection ID='${connId}'.`);
            // do not return/quit, since we are loading 1 or more connections, some may be successful, some may not...
          }
          else {
            // append connections
            allConns = [...allConns, ...col];
          }

        });
      });

      await Promise.all(getConns);


      // sort returned connections (by connectionid and itemid)
      allConns = _.sortBy(allConns, x => x.fields.Connection_x0020_ID + '|' + x.fields.id);


      // fix html encoding special char issues with Connections list data, some columns have common char encoding issues
      allConns.forEach(o => {
        o.fields.Borrower_x0020_Name = GenUtil.replaceHtmlSpecialChars(o.fields.Borrower_x0020_Name);
        o.fields.Connection_x0020_Name = GenUtil.replaceHtmlSpecialChars(o.fields.Connection_x0020_Name);
        o.fields.Asset_x0020_Name = GenUtil.replaceHtmlSpecialChars(o.fields.Asset_x0020_Name);
        o.fields.AssetAddress = GenUtil.replaceHtmlSpecialChars(o.fields.AssetAddress);
      });


      // get unique connections from all connections
      let uniqConns: ANConnection[] = [];

      allConns.forEach(a => {
        // The unique connections added should be the listitems that are contenttype=Assets, therefore having 'AssetId' filled in.
        // NOTE: latest 7/5/23, connections returned in link/bcm may not contain Asset info, so do not filter on that further

        // extract current connection id
        let curConnId = safeTrim(a.fields.Connection_x0020_ID);

        // get best not empty match for this field
        let tmpFullyAssigned = "";
        allConns.filter(x => eq(curConnId, x.fields.Connection_x0020_ID)).forEach(x => tmpFullyAssigned = NVL(tmpFullyAssigned, x.fields.Fully_x0020_Assigned));

        // get best not empty match for this field
        let tmpConnStatus = "";
        allConns.filter(x => eq(curConnId, x.fields.Connection_x0020_ID)).forEach(x => tmpConnStatus = NVL(tmpConnStatus, x.fields.Connection_x0020_Status));

        if (uniqConns.findIndex(b => eq(curConnId, b.fields.Connection_x0020_ID)) < 0) {
          // connid doesn't exist in collection yet, add it, and override field data with best match data
          let c = { ...a };
          c.fields.Fully_x0020_Assigned = tmpFullyAssigned;
          c.fields.Connection_x0020_Status = tmpConnStatus;
          uniqConns = [...uniqConns, c];
        }
      });


      // show warnings for potential data issues
      if (connIds.length > 0 && uniqConns.length <= 0) {
        console.warn(`WARNING: no unique connections were found, this is likely a data issue.`);
      }

      if (connIds.length > 0 && allConns.length <= 0) {
        console.warn(`WARNING: no connections found.`);
      }


      // sum collections per connection id
      // in the set of distinct connections, sum all the collection amounts with correct CT Type for each connection id
      // NOTE: currency conversion is not needed here, as per Feidhlim O'Hanlon (FOH), all Collections will be EUR
      //   further, this total is separate from the borrower/loan section, where those totals rely on "active"(checked) loans, here the total is the sum of all ignoring active/checked loans in borloan section
      uniqConns.forEach(a => {

        let sum = 0;
        let curConnId = a.fields.Connection_x0020_ID;

        allConns.filter(b => eq(b.fields.Connection_x0020_ID, curConnId) &&
          (eq(NVL(b.fields.ContentTypeImport, b.contentType.name), "Loans"))
        ).forEach(b => {
          sum += GenUtil.safeToNumber(b.fields.Collections);
        });

        a.fields.TotalCollections = sum; // save into extra field in Connection interface
      });


      // update status property of connections from saved AN connection details
      uniqConns.forEach(a => {
        // start with empty connection status, we do not want the status from the Connections list, we only want status from User Input (current or prior saved to listitem)
        let curConnStatus = "";

        // try to restore the status from the prior saved collection (user input)
        let idx = 0;

        idx = stateSelUniqueConns.findIndex(b => eq(b.rpt_ConnectionID, a.fields.Connection_x0020_ID));

        if (idx >= 0) {
          let tmp = stateSelUniqueConns[idx];
          curConnStatus = safeTrim(tmp.cv_ConnectionStatus);
        }

        // if still empty, try to restore the status from the listitem connectiondetails
        if (isNull(curConnStatus)) {

          idx = stateANSavedConnDetails.findIndex(b => eq(b.rpt_ConnectionID, a.fields.Connection_x0020_ID));

          if (idx >= 0) {
            let tmp = stateANSavedConnDetails[idx];
            curConnStatus = safeTrim(tmp.cv_ConnectionStatus);
          }
        }

        a.fields.Connection_x0020_Status = curConnStatus;
      });


      // save connections to state
      if (!ignore) {

        setStateSelUniqueConns(uniqConns.map(x => {
          return {
            rpt_ConnectionID: x.fields.Connection_x0020_ID,

            rpt_ConnectionName: x.fields.Connection_x0020_Name,

            cv_ConnectionStatus: x.fields.Connection_x0020_Status,

            rpt_FullyAssigned: x.fields.Fully_x0020_Assigned,

            // using the sum of collections per connection id, saved temporarily in non-used field, will be preserved in XML later, for readonly component to use
            rpt_TotalCollectionsAmount: GenUtil.numberToCurrency(GenUtil.safeToNumber(x.fields.TotalCollections)),
            cv_TotalCollectionsAmount: GenUtil.safeToNumber(x.fields.TotalCollections) + '',

            RowNumberConnectionDetails: x.fields.id,

          } as ConnectionDetailsItem;
        }));

        setStateSelAllConns(allConns); // all retrieved connection records

        setStateLoadingConnection(false); // reset loader
      }

    }, 800); // delay this to force the connection picker spinner to render
    // }, Consts.sleepMsAjax);

    return () => { ignore = true; }

  }, [stateSelProject, stateSelProjectItem, memoFormIsReadOnly, stateCurANConnIDs]);


  function handleDeleteAllTables() {
    // clear all other tables and selections

    // clear the connection state
    setStateSelANConnectionPicker([]);
    setStateCurANConnIDs([]);
    setStateSelUniqueConns([]);
    setStateSelAllConns([]);
    setStateANSavedConnDetails([]); // clear the original saved conndetails so status is reset

    // clear the 1:N grids/subtables
    // NOTE: do NOT clear the independent sections (like ProposedCosts)
    // NOTE: using getTime to force updates, see other notes about this
    setStateBorLoanInfo(new Date().getTime().toString());
    // setStateBorrowerInfo(new Date().getTime().toString());
    // setStateLoanInfo(new Date().getTime().toString());
    setStateAssetInfo(new Date().getTime().toString());
    // NOTE: do not have to delete SupportingDetails, reseting loans will reset this too

    // clear the DDLs
    setStateBorrowerOptions([]);
    // setStateLoanOptions([]);
    setStateAssetOptions([]);

    // clear the connection objects
    setStateBorrowerObjects([]);
    setStateLoanObjects([]);
    setStateAssetObjects([]);

    // NOTE: do not have to clear/reset the "_Items" state, they are cleared by reseting the "...Info" state to datetime/ticks
  }


  function onChangeConnectionDetail(connId: string, status: string) {
    // merge status change into added connection

    setStateSelUniqueConns(p => {
      let t = [...p];
      let idx = t.findIndex(x => x.rpt_ConnectionID === connId);
      if (idx >= 0)
        t[idx].cv_ConnectionStatus = status;
      return t;
    })
  }


  function onDeleteConnectionDetail(connId: string) {
    // delete the selected connection
    // also, delete any borrowers, loans, assets that use this connection
    // NOTE: not relevant for AN when project=REO

    setStateLoadingConnection(true);

    // this will trigger loading the proper connections, fix the DDLs/options, and remove the connection from the Connection Details section
    setStateCurANConnIDs(p => {
      return [...p].filter(x => x !== connId);
    });

    // but, we need to manually get rid of the objects in the 1:N sections
    // NOTE: we have to cheat and send a prop down to the 1:N sections, and listen for this change
    setStateBorrowerConnId2Del(connId);
    // setStateLoanConnId2Del(connId);
    setStateAssetConnId2Del(connId);
  }


  // special 1:N sections that depend on Connection loaded
  // these are the XML fields from the ANNote list
  // NOTE: using gettime() to generate a long number to enable easy resets
  //   the data from SP may be null/empty, then when calling "reset tables", the change would be "" -> "", which prevents the subcomponent useeffect from firing
  const [stateBorLoanInfo, setStateBorLoanInfo] = useState<string>(new Date().getTime().toString());
  // const [stateBorrowerInfo, setStateBorrowerInfo] = useState<string>(new Date().getTime().toString());
  // const [stateLoanInfo, setStateLoanInfo] = useState<string>(new Date().getTime().toString());
  const [stateAssetInfo, setStateAssetInfo] = useState<string>(new Date().getTime().toString());
  // const [stateSupportingDetailsInfo, setStateSupportingDetailsInfo] = useState<string>(new Date().getTime().toString()); // this may not be needed, we are never loading this from xml, instead this comes from special filtering of LoanInfo
  const [stateOtherNPLevel, setStateOtherNPLevel] = useState<string>(new Date().getTime().toString());

  // independent xml sections (not affiliated with connections)
  const [stateProposedCostsDetailsInfo, setStateProposedCostsDetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateLitigationMatrixDetailsInfo, setStateLitigationMatrixDetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateALM1DetailsInfo, setStateALM1DetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateALM2DetailsInfo, setStateALM2DetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateALM3DetailsInfo, setStateALM3DetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateALM4DetailsInfo, setStateALM4DetailsInfo] = useState<string>(new Date().getTime().toString());
  const [stateProposalBreakdownDetailsInfo, setStateProposalBreakdownDetailsInfo] = useState<string>(new Date().getTime().toString());


  // these are the DDL options that depend on the loaded Connection
  const [stateBorrowerOptions, setStateBorrowerOptions] = useState<any[]>([]);
  // const [stateLoanOptions, setStateLoanOptions] = useState<any[]>([]);
  const [stateAssetOptions, setStateAssetOptions] = useState<any[]>([]);


  // using these state to send a Connection Id to the 1:N sections to delete all data in those components that have same connId
  const [stateBorrowerConnId2Del, setStateBorrowerConnId2Del] = useState<string>('');
  // const [stateLoanConnId2Del, setStateLoanConnId2Del] = useState<string>('');
  const [stateAssetConnId2Del, setStateAssetConnId2Del] = useState<string>('');


  // these are associated connections for each category (to send to sub components for 'Add' button)
  const [stateBorrowerObjects, setStateBorrowerObjects] = useState<ANConnection[]>([]);
  const [stateLoanObjects, setStateLoanObjects] = useState<ANConnection[]>([]);
  const [stateAssetObjects, setStateAssetObjects] = useState<ANConnection[]>([]);


  // save 1:N sections data here (will be converted to xml to save back to ANNote item)
  const [stateBorLoanInfoItems, setStateBorLoanInfoItems] = useState<BorLoanBorItem[]>([]);
  // const [stateBorrowerInfoItems, setStateBorrowerInfoItems] = useState<BorrowerInfoItem[]>([]);
  // const [stateLoanInfoItems, setStateLoanInfoItems] = useState<LoanInfoItem[]>([]);
  const [stateAssetInfoItems, setStateAssetInfoItems] = useState<AssetInfoItem[]>([]);
  // const [stateSupportingDetailsItems, setStateSupportingDetailsItems] = useState<SupportingDetailsItem[]>([]);
  const [stateOtherNPLevelItems, setStateOtherNPLevelItems] = useState<OtherNPLevelItem[]>([]);

  // independent xml sections (not affiliated with connections)
  const [stateProposedCostsDetailsItems, setStateProposedCostsDetailsItems] = useState<ProposedCostsDetailsItem[]>([]);
  const [stateLitigationMatrixDetailsItems, setStateLitigationMatrixDetailsItems] = useState<LitigationMatrixItem[]>([]);
  const [stateALM1DetailsItems, setStateALM1DetailsItems] = useState<AssetLevelMatrixOneItem[]>([]);
  const [stateALM2DetailsItems, setStateALM2DetailsItems] = useState<AssetLevelMatrixTwoItem[]>([]);
  const [stateALM3DetailsItems, setStateALM3DetailsItems] = useState<AssetLevelMatrixThreeItem[]>([]);
  const [stateALM4DetailsItems, setStateALM4DetailsItems] = useState<AssetLevelMatrixFourItem[]>([]);
  const [stateProposalBreakdownDetailsItems, setStateProposalBreakdownDetailsItems] = useState<ProposalBreakdownItem[]>([]);


  // special field for disabling Proposed Costs section
  const [stateProposedCostIsNA, setStateProposedCostIsNA] = useState<boolean>(false);
  const onChangeProposedCostIsNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateProposedCostIsNA(!!checked); }, []);

  // special field for disabling Litigation Matrix section
  const [stateLitigationMatrixIsNA, setStateLitigationMatrixIsNA] = useState<boolean>(false);
  const onChangeLitigationMatrixIsNA = React.useCallback((ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => { setStateLitigationMatrixIsNA(!!checked); }, []);


  // saving objects back to XML for saving to SP Item
  const memoConnectionDetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtConnectionDetailsItem2Xml(stateSelUniqueConns);
    return xml;
  }, [stateSelUniqueConns]);

  const memoBorLoanInfoXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtBorLoanItems2Xml(stateBorLoanInfoItems);
    return xml;
  }, [stateBorLoanInfoItems]);

  // const memoBorrowerInfoXml = useMemo<string>(() => {
  //   let xml = ConvertToXml.cvtBorrowerInfoItems2Xml(stateBorrowerInfoItems);
  //   return xml;
  // }, [stateBorrowerInfoItems]);

  // const memoLoanInfoXml = useMemo<string>(() => {
  //   let xml = ConvertToXml.cvtLoanInfoItems2Xml(stateLoanInfoItems);
  //   return xml;
  // }, [stateLoanInfoItems]);

  const memoAssetInfoXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtAssetInfoItems2Xml(stateAssetInfoItems);
    return xml;
  }, [stateAssetInfoItems]);

  // const memoSupportingDetailsXml = useMemo<string>(() => {
  //   let xml = ConvertToXml.cvtSupportingDetailsItems2Xml(stateSupportingDetailsItems);
  //   return xml;
  // }, [stateSupportingDetailsItems]);

  const memoOtherNPLevelXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtOtherNPLevelItems2Xml(stateOtherNPLevelItems);
    return xml;
  }, [stateOtherNPLevelItems]);

  const memoOtherNPLevelPDFXml = useMemo<string>(() => {
    // filter the ARADetail rows, only return those that do not have N/A checked
    let col = [...stateOtherNPLevelItems].filter(x => !GenUtil.safeToBool(x.cv_NotApplicableChecked));
    let xml = ConvertToXml.cvtOtherNPLevelItems2Xml(col);
    return xml;
  }, [stateOtherNPLevelItems]);

  const memoProposedCostsDetailsXml = useMemo<string>(() => {
    // when Proposed Costs N/A box is checked, send empty collection since costs are irrelevant
    let xml = ConvertToXml.cvtProposedCostsDetailsItems2Xml(stateProposedCostIsNA ? [] : stateProposedCostsDetailsItems);
    return xml;
  }, [stateProposedCostsDetailsItems, stateProposedCostIsNA]);

  const memoLitigationMatrixDetailsXml = useMemo<string>(() => {
    // when Litigation Matrix N/A box is checked, send empty collection since costs are irrelevant
    let xml = ConvertToXml.cvtLitigationMatrixDetailsItems2Xml(stateLitigationMatrixIsNA ? [] : stateLitigationMatrixDetailsItems);
    return xml;
  }, [stateLitigationMatrixDetailsItems, stateLitigationMatrixIsNA]);

  const memoALM1DetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtALM1DetailsItems2Xml(stateALM1DetailsItems);
    return xml;
  }, [stateALM1DetailsItems]);

  const memoALM2DetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtALM2DetailsItems2Xml(stateALM2DetailsItems);
    return xml;
  }, [stateALM2DetailsItems]);

  const memoALM3DetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtALM3DetailsItems2Xml(stateALM3DetailsItems);
    return xml;
  }, [stateALM3DetailsItems]);

  const memoALM4DetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtALM4DetailsItems2Xml(stateALM4DetailsItems);
    return xml;
  }, [stateALM4DetailsItems]);

  const memoProposalBreakdownDetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtProposalBreakdownItems2Xml(stateProposalBreakdownDetailsItems);
    return xml;
  }, [stateProposalBreakdownDetailsItems]);


  // useEffect(() => {
  //   // supporting details rows depend on specific loan rows added
  //   // when loan collection changes, copy them here, filter on certain loan records
  //   // this should drive the xml change too

  //   let col = stateLoanInfoItems.filter(x => eq(x.rpt_RegulatoryStatus, "CCMA")).map(x => {
  //     return {
  //       rpt_SDLoanID: x.rpt_LoanID,
  //       cv_SDLoanID: x.rpt_LoanID,

  //       rpt_decimal_Collections: x.rpt_Collections,
  //       cv_Coll: x.rpt_Collections,

  //       rpt_CollectionsCurrency: x.cv_CollectionsCurrency,
  //       cv_CollCurr: x.cv_CollectionsCurrency,

  //       rpt_LoanType: x.rpt_RegulatoryStatus,
  //       cv_LoanTypeValue: x.rpt_RegulatoryStatus,

  //     } as SupportingDetailsItem;
  //   });

  //   setStateSupportingDetailsItems(col);

  // }, [stateLoanInfoItems]);


  function onUpdateSectionData(name: string, data: any) {
    // update 1:N state in this component when sub-components update

    if (eq(name, 'BorrowerLoans'))
      setStateBorLoanInfoItems(data);

    // else if (eq(name, 'Borrowers'))
    //   setStateBorrowerInfoItems(data);

    // else if (eq(name, 'Loans'))
    //   setStateLoanInfoItems(data);

    if (eq(name, 'Assets'))
      setStateAssetInfoItems(data);

    // NOTE: SupportingDetails is readonly and has no updates (not even delete) (its all controlled by loans selected/loaded)

    else if (eq(name, 'OtherNPLevel'))
      setStateOtherNPLevelItems(data);

    else if (eq(name, 'PropCosts'))
      setStateProposedCostsDetailsItems(data);
    else if (eq(name, 'LitigationMatrix'))
      setStateLitigationMatrixDetailsItems(data);
    else if (eq(name, 'ALM1'))
      setStateALM1DetailsItems(data);
    else if (eq(name, 'ALM2'))
      setStateALM2DetailsItems(data);
    else if (eq(name, 'ALM3'))
      setStateALM3DetailsItems(data);
    else if (eq(name, 'ALM4'))
      setStateALM4DetailsItems(data);
    else if (eq(name, 'ProposalBreakdown'))
      setStateProposalBreakdownDetailsItems(data);
  }


  const [stateAppSectionTotals, setStateAppSectionTotals] = useState<AppSectionTotals>({
    assetInfoTotalREVal: 0,
    borLoanTotalCollections: 0,
    borLoanTotalUPB: 0,
    connDetailsTotalCollections: 0,
    litMatrixTotalLegalBudget: 0,
    propBreakdownTotalExpMinAmt: 0,
    propCostTotalCosts: 0
  });


  function onUpdateAppSectionTotals(name: string, tot: number) {
    if (eq(name, 'Assets'))
      setStateAppSectionTotals(p => { return { ...p, assetInfoTotalREVal: tot }; });
    if (eq(name, 'BorrowerLoansCollections'))
      setStateAppSectionTotals(p => { return { ...p, borLoanTotalCollections: tot }; });
    if (eq(name, 'BorrowerLoansUPB'))
      setStateAppSectionTotals(p => { return { ...p, borLoanTotalUPB: tot }; });
    if (eq(name, 'ConnectionDetails'))
      setStateAppSectionTotals(p => { return { ...p, connDetailsTotalCollections: tot }; });
    if (eq(name, 'LitigationMatrix'))
      setStateAppSectionTotals(p => { return { ...p, litMatrixTotalLegalBudget: tot }; });
    if (eq(name, 'ProposalBreakdown'))
      setStateAppSectionTotals(p => { return { ...p, propBreakdownTotalExpMinAmt: tot }; });
    if (eq(name, 'PropCosts'))
      setStateAppSectionTotals(p => { return { ...p, propCostTotalCosts: tot }; });
  }


  useEffect(function convertConnectionsToOptionsAndCache() {
    // fill each section DDL from connection objects loaded
    // also store the relevent connections associated with the DDL options

    let borrowerOptions: FluOption[] = [];
    let loanOptions: FluOption[] = [];
    let assetOptions: FluOption[] = [];

    // save the relevant connections for each set (will be passed to sub-components for "add items")
    let borrowerObjects: ANConnection[] = [];
    let loanObjects: ANConnection[] = [];
    let assetObjects: ANConnection[] = [];

    stateSelAllConns.forEach((o, i) => {

      // ============== process borrower item
      if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Loans")) {

        let borrowerId = safeTrim(o.fields.Borrower_x0020_ID);
        let borrowerName = safeTrim(o.fields.Borrower_x0020_Name);

        if (borrowerId.length > 0 && borrowerName.length > 0 && borrowerOptions.findIndex(o2 => o2.key === borrowerId) < 0) {
          let t = GenUtil.safeToFluOption(borrowerId, `${borrowerId} - ${borrowerName}`);
          if (t) borrowerOptions.push(t);
          borrowerObjects.push({ ...o });
        }
      }

      // ============== process loan item
      if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Loans")) {

        let loanId = safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID);

        if (loanId.length > 0 && loanOptions.findIndex(o2 => o2.key === loanId) < 0) {
          let t = GenUtil.safeToFluOption(loanId, loanId);
          if (t) loanOptions.push(t);
          loanObjects.push({ ...o });
        }
      }

      // ============== process asset item
      if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Assets")) {

        // #note: for Cabot use Asset ID (Unit ID does not exist)
        let assetId = safeTrim(o.fields.Asset_x0020_ID);

        if (assetId.length > 0 && assetOptions.findIndex(o2 => o2.key === assetId) < 0) {
          let t = GenUtil.safeToFluOption(assetId, assetId);
          if (t) assetOptions.push(t);
          assetObjects.push({ ...o });
        }
      }

    });

    setStateBorrowerOptions(borrowerOptions);
    // setStateLoanOptions(loanOptions);
    setStateAssetOptions(assetOptions);

    setStateBorrowerObjects(borrowerObjects);
    setStateLoanObjects(loanObjects);
    setStateAssetObjects(assetObjects);

  }, [stateSelAllConns]);


  const memoShowDeleteAllTablesButton = useMemo<boolean>(() => {
    // show the Delete All Tables button only when:
    //   a connection is loaded
    //   or, any 1:N section has data added (i.e. not empty)

    if (stateSelUniqueConns.length > 0 ||
      !GenUtil.isInt(stateBorLoanInfo) ||
      //  !GenUtil.isInt(stateBorrowerInfo) ||
      // !GenUtil.isInt(stateLoanInfo) ||
      !GenUtil.isInt(stateAssetInfo)) {
      return true;
    }
    else
      return false;

  }, [
    stateSelUniqueConns,
    stateBorLoanInfo,
    // stateBorrowerInfo,
    // stateLoanInfo,
    stateAssetInfo
  ]);


  //#endregion


  //#region 'Legal Tracker: load from selected from connection'
  //-------------------------

  const [stateLegalTrackerItems, setStateLegalTrackerItems] = useState<ANLegalTrackerItem[]>([]);


  useEffect(function loadLegalTrackerItems() {
    // when selected connection ids change (when using UI or when loading from saved AN), load associated legal tracker rows using single connection id
    let ignore = false;

    setTimeout(async () => {

      // make sure initial lookup to get Legal Tracker list info was successful
      if (!memoLegalTrackerEnabled) return;

      let connIds = stateCurANConnIDs;
      let connId = '';
      let items: ANLegalTrackerItem[] = [];

      if (connIds.length <= 0 || connIds.length > 1 || isNull(connIds[0])) {
        // make sure 1 and only 1 connection should be considered, and has a valid connection id
        // i.e. bulk ANs not supported
        setStateLegalTrackerItems([]);
        return;
      }
      else {
        connId = safeTrim(connIds[0]); // search for the first connection id
      }

      let data = await GraphDataService.getANLegalTrackerItems(accounts, instance, connId);

      if (data.httpStatus >= 400) {
        // hide this error from user, in case a connection id is no longer matching what was saved previously?
        // or if the current user does not have access to the legal tracker list
        // AppHelper.toastError(`Error searching for associated Legal Tracker records in SharePoint: Filter=ConnectionID='${connId}'; Msg=${data.httpStatusText}`);
        console.warn(`Error searching for associated Legal Tracker records in SharePoint: ConnectionID='${connId}'; Msg=${data.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error searching for associated Legal Tracker records: ConnectionID='${connId}'`, data);
        return;
      }

      if (data && data.value && data.value.length > 0) {
        items = data.value;
      }

      // filter Legal Tracker records: exclude when ReferenceCheck="Yes"
      // sort descending by id(created)
      // also only show top N records
      items = items.filter(x => !eq(x.fields.ReferenceCheck, "Yes"));
      items = _.sortBy(items, x => parseInt(x.id) * -1).slice(0, 25);

      if (!ignore) {
        setStateLegalTrackerItems(items);
      }

    }, 100);

    return () => { ignore = true; }
  }, [stateCurANConnIDs, memoLegalTrackerEnabled]);

  //#endregion


  //#region 'Historical ANs: load related ANs based on connection id'
  //-------------------------

  const [stateCompletedANItems, setStateCompletedANItems] = useState<CompletedAN[]>([]);


  useEffect(function loadCompletedANs() {
    let ignore = false;

    setTimeout(async () => {

      let connIds = stateCurANConnIDs;
      let connId = '';
      let items: CompletedAN[] = [];

      if (connIds.length <= 0 || connIds.length > 1 || isNull(connIds[0])) {
        // make sure 1 and only 1 connection should be considered, and has a valid connection id
        // i.e. bulk ANs not supported
        setStateCompletedANItems([]);
        return;
      }
      else {
        connId = safeTrim(connIds[0]); // search for the first connection id
      }

      let data = await GraphDataService.getCompletedANs(accounts, instance, connId);

      if (data.httpStatus >= 400) {
        // #todo should this error be hidden to user, in case a connection id is no longer matching what was saved previously?
        AppHelper.toastError(`Error searching for associated Completed ANs in SharePoint: ConnectionID='${connId}'; Msg=${data.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error searching for associated Completed ANs: ConnectionID='${connId}'`, data);
        return;
      }

      if (data && data.value && data.value.length > 0) {
        items = data.value;
      }

      // take top N latest CompletedANs
      items = _.sortBy(items, x => parseInt(x.id) * -1).slice(0, 5); // TOP 5

      if (!ignore) {
        setStateCompletedANItems(items);
      }

    }, 100);

    return () => { ignore = true; }
  }, [stateCurANConnIDs]);

  //#endregion


  //#region 'rich text fields (html)'
  //-------------------------

  const [statePropertyDetailsBgInfo, setStatePropertyDetailsBgInfo] = useState('');
  const onChangePropertyDetailsBgInfo = useCallback((evt: any, v?: string) => { setStatePropertyDetailsBgInfo(v || ''); }, []);

  const [stateProposal, setStateProposal] = useState('');
  const onChangeProposal = useCallback((evt: any, v?: string) => { setStateProposal(v || ''); }, []);

  const [stateDetailsTOC, setStateDetailsTOC] = useState('');
  const onChangeDetailsTOC = useCallback((evt: any, v?: string) => { setStateDetailsTOC(v || ''); }, []);

  const [statePros, setStatePros] = useState('');
  const onChangePros = useCallback((evt: any, v?: string) => { setStatePros(v || ''); }, []);

  const [stateSupportingTables, setStateSupportingTables] = useState('');
  const onChangeSupportingTables = useCallback((evt: any, v?: string) => { setStateSupportingTables(v || ''); }, []);

  const [stateRecommendation, setStateRecommendation] = useState(StaticData.htmlDefaultRecComments);
  const onChangeRecommendation = useCallback((evt: any, v?: string) => { setStateRecommendation(v || ''); }, []);

  const [stateRecComments, setStateRecComments] = useState('');
  const onChangeRecComments = useCallback((evt: any, v?: string) => { setStateRecComments(v || ''); }, []);

  //#endregion


  //#region 'sfs assessment fields'
  //-------------------------

  // mlots

  const [stateSFSComments, setStateSFSComments] = useState('');
  const onChangeSFSComments = useCallback((evt: any, v?: string) => { setStateSFSComments(v || ''); }, []);

  const [stateSFSReasonsWhyStmt, setStateSFSReasonsWhyStmt] = useState('');
  const onChangeSFSReasonsWhyStmt = useCallback((evt: any, v?: string) => { setStateSFSReasonsWhyStmt(v || ''); }, []);


  // ddls

  const [stateSelSfsAssessmentIAFCA, setStateSelSfsAssessmentIAFCA] = useState<string>('');
  const onChangeSfsAssessmentIAFCA = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentIAFCA(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentIO, setStateSelSfsAssessmentIO] = useState<string>('');
  const onChangeSfsAssessmentIO = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentIO(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentLTIO, setStateSelSfsAssessmentLTIO] = useState<string>('');
  const onChangeSfsAssessmentLTIO = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentLTIO(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentMFPB, setStateSelSfsAssessmentMFPB] = useState<string>('');
  const onChangeSfsAssessmentMFPB = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentMFPB(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentSettle, setStateSelSfsAssessmentSettle] = useState<string>('');
  const onChangeSfsAssessmentSettle = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentSettle(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentCOA, setStateSelSfsAssessmentCOA] = useState<string>('');
  const onChangeSfsAssessmentCOA = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentCOA(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentEOLT, setStateSelSfsAssessmentEOLT] = useState<string>('');
  const onChangeSfsAssessmentEOLT = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentEOLT(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentRA, setStateSelSfsAssessmentRA] = useState<string>('');
  const onChangeSfsAssessmentRA = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentRA(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentDWD, setStateSelSfsAssessmentDWD] = useState<string>('');
  const onChangeSfsAssessmentDWD = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentDWD(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentSM, setStateSelSfsAssessmentSM] = useState<string>('');
  const onChangeSfsAssessmentSM = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentSM(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentLIO, setStateSelSfsAssessmentLIO] = useState<string>('');
  const onChangeSfsAssessmentLIO = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentLIO(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentMTR, setStateSelSfsAssessmentMTR] = useState<string>('');
  const onChangeSfsAssessmentMTR = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentMTR(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentVSale, setStateSelSfsAssessmentVSale] = useState<string>('');
  const onChangeSfsAssessmentVSale = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentVSale(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentVSurr, setStateSelSfsAssessmentVSurr] = useState<string>('');
  const onChangeSfsAssessmentVSurr = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentVSurr(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentATR, setStateSelSfsAssessmentATR] = useState<string>('');
  const onChangeSfsAssessmentATR = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentATR(option ? option.key + '' : ''); };

  const [stateSelSfsAssessmentSARA, setStateSelSfsAssessmentSARA] = useState<string>('');
  const onChangeSfsAssessmentSARA = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelSfsAssessmentSARA(option ? option.key + '' : ''); };

  //#endregion


  //#region 'attachments'
  //-------------------------

  const [stateNewAtts, setStateNewAtts] = useState<FileUploadWithType[]>([]);
  const [stateDelAttIds, setStateDelAttIds] = useState<string[]>([]);

  function handleAttsUpdate(attType: string, newFiles: FileUpload[], delIds: string[]) {

    // for files to upload, group the new files to upload by attType (each Attachments component should have a different "Type", for each section, ex: "Supporting", "CES1")
    setStateNewAtts(p => {
      let t = [...p];
      let idx = t.findIndex(x => eq(attType, x.attType));

      if (idx < 0) {
        t = [...t, { attType: attType, files: newFiles }]; // concat
      }
      else {
        t[idx] = { attType: attType, files: newFiles }; // replace
      }

      return t;
    });

    // for files to delete, when they are marked for deleting, there is no "undeleting" (without a full page reload), so just concat the ids
    if (delIds.length > 0) {
      setStateDelAttIds(p => {
        let t = [...p];
        t = [...t, ...delIds];
        t = _.uniq(t);
        return t;
      });
    }
  }

  //#endregion


  //#region 'connection metrics fields (flat section)'
  //-------------------------

  // checkbox
  const [stateRequestMetricsFromCES, setStateRequestMetricsFromCES] = useState<boolean>(false);
  function onChangeRequestMetricsFromCES() { setStateRequestMetricsFromCES(p => !p); }


  // slot number fields
  const [stateCMCollectionsUW, setStateCMCollectionsUW] = useState('');
  const onChangeCMCollectionsUW = useCallback((evt: any, v?: string) => { setStateCMCollectionsUW(v || ''); }, []);

  const [stateCMCollectionsRevBP, setStateCMCollectionsRevBP] = useState('');
  const onChangeCMCollectionsRevBP = useCallback((evt: any, v?: string) => { setStateCMCollectionsRevBP(v || ''); }, []);

  const [stateCMMultipleUW, setStateCMMultipleUW] = useState('');
  const onChangeCMMultipleUW = useCallback((evt: any, v?: string) => { setStateCMMultipleUW(v || ''); }, []);

  const [stateCMMultipleRevBP, setStateCMMultipleRevBP] = useState('');
  const onChangeCMMultipleRevBP = useCallback((evt: any, v?: string) => { setStateCMMultipleRevBP(v || ''); }, []);

  const [stateCMIRRUW, setStateCMIRRUW] = useState('');
  const onChangeCMIRRUW = useCallback((evt: any, v?: string) => { setStateCMIRRUW(v || ''); }, []);

  const [stateCMIRRRevBP, setStateCMIRRRevBP] = useState('');
  const onChangeCMIRRRevBP = useCallback((evt: any, v?: string) => { setStateCMIRRRevBP(v || ''); }, []);

  const [stateCMWALUW, setStateCMWALUW] = useState('');
  const onChangeCMWALUW = useCallback((evt: any, v?: string) => { setStateCMWALUW(v || ''); }, []);

  const [stateCMWALRevBP, setStateCMWALRevBP] = useState('');
  const onChangeCMWALRevBP = useCallback((evt: any, v?: string) => { setStateCMWALRevBP(v || ''); }, []);


  // deltas - calculated
  let memoDeltaCMColl = useMemo(() => {
    return AppHelper.calcDelta(stateCMCollectionsRevBP, stateCMCollectionsUW);
  }, [stateCMCollectionsUW, stateCMCollectionsRevBP]);

  let memoDeltaCMMult = useMemo(() => {
    return AppHelper.calcDelta(stateCMMultipleRevBP, stateCMMultipleUW);
  }, [stateCMMultipleUW, stateCMMultipleRevBP]);

  let memoDeltaCMIrr = useMemo(() => {
    return AppHelper.calcDelta(stateCMIRRRevBP, stateCMIRRUW);
  }, [stateCMIRRUW, stateCMIRRRevBP]);

  let memoDeltaCMWal = useMemo(() => {
    return AppHelper.calcDelta(stateCMWALRevBP, stateCMWALUW);
  }, [stateCMWALUW, stateCMWALRevBP]);

  //#endregion


  //#region 'DDL - Case Manager'
  //-------------------------

  const [stateSelCaseManagerUser, setStateSelCaseManagerUser] = useState<string>('');
  const onCaseManagerUserChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelCaseManagerUser(option ? option.key + '' : ''));


  const memoAllCaseManagerUsers = useMemo<SimpleUserInfo[]>(() => {
    // no sorting/ordering needed, rely on user input in SPListItem
    let col: SimpleUserInfo[] = [];

    stateAllANUsers.filter(o => eq(o.fields.Title, 'CES1')).forEach((o, i) => {
      if (i <= 0) {
        // only process first returned listitem
        let emails = safeTrim(o.fields.Email);
        let names = safeTrim(o.fields.Names);

        if (emails.length > 0) {
          // the cols Email and Names are filled with concat names and emails, comma or semicolon separated, the order is critical, email is mandatory, name is optional
          let emailParts = GenUtil.strToList(emails, ";");
          let nameParts = GenUtil.strToList(names, ";");

          for (let i = 0; i < emailParts.length; i++) {
            try {
              let _email = emailParts[i];
              let _name = '';
              try { _name = NVL(nameParts[i], emailParts[i]); } catch (error) { _name = _email; } // likely indexing exception here, loop using emailparts length, nameparts might be fewer, email is important, name is "nice"
              col = [...col, {
                email: _email,
                name: _name
              }];
            } catch (error) {
              console.error('ERROR @ memoAllCaseManagerUsers', i, names, emails, nameParts, emailParts, error);
            }
          }
        }
      }
    });

    return col;
  }, [stateAllANUsers]);


  const memoCaseManagerOptions = useMemo<any[]>(() => {
    let col = _.sortBy([...memoAllCaseManagerUsers], o => NVL(o.name, o.email));
    return col.map((o, i) => {
      return {
        key: o.email,
        text: o.name
      };
    });
  }, [memoAllCaseManagerUsers]);

  //#endregion


  //#region 'DDL - Servicer Submitters'
  //-------------------------

  const [stateSelServicerSubmitter, setStateSelServicerSubmitter] = useState<string>('');
  const onServicerSubmitterChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelServicerSubmitter(option ? option.key + '' : ''));


  const memoAllServicerSubmitters = useMemo<SimpleUserInfo[]>(() => {
    // get from splist, no sorting needed, already sorted on pageload
    let col: SimpleUserInfo[] = [];

    stateAllANUsers.filter(o => eq(o.fields.Title, "CabotReviewers")).forEach(o => {
      let u = o.fields.Users;
      u.forEach(o2 => {
        let email = safeTrim(o2.Email);
        let lookupId = safeTrim(o2.LookupId);
        let name = NVL(o2.LookupValue, o2.Email);
        if (!isNull(name) && !isNull(lookupId))
          col = [...col, {
            email: email,
            name: name,
            lookupId: lookupId
          }];
      });
    });

    return col;
  }, [stateAllANUsers]);


  const memoSelServicerSubmitter = useMemo<SimpleUserInfo | undefined>(() => {
    // return the selected servicer submitter, complex object
    if (memoAllServicerSubmitters.length <= 0 || isNull(stateSelServicerSubmitter))
      return undefined;
    else
      return memoAllServicerSubmitters.find(x => eq(stateSelServicerSubmitter, x.lookupId));
  }, [memoAllServicerSubmitters, stateSelServicerSubmitter]);


  const memoServicerSubmitterOptions = useMemo<any[]>(() => {
    let col = _.sortBy([...memoAllServicerSubmitters], o => NVL(o.name, o.email));
    return col.map((o, i) => {
      return {
        key: o.lookupId || '',
        text: o.name
      };
    });
  }, [memoAllServicerSubmitters]);

  //#endregion


  //#region 'conditionally show each 1:N top section'
  //-------------------------

  // NOTE: connection, borrower, loan, asset, and supporting details is always vis 
  // there is no special portfolio or reo situations
  // make sure Project is selected before showing them, since project is required for connection searcher
  // also, force user to choose ANType and ANSubType, these are needed in some cases, but make it always mandatory to show these sections too
  // ANType/SubType is used in the following sub components: ALM1, ALM4, ProposedCosts; so make sure these sections are hidden until project/antype/ansubtype is selected too


  const memoShowSectionConnectionDetails = useMemo<boolean>(() => {
    // show when proj and ansubtype selected
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANSubTypeObject]);


  const memoShowSectionBorrowerInfo = useMemo<boolean>(() => {
    // show when proj and ansubtype selected
    // hide if no connection loaded
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null || stateSelUniqueConns.length <= 0) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANSubTypeObject, stateSelUniqueConns]);


  const memoShowSectionOtherNPLevel = useMemo<boolean>(() => {
    // this is the ARA section, it depends on the BorLoan section, which depends on connection, which depends on Project/ANType/ANSubType
    // always show this, it has an internal warning when no borloans are found
    // hide if no connection loaded
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null || stateSelUniqueConns.length <= 0) return false;
    else return memoANSubTypeLUOtherNPLevel;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUOtherNPLevel, stateSelUniqueConns]);


  const memoShowSectionAssetInfo = useMemo<boolean>(() => {
    // show when proj and ansubtype selected
    // hide if no connection loaded
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null || stateSelUniqueConns.length <= 0) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANSubTypeObject, stateSelUniqueConns]);


  const memoShowSectionSfsAssessment = useMemo<boolean>(() => {
    // show when top section SFS is "Yes"
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else return GenUtil.safeToBool(stateSelSFSInfoAvailable);
  }, [stateSelSFSInfoAvailable]);


  // const memoShowSectionProposedCosts = true; // always vis

  // const memoShowSectionSupportingTables = true; // always vis

  // const memoShowSectionRecAndSupportDoc = true; // always vis

  // const memoShowSectionComments = true; // always vis


  const memoShowSectionConnectionMetrics = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else return memoANSubTypeLUConnectionMetrics;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUConnectionMetrics]);


  const memoShowSectionLitigationMatrix = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else return memoANSubTypeLULitigationMatrix;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLULitigationMatrix]);


  const memoShowSectionAlm3 = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else if (stateAssetOptions.length <= 0) return false; // override the lookup, if no assets avail for selection then hide all ALM sections
    else return memoANSubTypeLUAlm03;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUAlm03, stateAssetOptions]);


  const memoShowSectionAlm2 = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else if (stateAssetOptions.length <= 0) return false; // override the lookup, if no assets avail for selection then hide all ALM sections
    else return memoANSubTypeLUAlm2;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUAlm2, stateAssetOptions]);


  const memoShowSectionAlm1 = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else if (stateAssetOptions.length <= 0) return false; // override the lookup, if no assets avail for selection then hide all ALM sections
    else return memoANSubTypeLUAlm1;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUAlm1, stateAssetOptions]);


  const memoShowSectionAlm4 = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (stateSelProjectItem == null || stateSelANSubTypeObject == null) return false;
    else if (stateAssetOptions.length <= 0) return false; // override the lookup, if no assets avail for selection then hide all ALM sections
    else return memoANSubTypeLUAlm4;
  }, [stateSelProjectItem, stateSelANSubTypeObject, memoANSubTypeLUAlm4, stateAssetOptions]);


  const memoShowSectionProposalBreakdown = useMemo<boolean>(() => {
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else return memoANSubTypeLUProposalBreakdown;
  }, [memoANSubTypeLUProposalBreakdown]);


  //#endregion


  //#region 'form permissions: using email address comparisons'
  //-------------------------

  // CABOT1
  //lookup("AN Users", "Title", "Servicers", "Email")
  const memoPermsAppL1Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "Servicers")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";"); // remove spaces, consolidate ";"
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserAppL1 = useMemo<boolean>(() => {
    // NOTE: can be any user identified as a servicer, not just the user(servicer) that created the request
    return contains(`;${memoPermsAppL1Users};`, memoCurUsername);
  }, [memoPermsAppL1Users, memoCurUsername]);


  // CES1
  //lookup("AN Users", "Title", "CES1", "Email")
  const memoPermsApp6Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "CES1")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp6 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp6Users};`, memoCurUsername);
  }, [memoPermsApp6Users, memoCurUsername]);


  // SRV
  //lookup("AN Users", "Title", "Servicers", "Email")
  // NOTE: as of 8-22-23 this role comes from a different row of users, so that "Servicers" are different at AppL1 and App3
  const memoPermsApp3Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "CabotReviewers")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp3 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp3Users};`, memoCurUsername);
  }, [memoPermsApp3Users, memoCurUsername]);


  // RPE1
  //lookup("AN Users", "Title", "RPE1", "Email")
  const memoPermsApp8Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "RPE1")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp8 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp8Users};`, memoCurUsername);
  }, [memoPermsApp8Users, memoCurUsername]);


  // PSI
  //lookup("AN Users", "Title", "PSI", "Email")
  const memoPermsApp4Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "PSI")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp4 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp4Users};`, memoCurUsername);
  }, [memoPermsApp4Users, memoCurUsername]);


  // CES2
  //lookup("AN Users", "Title", "CES2", "Email")
  const memoPermsApp1Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "CES2")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp1 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp1Users};`, memoCurUsername);
  }, [memoPermsApp1Users, memoCurUsername]);


  // CGI1
  //lookup("AN Users", "Title", "CGI1", "Email")
  const memoPermsApp7Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "CGI1")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp7 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp7Users};`, memoCurUsername);
  }, [memoPermsApp7Users, memoCurUsername]);


  // CGI2
  //lookup("AN Users", "Title", "CGI2", "Email")
  const memoPermsApp2Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "CGI2")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp2 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp2Users};`, memoCurUsername);
  }, [memoPermsApp2Users, memoCurUsername]);


  // LTH
  //lookup("AN Users", "Title", "LTH", "Email")
  const memoPermsApp5Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "LTH")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp5 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp5Users};`, memoCurUsername);
  }, [memoPermsApp5Users, memoCurUsername]);


  // EO
  //lookup("AN Users", "Title", "EO", "Email")
  const memoPermsApp9Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "EO")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp9 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp9Users};`, memoCurUsername);
  }, [memoPermsApp9Users, memoCurUsername]);


  // RPE2
  //lookup("AN Users", "Title", "RPE2", "Email")
  const memoPermsApp10Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => eq(x.fields.Title, "RPE2")).map(x => safeTrim(x.fields.Email).replace(/,/ig, ";").replace(/(\[|\])/ig, ""));
    let s = col.join(";");
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, "").replace(/;+/ig, ";");
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp10 = useMemo<boolean>(() => {
    return contains(`;${memoPermsApp10Users};`, memoCurUsername);
  }, [memoPermsApp10Users, memoCurUsername]);


  const memoPermsIsCurUserAnyRole = useMemo<boolean>(() => {
    // check if current user is a member of ANY role
    return memoPermsIsCurUserApp10 || memoPermsIsCurUserApp1 || memoPermsIsCurUserApp2 || memoPermsIsCurUserApp3 ||
      memoPermsIsCurUserApp4 || memoPermsIsCurUserApp5 || memoPermsIsCurUserApp6 || memoPermsIsCurUserApp7 ||
      memoPermsIsCurUserApp8 || memoPermsIsCurUserApp9 || memoPermsIsCurUserAppL1;
  }, [
    memoPermsIsCurUserApp10, memoPermsIsCurUserApp1, memoPermsIsCurUserApp2, memoPermsIsCurUserApp3,
    memoPermsIsCurUserApp4, memoPermsIsCurUserApp5, memoPermsIsCurUserApp6, memoPermsIsCurUserApp7,
    memoPermsIsCurUserApp8, memoPermsIsCurUserApp9, memoPermsIsCurUserAppL1
  ]);

  //#endregion


  //#region 'workflow form fields'
  //-------------------------

  /**
   * 
      AppL1, Cabot1
      App6, CES
      App3, SVR
      App8, RPE1
      App4, PSI
      App1, CES2
      App7, CGI1
      App2, CGI2
      App5, LTH
      App9, EO
      App10, RPE2


      APPL1 - CABOT1 - "CABOT REVIEWER 1"
      APP6 - CES1 - "CES - Case Manager"
      APP3 - SVR - "SERVICER"
      APP8 - RPE1 - "REGULATED PROM ENTITY"
      APP4 - PSI - "PSI"
      APP1 - CES2 - "CES - Recommender"
      APP7 - CGI1 - "CGI - Signature 1"
      APP2 - CGI2 - "CGI - Signature 2"
      APP5 - LTH - "LegalTitleHolder"
      APP9 - EO - "ECONOMIC OWNER"
      APP10 - RPE2 - "REGULATED PROM ENTITY"
      
   * 
   */


  //-------------------------
  // special fields: RPE1 Override
  const [stateSelApp8OVR, setStateSelApp8OVR] = useState<string>("");
  const onChangeApp8OVR = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp8OVR(option ? option.key + "" : ""); }

  // special field: KeyDecision Override
  const [stateSelApp8KeyDecOvr, setStateSelApp8KeyDecOvr] = useState<string>("");
  const onChangeApp8KeyDecOvr = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp8KeyDecOvr(option ? option.key + "" : ""); }


  //-------------------------
  // bbp fields
  const [stateSelApp6BBP, setStateSelApp6BBP] = useState<string>("");
  const onChangeApp6BBP = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp6BBP(option ? option.key + "" : ""); }

  const [stateSelApp7BBP, setStateSelApp7BBP] = useState<string>("");
  const onChangeApp7BBP = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp7BBP(option ? option.key + "" : ""); }


  //-------------------------
  // important memos/calculations

  const memoCCMAFlag = useMemo<boolean>(() => {
    // from legacy code, if a loan is added to bor/loan section and is active, and has RegulatoryStatus = "CCMA", then return "Yes"
    // from FOH (Feidhlim O'Hanlon): "presense of a CCMA loan overrrides a NO to a YES for ANType:KeyDecision"
    for (const bo of stateBorLoanInfoItems) {
      for (const lo of bo.col_LoanItems) {
        if (lo.cv_IsActive && eq(lo.rpt_RegulatoryStatus, "CCMA")) {
          return true;
        }
      }
    }
    return false;
  }, [stateBorLoanInfoItems]);


  const memoKeyDecision = useMemo<boolean>(() => {
    // get KD from ANType list field, if false then try CCMA override, else return "No"
    let kd = false;

    if (memoANSubTypeLUKeyDecision)
      kd = true;
    else if (memoCCMAFlag)
      kd = true;

    if (!inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft, StaticData.wfStatusCABOT1) && !isNull(stateSelApp8KeyDecOvr)) {
      // once this field is set use this value instead, as it is an override on keydecision
      // also do not consider using it until the form has been submitted to CES1 (and thereafter)
      kd = GenUtil.safeToBool(stateSelApp8KeyDecOvr);
    }

    return kd;
  }, [memoANSubTypeLUKeyDecision, memoCCMAFlag, memoFormStatus, stateSelApp8KeyDecOvr]);


  const memoALMTypeNum = useMemo<string>(() => {
    // this is confirmed OK, matches legacy
    let tmp = "";

    if (memoANSubTypeLUAlm1)
      tmp = "1";
    else
      tmp = "None";

    if (memoANSubTypeLUAlm2)
      tmp = "2;" + tmp;

    if (memoANSubTypeLUAlm03) // legacy has "AssetMatrix3", which points to "c6jb", which is "Asset Level Metrics", which is "memoANSubTypeLUAlm0"
      tmp = "3;" + tmp;

    if (memoANSubTypeLUAlm4)
      tmp = "4;" + tmp;

    return tmp;
  }, [memoANSubTypeLUAlm1, memoANSubTypeLUAlm2, memoANSubTypeLUAlm03, memoANSubTypeLUAlm4]);


  const memoOldNew = "new"; // will always be "new" for this app, "old" was before a prior release in legacy nintex app


  const memoWorkflowPath = useMemo<number>(() => {
    // get workflow path

    /**
     * adm_WorkflowPath => js_WorkflowPath
     * default value in nintex is "0"
     * it is set in jquery, only when the submit action is called
     * its is read from dom in submit function, then set again shortly after, but not refreshed, so the initial value is used
     * it will be "0" when saving new or draft, but saved with real val when actually submitted the first time
     * 
     * based on:
     * 
     * Regulated:
     * simple lookup
     * <Formula>lookup("Entities", "Projects", {Control:cv_Entities}, "Regulated")</Formula>
     * 
     * IntExt:
     * simple lookup
     * <Formula>lookup("Entities", "Projects", {Control:cv_Entities}, "IntExt")</Formula>
     * 
     * KeyDecision:
     * comes from ANType table column, => if (currentKD == "" || currentKD == null || CCMAFlag == "No" || KeyDecision != currentKD) { NWF$('#' + js_KD).val(KeyDecision); }
     * also in adding loan function, building supporting details col, iterating through loans, if RegulatoryStatus = "CCMA" then set to Yes
     * also in nintex from this formula: <Formula>if((isNullOrEmpty({Control:dd_AdvisoryNoteType}) || isNullOrEmpty({Control:adm_ANST})) && contains({Control:cv_FormStatus}, "Draft"), "", if(contains({Control:adm_cv_KD}, "No") && contains({Control:adm_CV_LoanTypeValue}, "No"), "No", "Yes"))</Formula>
     *   skip this one, not sure exactly what its trying to do
     * 
     * oldNew:
     * simple formula, always "new" for this development
     * <Formula>if(convertToDate({ItemProperty:DateSubmitted}) < convertToDate({Control:UserFormVariable/ALMDate/"06/30/2021"}), "old", "new")</Formula>
     * 
     */

    if (inn(memoFormStatus, StaticData.wfStatusDraft)) {
      return 0;
    }

    const regulated: boolean = memoProjLURegulated;
    const intExt: string = memoProjLUIntExt;
    const keyDecision: boolean = memoKeyDecision;

    let wfPath: number = 0;

    if (!regulated) {
      wfPath = 1;

    } else if (eq(intExt, "Internal") && keyDecision) {
      wfPath = 2;

    } else if (eq(intExt, "Internal") && !keyDecision && eq(memoOldNew, "new")) {
      wfPath = 3;

    } else if (eq(intExt, "Internal") && !keyDecision && eq(memoOldNew, "old")) {
      // path deprecated!
      wfPath = 3;

    } else if (eq(intExt, "External") && keyDecision) {
      wfPath = 4;

    } else if (eq(intExt, "External") && !keyDecision && eq(memoOldNew, "new")) {
      wfPath = 5;

    } else if (eq(intExt, "External") && !keyDecision && eq(memoOldNew, "old")) {
      // path deprecated!
      wfPath = 5;
    }

    return wfPath;
  }, [memoFormStatus, memoProjLURegulated, memoProjLUIntExt, memoKeyDecision]);


  const memoShowBBP = useMemo<boolean>(() => {
    // NOTE: latest 7-7-23, always show BBP from now on, since RPE1 could change the workflow path midway through workflow
    // NOTE: latest 7-11-23, BBP is now shown based on ANType lookup field
    if (inn(memoWorkflowPath, 0))
      return false;
    else
      return memoANSubTypeLUBelowBpCheck;
  }, [memoWorkflowPath, memoANSubTypeLUBelowBpCheck]);

  const memoHideBBP = !memoShowBBP;


  //-------------------------
  // special field: RPE1 Override
  // NOTE: "regulated entity to check" or "regulated entity to override", when Checked/True/Yes then RPE2 is needed

  const memoShowApp8OVR = useMemo<boolean>(() => {
    // RPE1 override is only relevant for workflow paths 2 & 4
    // return inn(memoWorkflowPath, 2, 4);
    // NOTE: as of 7-7-23, never show this control, its replaced by App8KeyDecOvr
    return false;
  }, [memoWorkflowPath]);


  //-------------------------
  // special field: KeyDecision Override
  // NOTE: as of 7-7-23, instead of using App8OVR, lets use these fields at RPE1/app8 to allow user rpe1 to switch the keydecision value
  // better to use a new field than to reuse the App8OVR, since that field is heavily used to change the Action DDLs, and workflow routing
  // the UI for this field will be identical to the app8ovr, but internally the answer is saved in a diff field, and the impact is only to switch the workflowpath

  const memoShowApp8KeyDecOvr = useMemo<boolean>(() => {
    // this override (for keydecision, thus changing workflowpath) will be enabled for all but workflow path 1
    return inn(memoWorkflowPath, 2, 3, 4, 5);
  }, [memoWorkflowPath]);


  //-------------------------
  // comment mlot fields (for all sections)

  const [stateAppL1Comment, setStateAppL1Comment] = useState('');
  const onChangeAppL1Comment = useCallback((evt: any, v?: string) => { setStateAppL1Comment(v || ''); }, []);

  // *deprecated
  // const [stateAppL2Comment, setStateAppL2Comment] = useState('');
  // const onChangeAppL2Comment = useCallback((evt: any, v?: string) => { setStateAppL2Comment(v || ''); }, []);

  const [stateApp6Comment, setStateApp6Comment] = useState('');
  const onChangeApp6Comment = useCallback((evt: any, v?: string) => { setStateApp6Comment(v || ''); }, []);

  const [stateApp3Comment, setStateApp3Comment] = useState('');
  const onChangeApp3Comment = useCallback((evt: any, v?: string) => { setStateApp3Comment(v || ''); }, []);

  const [stateApp8Comment, setStateApp8Comment] = useState('');
  const onChangeApp8Comment = useCallback((evt: any, v?: string) => { setStateApp8Comment(v || ''); }, []);

  const [stateApp4Comment, setStateApp4Comment] = useState('');
  const onChangeApp4Comment = useCallback((evt: any, v?: string) => { setStateApp4Comment(v || ''); }, []);

  const [stateApp1Comment, setStateApp1Comment] = useState('');
  const onChangeApp1Comment = useCallback((evt: any, v?: string) => { setStateApp1Comment(v || ''); }, []);

  const [stateApp7Comment, setStateApp7Comment] = useState('');
  const onChangeApp7Comment = useCallback((evt: any, v?: string) => { setStateApp7Comment(v || ''); }, []);

  const [stateApp2Comment, setStateApp2Comment] = useState('');
  const onChangeApp2Comment = useCallback((evt: any, v?: string) => { setStateApp2Comment(v || ''); }, []);

  const [stateApp5Comment, setStateApp5Comment] = useState('');
  const onChangeApp5Comment = useCallback((evt: any, v?: string) => { setStateApp5Comment(v || ''); }, []);

  const [stateApp9Comment, setStateApp9Comment] = useState('');
  const onChangeApp9Comment = useCallback((evt: any, v?: string) => { setStateApp9Comment(v || ''); }, []);

  const [stateApp10Comment, setStateApp10Comment] = useState('');
  const onChangeApp10Comment = useCallback((evt: any, v?: string) => { setStateApp10Comment(v || ''); }, []);


  //-------------------------
  // action fields

  //------------
  const [stateSelAppL1Action, setStateSelAppL1Action] = useState<string>('');
  const onChangeAppL1Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelAppL1Action(option ? option.key + '' : ''); };

  const memoAppL1ActionChoices = useMemo(() => {
    let actions = [];

    if (contains(memoANNote.WFStatus, "Requests Additional Information from")) {
      actions.push(StaticData.wfActionProvideAddtlInfo);
    }
    else {
      actions.push(StaticData.wfActionRecommend);
    }

    actions.push(StaticData.wfActionWithdraw);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  // *deprecated
  // const [stateSelAppL2Action, setStateSelAppL2Action] = useState<string>('');
  // const onChangeAppL2Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelAppL2Action(option ? option.key + '' : ''); };

  // const memoAppL2ActionChoices = useMemo(() => {
  //   let actions = [];
  //   actions.push(StaticData.wfActionRecommend);
  //   actions.push(StaticData.wfActionWithdraw);
  //   actions.push(StaticData.wfActionRequestAddtlInfo);
  //   return actions.map(o => { return { key: o, text: o }; });
  // }, [memoANNote]);


  //------------
  const [stateSelApp6Action, setStateSelApp6Action] = useState<string>('');
  const onChangeApp6Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp6Action(option ? option.key + '' : ''); };

  const memoApp6ActionChoices = useMemo(() => {
    let actions = [];

    //#todo may need to change this for changes to Servicer, making Servicer always required
    let hide1 = false;
    if (!eq(memoANNote.WFStatus, "Request Additional Information from CES - Case Manager") &&
      !eq(memoANNote.WFStatus, "Servicer Requests Additional Information from CES")) {
      hide1 = true;
    }

    if (!hide1) {
      actions.push(StaticData.wfActionProvideAddtlInfo);
    }
    else {
      // hide1 = false;
      // if (eq(memoANNote.WFStatus, "Additional Information from CES - Case Manager") ||
      //   eq(memoANNote.WFStatus, "Request Additional Information from CES - Case Manager"))
      //   hide1 = true;

      // if (!hide1) {
      //   actions.push(StaticData.wfActionRecommend);
      // }

      // NOTE: either "provide additional info", or "recommend", only 2 options
      actions.push(StaticData.wfActionRecommend);

      // NOTE: updated 6/15/23, add this option as per dev requests, comments are required for this scenario, moves it to next step as usual
      actions.push(StaticData.wfActionNotSupported);
    }

    actions.push(StaticData.wfActionRequestAddtlInfo);

    // 6/14/23 reject is only applicable after CES2
    // if (!memoKeyDecision) // Reject is not available when KeyDecision=Yes
    //   actions.push(StaticData.wfActionReject);

    actions.push(StaticData.wfActionReassign);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote, memoKeyDecision]);


  //------------
  const [stateSelApp3Action, setStateSelApp3Action] = useState<string>('');
  const onChangeApp3Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp3Action(option ? option.key + '' : ''); };

  const memoApp3ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (contains(memoANNote.WFStatus, "Requests Additional Information") ||
      contains(memoANNote.WFStatus, "Request Additional Information from Servicer")) {
      hide1 = true;
    }

    if (!hide1) {
      actions.push(StaticData.wfActionSubmit);
    }
    else {
      actions.push(StaticData.wfActionSubmitAddtlInfo);
    }

    actions.push(StaticData.wfActionWithdraw);
    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  const [stateSelApp8Action, setStateSelApp8Action] = useState<string>('');
  const onChangeApp8Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp8Action(option ? option.key + '' : ''); };

  const memoApp8ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Additional Information"))
      hide1 = true;
    if (contains(memoANNote.WFStatus, "Servicer Provides Additional Information to Regulated Entity"))
      hide1 = true;

    if (!hide1) {
      actions.push(StaticData.wfActionProvideAddtlInfo);
    }
    else {
      // special situation, change action text based on RPE1 override choice
      // only when wfpath is 2 or 4
      if (inn(memoWorkflowPath, 2, 4)) {
        if (GenUtil.safeToBool(stateSelApp8OVR)) {
          actions.push(StaticData.wfActionRecommend);
        }
        else {
          // NOTE: as of 7-7-23 change, this branch shouldn't be reachable, stateSelApp8OVR will always be "Yes"
          actions.push(StaticData.wfActionSignAndApprove);
        }
      }
      else {
        actions.push(StaticData.wfActionSeeksAdvice); // "Seeks" with "s"
      }
    }

    // 6/14/23 reject is only applicable after CES2
    // actions.push(StaticData.wfActionReject);

    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote, memoWorkflowPath, stateSelApp8OVR]);


  //------------
  const [stateSelApp4Action, setStateSelApp4Action] = useState<string>('');
  const onChangeApp4Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp4Action(option ? option.key + '' : ''); };

  const memoApp4ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Additional Information from PSI"))
      hide1 = true;

    if (!hide1)
      actions.push(StaticData.wfActionProvideAddtlInfo);
    else
      actions.push(StaticData.wfActionSeekAdvice); // "Seek" without "s"

    // 6/14/23 reject is only applicable after CES2
    // actions.push(StaticData.wfActionReject);

    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  const [stateSelApp1Action, setStateSelApp1Action] = useState<string>('');
  const onChangeApp1Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp1Action(option ? option.key + '' : ''); };

  const memoApp1ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Requests Additional Information"))
      hide1 = true;

    if (!hide1)
      actions.push(StaticData.wfActionProvideAddtlInfo);
    else
      actions.push(StaticData.wfActionSignAndRecommend);

    // 6/14/23 reject is only applicable after CES2
    // actions.push(StaticData.wfActionReject);

    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  const [stateSelApp7Action, setStateSelApp7Action] = useState<string>('');
  const onChangeApp7Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp7Action(option ? option.key + '' : ''); };

  const memoApp7ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Requests Additional Information"))
      hide1 = true;

    if (!hide1)
      actions.push(StaticData.wfActionProvideAddtlInfo);
    else
      actions.push(StaticData.wfActionSignAndRecommend);

    // NOTE: dd_App7Action01 sits in the middle layer, on top of dd_App7Action02, and does not have a rule to hide it, so it will always effectively hide dd_App7Action02, and dd_App7Action02 has action="Sign and approve", so this action never added to list
    //actions.push(StaticData.wfActionSignAndApprove);

    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReject);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  const [stateSelApp2Action, setStateSelApp2Action] = useState<string>('');
  const onChangeApp2Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp2Action(option ? option.key + '' : ''); };

  const memoApp2ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Requests Additional Information from CGI"))
      hide1 = true;

    if (!hide1) {
      actions.push(StaticData.wfActionProvideAddtlInfo);
    }
    else {
      let considerSkipRPE2: boolean = memoShowApp8OVR;
      let skipRPE2: boolean = !GenUtil.safeToBool(stateSelApp8OVR);

      let app7BBP = GenUtil.safeToBool(memoANNote.App7BBP);

      if (inn(memoWorkflowPath, 1, 3, 5)) {
        if (app7BBP)
          actions.push(StaticData.wfActionSignAndRecommend);
        else
          actions.push(StaticData.wfActionSignAndApprove);
      }
      else if (inn(memoWorkflowPath, 2)) {
        if (considerSkipRPE2 && skipRPE2) {
          // NOTE: as of 7-7-23 change, this branch shouldn't be reachable
          actions.push(StaticData.wfActionSignAndApprove);
        }
        else
          actions.push(StaticData.wfActionSignAndRecommend);
      }
      else if (inn(memoWorkflowPath, 4)) {
        if (app7BBP)
          actions.push(StaticData.wfActionSignAndRecommend);
        else {
          if (considerSkipRPE2 && skipRPE2) {
            // NOTE: as of 7-7-23 change, this branch shouldn't be reachable
            actions.push(StaticData.wfActionSignAndApprove);
          }
          else
            actions.push(StaticData.wfActionSignAndRecommend);
        }
      }
    }

    actions.push(StaticData.wfActionReject);
    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote, memoWorkflowPath, memoShowApp8OVR, stateSelApp8OVR]);


  //------------
  const [stateSelApp5Action, setStateSelApp5Action] = useState<string>('');
  const onChangeApp5Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp5Action(option ? option.key + '' : ''); };

  const memoApp5ActionChoices = useMemo(() => {
    let actions = [];
    actions.push(StaticData.wfActionSignAndApprove);
    actions.push(StaticData.wfActionReject);
    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);
    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  const [stateSelApp9Action, setStateSelApp9Action] = useState<string>('');
  const onChangeApp9Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp9Action(option ? option.key + '' : ''); };

  const memoApp9ActionChoices = useMemo(() => {
    let actions = [];

    let hide1 = false;
    if (!contains(memoANNote.WFStatus, "Requests Additional Information"))
      hide1 = true;

    if (!hide1) {
      actions.push(StaticData.wfActionProvideAddtlInfo);
    }
    else {
      let considerSkipRPE2: boolean = memoShowApp8OVR;
      let skipRPE2: boolean = !GenUtil.safeToBool(stateSelApp8OVR);

      if (inn(memoWorkflowPath, 4)) {
        if (considerSkipRPE2 && skipRPE2) {
          // NOTE: as of 7-7-23 change, this branch shouldn't be reachable
          actions.push(StaticData.wfActionSignAndApprove);
        }
        else
          actions.push(StaticData.wfActionSignAndRecommend);
      }
      else {
        actions.push(StaticData.wfActionSignAndApprove);
      }
    }

    actions.push(StaticData.wfActionReject);
    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote, memoWorkflowPath, memoShowApp8OVR, stateSelApp8OVR]);


  //------------
  const [stateSelApp10Action, setStateSelApp10Action] = useState<string>('');
  const onChangeApp10Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp10Action(option ? option.key + '' : ''); };

  const memoApp10ActionChoices = useMemo(() => {
    let actions = [];
    actions.push(StaticData.wfActionSignAndApprove);
    actions.push(StaticData.wfActionRequestAddtlInfo);
    actions.push(StaticData.wfActionReject);
    actions.push(StaticData.wfActionReturnToCES);
    actions.push(StaticData.wfActionReturnToServicer);
    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //------------
  // useful memo to get the single DDL action selected by user
  // NOTE: action DDL values are NOT restored on pageload, they have to be selected by user, and are used to determine form/approval submission routing
  const memoSelAppActionOverall = useMemo<string>(() => {
    return NVL(stateSelAppL1Action,
      stateSelApp6Action, stateSelApp3Action, stateSelApp8Action,
      stateSelApp4Action, stateSelApp1Action, stateSelApp7Action,
      stateSelApp2Action, stateSelApp5Action, stateSelApp9Action,
      stateSelApp10Action);
  }, [
    stateSelAppL1Action,
    stateSelApp6Action, stateSelApp3Action, stateSelApp8Action,
    stateSelApp4Action, stateSelApp1Action, stateSelApp7Action,
    stateSelApp2Action, stateSelApp5Action, stateSelApp9Action,
    stateSelApp10Action
  ]);

  //#endregion


  //#region 'workflow section show/hide and enable/disable'
  //-------------------------

  // AppL1/Cabot1
  const memoWFSectionHideAppL1 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: is_new_mode
    if (memoIsNewItem) return true;
    // hide: contains(viewedlink1, "0")
    else if (contains(memoANNote.ViewedLink1, "0")) return true;

    // NOTE: updated 6/15/23, always show CABOT1 after it was set to viewed
    // // hide: not(contains(toLower(adm_ServicersEmails), toLower(curuseremail)))
    // else if (!memoPermsIsCurUserApp3) return true;
    // // hide: not(contains(formstatus, "CABOT"))
    // //   NOTE: this branch uses contains match so will match CABOT1 and CABOT2
    // else if (!contains(memoFormStatus, "CABOT")) return true;

    else return false;
  }, [memoANNote, memoIsNewItem]);

  const memoWFSectionDisableAppL1 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "CABOT1"))
    if (!contains(memoFormStatus, "CABOT1")) return true;
    // NOTE: updated 6/15/23, add the perms check here
    else if (!memoPermsIsCurUserAppL1) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserAppL1]);


  // App6/CES
  const memoWFSectionHideApp6 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: is_new_mode
    if (memoIsNewItem) return true;
    // hide: contains(viewedces1, "0")
    else if (contains(memoANNote.ViewedCES1, "0")) return true;
    else return false;
  }, [memoANNote, memoIsNewItem]);

  const memoWFSectionDisableApp6 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "CES1"))
    if (!contains(memoFormStatus, "CES1")) return true;
    // disable: not(contains(toLower(adm_ces1users), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp6) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp6]);


  // App3/SVR
  // NOTE: updated 6/14/23, this is no longer conditional, always show when relevant
  const memoWFSectionHideApp3 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: contains(viewedsrv, "0")
    else if (contains(memoANNote.ViewedSRV, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp3 = useMemo<boolean>(() => {
    // disable: if(equals(cv_serviceremailmatchrule, "false"), 0, 1)
    if (!memoPermsIsCurUserApp3) return true;
    // disable: if(equals(cv_servicerrulestatus, "false"), 0, 1)
    else if (!eq(memoFormStatus, "Servicer")) return true;
    // disable: contains(wfstatus, "CES Requests Additional Information from Servicer")
    else if (contains(memoANNote.WFStatus, "CES Requests Additional Information from Servicer")) return true;
    else return false;
  }, [memoFormStatus, memoANNote, memoPermsIsCurUserApp3]);


  // App8/RPE1
  const memoWFSectionHideApp8 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: is_new_mode
    if (memoIsNewItem) return true;
    // hide: contains(workflowpath, "1")
    else if (memoWorkflowPath === 1) return true;
    // hide: contains(viewedrpe1, "0")
    else if (contains(memoANNote.ViewedRPE1, "0")) return true;
    else return false;
  }, [memoANNote, memoIsNewItem, memoWorkflowPath]);

  const memoWFSectionDisableApp8 = useMemo<boolean>(() => {
    // disable: not(contains(toLower(adm_RPE1Users), toLower(curuseremail)))
    if (!memoPermsIsCurUserApp8) return true;
    // disable: not(equals(formstatus, "RPE1"))
    else if (!eq(memoFormStatus, "RPE1")) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp8]);


  // App4/PSI
  const memoWFSectionHideApp4 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: contains(formstatus, "Approved") && isNullOrEmpty(adm_app4email)
    else if (contains(memoFormStatus, "Approved") && isNull(memoANNote.App4Email)) return true;
    // hide: contains(workflowpath, "1") || contains(workflowpath, "2") || contains(workflowpath, "3") || contains(workflowpath, "0")
    else if (inn(memoWorkflowPath, 1, 2, 3, 0)) return true;
    // hide: contains(viewedpsi, "0")
    else if (contains(memoANNote.ViewedPSI, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath, memoFormStatus]);

  const memoWFSectionDisableApp4 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "PSI"))
    if (!contains(memoFormStatus, "PSI")) return true;
    // disable: not(contains(toLower(adm_psiusers), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp4) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp4]);


  // App1/CES2
  const memoWFSectionHideApp1 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: is_new_mode
    if (memoIsNewItem) return true;
    // hide: contains(viewedces2, "0")
    else if (contains(memoANNote.ViewedCES2, "0")) return true;
    else return false;
  }, [memoANNote, memoIsNewItem]);

  const memoWFSectionDisableApp1 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "CES2")) || contains(txt_wfstatus, "Awaiting CES Review")
    if (!contains(memoFormStatus, "CES2") || contains(memoANNote.WFStatus, "Awaiting CES Review")) return true;
    // disable: not(contains(toLower(adm_ces2users), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp1) return true;
    else return false;
  }, [memoANNote, memoFormStatus, memoPermsIsCurUserApp1]);


  // App7/CGI1
  const memoWFSectionHideApp7 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: contains(viewedcgi1, "0")
    else if (contains(memoANNote.ViewedCGI1, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp7 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "CGI1"))
    if (!contains(memoFormStatus, "CGI1")) return true;
    // disable: not(contains(toLower(adm_cgi1users), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp2) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp2]);


  // App2/CGI2
  const memoWFSectionHideApp2 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: contains(viewedcgi2, "0")
    else if (contains(memoANNote.ViewedCGI2, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp2 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "CGI2"))
    if (!contains(memoFormStatus, "CGI2")) return true;
    // disable: equals(toLower(adm_app7email), toLower(curuseremail)) && not(contains(toLower(curuseremail), "jvkuijk@cerberusglobal.nl"))
    //*NOTE: this rule is to prevent the same user to be approver for both app7/CGI1 and app2/CGI2
    else if (!Consts.admOvrAddCurUserToAllRoles() && eq(memoANNote.App7Email, memoCurUsername)) return true;
    // disable: not(contains(toLower(adm_cgi2users), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp2) return true;
    else return false;
  }, [memoFormStatus, memoANNote, memoPermsIsCurUserApp2]);

  const memoHasSameCGIs = useMemo<boolean>(() => {
    // helper to determine if CGI1 and CGI2 are the same person, during the CGI2 WF Step
    if (GenUtil.eq(memoFormStatus, "CGI2") && GenUtil.contains(`;${memoANNote.App7Email};`, memoCurUsername))
      // when form status is CGI2, make sure current user is not same person that signed at CGI1/app7 step
      return true;
    else
      return false;
  }, [memoANNote, memoFormStatus]);


  // App5/LTH
  const memoWFSectionHideApp5 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contavins(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: contains(workflowpath, "2") || contains(workflowpath, "4") || contains(workflowpath, "5") || contains(dd_belowbp_cgi1, "No") || contains(workflowpath, "0")
    else if (inn(memoWorkflowPath, 0, 2, 4, 5) || contains(memoANNote.App7BBP, "No")) return true;
    // hide: contains(dd_belowbp_cgi1, "No")
    else if (contains(memoANNote.App7BBP, "No")) return true;
    // hide: contains(viewedlth, "0")
    else if (contains(memoANNote.ViewedLTH, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp5 = useMemo<boolean>(() => {
    // disable: not(contains(toLower(adm_lthusers), toLower(curuseremail)))
    if (!memoPermsIsCurUserApp5) return true;
    // disable: not(contains(formstatus, "LTH"))
    if (!contains(memoFormStatus, "LTH")) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp5]);


  // App9/EO
  const memoWFSectionHideApp9 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: is_new_mode
    if (memoIsNewItem) return true;
    // hide: contains(adm_workflowpath, "1") || contains(adm_workflowpath, "2") || contains(adm_workflowpath, "3") || contains(dd_belowbp_cgi2, "No") || contains(workflowpath, "0")
    //   NOTE: dd_belowbp_cgi2 is not found in nintex at all, maybe legacy/bug, ignore this part
    else if (inn(memoWorkflowPath, 1, 2, 3, 0)) return true;
    // hide: contains(app7bbp, "No") || contains(dd_belowbp_cgi1, "No")
    else if (contains(memoANNote.App7BBP, "No")) return true;
    // hide: contains(viewedeo, "0")
    else if (contains(memoANNote.ViewedEO, "0")) return true;
    else return false;
  }, [memoANNote, memoIsNewItem, memoWorkflowPath]);

  const memoWFSectionDisableApp9 = useMemo<boolean>(() => {
    // disable: not(contains(toLower(adm_eousers), toLower(curuseremail)))
    if (!memoPermsIsCurUserApp9) return true;
    // disable: not(contains(formstatus, "EO"))
    if (!contains(memoFormStatus, "EO")) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp9]);


  // App10/RPE2
  const memoWFSectionHideApp10 = useMemo<boolean>(() => {
    // override
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return false;
    // hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    // hide: (contains(workflowpath, "5") || contains(workflowpath, "3") || contains(workflowpath, "1") || contains(workflowpath, "0")) && contains(cv_oldnew, "new")
    if (inn(memoWorkflowPath, 5, 3, 1, 0) && contains(memoOldNew, "new")) return true;
    // hide: (contains(workflowpath, "1") || contains(workflowpath, "0")) && contains(cv_oldnew, "old")
    if (inn(memoWorkflowPath, 1, 0) && contains(memoOldNew, "old")) return true;
    // hide: contains(viewedrpe2, "0")
    else if (contains(memoANNote.ViewedRPE2, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath, memoOldNew]);

  const memoWFSectionDisableApp10 = useMemo<boolean>(() => {
    // disable: not(contains(formstatus, "RPE2"))
    if (!contains(memoFormStatus, "RPE2")) return true;
    // disable: not(contains(toLower(adm_rpe1users), toLower(curuseremail)))
    else if (!memoPermsIsCurUserApp10) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp10]);


  const memoWFSectionIsAnyShownEnabled = useMemo<boolean>(() => {
    // determine if any workflow section is shown AND enabled
    // we can use this with the [memoPermsIsCurUserAnyRole] memo to determine if the current user has any role/permission and ability to submit a workflow action
    let r = false;
    r = r || (!memoWFSectionHideAppL1 && !memoWFSectionDisableAppL1);
    r = r || (!memoWFSectionHideApp6 && !memoWFSectionDisableApp6);
    r = r || (!memoWFSectionHideApp3 && !memoWFSectionDisableApp3);
    r = r || (!memoWFSectionHideApp8 && !memoWFSectionDisableApp8);
    r = r || (!memoWFSectionHideApp4 && !memoWFSectionDisableApp4);
    r = r || (!memoWFSectionHideApp1 && !memoWFSectionDisableApp1);
    r = r || (!memoWFSectionHideApp7 && !memoWFSectionDisableApp7);
    r = r || (!memoWFSectionHideApp2 && !memoWFSectionDisableApp2);
    r = r || (!memoWFSectionHideApp5 && !memoWFSectionDisableApp5);
    r = r || (!memoWFSectionHideApp9 && !memoWFSectionDisableApp9);
    r = r || (!memoWFSectionHideApp10 && !memoWFSectionDisableApp10);
    return r;
  }, [
    memoWFSectionHideAppL1, memoWFSectionDisableAppL1,
    memoWFSectionHideApp6, memoWFSectionDisableApp6,
    memoWFSectionHideApp3, memoWFSectionDisableApp3,
    memoWFSectionHideApp8, memoWFSectionDisableApp8,
    memoWFSectionHideApp4, memoWFSectionDisableApp4,
    memoWFSectionHideApp1, memoWFSectionDisableApp1,
    memoWFSectionHideApp7, memoWFSectionDisableApp7,
    memoWFSectionHideApp2, memoWFSectionDisableApp2,
    memoWFSectionHideApp5, memoWFSectionDisableApp5,
    memoWFSectionHideApp9, memoWFSectionDisableApp9,
    memoWFSectionHideApp10, memoWFSectionDisableApp10
  ]);


  //#endregion


  //#region 'saving/submitting form'
  //-------------------------


  // readonly check for case manager DDL

  const memoCaseManagerIsReadOnly = useMemo<boolean>(() => {
    // this field is enabled when: Is_New_Mode || FormStatus==Saved || FormStatus==Draft || FormStatus==CES1(and App6Action is Reassign)
    // NOTE: when set to CABOT1, DDL is not editable because the case manager sent it back, so it must go back to same case manager
    // NOTE: updated 6/15/23, allow CABOT1 to edit case manager ddl to reassign
    if (memoIsNewItem || inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft, StaticData.wfStatusCABOT1))
      return false;
    else if (eq(memoFormStatus, StaticData.wfStatusCES1) && eq(stateSelApp6Action, StaticData.wfActionReassign))
      return false;
    else
      return true;
  }, [memoIsNewItem, memoFormStatus, stateSelApp6Action]);


  // show/hide rules for save/submit/cancel buttons

  const memoShowSaveButton = useMemo<boolean>(() => {
    // enable if isnewmode or is Draft or Saved
    // NOTE: if status is CABOT1, force the user to submit, saving is not an option
    if (memoIsNewItem || inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft))
      return true;
    else
      return false;
  }, [memoIsNewItem, memoFormStatus]);


  const memoEnableSubmitButton = useMemo<boolean>(() => {
    // disable if form status is terminal, otherwise allow submitting
    if (inn(memoFormStatus, StaticData.wfStatusApproved, StaticData.wfStatusRejected, StaticData.wfStatusWithdrawn))
      return false;
    else {
      if (memoFormIsReadOnly) {
        // form is readonly, submit button is only useful to submit a workflow action, therefore user must have permission to use a workflow section to submit and the AN is has that section shown/enabled
        return memoPermsIsCurUserAnyRole && memoWFSectionIsAnyShownEnabled;
      }
      else {
        // form is NOT readonly
        // need special case for CABOT1 and perm check
        if (eq(memoFormStatus, StaticData.wfStatusCABOT1) && !memoPermsIsCurUserAppL1) {
          // scenario: returned to CABOT1, but does not have permissions to use the CABOT1 approver section, so don't let them save the whole form and submit
          return false;
        }
        else {
          // scenario: form status should be new/saved/draft
          return true;
        }
      }
    }
  }, [memoFormStatus, memoFormIsReadOnly, memoPermsIsCurUserAnyRole, memoWFSectionIsAnyShownEnabled, memoPermsIsCurUserAppL1]);


  const memoEnableCancelButton = useMemo<boolean>(() => {
    // always allow user to cancel and navigate away
    return true;
  }, []);


  function handleSave() {
    saveChanges(true);
  }


  function handleSubmit() {
    saveChanges(false);
  }


  function handleCancel() {
    // navigate back to the root ANNote list
    if (getWinDirty()) {
      // if window is dirty, then user will already be prompted, so do not prompt here
      (window as any).open(stateANNotesListWebUrl, "_top");
    }
    else if ((window as any).confirm("Are you sure?")) {
      (window as any).open(stateANNotesListWebUrl, "_top");
    }
  }


  function saveChanges(fromSaveButton: boolean) {
    // main save changes function!

    // validating
    // NOTE: only show toast warning saving/submitting should be stopped (first draft save has fewer conditions to prevent user from saving, submitting has full form validation)
    setStateFormSubmitted(true);

    if (fromSaveButton) {
      // limited validation for "save"
      if (!validateForm(true)) {
        AppHelper.toastWarn("Form is not valid, please correct any errors displayed before saving: " + __badFields);
        return;
      }
    }
    else {
      // full validation for "submit"
      if (!validateForm(false)) {
        AppHelper.toastWarn("Form is not valid, please correct any errors displayed before submitting: " + __badFields);
        return;
      }
    }


    // create new empty element for sending inserts/updates to SP
    let an: Partial<ANNoteFields> = {};


    // setup main wf fields and defaults
    let workflowPath = memoWorkflowPath;
    // let project = stateSelProject;
    // let regulated = memoProjLURegulated;
    // let keyDecision = memoKeyDecision;
    // let intExt = memoProjLUIntExt;
    // let oldNew = memoOldNew;
    let wfStatus: string = safeTrim(memoANNote.WFStatus);

    let formStatus: string = memoFormStatus; // conversion of form status from Saved to Draft is not needed, the memo does it

    let beaconCurrent = safeTrim(memoANNote.BeaconStage);

    let curActionGroup: string = eq(formStatus, StaticData.wfStatusDraft) ?
      StaticData.wfStatusServicer : formStatus;


    // gather actions
    let appL1Action = stateSelAppL1Action;
    // let appL2Action = stateSelAppL2Action;
    let app6Action = stateSelApp6Action;
    let app3Action = stateSelApp3Action;
    let app8Action = stateSelApp8Action;
    let app4Action = stateSelApp4Action;
    let app1Action = stateSelApp1Action;
    let app7Action = stateSelApp7Action;
    let app2Action = stateSelApp2Action;
    let app5Action = stateSelApp5Action;
    let app9Action = stateSelApp9Action;
    let app10Action = stateSelApp10Action;


    // gather BBP choices
    // NOTE: even in latest changes, we can still use only app7 here, because BBP decision is only used in latest stages of workflow, after cgi1/app7 will reaffirm the bbp decision in UI/workflow
    // let app6BBP = stateSelApp6BBP; // not needed!
    let bbpOverall = GenUtil.safeToBool(stateSelApp7BBP);



    // gather app8/rpe1 and app10/rpe2 special override choice
    let considerSkipRPE2: boolean = memoShowApp8OVR;
    let skipRPE2: boolean = !GenUtil.safeToBool(stateSelApp8OVR); // when rpe1 choice is "yes", then RPE2 is relevant and should not be skipped



    // gather user info (current and createdby)
    let createdByEmail = safeTrim(memoANNote.CreatedByEmail);
    let createdByName = safeTrim(memoANNote.CreatedByName);
    let currentUserEmail = memoCurUsername;
    let currentUserName = memoCurUserDispName;



    // gather emails
    let caseManager = stateSelCaseManagerUser;
    let linkReviewer = memoSelServicerSubmitter ? memoSelServicerSubmitter.email : ""; // get the selected Servicer Submitter, this is the person assigned to App3/Servicer

    let app1Emails = memoPermsApp1Users; // CES2 users
    let app2Emails = memoPermsApp2Users.toLowerCase(); // CGI2 users
    let app3Emails = memoPermsApp3Users; // Servicer, but not used, sendEmailTo uses someone else
    let app4Emails = memoPermsApp4Users; // PSI users
    let app5Emails = memoPermsApp5Users; // LTH users
    let app6Emails = caseManager; // use the selected Case Manager, not all "CES1" users
    let app7Emails = app2Emails.replace(an.App7Email || "", ""); // remove the "App7=CGI1" user from emails (App2=CGI2)
    let app8Emails = memoPermsApp8Users; // RPE1 users
    let app9Emails = memoPermsApp9Users; // EO users
    let app10Emails = memoPermsApp10Users; // RPE2 users


    let actedCES1Email = safeTrim(memoANNote.App6Email);
    let actedSRVEmail = safeTrim(memoANNote.App3Email);
    let actedRPE1Email = safeTrim(memoANNote.App8Email);
    let actedPSIEmail = safeTrim(memoANNote.App4Email);
    let actedCES2Email = safeTrim(memoANNote.App1Email);
    let actedCGI1Email = safeTrim(memoANNote.App7Email);
    let actedCGI2Email = safeTrim(memoANNote.App2Email);
    let actedLTHEmail = safeTrim(memoANNote.App5Email);
    let actedEOEmail = safeTrim(memoANNote.App9Email);
    let actedRPE2Email = safeTrim(memoANNote.App10Email);


    let emailSubject = "Please Review Advisory Note!";





    __tracking += `curFormStatus=${formStatus}/curWFStatus=${wfStatus}/curWFPath=${workflowPath}/curUsername=${memoCurUsername}/`;



    updatePayloadForEverySave(an, curActionGroup);

    if (memoIsNewItem) {
      // saving a NEW record
      // NOTE: this branch will only run a single time

      updatePayloadForNew(an);
      updatePayloadSavingMainForm(an);
      updatePayloadForSavingCaseManager(an);
      updatePayloadDefaultWorkflowValues(an);

      if (fromSaveButton) {
        // Save button clicked (save and quit, user will come back and submit later)
        // 1-save new record (full save NEW, set to Saved)
        __tracking += `save/`;

        an.FormStatus = StaticData.wfStatusSaved;

      }
      else {
        // Submit button clicked (save and submit immed into workflow)
        // 3-submit for appr new record (full save NEW, set to CES1)
        __tracking += `submit/`;

        updatePayloadSubmitToCES1(an, currentUserEmail, currentUserName, beaconCurrent, caseManager, createdByName, emailSubject, false);
        updatePayloadSubmitToCES1DecisionFields(an);
      }

      updatePayloadSaveTracking(an);

      graphCreateRecord(an, true, "Advisory Note Created");

    }
    else {
      // update an EXISTING record
      // NOTE: this branch will run on all subsequent saves/submits

      if (!memoFormIsReadOnly)
        updatePayloadSavingMainForm(an);

      if (!memoCaseManagerIsReadOnly)
        updatePayloadForSavingCaseManager(an); // for reassign

      if (fromSaveButton) {
        // Save button clicked
        // 2-save existing record (full save EXISTING, set to Saved)
        __tracking += `save/`;

        an.FormStatus = StaticData.wfStatusSaved;

        updatePayloadSaveTracking(an);

        graphUpdateRecord(an, "Advisory Note Saved");

      }
      else {
        // Submit button clicked
        // 4-if curstatus is Draft/Saved/CABOT1, full save EXISTING, set to CES1
        // 5-else limited save, wf action section determines next steps

        __tracking += `submit/`;


        //#region 'DRAFT AND RECOMMEND'
        // #focus

        if (eq(formStatus, "Draft") ||
          (eq(formStatus, "CABOT1") && inn(appL1Action, "Recommend")) ||
          (eq(formStatus, "CES1") && eq(app6Action, "Reassign"))) {

          let isReassign = eq(formStatus, "CES1") && eq(app6Action, "Reassign");

          updatePayloadSubmitToCES1(an, currentUserEmail, currentUserName, beaconCurrent, caseManager, createdByName, emailSubject, isReassign);
          updatePayloadSubmitToCES1DecisionFields(an);

          app3Action = "";
          app8Action = "";
          app4Action = "";
          app1Action = "";
          app7Action = "";
          app2Action = "";
          app5Action = "";
          app9Action = "";
          app10Action = "";

        } else if (eq(formStatus, "CES1") && inn(memoSelAppActionOverall, "Recommend", "Not Supported")) {

          // NOTE: updated 6/14/23, always redirect from CES1 to Servicer/app3

          console.log("***WFSTEP", "2990");
          __tracking += `2990/`;

          an.FormStatus = "Servicer";
          an.WFStatus = eq(memoSelAppActionOverall, "Recommend") ? "Recommend to Servicer" : "Not Supported";
          an.ViewedSRV = "1";

          an.App6Action = eq(memoSelAppActionOverall, "Recommend") ? "Recommend to Servicer" : "Not Supported";
          an.App6Date = todayISO;
          an.App6Email = currentUserEmail;
          an.App6Name = currentUserName;

          an.App3Action = "";
          an.App8Action = "";
          an.App4Action = "";
          an.App1Action = "";
          an.App7Action = "";
          an.App2Action = "";
          an.App5Action = "";
          an.App9Action = "";
          an.App10Action = "";

          app8Action = "";
          app4Action = "";
          app1Action = "";
          app7Action = "";
          app2Action = "";
          app5Action = "";
          app9Action = "";
          app10Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Servicer";

          an.SendEmailTo = linkReviewer;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

          // if (workflowPath === 1) {

          // #todo delete me after testing
          // console.log("***WFSTEP", "2924");
          // tracking += `2924/`;

          // an.FormStatus = "CES2";
          // an.WFStatus = "Recommend to CES2 - Recommenders";
          // an.ViewedCES2 = "1";

          // an.App6Action = "Recommend to CES2";
          // an.App6Date = todayISO;
          // an.App6Email = currentUserEmail;
          // an.App6Name = currentUserName;

          // an.App1Action = "";
          // an.App3Action = "";
          // an.App8Action = "";
          // an.App4Action = "";
          // an.App7Action = "";
          // an.App2Action = "";
          // an.App5Action = "";
          // an.App9Action = "";
          // an.App10Action = "";

          // app1Action = "";
          // app8Action = "";
          // app4Action = "";
          // app7Action = "";
          // app2Action = "";
          // app5Action = "";
          // app9Action = "";
          // app10Action = "";

          // an.BeaconStart = "NO";
          // an.BeaconStageEnd = beaconCurrent;
          // an.BeaconStage = "CES2";

          // an.SendEmailTo = app1Emails;
          // an.SendEmail = "Yes";
          // an.EmailSubject = emailSubject;

          // } else {

          // #todo delete me after testing
          // console.log("***WFSTEP", "2990");
          // tracking += `2990/`;

          // an.FormStatus = "Servicer";
          // an.WFStatus = "Recommend to Servicer";
          // an.ViewedSRV = "1";

          // an.App6Action = "Recommend to Servicer";
          // an.App6Date = todayISO;
          // an.App6Email = currentUserEmail;
          // an.App6Name = currentUserName;

          // an.App3Action = "";
          // an.App8Action = "";
          // an.App4Action = "";
          // an.App1Action = "";
          // an.App7Action = "";
          // an.App2Action = "";
          // an.App5Action = "";
          // an.App9Action = "";
          // an.App10Action = "";

          // app8Action = "";
          // app4Action = "";
          // app1Action = "";
          // app7Action = "";
          // app2Action = "";
          // app5Action = "";
          // app9Action = "";
          // app10Action = "";

          // an.BeaconStart = "NO";
          // an.BeaconStageEnd = beaconCurrent;
          // an.BeaconStage = "Servicer";

          // an.SendEmailTo = linkReviewer;
          // an.SendEmail = "Yes";
          // an.EmailSubject = emailSubject;

          // }

        } else if (eq(formStatus, "Servicer") && eq(app3Action, "Submit")) {

          if (inn(workflowPath, 2, 3, 4, 5)) {

            console.log("***WFSTEP", "3053");
            __tracking += `3053/`;

            an.FormStatus = "RPE1";
            an.WFStatus = "Submit to Regulated Entity";
            an.ViewedRPE1 = "1";

            an.App3Action = "Submit to Regulated Entity";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App8Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "RPE1";

            an.SendEmailTo = app8Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 1)) {

            // NOTE: updated 6/14/23, since servicer is always required, this branch moved here

            console.log("***WFSTEP", "2924");
            __tracking += `2924/`;

            an.FormStatus = "CES2";
            an.WFStatus = "Recommend to CES2 - Recommenders";
            an.ViewedCES2 = "1";

            an.App3Action = "Recommend to CES2";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App8Action = "";
            an.App4Action = "";
            an.App1Action = "";
            an.App7Action = "";
            an.App2Action = "";
            an.App5Action = "";
            an.App9Action = "";
            an.App10Action = "";

            app8Action = "";
            app4Action = "";
            app1Action = "";
            app7Action = "";
            app2Action = "";
            app5Action = "";
            app9Action = "";
            app10Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CES2";

            an.SendEmailTo = app1Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "CES2") && inn(app1Action, "Sign and recommend", "Sign and approve")) {

          console.log("***WFSTEP", "3080");
          __tracking += `3080/`;

          an.FormStatus = "CGI1";
          an.WFStatus = "CES recommends to CGI";
          an.ViewedCGI1 = "1";

          an.App1Action = "CES recommends to CGI";
          an.App1Date = todayISO;
          an.App1Email = currentUserEmail;
          an.App1Name = currentUserName;

          an.App7Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI1";

          an.SendEmailTo = app7Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CGI1") && inn(app7Action, "Sign and recommend", "Sign and approve")) {
          //#todo i added the extra "inn" condition

          // #todo no need for this branch, always goto CGI2
          // if ((eq(app7Action, "Sign and recommend") && (app7BBP || workflowPath === 2)) ||
          //   (eq(app7Action, "Sign and approve") && !app7BBP) ||
          //   (eq(app7Action, "Sign and recommend") && (inn(workflowPath, 4, 3, 1, 5)))) {

          console.log("***WFSTEP", "3111");
          __tracking += `3111/`;

          an.FormStatus = "CGI2";
          an.WFStatus = "Signed by CGI - Signature 1";
          an.ViewedCGI2 = "1";

          an.App7Action = "Signed by CGI - Signature 1";
          an.App7Date = todayISO;
          an.App7Email = currentUserEmail;
          an.App7Name = currentUserName;

          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI2";

          an.SendEmailTo = app2Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

          // }

        } else if (eq(formStatus, "CGI2") && inn(app2Action, "Sign and recommend", "Sign and approve")) {

          if ((inn(workflowPath, 2) && considerSkipRPE2 && skipRPE2) ||
            (inn(workflowPath, 4) && !bbpOverall && considerSkipRPE2 && skipRPE2)) {
            // from CGI2, when in path 2 or 4, and override is relevant, and RPE2 is not needed, skip RPE2 and set to Approved

            console.log("***WFSTEP", "3960");
            __tracking += `3960/`;

            an.FormStatus = "Approved";
            an.WFStatus = "Approved";

            an.App2Action = "Approved";

            an.BeaconStage = "Approve";
            an.SendEmailTo = createdByEmail;
            an.EmailSubject = "Advisory Note Approved!";

          } else if (bbpOverall) {

            if (inn(workflowPath, 1, 3)) {

              console.log("***WFSTEP", "3143");
              __tracking += `3143/`;

              an.FormStatus = "LTH";
              an.WFStatus = "CGI recommends to Legal Title Holder";
              an.ViewedLTH = "1";

              an.App2Action = "CGI recommends to LTH";

              an.App5Action = "";

              an.BeaconStage = "LTH";
              an.SendEmailTo = app5Emails;
              an.EmailSubject = emailSubject;

            } else if (inn(workflowPath, 4, 5)) {

              console.log("***WFSTEP", "3160");
              __tracking += `3160/`;

              an.FormStatus = "EO";
              an.WFStatus = "CGI recommends to Economic Owner";
              an.ViewedEO = "1";

              an.App2Action = "CGI recommends to Economic Owner";

              an.App9Action = "";

              an.BeaconStage = "EO";
              an.SendEmailTo = app9Emails;
              an.EmailSubject = emailSubject;

            } else if (inn(workflowPath, 2)) {

              console.log("***WFSTEP", "3178");
              __tracking += `3178/`;

              an.FormStatus = "RPE2";
              an.WFStatus = "CGI recommends to Regulated Entity";
              an.ViewedRPE2 = "1";

              an.App2Action = "CGI recommends to Regulated Entity";

              an.App10Action = "";

              an.BeaconStage = "RPE2";
              an.SendEmailTo = app10Emails;
              an.EmailSubject = emailSubject;

            }

          } else if (!bbpOverall) {

            if (inn(workflowPath, 1, 3, 5)) {
              console.log("***WFSTEP", "3212");
              __tracking += `3212/`;

              an.FormStatus = "Approved";
              an.WFStatus = "Approved";

              an.App2Action = "Approved";

              an.BeaconStage = "Approve";
              an.SendEmailTo = createdByEmail;
              an.EmailSubject = "Advisory Note Approved!";

            }
            else if (inn(workflowPath, 2, 4)) {
              // NOTE: for path 2, BBP is not relevant, thus will be false
              // NOTE: for path 4, RPE2 is not being skipped at this branch
              console.log("***WFSTEP", "3196");
              __tracking += `3196/`;

              an.FormStatus = "RPE2";
              an.WFStatus = "CGI recommends to Regulated Entity";
              an.ViewedRPE2 = "1";

              an.App2Action = "CGI recommends to Regulated Entity";

              an.App10Action = "";

              an.BeaconStage = "RPE2";
              an.SendEmailTo = app10Emails;
              an.EmailSubject = emailSubject;
            }

          }

          an.App2Date = todayISO;
          an.App2Email = currentUserEmail;
          an.App2Name = currentUserName;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.SendEmail = "Yes";

        } else if (eq(formStatus, "PSI") && eq(app4Action, "Seek Advice")) {

          console.log("***WFSTEP", "3255");
          __tracking += `3255/`;

          an.FormStatus = "CES2";
          an.WFStatus = "PSI recommends to CES2";
          an.ViewedCES2 = "1";

          an.App4Action = "PSI recommends to CES2";
          an.App4Date = todayISO;
          an.App4Email = currentUserEmail;
          an.App4Name = currentUserName;

          an.App1Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CES2";

          an.SendEmailTo = app1Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "LTH") && inn(app5Action, "Sign and recommend", "Sign and approve")) {

          console.log("***WFSTEP", "3278");
          __tracking += `3278/`;

          an.FormStatus = "Approved";
          an.WFStatus = "Approved";

          an.App5Action = "Approved";
          an.App5Date = todayISO;
          an.App5Email = currentUserEmail;
          an.App5Name = currentUserName;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Approve";

          an.SendEmailTo = createdByEmail;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Approved!";

        } else if (eq(formStatus, "EO") && inn(app9Action, "Sign and recommend", "Sign and approve")) {
          //#todo i added the extra "inn" condition

          let gotoRPE2 = true;
          if (inn(workflowPath, 4) && considerSkipRPE2 && skipRPE2) {
            gotoRPE2 = false;
          }

          if (!gotoRPE2 || eq(app9Action, "Sign and approve")) {

            console.log("***WFSTEP", "3299");
            __tracking += `3299/`;

            an.FormStatus = "Approved";
            an.WFStatus = "Approved";

            an.App9Action = "Approved";
            an.App9Date = todayISO;
            an.App9Email = currentUserEmail;
            an.App9Name = currentUserName;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "Approve";

            an.SendEmailTo = createdByEmail;
            an.SendEmail = "Yes";
            an.EmailSubject = "Advisory Note Approved!";

          } else if (gotoRPE2 || eq(app9Action, "Sign and recommend")) {

            console.log("***WFSTEP", "3318");
            __tracking += `3318/`;

            an.FormStatus = "RPE2";
            an.WFStatus = "Economic Owner recommends to Regulated Entity";
            an.ViewedRPE2 = "1";

            an.App9Action = "EO recommends to RPE";
            an.App9Date = todayISO;
            an.App9Email = currentUserEmail;
            an.App9Name = currentUserName;

            an.App10Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "RPE2";

            an.SendEmailTo = app10Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "RPE1") && inn(app8Action, StaticData.wfActionSeeksAdvice, StaticData.wfActionRecommend, StaticData.wfActionSignAndApprove)) {

          if (inn(workflowPath, 2, 3)) {
            // goto CES2

            console.log("***WFSTEP", "3345");
            __tracking += `3345/`;

            let _status = "";
            if (eq(app8Action, StaticData.wfActionSeeksAdvice)) {
              _status = "Seeks Advice from CES - Recommender";
            }
            else if (eq(app8Action, StaticData.wfActionRecommend)) {
              _status = "Regulated Prom Entity Recommends to CES - Recommender";
            }
            else if (eq(app8Action, StaticData.wfActionSignAndApprove)) {
              _status = "Signed by Regulated Prom Entity";
            }

            an.FormStatus = "CES2";
            an.WFStatus = _status;
            an.ViewedCES2 = "1";

            an.App8Action = _status;
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;

            an.App1Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CES2";

            an.SendEmailTo = app1Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else {
            // goto PSI

            console.log("***WFSTEP", "3368");
            __tracking += `3368/`;

            let _status = "";
            if (eq(app8Action, StaticData.wfActionSeeksAdvice)) {
              _status = "Seeks Advice from PSI";
            }
            else if (eq(app8Action, StaticData.wfActionRecommend)) {
              _status = "Regulated Prom Entity Recommends to PSI";
            }
            else if (eq(app8Action, StaticData.wfActionSignAndApprove)) {
              _status = "Signed by Regulated Prom Entity";
            }

            an.FormStatus = "PSI";
            an.WFStatus = _status;
            an.ViewedPSI = "1";

            an.App8Action = _status;
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;

            an.App4Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "PSI";

            an.SendEmailTo = app4Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "RPE2") && inn(app10Action, "Sign and recommend", "Sign and approve")) {

          console.log("***WFSTEP", "3392");
          __tracking += `3392/`;

          an.FormStatus = "Approved";
          an.WFStatus = "Approved";

          an.App10Action = "Approved";
          an.App10Date = todayISO;
          an.App10Email = currentUserEmail;
          an.App10Name = currentUserName;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Approve";

          an.SendEmailTo = createdByEmail;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Approved!";

        } else if (eq(formStatus, "Servicer") && eq(wfStatus, "CES Requests Additional Information from Servicer")) {
          // #todo the else if uses wfStatus as match instead of action
          // why is this branch here!? this is sending it backward from Servicer to CES1
          // this the only main branch if/else that uses wfstatus, all others are nested in main branch if/else
          // but this is in the recommend section if/else branches, and is last?
          // is this logic duplicated below in the REQUEST ADDITIONAL INFO region?
          // NOTE: when at Servicer, and sending back to CES1 for request additional info, path 3612 is hit correctly, this path is not hit!

          console.log("***WFSTEP", "3411");
          __tracking += `3411/`;

          an.FormStatus = "CES1";
          an.WFStatus = "Awaiting CES Review"; // used in App1/CES2 section hide/disabled logic
          an.ViewedCES1 = "1";

          // seems missing setting app3/svr info, like App3Action/App3Date/App3Email/App3Name

          an.App6Action = "";
          an.App6Email = caseManager; // why?

          an.CreatedByEmail = currentUserEmail; // why?
          an.CreatedByName = createdByName; // why?

          an.BeaconStart = "YES";
          // an.BeaconStageEnd = beaconCurrent; // missing!
          an.BeaconStage = "CES1";

          an.SendEmail = "Yes";
          an.SendEmailTo = caseManager;
          an.EmailSubject = emailSubject;

        }

        //#endregion


        //#region 'REQUEST ADDITIONAL INFO'
        // #focus

        if (eq(formStatus, "CES1") && eq(app6Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3443");
          __tracking += `3443/`;

          an.FormStatus = "CABOT1";
          an.WFStatus = "CES Requests Additional Information from Servicer";

          an.App6Action = "CES Requests Additional Information from Servicer";
          an.App6Date = todayISO;
          an.App6Email = currentUserEmail;
          an.App6Name = currentUserName;

          an.AppL1Action = "";
          an.App3Action = "";

          an.CESReviewed = "No";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CABOT1";

          an.SendEmail = "Yes";
          an.SendEmailTo = createdByEmail;
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CES2") && eq(app1Action, "Request Additional Information")) {

          if (inn(workflowPath, 1)) {

            // NOTE: updated 6/14/23, go back to Servicer, not to CES1

            console.log("***WFSTEP", "3508");
            __tracking += `3508/`;

            an.FormStatus = "Servicer";
            an.WFStatus = "Request Additional Information from Servicer";

            an.App1Action = "Request Additional Information from Servicer";

            an.App3Action = "";

            an.SendEmailTo = linkReviewer;
            an.BeaconStage = "Servicer";

          } else if (inn(workflowPath, 2, 3)) {

            console.log("***WFSTEP", "3526");
            __tracking += `3526/`;

            an.FormStatus = "RPE1";
            an.WFStatus = "Request Additional Information from Regulated Entity";

            an.App1Action = "Request Additional Information from Regulated Entity";

            an.App8Action = "";

            an.SendEmailTo = app8Emails;
            an.BeaconStage = "RPE1";

          } else if (inn(workflowPath, 4, 5)) {

            console.log("***WFSTEP", "3555");
            __tracking += `3555/`;

            an.FormStatus = "PSI";
            an.WFStatus = "Request Additional Information from PSI";

            an.App1Action = "Request Additional Information from PSI";

            an.App4Action = "";

            an.SendEmailTo = app4Emails;
            an.BeaconStage = "PSI";

          }

          an.App1Date = todayISO;
          an.App1Email = currentUserEmail;
          an.App1Name = currentUserName;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;

          an.EmailSubject = emailSubject;
          an.SendEmail = "Yes";

        } else if (eq(formStatus, "CGI1") && eq(app7Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3584");
          __tracking += `3584/`;

          an.FormStatus = "CES2";
          an.WFStatus = "CGI Requests Additional Information from CES";

          an.App7Action = "CGI Requests Additional Information from CES";
          an.App7Date = todayISO;
          an.App7Email = currentUserEmail;
          an.App7Name = currentUserName;

          an.App1Action = "";
          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CES2";

          an.SendEmailTo = app1Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "Servicer") && eq(app3Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3612");
          __tracking += `3612/`;

          an.FormStatus = "CES1";
          an.WFStatus = "Servicer Requests Additional Information from CES";

          an.App3Action = "Servicer Requests Additional Information from CES";
          an.App3Date = todayISO;
          an.App3Email = currentUserEmail;
          an.App3Name = currentUserName;

          an.App6Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Servicer"; // shouldn't this be CES1?

          an.SendEmailTo = caseManager;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CGI2") && eq(app2Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3637");
          __tracking += `3637/`;

          an.FormStatus = "CGI1";
          an.WFStatus = "CGI2 Requests Additional Information from CGI1";

          an.App2Action = "CGI2 Requests Additional Information from CGI1";
          an.App2Date = todayISO;
          an.App2Email = currentUserEmail;
          an.App2Name = currentUserName;

          an.App7Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI1";

          an.SendEmailTo = app7Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "PSI") && eq(app4Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3660");
          __tracking += `3660/`;

          an.FormStatus = "RPE1";
          an.WFStatus = "PSI Requests Additional Information from Regulated Entity";

          an.App4Action = "Request Additional Information from RPE";
          an.App4Date = todayISO;
          an.App4Email = currentUserEmail;
          an.App4Name = currentUserName;

          an.App8Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "RPE1";

          an.SendEmailTo = app8Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "RPE1") && eq(app8Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3682");
          __tracking += `3682/`;

          an.FormStatus = "Servicer";
          an.WFStatus = "Regulated Entity Requests Additional Information from Servicer";

          an.App8Action = "Request Additional Information from Servicer";
          an.App8Date = todayISO;
          an.App8Email = currentUserEmail;
          an.App8Name = currentUserName;

          an.App3Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Servicer";

          an.SendEmailTo = linkReviewer;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "EO") && eq(app9Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3704");
          __tracking += `3704/`;

          an.FormStatus = "CGI2";
          an.WFStatus = "EO Requests Additional Information from CGI2";

          an.App9Action = "Request Additional Information from CGI2";
          an.App9Date = todayISO;
          an.App9Email = currentUserEmail;
          an.App9Name = currentUserName;

          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI2";

          an.SendEmailTo = app2Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "RPE2") && eq(app10Action, "Request Additional Information")) {

          // NOTE: when sending back from RPE2, do not need to check app8override, since user obviously was at RPE2 step to send it back for more info

          if (inn(workflowPath, 2)) {

            console.log("***WFSTEP", "3730");
            __tracking += `3730/`;

            an.FormStatus = "CGI2";
            an.WFStatus = "Regulated Entity Requests Additional Information from CGI2";

            an.App10Action = "Request Additional Information from CGI2";
            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;

            an.App2Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CGI2";

            an.SendEmailTo = app2Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 4) && bbpOverall) {

            console.log("***WFSTEP", "3754");
            __tracking += `3754/`;

            an.FormStatus = "EO";
            an.WFStatus = "Regulated Entity Requests Additional Information from Economic Owner";

            an.App10Action = "Request Additional Information from EO";
            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;

            an.App9Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "EO";

            an.SendEmailTo = app9Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 4) && !bbpOverall) {

            console.log("***WFSTEP", "3778");
            __tracking += `3778/`;

            an.FormStatus = "CGI2";
            an.WFStatus = "Regulated Entity Requests Additional Information from CGI2";

            an.App10Action = "Request Additional Information from CGI2";
            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;

            an.App2Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CGI2";

            an.SendEmailTo = app2Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "LTH") && eq(app5Action, "Request Additional Information")) {

          console.log("***WFSTEP", "3804");
          __tracking += `3804/`;

          an.FormStatus = "CGI2";
          an.WFStatus = "Legal Title Holder Requests Additional Information from CGI2";

          an.App5Action = "Request Additional Information from CGI2";
          an.App5Date = todayISO;
          an.App5Email = currentUserEmail;
          an.App5Name = currentUserName;

          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI2";

          an.SendEmailTo = app2Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        }

        //#endregion


        //#region 'PROVIDE ADDITIONAL INFO'
        // #focus

        if (eq(formStatus, "CABOT1") && inn(appL1Action, "Submit Additional Information", "Provide Additional Information")) {

          console.log("***WFSTEP", "4549");
          __tracking += `4549/`;

          an.FormStatus = "CES1";
          an.WFStatus = "Provide Additional Information to CES - Case Manager";

          an.AppL1Action = "Provide Additional Information";
          an.AppL1Date = todayISO;
          an.AppL1Email = currentUserEmail;
          an.AppL1Name = currentUserName;

          an.App6Action = "";

          an.BeaconStart = "YES";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CES1";

          an.SendEmailTo = caseManager;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

          updatePayloadSubmitToCES1DecisionFields(an);

        }
        else if (eq(formStatus, "Servicer") && inn(app3Action, "Submit Additional Information", "Provide Additional Information")) {

          if (inn(workflowPath, 1)) {

            // NOTE: updated 6/14/23, for this path need to route to CES2

            console.log("***WFSTEP", "3981");
            __tracking += `3981/`;

            an.FormStatus = "CES2";
            an.WFStatus = "Servicer Provides Additional Information to CES - Recommenders";

            an.App3Action = "Servicer Provides Additional Information";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App1Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CES2";

            an.SendEmailTo = app1Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }
          else if (inn(workflowPath, 2, 3, 4, 5) && eq(wfStatus, "Regulated Entity Requests Additional Information from Servicer")) {

            console.log("***WFSTEP", "3835");
            __tracking += `3835/`;

            an.FormStatus = "RPE1";
            an.WFStatus = "Servicer Provides Additional Information to Regulated Entity";

            an.App3Action = "Servicer Provides Additional Information";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App8Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "RPE1";

            an.SendEmailTo = app8Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 5) && eq(wfStatus, "PSI Requests Additional Information from Servicer")) {
            // #todo why are we jumping from Servicer to PSI, skipping rpe1/app8?

            console.log("***WFSTEP", "3857");
            __tracking += `3857/`;

            an.FormStatus = "PSI";
            an.WFStatus = "Servicer Provides Additional Information to PSI";

            an.App8Action = "Recommend to PSI";
            an.App8Date = todayISO;
            an.App8Email = ""; // deliberate

            an.App3Action = "Servicer Provides Additional Information";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App4Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "PSI";

            an.SendEmailTo = app4Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (eq(wfStatus, "CES Requests Additional Information from Servicer")) {
            // #todo why are we going backwards from Servicer to CES1 in "provide additional info" section

            console.log("***WFSTEP", "3883");
            __tracking += `3883/`;

            an.FormStatus = "CES1";
            an.WFStatus = "Servicer Provides Additional Information to CES - Case Manager";

            an.App3Action = "Provide Additional Information";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            an.App6Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CES1";

            an.SendEmailTo = app6Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "RPE1") && inn(app8Action, "Submit Additional Information", "Provide Additional Information")) {

          if (inn(workflowPath, 1, 2, 3)) {

            console.log("***WFSTEP", "3911");
            __tracking += `3911/`;

            an.FormStatus = "CES2";
            an.WFStatus = "Provide Additional Information to CES - Recommender";

            an.App8Action = "Provide Additional Information";
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;

            an.App1Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "CES2";

            an.SendEmailTo = app1Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 4, 5)) {

            console.log("***WFSTEP", "3933");
            __tracking += `3933/`;

            an.FormStatus = "PSI";
            an.WFStatus = "Provide Additional Information to PSI";

            an.App8Action = "Provide Additional Information";
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;

            an.App4Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "PSI";

            an.SendEmailTo = app4Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "PSI") && inn(app4Action, "Submit Additional Information", "Provide Additional Information")) {

          console.log("***WFSTEP", "3957");
          __tracking += `3957/`;

          an.FormStatus = "CES2";
          an.WFStatus = "PSI Provides Additional Information to CES2";

          an.App4Action = "PSI Provides Additional Information";
          an.App4Date = todayISO;
          an.App4Email = currentUserEmail;
          an.App4Name = currentUserName;

          an.App1Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CES2";

          an.SendEmailTo = app1Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CES1") && inn(app6Action, "Submit Additional Information", "Provide Additional Information")) {

          // NOTE: updated 6/14/23, always go from CES1 to Servicer

          console.log("***WFSTEP", "4003");
          __tracking += `4003/`;

          an.FormStatus = "Servicer";
          an.WFStatus = "Case Manager Provides Additional Information to Servicer";
          an.ViewedSRV = "1";

          an.App6Action = "Case Manager Provides Additional Information";
          an.App6Date = todayISO;
          an.App6Email = currentUserEmail;
          an.App6Name = currentUserName;

          an.App3Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Servicer";

          an.SendEmailTo = linkReviewer;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

          if (inn(workflowPath, 1)) {

            // console.log("***WFSTEP", "3981");
            // tracking += `3981/`;

            // an.FormStatus = "CES2";
            // an.WFStatus = "Case Manager Provides Additional Information to CES - Recommenders";

            // an.App6Action = "Case Manager Provides Additional Information";
            // an.App6Date = todayISO;
            // an.App6Email = currentUserEmail;
            // an.App6Name = currentUserName;

            // an.App1Action = "";

            // an.BeaconStart = "NO";
            // an.BeaconStageEnd = beaconCurrent;
            // an.BeaconStage = "CES2";

            // an.SendEmailTo = app1Emails;
            // an.SendEmail = "Yes";
            // an.EmailSubject = emailSubject;

          } else {

            // console.log("***WFSTEP", "4003");
            // tracking += `4003/`;

            // an.FormStatus = "Servicer";
            // an.WFStatus = "Case Manager Provides Additional Information to Servicer";
            // an.ViewedSRV = "1";

            // an.App6Action = "Case Manager Provides Additional Information";
            // an.App6Date = todayISO;
            // an.App6Email = currentUserEmail;
            // an.App6Name = currentUserName;

            // an.App3Action = "";

            // an.BeaconStart = "NO";
            // an.BeaconStageEnd = beaconCurrent;
            // an.BeaconStage = "Servicer";

            // an.SendEmailTo = linkReviewer;
            // an.SendEmail = "Yes";
            // an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "CES2") && inn(app1Action, "Submit Additional Information", "Provide Additional Information")) {

          console.log("***WFSTEP", "4028");
          __tracking += `4028/`;

          an.FormStatus = "CGI1";
          an.WFStatus = "CES Provides Additional Information to CGI";

          an.App1Action = "CES Provides Additional Information";
          an.App1Date = todayISO;
          an.App1Email = currentUserEmail;
          an.App1Name = currentUserName;

          an.App7Action = "";
          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI1";

          an.SendEmailTo = app7Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CGI1") && inn(app7Action, "Submit Additional Information", "Provide Additional Information")) {

          console.log("***WFSTEP", "4055");
          __tracking += `4055/`;

          an.FormStatus = "CGI2";
          an.WFStatus = "CGI1 Provides Additional Information to CGI2";

          an.App7Action = "CGI1 Provides Additional Information";
          an.App7Date = todayISO;
          an.App7Email = currentUserEmail;
          an.App7Name = currentUserName;

          an.App2Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "CGI2";

          an.SendEmailTo = app2Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        } else if (eq(formStatus, "CGI2") && inn(app2Action, "Submit Additional Information", "Provide Additional Information")) {

          if (inn(workflowPath, 1, 3)) {

            console.log("***WFSTEP", "4081");
            __tracking += `4081/`;

            an.FormStatus = "LTH";
            an.WFStatus = "Signed by CGI - Signature 2";

            an.App2Action = "Signed by CGI - Signature 2";
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;

            an.App5Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "LTH";

            an.SendEmailTo = app5Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 2)) {

            // send back to RPE2 without checking for app8override, since it was sent back from RPE2, otherwise it would have already been approved/completed

            console.log("***WFSTEP", "4103");
            __tracking += `4103/`;

            an.FormStatus = "RPE2";
            an.WFStatus = "Signed by CGI - Signature 2";

            an.App2Action = "Signed by CGI - Signature 2";
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;

            an.App10Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "RPE2";

            an.SendEmailTo = app10Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if ((inn(workflowPath, 4) && bbpOverall) || inn(workflowPath, 5)) {

            // NOTE: no app8override routing needed here, goto EO always

            console.log("***WFSTEP", "4125");
            __tracking += `4125/`;

            an.FormStatus = "EO";
            an.WFStatus = "Signed by CGI - Signature 2";

            an.App2Action = "Signed by CGI - Signature 2";
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;

            an.App9Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "EO";

            an.SendEmailTo = app9Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          } else if (inn(workflowPath, 4) && !bbpOverall) {

            // NOTE: no app8override routing needed here

            console.log("***WFSTEP", "4151");
            __tracking += `4215/`;

            an.FormStatus = "RPE2";
            an.WFStatus = "Signed by CGI - Signature 2";

            an.App2Action = "Signed by CGI - Signature 2";
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;

            an.App10Action = "";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = "RPE2";

            an.SendEmailTo = app10Emails;
            an.SendEmail = "Yes";
            an.EmailSubject = emailSubject;

          }

        } else if (eq(formStatus, "EO") && inn(app9Action, "Submit Additional Information", "Provide Additional Information")) {

          // NOTE: no app8override routing needed here, the action implies it was already sent back from RPE2 (path=4 with RPE2 as last step), so send it to RPE2

          console.log("***WFSTEP", "4173");
          __tracking += `4173/`;

          an.FormStatus = "RPE2";
          an.WFStatus = "EO Provides Additional Information to Regulated Entity";

          an.App9Action = "Provide Additional Information";
          an.App9Date = todayISO;
          an.App9Email = currentUserEmail;
          an.App9Name = currentUserName;

          an.App10Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "RPE2";

          an.SendEmailTo = app10Emails;
          an.SendEmail = "Yes";
          an.EmailSubject = emailSubject;

        }

        //#endregion


        //#region 'WITHDRAWN BY SERVICER, CES - CASE MANAGER'
        // #focus

        if (inn("Withdraw", memoSelAppActionOverall)) {
          // #testing i refactored a bunch in here, to show more accurate info

          console.log("***WFSTEP", "4203");
          __tracking += `4203/`;

          let sendEmailTo = "";

          if (eq(formStatus, "CABOT1")) {
            an.AppL1Action = "Withdrawn";
            an.AppL1Date = todayISO;
            an.AppL1Email = currentUserEmail;
            an.AppL1Name = currentUserName;

            sendEmailTo = an.CreatedByEmail || "";

          } else if (eq(formStatus, "CES1")) {
            an.App6Action = "Withdrawn";
            an.App6Date = todayISO;
            an.App6Email = currentUserEmail;
            an.App6Name = currentUserName;

            sendEmailTo = [currentUserEmail, actedSRVEmail].join(";");

          } else if (eq(formStatus, "Servicer")) {
            an.App3Action = "Withdrawn";
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;

            sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail].join(";");
          }

          if (!sendEmailTo)
            sendEmailTo = currentUserEmail;

          an.FormStatus = "Withdrawn";
          an.WFStatus = "Withdrawn";

          an.SendEmailTo = sendEmailTo;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Withdrawn!";

        }

        //#endregion


        //#region 'REJECTED'
        // #focus

        if (inn("Reject", memoSelAppActionOverall)) {

          let sendEmailTo = "";

          if (eq(formStatus, "CES1")) {

            console.log("***WFSTEP", "4234");
            __tracking += `4234/`;

            an.App6Action = "Rejected";
            an.App6Date = todayISO;
            an.App6Email = currentUserEmail;
            an.App6Name = currentUserName;

            sendEmailTo = [currentUserEmail, actedSRVEmail].join(";");

            an.CancelReason = safeTrim(stateApp6Comment);

          } else if (eq(formStatus, "CES2")) {

            console.log("***WFSTEP", "4247");
            __tracking += `4247/`;

            if (inn(workflowPath, 1)) {
              sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail].join(";");
            } else if (inn(workflowPath, 2, 3)) {
              sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail, actedRPE1Email].join(";");
            } else if (inn(workflowPath, 4, 5)) {
              sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail, actedRPE1Email, actedPSIEmail].join(";");
            }

            an.App1Date = todayISO;
            an.App1Email = currentUserEmail;
            an.App1Name = currentUserName;
            an.App1Action = "Rejected";

            an.CancelReason = safeTrim(stateApp1Comment);

          } else if (eq(formStatus, "CGI1")) {

            console.log("***WFSTEP", "4266");
            __tracking += `4266/`;

            if (inn(workflowPath, 1)) {
              sendEmailTo = [currentUserEmail, actedCES2Email, actedCES1Email, actedSRVEmail].join(";");
            } else if (inn(workflowPath, 2, 3)) {
              sendEmailTo = [currentUserEmail, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email].join(";");
            } else if (inn(workflowPath, 4, 5)) {
              sendEmailTo = [currentUserEmail, actedCES2Email, actedCES1Email, actedSRVEmail, actedPSIEmail, actedRPE1Email].join(";");
            }

            an.App7Date = todayISO;
            an.App7Email = currentUserEmail;
            an.App7Name = currentUserName;
            an.App7Action = "Rejected";

            an.CancelReason = safeTrim(stateApp7Comment);

          } else if (eq(formStatus, "CGI2")) {

            console.log("***WFSTEP", "4285");
            __tracking += `4285/`;

            if (inn(workflowPath, 1)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail].join(";");
            } else if (inn(workflowPath, 2, 3)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email].join(";");
            } else if (inn(workflowPath, 4, 5)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedPSIEmail, actedRPE1Email].join(";");
            }

            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;
            an.App2Action = "Rejected";

            an.CancelReason = safeTrim(stateApp2Comment);

          } else if (eq(formStatus, "Servicer")) {

            console.log("***WFSTEP", "4305");
            __tracking += `4305/`;

            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;
            an.App3Action = "Rejected";

            sendEmailTo = [currentUserEmail, actedCES1Email].join(";");

            an.CancelReason = safeTrim(stateApp3Comment);

          } else if (eq(formStatus, "PSI")) {

            console.log("***WFSTEP", "4318");
            __tracking += `4318/`;

            an.App4Date = todayISO;
            an.App4Email = currentUserEmail;
            an.App4Name = currentUserName;
            an.App4Action = "Rejected";

            sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail, actedRPE1Email].join(";");

            an.CancelReason = safeTrim(stateApp4Comment);

          } else if (eq(formStatus, "LTH")) {

            console.log("***WFSTEP", "4331");
            __tracking += `4331/`;

            if (inn(workflowPath, 1)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail].join(";");
            } else if (inn(workflowPath, 2)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email].join(";");
            }

            an.App5Date = todayISO;
            an.App5Email = currentUserEmail;
            an.App5Name = currentUserName;
            an.App5Action = "Rejected";

            sendEmailTo = [currentUserEmail, actedPSIEmail, actedCGI2Email, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail].join(";");

            an.CancelReason = safeTrim(stateApp5Comment);

          } else if (eq(formStatus, "RPE2")) {

            console.log("***WFSTEP", "4352");
            __tracking += `4352/`;

            if (inn(workflowPath, 2)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedCGI2Email].join(";");
            } else if (inn(workflowPath, 4)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCGI2Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email, actedEOEmail].join(";");
            }

            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;
            an.App10Action = "Rejected";

            an.CancelReason = safeTrim(stateApp10Comment);

          } else if (eq(formStatus, "RPE1")) {

            console.log("***WFSTEP", "4370");
            __tracking += `4370/`;

            if (inn(workflowPath, 2)) {
              sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail].join(";");
            } else if (inn(workflowPath, 4)) {
              sendEmailTo = [currentUserEmail, actedCES1Email, actedSRVEmail].join(";");
            }

            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;
            an.App8Action = "Rejected";

            an.CancelReason = safeTrim(stateApp8Comment);

          } else if (eq(formStatus, "EO")) {

            console.log("***WFSTEP", "4387");
            __tracking += `4387/`;

            if (inn(workflowPath, 4)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCGI2Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email, actedEOEmail].join(";");
            } else if (inn(workflowPath, 5)) {
              sendEmailTo = [currentUserEmail, actedCGI1Email, actedCGI2Email, actedCES2Email, actedCES1Email, actedSRVEmail, actedRPE1Email, actedEOEmail].join(";");
            }

            an.App9Date = todayISO;
            an.App9Email = currentUserEmail;
            an.App9Name = currentUserName;
            an.App9Action = "Rejected";

            an.CancelReason = safeTrim(stateApp9Comment);

          }

          an.FormStatus = "Rejected";
          an.WFStatus = "Rejected";

          an.SendEmailTo = sendEmailTo;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Rejected!";

        }

        //#endregion


        //#region 'Return to Servicer/CABOT1'
        // #focus

        if (inn(StaticData.wfActionReturnToServicer, memoSelAppActionOverall)) {
          // #testing i refactored a bunch in here, to save more accurate info

          console.log("***WFSTEP", "4431");
          __tracking += `4431/`;

          if (eq(stateSelApp3Action, StaticData.wfActionReturnToServicer)) {
            an.App3Action = StaticData.wfActionReturnToServicer;
            an.App3Date = todayISO;
            an.App3Email = currentUserEmail;
            an.App3Name = currentUserName;
          }
          else if (eq(stateSelApp8Action, StaticData.wfActionReturnToServicer)) {
            an.App8Action = StaticData.wfActionReturnToServicer;
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;
          }
          else if (eq(stateSelApp4Action, StaticData.wfActionReturnToServicer)) {
            an.App4Action = StaticData.wfActionReturnToServicer;
            an.App4Date = todayISO;
            an.App4Email = currentUserEmail;
            an.App4Name = currentUserName;
          }
          else if (eq(stateSelApp1Action, StaticData.wfActionReturnToServicer)) {
            an.App1Action = StaticData.wfActionReturnToServicer;
            an.App1Date = todayISO;
            an.App1Email = currentUserEmail;
            an.App1Name = currentUserName;
          }
          else if (eq(stateSelApp7Action, StaticData.wfActionReturnToServicer)) {
            an.App7Action = StaticData.wfActionReturnToServicer;
            an.App7Date = todayISO;
            an.App7Email = currentUserEmail;
            an.App7Name = currentUserName;
          }
          else if (eq(stateSelApp2Action, StaticData.wfActionReturnToServicer)) {
            an.App2Action = StaticData.wfActionReturnToServicer;
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;
          }
          else if (eq(stateSelApp5Action, StaticData.wfActionReturnToServicer)) {
            an.App5Action = StaticData.wfActionReturnToServicer;
            an.App5Date = todayISO;
            an.App5Email = currentUserEmail;
            an.App5Name = currentUserName;
          }
          else if (eq(stateSelApp9Action, StaticData.wfActionReturnToServicer)) {
            an.App9Action = StaticData.wfActionReturnToServicer;
            an.App9Date = todayISO;
            an.App9Email = currentUserEmail;
            an.App9Name = currentUserName;
          }
          else if (eq(stateSelApp10Action, StaticData.wfActionReturnToServicer)) {
            an.App10Action = StaticData.wfActionReturnToServicer;
            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;
          }

          an.FormStatus = "CABOT1";
          an.WFStatus = "Request Additional Information from Servicer";

          an.AppL1Action = ""; // LINK1 step

          an.SendEmail = "Yes";
          an.SendEmailTo = createdByEmail; // same as "request additional info" from CES1 to LINK1
          an.EmailSubject = emailSubject;
        }

        //#endregion


        //#region 'Return to CES1'
        // #focus

        if (inn("Return to CES", memoSelAppActionOverall)) {
          // #testing i refactored a bunch in here, to save more accurate info

          console.log("***WFSTEP", "4446");
          __tracking += `4446/`;

          if (eq(stateSelApp8Action, "Return to CES")) {
            an.App8Action = "Return to CES";
            an.App8Date = todayISO;
            an.App8Email = currentUserEmail;
            an.App8Name = currentUserName;
          }
          else if (eq(stateSelApp4Action, "Return to CES")) {
            an.App4Action = "Return to CES";
            an.App4Date = todayISO;
            an.App4Email = currentUserEmail;
            an.App4Name = currentUserName;
          }
          else if (eq(stateSelApp1Action, "Return to CES")) {
            an.App1Action = "Return to CES";
            an.App1Date = todayISO;
            an.App1Email = currentUserEmail;
            an.App1Name = currentUserName;
          }
          else if (eq(stateSelApp7Action, "Return to CES")) {
            an.App7Action = "Return to CES";
            an.App7Date = todayISO;
            an.App7Email = currentUserEmail;
            an.App7Name = currentUserName;
          }
          else if (eq(stateSelApp2Action, "Return to CES")) {
            an.App2Action = "Return to CES";
            an.App2Date = todayISO;
            an.App2Email = currentUserEmail;
            an.App2Name = currentUserName;
          }
          else if (eq(stateSelApp5Action, "Return to CES")) {
            an.App5Action = "Return to CES";
            an.App5Date = todayISO;
            an.App5Email = currentUserEmail;
            an.App5Name = currentUserName;
          }
          else if (eq(stateSelApp9Action, "Return to CES")) {
            an.App9Action = "Return to CES";
            an.App9Date = todayISO;
            an.App9Email = currentUserEmail;
            an.App9Name = currentUserName;
          }
          else if (eq(stateSelApp10Action, "Return to CES")) {
            an.App10Action = "Return to CES";
            an.App10Date = todayISO;
            an.App10Email = currentUserEmail;
            an.App10Name = currentUserName;
          }

          an.FormStatus = "CES1";
          an.WFStatus = "Request Additional Information from CES1";

          an.App6Action = ""; // CES1 step

          an.SendEmail = "Yes";
          an.SendEmailTo = caseManager; // same as "request additional info" from Servicer to CES1
          an.EmailSubject = emailSubject;
        }

        //#endregion


        //#region 'APPROVED'
        // #focus

        if (eq(formStatus, "Approved")) {
          /* Already approved, do nothing */

          console.log("***WFSTEP", "4421");
          __tracking += `4421/`;
        }

        //#endregion


        updatePayloadSaveTracking(an);

        graphUpdateRecord(an, "Advisory Note Submitted");

      }
    }
  }


  //#endregion


  //#region 'update AN payload functions'
  //-------------------------

  function updatePayloadForEverySave(an: Partial<ANNoteFields>, curActionGroup: string) {
    // update payload for every insert/update "save" to graph/sp
    // NOTE: this is the first payload helper function called above!

    an.Browser = safeTrim(memoANNote.Browser) + `${memoCurUsername} at ${today.toISOString()} using ${(window as any).navigator.userAgent}||`; // concat each time
    an.WorkflowPath = safeTrim(memoWorkflowPath);
    an.KeyDecision = GenUtil.boolToHtmlYesNo(memoKeyDecision);
    an.ALMTypeNum = memoALMTypeNum;
    an.CurActionGroup = curActionGroup;

    // need to save these manually on each save, the "form save/submit" process doesn't set these, they were connected fields in the Nintext form, and thus auto saved per submit action

    // NOTE: do not save actions from DDLs here, they are handled in the workflow steps, the "overall" action (pretty) is saved, does not exactly match the action DDL values

    an.App6BBP = safeTrim(stateSelApp6BBP);
    an.App7BBP = safeTrim(stateSelApp7BBP);
    an.App8OVR = safeTrim(stateSelApp8OVR);
    an.App8KeyDecOvr = safeTrim(stateSelApp8KeyDecOvr);

    an.AppL1Comments = safeTrim(stateAppL1Comment);
    // an.AppL2Comments = safeTrim(stateAppL2Comment);
    an.App1Comments = safeTrim(stateApp1Comment);
    an.App2Comments = safeTrim(stateApp2Comment);
    an.App3Comments = safeTrim(stateApp3Comment);
    an.App4Comments = safeTrim(stateApp4Comment);
    an.App5Comments = safeTrim(stateApp5Comment);
    an.App6Comments = safeTrim(stateApp6Comment);
    an.App7Comments = safeTrim(stateApp7Comment);
    an.App8Comments = safeTrim(stateApp8Comment);
    an.App9Comments = safeTrim(stateApp9Comment);
    an.App10Comments = safeTrim(stateApp10Comment);

  }


  function updatePayloadForNew(an: Partial<ANNoteFields>) {
    // update payload for a first time insert

    // these fields only set when creating new ANNote
    an.Title = `AN-${today.toISOString()}`; // ex: 'AN-2022-12-09T18:54:51.044Z'
    an.CreatedByEmail = memoCurUsername;
    an.CreatedByName = memoCurUserDispName;
    an.JsVersion = Consts.version;

    // constants
    an.Servicer = StaticData.defaultServicerName;
    an.BrokerCode = StaticData.defaultBrokerCode;
  }


  function updatePayloadSubmitToCES1(an: Partial<ANNoteFields>,
    currentUserEmail: string, currentUserName: string, beaconCurrent: string, caseManager: string, createdByName: string, emailSubject: string,
    isReassign: boolean) {

    console.log("***WFSTEP", "2766");
    __tracking += `2766/`;

    an.FormStatus = "CES1";
    an.WFStatus = "Recommend to CES1";
    an.ViewedLink1 = "1";
    an.ViewedCES1 = "1";

    if (!isReassign) {
      // on reassign we don't want to reset AppL1 fields (the original submitter)
      an.AppL1Action = "Recommend to CES1";
      an.AppL1Date = todayISO;
      an.AppL1Email = currentUserEmail;
      an.AppL1Name = currentUserName;
    }

    an.App6Email = caseManager;
    an.App6Action = "";

    an.App3Action = "";
    an.App8Action = "";
    an.App4Action = "";
    an.App1Action = "";
    an.App7Action = "";
    an.App2Action = "";
    an.App5Action = "";
    an.App9Action = "";
    an.App10Action = "";

    an.BeaconStart = "YES";
    an.BeaconStageEnd = beaconCurrent;
    an.BeaconStage = "CABOT1";

    if (!isReassign) {
      // on reassign we don't want to reset AppL1 fields (the original submitter)
      an.CreatedByEmail = currentUserEmail;
      an.CreatedByName = createdByName;
    }

    an.SendEmailTo = caseManager;
    an.SendEmail = "Yes";
    an.EmailSubject = emailSubject;
  }


  function updatePayloadSubmitToCES1DecisionFields(an: Partial<ANNoteFields>) {
    // lets set some defaults at this step, before the form is openned by CES1
    // these fields are important for determining workflow path and decisions in the workflow

    // need to set BBP default choices to "No", since they may be hidden in UI as of 7-11-23 change (bbp is now show/hide based on ANType field)
    an.App6BBP = "No";
    an.App7BBP = "No";

    // UPDATE: as of 7-7-23 we no longer use this, instead using keydecision override, so lets set this to "Yes" to always ignore this override
    // NOTE: when Checked/True/Yes then RPE2 is needed (i.e. the normal flow for wf lanes 2&4)
    an.App8OVR = "Yes";

    // lets set the default value of this keydecision override to the actual keydecision value
    // this way, when this field is not empty/null, we can rely on it as the actual key decision going forward
    an.App8KeyDecOvr = GenUtil.boolToHtmlYesNo(memoKeyDecision);

  }


  function updatePayloadDefaultWorkflowValues(an: Partial<ANNoteFields>) {

    an.FormStatus = StaticData.wfStatusDraft;
    an.WFStatus = StaticData.wfStatusDraft;
    an.WorkflowPath = "0";
    an.WorkflowStatus = "";

    an.App10Action = "";
    an.App10Comments = "";
    an.App10Date = "";
    an.App10Email = "";
    an.App10Name = "";

    an.App1Action = "";
    an.App1Comments = "";
    an.App1Date = "";
    an.App1Email = "";
    an.App1Name = "";

    an.App2Action = "";
    an.App2ActionKeyNo = "";
    an.App2ActionKeyYes = "";
    an.App2BBP = "";
    an.App2Comments = "";
    an.App2Date = "";
    an.App2Email = "";
    an.App2Name = "";

    an.App3Action = "";
    an.App3Comments = "";
    an.App3Date = "";
    an.App3Email = "";
    an.App3Name = "";

    an.App4Action = "";
    an.App4ActionReq = "";
    an.App4Comments = "";
    an.App4Date = "";
    an.App4Email = "";
    an.App4Name = "";

    an.App5Action = "";
    an.App5Comments = "";
    an.App5Date = "";
    an.App5Email = "";
    an.App5Name = "";

    an.App6Action = "";
    an.App6BBP = "";
    an.App6Comments = "";
    an.App6Date = "";
    an.App6Email = "";
    an.App6Name = "";

    an.App7Action = "";
    an.App7BBP = "";
    an.App7Comments = "";
    an.App7Date = "";
    an.App7Email = "";
    an.App7Name = "";

    an.App8Action = "";
    an.App8Comments = "";
    an.App8Date = "";
    an.App8Email = "";
    an.App8Name = "";
    an.App8OVR = "";
    an.App8KeyDecOvr = "";

    an.App9Action = "";
    an.App9Comments = "";
    an.App9Date = "";
    an.App9Email = "";
    an.App9Name = "";

    an.AppL1Action = "";
    an.AppL1Comments = "";
    an.AppL1Date = "";
    an.AppL1Email = "";
    an.AppL1Name = "";
    /*
      these are Request section fields, not workflow:
        AppL1CustReq
        AppL1CustReqDate
        AppL1DeadlineForResponse
     */

    // *deprecated, but reset anyway
    an.AppL2Action = "";
    an.AppL2Comments = "";
    an.AppL2Date = "";
    an.AppL2Email = ""; // this is link approver user from DDL, similar to case manager
    an.AppL2Name = "";

    an.BeaconStart = "YES";
    an.BeaconStage = "";
    an.BeaconStageEnd = "";

    an.CESReviewed = "No";
    an.CurActionGroup = StaticData.wfStatusServicer;

    an.CancelReason = "";

    an.EmailBody = "";
    an.EmailSubject = "";
    an.SendEmail = "";
    an.SendEmailTo = "";

    an.ViewedCES1 = "0";
    an.ViewedCES2 = "0";
    an.ViewedCGI1 = "0";
    an.ViewedCGI2 = "0";
    an.ViewedEO = "0";
    an.ViewedLink1 = "0";
    // an.ViewedLink2 = "0";
    an.ViewedLTH = "0";
    an.ViewedPSI = "0";
    an.ViewedRPE1 = "0";
    an.ViewedRPE2 = "0";
    an.ViewedSRV = "0";

    // an.Addtl_Info = ""; // use TrackingInfo for this form
    an.TrackingInfo = ""; // reset this custom tracking field, i use this as a helper for tracking workflow info/path per step
    an.Browser = ""; // reset browser/user tracking
  }


  function updatePayloadSaveTracking(an: Partial<ANNoteFields>) {

    an.TrackingInfo = safeTrim(memoANNote.TrackingInfo)
      + "trigger=" + NVL(memoSelAppActionOverall, "Button")
      + ";info=" + __tracking
      + "||";
  }


  function updatePayloadForSavingCaseManager(an: Partial<ANNoteFields>) {
    // update payload for saving Case Manager only
    an.CaseManager = safeTrim(stateSelCaseManagerUser); // expecting email address

    if (!isNull(stateSelServicerSubmitter)) {
      // do not allow setting empty/blank since its expecting a lookup value (integer)
      an.Link_x0020_ReviewerLookupId = safeTrim(stateSelServicerSubmitter); // expecting LookupId number
    }
  }


  function updatePayloadSavingMainForm(an: Partial<ANNoteFields>) {
    // update payload for saving all the data in the main form
    // not including Case Manager
    // not including WF sections


    // top section fields

    an.Project = safeTrim(stateSelProject);
    an.Entities = memoProjLUEntity;

    an.AdvisoryNoteType = safeTrim(stateSelANType);
    an.AdvisoryNoteSubtype = safeTrim(stateSelANSubType);

    an.IntExt = memoProjLUIntExt;
    an.ExternalLegalTitleHolder = memoProjLUIntExt;
    an.LTHName = memoProjLULTHName;
    an.Regulated = GenUtil.boolToHtmlYesNo(memoProjLURegulated);

    an.SFS_Info_Available = safeTrim(stateSelSFSInfoAvailable);
    //an.Lender_approval_Security_release = safeTrim(stateSelLenderApprovalSecurityRelease); // DEPRECATED - not used anymore


    // sfs assessment

    an.SFS_Assessment = safeTrim(stateSFSComments);
    an.SFS_ReasonsWhy = safeTrim(stateSFSReasonsWhyStmt);
    an.SFS_IFC = safeTrim(stateSelSfsAssessmentIAFCA);
    an.SFS_IO = safeTrim(stateSelSfsAssessmentIO);
    an.SFS_LTIO = safeTrim(stateSelSfsAssessmentLTIO);
    an.SFS_Moratorium = safeTrim(stateSelSfsAssessmentMFPB);
    an.SFS_Settlement = safeTrim(stateSelSfsAssessmentSettle);
    an.SFS_CapOfArrears = safeTrim(stateSelSfsAssessmentCOA);
    an.SFS_ExtLoanTerm = safeTrim(stateSelSfsAssessmentEOLT);
    an.SFS_RA = safeTrim(stateSelSfsAssessmentRA);
    an.SFS_DebtWriteDown = safeTrim(stateSelSfsAssessmentDWD);
    an.SFS_SplitMortgage = safeTrim(stateSelSfsAssessmentSM);
    an.SFS_LongTIO = safeTrim(stateSelSfsAssessmentLIO);
    an.SFS_MTR = safeTrim(stateSelSfsAssessmentMTR);
    an.SFS_VS = safeTrim(stateSelSfsAssessmentVSale);
    an.SFS_VoluntarySurrender = safeTrim(stateSelSfsAssessmentVSurr);
    an.SFS_AbilityToRepay = safeTrim(stateSelSfsAssessmentATR);
    an.SFS_SustainableARA = safeTrim(stateSelSfsAssessmentSARA);


    // mlots/richtext

    an.Property_Details = safeTrim(statePropertyDetailsBgInfo);
    an.Proposal = safeTrim(stateProposal);
    an.Details = safeTrim(stateDetailsTOC);
    an.Pros = safeTrim(statePros);
    an.SupportingTables = safeTrim(stateSupportingTables);
    an.Recommendation = safeTrim(stateRecommendation);
    an.Comments = safeTrim(stateRecComments);


    // connection metrics section (flat)

    updatePayloadConnMetrics(an);

    an.ReqConnMetricsCES = GenUtil.boolToHtmlYesNo(stateRequestMetricsFromCES);


    // 1:N sections

    // connection driven
    an.ConnectionDetails = memoConnectionDetailsXml;
    an.BorrowerInfo = memoBorLoanInfoXml;
    an.OtherNPLevel = memoOtherNPLevelXml;
    an.OtherNPLevelPDF = memoOtherNPLevelPDFXml; // filtered version without N/A=true rows
    an.AssetInfo = memoAssetInfoXml;
    // user driven
    an.ProposedCostsDetails = memoProposedCostsDetailsXml;
    an.LitigationMatrix = memoLitigationMatrixDetailsXml;
    an.AssetLevelMatrix1 = memoALM1DetailsXml;
    an.AssetLevelMatrix2 = memoALM2DetailsXml;
    an.AssetLevelMatrix3 = memoALM3DetailsXml;
    an.AssetLevelMatrix4 = memoALM4DetailsXml;
    an.ProposalBreakdown = memoProposalBreakdownDetailsXml;
    // NOTE: never save the following, they are used in AN Form only for approver assistance, not for reporting: legal tracker, historical ANs


    // special situation for proposed costs
    an.PropCostNA = GenUtil.boolToHtmlYesNo(stateProposedCostIsNA);
    an.LitigationMatrixNA = GenUtil.boolToHtmlYesNo(stateLitigationMatrixIsNA);


    // section totals

    an.TotalCollections = GenUtil.numberToCurrency(stateAppSectionTotals.connDetailsTotalCollections); // sum the collections in Connection details section (all, not just active)
    an.CollectionsTotal = GenUtil.numberToCurrency(stateAppSectionTotals.borLoanTotalCollections); // sum the borloan collection amounts (active only)
    an.UPBTotal = GenUtil.numberToCurrency(stateAppSectionTotals.borLoanTotalUPB); // sum the borloan upb amounts (active only)
    an.TotalREValue = GenUtil.numberToCurrency(stateAppSectionTotals.assetInfoTotalREVal); // asset section, Real Estate Value, only sum when "Asset Status - Sold?" is NOT "Y"
    an.ProposalBreakdownTotal1 = GenUtil.numberToCurrency(stateAppSectionTotals.propBreakdownTotalExpMinAmt); // Expected Min Amount

    if (stateProposedCostIsNA)
      an.PropCostTotal1 = GenUtil.numberToCurrency(0);
    else
      an.PropCostTotal1 = GenUtil.numberToCurrency(stateAppSectionTotals.propCostTotalCosts); // "Proposed Costs", sum only relevant when all currencies in the coll are the same

    if (stateLitigationMatrixIsNA)
      an.LitTotal1 = GenUtil.numberToCurrency(0);
    else
      an.LitTotal1 = GenUtil.numberToCurrency(stateAppSectionTotals.litMatrixTotalLegalBudget); // legal budget


    // save first ids

    if (stateSelUniqueConns.length > 0) {
      an.Connection_ID = stateSelUniqueConns[0].rpt_ConnectionID;
      an.Connection_Name = stateSelUniqueConns[0].rpt_ConnectionName;
    }
    else {
      an.Connection_ID = "";
      an.Connection_Name = "";
    }

    if (stateBorLoanInfoItems.length > 0)
      an.BorrowerID = stateBorLoanInfoItems[0].rpt_BorrowerID;
    else an.BorrowerID = "";

    if (stateBorLoanInfoItems.length > 0 && stateBorLoanInfoItems[0].col_LoanItems.length > 0)
      an.LoanID1 = stateBorLoanInfoItems[0].col_LoanItems[0].rpt_LoanID;
    else an.LoanID1 = "";


    // when certain sections are hidden, lets wipe them here

    if (!memoShowSectionSfsAssessment) {
      an.SFS_Assessment = "";
      an.SFS_ReasonsWhy = "";
      an.SFS_IFC = "";
      an.SFS_IO = "";
      an.SFS_LTIO = "";
      an.SFS_Moratorium = "";
      an.SFS_Settlement = "";
      an.SFS_CapOfArrears = "";
      an.SFS_ExtLoanTerm = "";
      an.SFS_RA = "";
      an.SFS_DebtWriteDown = "";
      an.SFS_SplitMortgage = "";
      an.SFS_LongTIO = "";
      an.SFS_MTR = "";
      an.SFS_VS = "";
      an.SFS_VoluntarySurrender = "";
      an.SFS_AbilityToRepay = "";
      an.SFS_SustainableARA = "";
    }

    if (!memoShowSectionOtherNPLevel) {
      an.OtherNPLevel = "";
      an.OtherNPLevelPDF = "";
    }

    if (!memoShowSectionConnectionMetrics) {
      an.CMGrossUW = 0;
      an.CMMultUW = 0;
      an.CMIRRUW = 0;
      an.CMWALUM = 0;
      an.CMGrossBP = 0;
      an.CMMultBP = 0;
      an.CMIRRBP = 0;
      an.CMWALBP = 0;
      an.CMGrossDelta = 0;
      an.CMMultDelta = 0;
      an.CMIRRDelta = 0;
      an.CMWALDelta = 0;
    }

    if (!memoShowSectionLitigationMatrix) {
      an.LitigationMatrix = "";
      an.LitTotal1 = "";
    }

    if (!memoShowSectionAlm1) {
      an.AssetLevelMatrix1 = "";
    }

    if (!memoShowSectionAlm2) {
      an.AssetLevelMatrix2 = "";
    }

    if (!memoShowSectionAlm3) {
      an.AssetLevelMatrix3 = "";
    }

    if (!memoShowSectionAlm4) {
      an.AssetLevelMatrix4 = "";
    }

    if (!memoShowSectionProposalBreakdown) {
      an.ProposalBreakdown = "";
      an.ProposalBreakdownTotal1 = "";
    }
  }


  function updatePayloadConnMetrics(an: Partial<ANNoteFields>) {
    // flat connection metrics section

    an.CMGrossUW = GenUtil.safeToNumberOrNull(stateCMCollectionsUW);
    an.CMMultUW = GenUtil.safeToNumberOrNull(stateCMMultipleUW);
    an.CMIRRUW = GenUtil.safeToNumberOrNull(stateCMIRRUW);
    an.CMWALUM = GenUtil.safeToNumberOrNull(stateCMWALUW);

    an.CMGrossBP = GenUtil.safeToNumberOrNull(stateCMCollectionsRevBP);
    an.CMMultBP = GenUtil.safeToNumberOrNull(stateCMMultipleRevBP);
    an.CMIRRBP = GenUtil.safeToNumberOrNull(stateCMIRRRevBP);
    an.CMWALBP = GenUtil.safeToNumberOrNull(stateCMWALRevBP);

    an.CMGrossDelta = GenUtil.safeToNumberOrNull(memoDeltaCMColl);
    an.CMMultDelta = GenUtil.safeToNumberOrNull(memoDeltaCMMult);
    an.CMIRRDelta = GenUtil.safeToNumberOrNull(memoDeltaCMIrr);
    an.CMWALDelta = GenUtil.safeToNumberOrNull(memoDeltaCMWal);
  }


  //#endregion


  //#region 'graph create/update AN wrappers'
  //-------------------------

  // state and function to control the Fluent UI modal/dialog for showing the save/submit result and navigate back to the list
  const [stateShowDialog, setStateShowDialog] = useState<boolean>(false);
  const [stateDialogMsg, setStateDialogMsg] = useState<string>('');


  function handleBackToList() {
    // redirect back to the SP List, do not close the dialog
    //setStateShowDialog(false);
    window.location.href = stateANNotesListWebUrl;
  }


  function graphCreateRecord(an: Partial<ANNoteFields>, uploadAtts: boolean, msg: string, skipDialog: boolean = false) {

    setStateSaving(true);

    setTimeout(async () => {

      let payload: any = { fields: { ...an } };

      AppHelper.aiTrackData(StaticData.defaultBrokerCode, stateANItemId, "Calling graphCreateRecord", an);

      let resp = await insertItem(accounts, instance, Config.Settings().SiteRelPathCabot, Config.Settings().ListTitleANNotesCabot, payload);

      if (resp.httpStatus < 400) {

        let newANId = GenUtil.safeToNumber(resp.id);

        if (uploadAtts) {
          await graphSaveAttachments(newANId, true);
        }

        AppHelper.toastSuccess(msg);

        if (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
          Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
          Consts.admOvrShowAllSectionsFields())) {

          if (!skipDialog) {
            setStateDialogMsg(msg);
            setStateShowDialog(true);
          }

          setStateSaving(false);

        }
        else {
          setTimeout(() => {
            window.location.href = stateANNotesListWebUrl;
          }, 100);
        }

      }
      else {
        AppHelper.toastError(`Error saving Advisory Note to SharePoint: Msg=${resp.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, "Calling graphCreateRecord", resp);
      }

    }, Consts.sleepMsAjax);
  }


  function graphUpdateRecord(an: Partial<ANNoteFields>, msg: string = '', skipDialog: boolean = false) {

    setStateSaving(true);

    setTimeout(async () => {

      // useful for forcing an error
      // an.SendEmail = "helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222helloworld222";

      let payload: any = { ...an };

      AppHelper.aiTrackData(StaticData.defaultBrokerCode, stateANItemId, "Calling graphUpdateRecord", an);

      let resp = await updateItem(accounts, instance, Config.Settings().SiteRelPathCabot, Config.Settings().ListTitleANNotesCabot, stateANItemId, payload);

      if (resp.httpStatus < 400) {

        await graphSaveAttachments(stateANItemId, false);

        AppHelper.toastSuccess(msg);

        if (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
          Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
          Consts.admOvrShowAllSectionsFields())) {

          if (!skipDialog) {
            setStateDialogMsg(msg);
            setStateShowDialog(true);
          }

          setStateSaving(false);

        }
        else {
          setTimeout(() => {
            window.location.href = stateANNotesListWebUrl;
          }, 100);
        }

      }
      else {
        AppHelper.toastError(`Error updating existing Advisory Note in SharePoint: Msg=${resp.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, "Calling graphUpdateRecord", resp);
      }

    }, Consts.sleepMsAjax);
  }


  async function graphSaveAttachments(anItemId: number, isNewItem: boolean): Promise<void> {

    if (!stateAttsEnabled) {
      // if attachments is not enabled because of delay or problems, do not save any attachments changes
      return;
    }

    let attSvcLogicAppUrl = Config.Settings().AttSvcLogicAppUrlCabot;
    let attSvcSiteAbsUrl = Config.Settings().AttSvcSiteAbsUrlCabot;
    let listTitleANNotes = Config.Settings().ListTitleANNotesCabot;
    let siteRelPath = Config.Settings().SiteRelPathCabot;

    let atts = [...stateANAtts];

    // save new files
    for (const o of stateNewAtts) {
      for (const f of o.files) {
        console.log(`ATTACHMENTS: Saving file`, NVL(o.attType, "NA"), f.name, f.size, f.type);

        if (isNull(f.name) || f.size <= 0) {
          AppHelper.toastWarn(`Skip saving empty attachment: ${f.name}`);
        }
        else {
          AppHelper.aiTrackData(StaticData.defaultBrokerCode, anItemId, "Calling addListItemAttachment", { attType: o.attType, name: f.name, size: f.size, type: f.type });

          let resp = await addListItemAttachment(accounts, instance,
            attSvcLogicAppUrl,
            attSvcSiteAbsUrl,
            listTitleANNotes,
            anItemId, f);

          if (resp.httpStatus >= 400) {
            AppHelper.toastError(`Error saving attachment: ${f.name}`);
            AppHelper.aiTrackErr(StaticData.defaultBrokerCode, anItemId, `Error saving attachment`, resp);
          }

          // list item attachment absurl is returned, lets add that to the collection, with the correct category
          atts = [...atts, {
            attType: o.attType,
            fullUrl: resp.data,
            deleteMe: false
          }];
        }
      }
    }

    // delete old files
    for (const id of stateDelAttIds) {
      console.log("ATTACHMENTS: Deleting file", id);

      AppHelper.aiTrackData(StaticData.defaultBrokerCode, anItemId, "Calling deleteListItemAttachment", { id });

      let resp = await deleteListItemAttachment(accounts, instance,
        attSvcLogicAppUrl,
        attSvcSiteAbsUrl,
        listTitleANNotes,
        anItemId, id);

      if (resp.httpStatus >= 400) {
        AppHelper.toastError(`Error deleting attachment: ${id}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, anItemId, `Error deleting attachment`, resp);
      }

      // remove the matching file (based on absurl) from collection, category doesn't matter since filename/path must be unqiue for the whole AN list item
      atts = atts.filter(x => !eq(x.fullUrl, id));
    }

    // save attachment data (JSON and filenames) back to SPListItem
    let attData: string = JSON.stringify(atts.map(x => { return { attType: x.attType, fullUrl: x.fullUrl }; }), null, 2);
    let attNames: string = atts.length <= 0 ? "" : atts.map(x => AppHelper.getFilename(x.fullUrl)).join("; ");

    let doUpdate = (stateNewAtts.length + stateDelAttIds.length) > 0 ||
      !eq(memoANNote.AttachmentData, attData) ||
      !eq(memoANNote.AttachmentFilenames, attNames);

    if (!doUpdate) {
      console.log("Skip sync attachment data with SPListItem, no changes detected.", stateNewAtts.length, stateDelAttIds.length, eq(memoANNote.AttachmentData, attData), eq(memoANNote.AttachmentFilenames, attNames), attData, attNames);
    }
    else {
      console.log("Sync attachment data with SPListItem.");

      let payload = {
        AttachmentData: attData,
        AttachmentFilenames: attNames
      };

      let r = await updateItem(accounts, instance,
        siteRelPath,
        listTitleANNotes,
        anItemId, payload);

      if (r.httpStatus >= 400) {
        AppHelper.toastError(`Error updating AN attachment data.`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, anItemId, `Error updating AN attachment data`, r);
      }
    }
  }

  //#endregion


  //#region 'admin overrides'
  //-------------------------

  const [stateAdmOvrFieldName, setStateAdmOvrFieldName] = useState('');
  const onChangeAdmOvrFieldName = useCallback((evt: any, v?: string) => { setStateAdmOvrFieldName(v || ''); }, []);

  const [stateAdmOvrFieldVal, setStateAdmOvrFieldVal] = useState('');
  const onChangeAdmOvrFieldVal = useCallback((evt: any, v?: string) => { setStateAdmOvrFieldVal(v || ''); }, []);


  function adminSaveForm(mode: number) {

    let an: Partial<ANNoteFields> = {};

    if (mode === 1) {
      // Reset To Draft

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      updatePayloadDefaultWorkflowValues(an);

      graphUpdateRecord(an, 'Admin Override Complete');

      return;

    }
    else if (mode === 2) {
      // clone AN item

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      // clone the current full ANNote object (just the "fields" part, with everything marked as optional)
      let o: any = { ...memoANNote };

      // clear any property that is not in whitelist
      let wl = `;${anNoteFieldNames.map(x => x.trim().toLowerCase()).join(";")};`.replace(/\s+/ig, '');

      for (const key in o) {
        if (Object.prototype.hasOwnProperty.call(o, key)) {
          // const el = o[key];
          if (wl.indexOf(`;${key.toLowerCase()};`) < 0)
            o[key] = undefined;
        }
      }

      // reinit these fields
      o.Title = `AN-${today.toISOString()}`; // ex: 'AN-2022-12-09T18:54:51.044Z'
      o.JsVersion = Consts.version;
      o.TrackingInfo = "Cloned||";
      o.Browser = "Cloned||";
      o.AttachmentFilenames = ""; // attachments are not cloned, so get rid of concat attachment filenames
      o.AttachmentData = ""; // attachments are not cloned, so get rid of saved listitem attachment JSON data

      // wipe these so no emails are sent
      o.SendEmail = "";
      o.SendEmailTo = "";

      graphCreateRecord(o, false, 'Advisory Note Cloned');

      return;

    }
    else if (mode === 3) {
      // Admin Override: Edit Mode is activated
      // NOTE: record is tricked into having current Form Status set to DRAFT, so entire form can be saved, but DO NOT SAVE workflow/status related fields
      // WARNING: some fields could impact workflowpath, but we're not going to set them here, if expected changes to AN record will impact workflow, it would be better to revert to draft

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      updatePayloadSavingMainForm(an);
      updatePayloadForSavingCaseManager(an);

      // wipe these so no emails are sent
      an.SendEmail = "";
      an.SendEmailTo = "";

      graphUpdateRecord(an, 'Admin Override Complete');

      return;

    }
    else if (mode === 4) {
      // Admin Override: Reset AN to CGI1 step, all paths have this step near the end of their lanes

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      an.FormStatus = "CGI1";
      an.WFStatus = "CES recommends to CGI";
      an.ViewedCGI1 = "1";

      an.App1Action = "CES recommends to CGI";
      an.App1Date = todayISO;
      an.App1Email = memoCurUsername;
      an.App1Name = memoCurUserDispName;

      an.App7Action = "";

      an.BeaconStart = "NO";
      an.BeaconStageEnd = safeTrim(an.BeaconStage);
      an.BeaconStage = "CGI1";

      an.SendEmailTo = "";
      an.SendEmail = "No";
      an.EmailSubject = "Please Review Advisory Note!";

      graphUpdateRecord(an, 'Admin Override Complete');

      return;

    }
    else if (mode === 5) {
      // Admin Override: Reset AN to RPE1 step

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      if (inn(memoWorkflowPath, 1)) {
        alert('Cannot revert to RPE1, current workflow path is 1.');
        return;
      }

      an.FormStatus = "RPE1";
      an.WFStatus = "Submit to Regulated Entity";
      an.ViewedRPE1 = "1";

      an.App3Action = "Submit to Regulated Entity";
      an.App3Date = todayISO;
      an.App3Email = memoCurUsername;
      an.App3Name = memoCurUserDispName;

      an.App8Action = "";

      an.BeaconStart = "NO";
      an.BeaconStageEnd = safeTrim(an.BeaconStage);
      an.BeaconStage = "RPE1";

      an.SendEmailTo = "";
      an.SendEmail = "No";
      an.EmailSubject = "Please Review Advisory Note!";

      graphUpdateRecord(an, 'Admin Override Complete');

      return;

    }
    else if (mode === 6) {
      // Admin override: manually set any SPField with a new value

      if (!(window as any).confirm("Are you sure?")) {
        return;
      }

      if (isNull(stateAdmOvrFieldName)) return;

      (an as any)[stateAdmOvrFieldName] = safeTrim(stateAdmOvrFieldVal);

      // wipe these so no emails are sent
      an.SendEmail = "";
      an.SendEmailTo = "";

      graphUpdateRecord(an, 'Admin Override Complete', true);

      return;

    }
    else {
      return;
    }
  }

  //#endregion


  //#region 'form validation'
  //-------------------------

  // do not show validation errors until the user tries to save/submit
  const [stateFormSubmitted, setStateFormSubmitted] = useState<boolean>(false); // track if the submit/save button was clicked by user, to trigger validation checks


  function validateForm(saveOnly: boolean): boolean {

    let isErr = false;

    __badFields = "";
    let fields: string[] = [];

    isErr = isErr || isErrProject();
    isErrProject() && fields.push('Project');
    console.log("validation:", isErr, "isErrProject");

    isErr = isErr || isErrANType();
    isErrANType() && fields.push('AN Type');
    console.log("validation:", isErr, "isErrANType");

    isErr = isErr || isErrANSubType();
    isErrANSubType() && fields.push('AN Sub Type');
    console.log("validation:", isErr, "isErrANSubType");

    isErr = isErr || isErrSFSInfoAvailable();
    isErrSFSInfoAvailable() && fields.push('SFS Assessment Information');
    console.log("validation:", isErr, "isErrSFSInfoAvailable");

    isErr = isErr || isErrConnection();
    isErrConnection() && fields.push('Connection Details');
    console.log("validation:", isErr, "isErrConnection");

    if (!saveOnly) {
      // only validate these next when submitting, not saving (draft/saved)

      // top 1:N sections
      isErr = isErr || isErrBorLoans();
      isErrBorLoans() && fields.push('Borrower & Loan Information');
      console.log("validation:", isErr, "isErrBorLoans");

      isErr = isErr || isErrOtherNPLevelARA();
      isErrOtherNPLevelARA() && fields.push('Alternative Repayment Arrangement');
      console.log("validation:", isErr, "isErrOtherNPLevelARA");

      isErr = isErr || isErrAssetInfo();
      isErrAssetInfo() && fields.push('Asset Information');
      console.log("validation:", isErr, "isErrAssetInfo");

      // special section: Proposed Costs
      isErr = isErr || isErrProposedCosts();
      isErrProposedCosts() && fields.push('Proposed Costs');
      console.log("validation:", isErr, "isErrProposedCosts");

      // mlots
      isErr = isErr || isErrProposalMlot();
      isErrProposalMlot() && fields.push('Proposal');
      console.log("validation:", isErr, "isErrProposalMlot");

      isErr = isErr || isErrDetailsMlot();
      isErrDetailsMlot() && fields.push('Details');
      console.log("validation:", isErr, "isErrDetailsMlot");

      isErr = isErr || isErrProsMlot();
      isErrProsMlot() && fields.push('Pro\'s');
      console.log("validation:", isErr, "isErrProsMlot");

      isErr = isErr || isErrSupportingTablesMlot();
      isErrSupportingTablesMlot() && fields.push('Supporting Tables');
      console.log("validation:", isErr, "isErrSupportingTablesMlot");

      isErr = isErr || isErrRecommendationMlot();
      isErrRecommendationMlot() && fields.push('Recommendation');
      console.log("validation:", isErr, "isErrRecommendationMlot");

      isErr = isErr || isErrCommentsMlot();
      isErrCommentsMlot() && fields.push('Comments');
      console.log("validation:", isErr, "isErrCommentsMlot");

      // flat section: Connection Metrics
      isErr = isErr || isErrConnectionMetrics();
      isErrConnectionMetrics() && fields.push('Connection Metrics');
      console.log("validation:", isErr, "isErrConnectionMetrics");

      // individual fields
      isErr = isErr || isErrCMCollectionsRevBP();
      isErrCMCollectionsRevBP() && fields.push('Collections Rev-BP');
      console.log("validation:", isErr, "isErrCMCollectionsRevBP");

      isErr = isErr || isErrCMCollectionsUW();
      isErrCMCollectionsUW() && fields.push('Collections UW');
      console.log("validation:", isErr, "isErrCMCollectionsUW");

      isErr = isErr || isErrCMIRRRevBP();
      isErrCMIRRRevBP() && fields.push('IRR Rev-BP');
      console.log("validation:", isErr, "isErrCMIRRRevBP");

      isErr = isErr || isErrCMIRRUW();
      isErrCMIRRUW() && fields.push('IRR UW');
      console.log("validation:", isErr, "isErrCMIRRUW");

      isErr = isErr || isErrCMMultipleRevBP();
      isErrCMMultipleRevBP() && fields.push('Multiple Rev-BP');
      console.log("validation:", isErr, "isErrCMMultipleRevBP");

      isErr = isErr || isErrCMMultipleUW();
      isErrCMMultipleUW() && fields.push('Multiple UW');
      console.log("validation:", isErr, "isErrCMMultipleUW");

      isErr = isErr || isErrCMWALRevBP();
      isErrCMWALRevBP() && fields.push('WAL Rev-BP');
      console.log("validation:", isErr, "isErrCMWALRevBP");

      isErr = isErr || isErrCMWALUW();
      isErrCMWALUW() && fields.push('WAL UW');
      console.log("validation:", isErr, "isErrCMWALUW");

      // Litigation Matrix
      isErr = isErr || isErrLitigationMatrix();
      isErrLitigationMatrix() && fields.push('Litigation Matrix');
      console.log("validation:", isErr, "isErrLitigationMatrix");

      // ALM sections
      isErr = isErr || isErrALM3();
      isErrALM3() && fields.push('Asset Level Matrix');
      console.log("validation:", isErr, "isErrALM3");

      isErr = isErr || isErrALM2();
      isErrALM2() && fields.push('Asset Level Matrix');
      console.log("validation:", isErr, "isErrALM2");

      isErr = isErr || isErrALM1();
      isErrALM1() && fields.push('Asset Level Matrix');
      console.log("validation:", isErr, "isErrALM1");

      isErr = isErr || isErrALM4();
      isErrALM4() && fields.push('Asset Level Matrix');
      console.log("validation:", isErr, "isErrALM4");

      // ProposalBreakdown section
      isErr = isErr || isErrProposalBreakdown();
      isErrProposalBreakdown() && fields.push('Proposal Breakdown');
      console.log("validation:", isErr, "isErrProposalBreakdown");

      // Case Manager
      isErr = isErr || isErrCaseManager();
      isErrCaseManager() && fields.push('Case Manager');
      console.log("validation:", isErr, "isErrCaseManager");

      isErr = isErr || isErrServicerSubmitter();
      isErrServicerSubmitter() && fields.push('Servicer Submitter');
      console.log("validation:", isErr, "isErrServicerSubmitter");

      // Workflow fields
      isErr = isErr || isErrAppL1Action();
      isErrAppL1Action() && fields.push('CABOT1 Action');
      console.log("validation:", isErr, "isErrAppL1Action");

      isErr = isErr || isErrApp6Action();
      isErrApp6Action() && fields.push('CES1 Action');
      console.log("validation:", isErr, "isErrApp6Action");

      isErr = isErr || isErrApp3Action();
      isErrApp3Action() && fields.push('Servicer Action');
      console.log("validation:", isErr, "isErrApp3Action");

      isErr = isErr || isErrApp8Action();
      isErrApp8Action() && fields.push('RPE1 Action');
      console.log("validation:", isErr, "isErrApp8Action");

      isErr = isErr || isErrApp4Action();
      isErrApp4Action() && fields.push('PSI Action');
      console.log("validation:", isErr, "isErrApp4Action");

      isErr = isErr || isErrApp1Action();
      isErrApp1Action() && fields.push('CES2 Action');
      console.log("validation:", isErr, "isErrApp1Action");

      isErr = isErr || isErrApp7Action();
      isErrApp7Action() && fields.push('CGI1 Action');
      console.log("validation:", isErr, "isErrApp7Action");

      isErr = isErr || isErrApp2Action();
      isErrApp2Action() && fields.push('CGI2 Action');
      console.log("validation:", isErr, "isErrApp2Action");

      isErr = isErr || isErrApp5Action();
      isErrApp5Action() && fields.push('LTH Action');
      console.log("validation:", isErr, "isErrApp5Action");

      isErr = isErr || isErrApp9Action();
      isErrApp9Action() && fields.push('EO Action');
      console.log("validation:", isErr, "isErrApp9Action");

      isErr = isErr || isErrApp10Action();
      isErrApp10Action() && fields.push('RPE2 Action');
      console.log("validation:", isErr, "isErrApp10Action");

      isErr = isErr || isErrApp6BBP();
      isErrApp6BBP() && fields.push('CES1 BBP');
      console.log("validation:", isErr, "isErrApp6BBP");

      isErr = isErr || isErrApp7BBP();
      isErrApp7BBP() && fields.push('CGI1 BBP');
      console.log("validation:", isErr, "isErrApp7BBP");

      isErr = isErr || isErrApp8OVR();
      isErrApp8OVR() && fields.push('RPE1 Question');
      console.log("validation:", isErr, "isErrApp8OVR");

      isErr = isErr || isErrApp8KeyDecOvr();
      isErrApp8KeyDecOvr() && fields.push('RPE1 Key Decision');
      console.log("validation:", isErr, "isErrApp8KeyDecOvr");

      isErr = isErr || isErrAppL1Comment();
      isErrAppL1Comment() && fields.push('CABOT1 Comment');
      console.log("validation:", isErr, "isErrAppL1Comment");

      isErr = isErr || isErrApp6Comment();
      isErrApp6Comment() && fields.push('CES1 Comment');
      console.log("validation:", isErr, "isErrApp6Comment");

      isErr = isErr || isErrApp3Comment();
      isErrApp3Comment() && fields.push('Servicer Comment');
      console.log("validation:", isErr, "isErrApp3Comment");

      isErr = isErr || isErrApp8Comment();
      isErrApp8Comment() && fields.push('RPE1 Comment');
      console.log("validation:", isErr, "isErrApp8Comment");

      isErr = isErr || isErrApp4Comment();
      isErrApp4Comment() && fields.push('PSI Comment');
      console.log("validation:", isErr, "isErrApp4Comment");

      isErr = isErr || isErrApp1Comment();
      isErrApp1Comment() && fields.push('CES2 Comment');
      console.log("validation:", isErr, "isErrApp1Comment");

      isErr = isErr || isErrApp7Comment();
      isErrApp7Comment() && fields.push('CGI1 Comment');
      console.log("validation:", isErr, "isErrApp7Comment");

      isErr = isErr || isErrApp2Comment();
      isErrApp2Comment() && fields.push('CGI2 Comment');
      console.log("validation:", isErr, "isErrApp2Comment");

      isErr = isErr || isErrApp5Comment();
      isErrApp5Comment() && fields.push('LTH Comment');
      console.log("validation:", isErr, "isErrApp5Comment");

      isErr = isErr || isErrApp9Comment();
      isErrApp9Comment() && fields.push('EO Comment');
      console.log("validation:", isErr, "isErrApp9Comment");

      isErr = isErr || isErrApp10Comment();
      isErrApp10Comment() && fields.push('RPE2 Comment');
      console.log("validation:", isErr, "isErrApp10Comment");
    }

    __badFields = fields.join(", ");

    return !isErr;
  }


  // ======================= top section

  const memoIsErrProject = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProject();
  }, [stateFormSubmitted, stateSelProjectItem]);

  function isErrProject() {
    // always required
    return stateSelProjectItem == null;
  }


  const memoIsErrANType = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrANType();
  }, [stateFormSubmitted, stateSelANTypeObject]);

  function isErrANType() {
    // always required
    return stateSelANTypeObject == null;
  }


  const memoIsErrANSubType = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrANSubType();
  }, [stateFormSubmitted, stateSelANSubTypeObject]);

  function isErrANSubType() {
    // always required
    return stateSelANSubTypeObject == null;
  }


  const memoIsErrSFSInfoAvailable = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrSFSInfoAvailable();
  }, [stateFormSubmitted, stateSelSFSInfoAvailable]);

  function isErrSFSInfoAvailable() {
    // always required
    return isNull(stateSelSFSInfoAvailable);
  }


  // ======================= Connection Details:

  const memoIsErrConnection = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrConnection();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoIsOtherPortfolioLevel, memoShowSectionConnectionDetails, stateSelUniqueConns]);

  function isErrConnection() {
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly)
      return false;
    else if (memoIsOtherPortfolioLevel) {
      // when antype is portfolio, then connection (and all dependant sections) are optional
      return false;
    } else {
      return memoShowSectionConnectionDetails && stateSelUniqueConns.length <= 0;
    }
  }


  // ======================= Borrower Information:

  const memoIsErrBorLoans = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrBorLoans();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoIsOtherPortfolioLevel, memoShowSectionBorrowerInfo, stateBorrowerOptions, stateBorLoanInfoItems]);

  function isErrBorLoans() {
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly)
      return false;
    else if (memoIsOtherPortfolioLevel && stateBorLoanInfoItems.length <= 0) {
      // when antype is portfolio, then connection (and all dependant sections) are optional
      return false;
    }
    else {
      // if user adds any borrowers, do full validation
      let borCount = 0;
      let loanCount = 0;
      let activeLoanCount = 0;
      for (const bo of stateBorLoanInfoItems) {
        let loanCountPerBor = 0;
        let activeLoanCountPerBor = 0;
        borCount++;
        for (const lo of bo.col_LoanItems) {
          loanCountPerBor++;
          loanCount++;
          if (lo.cv_IsActive) {
            activeLoanCount++;
            activeLoanCountPerBor++;
          }
        }
        if (isNull(bo.rpt_RecourseValue)) { // Recourse is required
          return true;
        }
        if (loanCountPerBor > 0 && activeLoanCountPerBor <= 0) { // each Borrower must have atleast one active Loan selected
          return true;
        }
      }
      return memoShowSectionBorrowerInfo && stateBorrowerOptions.length > 0 && (borCount <= 0 || loanCount <= 0 || activeLoanCount <= 0);
    }
  }


  // ======================= ARA (OtherNPLevel):

  const memoIsErrOtherNPLevelARA = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrOtherNPLevelARA();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoShowSectionOtherNPLevel, stateOtherNPLevelItems]);

  function isErrOtherNPLevelARA() {
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly)
      return false;
    let rowCount = 0;
    let emptyFieldCount = 0;
    for (const o of stateOtherNPLevelItems) {
      rowCount++;
      if (isNull(o.rpt_LoanID)) {
        // LoanID is always required
        emptyFieldCount++;
      }
      else if (!GenUtil.safeToBool(o.cv_NotApplicableChecked)) {
        // when N/A is not checked, then all other fields are required
        if (isNull(o.rpt_CommittedOption) ||
          isNull(o.rpt_StartDate) ||
          isNull(o.rpt_EndDate) ||
          isNull(o.rpt_Capatilised) ||
          isNull(o.rpt_ARABroken) ||
          isNull(o.rpt_MonthlyARAPayment) ||
          isNull(o.rpt_DetailOnARA) ||
          isNull(o.rpt_WarehouseSplitAmt) ||
          isNull(o.rpt_WritedownAmt) ||
          // isNull(o.rpt_WritedownDate) || // as of 8-30-23 these are allowed to be null
          // isNull(o.rpt_TermExtensionDate) || // as of 8-30-23 these are allowed to be null
          // isNull(o.rpt_UpdatedInterestDate) || // as of 8-30-23 these are allowed to be null
          isNull(o.rpt_UpdatedInterestRate) ||
          isNull(o.rpt_InterestRateType))
          emptyFieldCount++;
      }
    }
    // all fields are required in each row found
    return memoShowSectionOtherNPLevel && (rowCount > 0 && emptyFieldCount > 0);
  }


  // ======================= Asset Information:

  const memoIsErrAssetInfo = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrAssetInfo();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoIsOtherPortfolioLevel, memoShowSectionAssetInfo, stateAssetOptions, stateAssetInfoItems]);

  function isErrAssetInfo() {
    // 1 or more required when visible, when options are avail too
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly)
      return false;
    else if (memoIsOtherPortfolioLevel && stateAssetInfoItems.length <= 0) {
      return false;
    }
    else {
      // FOH says: "Asset selection should be mandatory unless all assets in the connection are marked 'Sold'."
      // lets assume the user still adds these "Sold" assets to the AN and therefore the asset section has 1 or more rows added, skip determing if all options are Sold="Yes"
      return memoShowSectionAssetInfo && stateAssetOptions.length > 0 && stateAssetInfoItems.length <= 0;
    }
  }


  // ======================= SFS Assessment: ALWAYS OPTIONAL


  // ======================= Proposed Costs:

  const memoIsErrProposedCosts = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProposedCosts();
  }, [stateFormSubmitted, memoANSubTypeLUMandatoryCostTable, stateSelANSubTypeObject, stateProposedCostsDetailsItems, stateProposedCostIsNA]);

  function isErrProposedCosts() {
    if (stateProposedCostIsNA) {
      // N/A checkbox override is selected, no rows needed, section will be empty
      return false;
    }
    else if (!memoANSubTypeLUMandatoryCostTable && stateProposedCostsDetailsItems.length <= 0) {
      // table and rows are optional, no rows added, stop validating
      return false;
    }
    else {
      // NOTE: a default row is added to the table, so we assume that at least one row is added and must be validated
      const showPaidByReceiver = GenUtil.safeToBool(stateSelANSubTypeObject?.fields.PaidByReceiver);

      for (const o of stateProposedCostsDetailsItems) {

        let isRC = GenUtil.safeToBool(o.rpt_RateCard);

        // always validate vendor picker, vendor is always required
        if (isNull(o.dd_Vendors)) return true;

        if (memoANSubTypeLUMandatoryCostTable) {
          // the following are only required if ANType field is "yes"
          if (isNull(o.rpt_RateCard)) return true;

          if (!isRC) {
            if (GenUtil.safeToNumberOrNull(o.rpt_decimal_Costs) == null) return true;
            if (isNull(o.rpt_CostsCurrency)) return true;
          }
          else {
            if (isNull(o.rpt_RCType)) return true;
            if (isNull(o.rpt_RCInstrType)) return true;
            if (GenUtil.safeToNumberOrNull(o.rpt_RCQuantity) == null) return true;
            if (GenUtil.safeToNumberOrNull(o.rpt_RCAmount) == null) return true;
          }

          if (isNull(o.rpt_PaymentMethod)) return true;
          if (showPaidByReceiver && isNull(o.rpt_PaidByReceiver)) return true;
          if (isNull(o.rpt_Description)) return true;
        }
      }

      return false;
    }
  }


  // ======================= MLOTS:

  const memoIsErrProposalMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProposalMlot();
  }, [stateFormSubmitted, stateProposal]);

  function isErrProposalMlot() {
    // always required
    return isNull(stateProposal);
  }


  const memoIsErrDetailsMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrDetailsMlot();
  }, [stateFormSubmitted, stateDetailsTOC]);

  function isErrDetailsMlot() {
    // always required
    return isNull(stateDetailsTOC);
  }


  const memoIsErrProsMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProsMlot();
  }, [stateFormSubmitted, statePros]);

  function isErrProsMlot() {
    // always required
    return isNull(statePros);
  }


  const memoIsErrSupportingTablesMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrSupportingTablesMlot();
  }, [stateFormSubmitted, stateSupportingTables]);

  function isErrSupportingTablesMlot() {
    // always required
    return isNull(stateSupportingTables);
  }


  const memoIsErrRecommendationMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrRecommendationMlot();
  }, [stateFormSubmitted, stateRecommendation]);

  function isErrRecommendationMlot() {
    // always required
    return isNull(stateRecommendation);
  }


  const memoIsErrCommentsMlot = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCommentsMlot();
  }, [stateFormSubmitted, stateRecComments]);

  function isErrCommentsMlot() {
    // always optional
    return false;
  }


  // ======================= Connection Metrics: overall

  const memoIsErrConnectionMetrics = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrConnectionMetrics();
  }, [
    stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES,
    stateCMCollectionsRevBP, stateCMCollectionsUW, stateCMIRRRevBP, stateCMIRRUW,
    stateCMMultipleRevBP, stateCMMultipleUW, stateCMWALRevBP, stateCMWALUW,
  ]);

  function isErrConnectionMetrics() {
    // if checkbox is checked then section is optional
    if (!memoShowSectionConnectionMetrics)
      return false;
    else {
      if (stateRequestMetricsFromCES)
        return false;
      else {
        if (GenUtil.safeToNumberOrNull(stateCMCollectionsRevBP) == null || GenUtil.safeToNumberOrNull(stateCMCollectionsUW) == null ||
          GenUtil.safeToNumberOrNull(stateCMIRRRevBP) == null || GenUtil.safeToNumberOrNull(stateCMIRRUW) == null ||
          GenUtil.safeToNumberOrNull(stateCMMultipleRevBP) == null || GenUtil.safeToNumberOrNull(stateCMMultipleUW) == null ||
          GenUtil.safeToNumberOrNull(stateCMWALRevBP) == null || GenUtil.safeToNumberOrNull(stateCMWALUW) == null)
          return true;
        else
          return false;
      }
    }
  }


  // ======================= Connection Metrics: fields
  // 8 fields, all numbers, optional if checkbox is checked

  function isErrCMFieldHelper(a: string) {
    if (memoShowSectionConnectionMetrics) {
      if (isNull(a)) {
        if (!stateRequestMetricsFromCES) return true; // section visible, empty value found, empty values not allowed
      }
      else {
        if (!GenUtil.hasNumber(a)) return true; // section visible, non empty value found, must be "number-like"
      }
    }
    return false; // default condition, do not wrap this in else
  }


  const memoIsErrCMCollectionsRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMCollectionsRevBP();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMCollectionsRevBP]);

  function isErrCMCollectionsRevBP() {
    return isErrCMFieldHelper(stateCMCollectionsRevBP);
  }


  const memoIsErrCMCollectionsUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMCollectionsUW();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMCollectionsUW]);

  function isErrCMCollectionsUW() {
    return isErrCMFieldHelper(stateCMCollectionsUW);
  }


  const memoIsErrCMIRRRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMIRRRevBP();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMIRRRevBP]);

  function isErrCMIRRRevBP() {
    return isErrCMFieldHelper(stateCMIRRRevBP);
  }


  const memoIsErrCMIRRUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMIRRUW();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMIRRUW]);

  function isErrCMIRRUW() {
    return isErrCMFieldHelper(stateCMIRRUW);
  }


  const memoIsErrCMMultipleRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMMultipleRevBP();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMMultipleRevBP]);

  function isErrCMMultipleRevBP() {
    return isErrCMFieldHelper(stateCMMultipleRevBP);
  }


  const memoIsErrCMMultipleUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMMultipleUW();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMMultipleUW]);

  function isErrCMMultipleUW() {
    return isErrCMFieldHelper(stateCMMultipleUW);
  }


  const memoIsErrCMWALRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMWALRevBP();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMWALRevBP]);

  function isErrCMWALRevBP() {
    return isErrCMFieldHelper(stateCMWALRevBP);
  }


  const memoIsErrCMWALUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCMWALUW();
  }, [stateFormSubmitted, memoShowSectionConnectionMetrics, stateRequestMetricsFromCES, stateCMWALUW]);

  function isErrCMWALUW() {
    return isErrCMFieldHelper(stateCMWALUW);
  }


  // ======================= Litigation Matrix: if you start the table, it must be completed

  const memoIsErrLitigationMatrix = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrLitigationMatrix();
  }, [stateFormSubmitted, memoShowSectionLitigationMatrix, stateLitigationMatrixDetailsItems, stateLitigationMatrixIsNA]);

  function isErrLitigationMatrix() {
    if (stateLitigationMatrixIsNA) {
      // N/A checkbox override is selected, no rows needed, section will be empty
      return false;
    }
    else if (!memoShowSectionLitigationMatrix)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateLitigationMatrixDetailsItems) {
        rowCount++;
        if (isNull(o.rpt_LMProceedings) ||
          isNull(o.rpt_LMLitigationReference) ||
          isNull(o.rpt_LMRecordNumber) ||
          GenUtil.safeToNumberOrNull(o.rpt_LMLegalBudget) == null)
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= ALM3: if you start the table, it must be completed

  const memoIsErrALM3 = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALM3();
  }, [stateFormSubmitted, memoShowSectionAlm3, stateALM3DetailsItems]);

  function isErrALM3() {
    if (!memoShowSectionAlm3)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateALM3DetailsItems) {
        rowCount++;
        if (GenUtil.safeToNumberOrNull(o.rpt_Actual2Gross) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_Actual2Net) == null ||
          isNull(o.rpt_dt_ALAct2Exit) ||
          GenUtil.safeToNumberOrNull(o.rpt_BP2Gross) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_BP2Net) == null ||
          isNull(o.rpt_dt_ALBP2Exit))
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= ALM2: if you start the table, it must be completed

  const memoIsErrALM2 = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALM2();
  }, [stateFormSubmitted, memoShowSectionAlm2, stateALM2DetailsItems]);

  function isErrALM2() {
    if (!memoShowSectionAlm2)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateALM2DetailsItems) {
        rowCount++;
        if (isNull(o.rpt_ALM2_AssetID) ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM2_Reserve) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM2_EstNetSalesProceeds) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM2_BPGDP) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM2_BPNet) == null ||
          isNull(o.rpt_ALM2_BPExitDate))
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= ALM1: if you start the table, it must be completed

  const memoIsErrALM1 = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALM1();
  }, [stateFormSubmitted, memoShowSectionAlm1, stateALM1DetailsItems]);

  function isErrALM1() {
    if (!memoShowSectionAlm1)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateALM1DetailsItems) {
        rowCount++;
        if (isNull(o.rpt_ALM1_AssetID) ||
          GenUtil.safeToNumberOrNull(o.rpt_ALM1_Guide) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM1_BPGDP) == null ||
          isNull(o.rpt_ALM1_BPExitDate))
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= ALM4: if section is shown, all but 2 fields are required
  // also, this is the special section where there will be always 1 and only 1 row added, not 0 or more than 1

  const memoIsErrALM4 = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALM4();
  }, [stateFormSubmitted, memoShowSectionAlm4, stateALM4DetailsItems]);

  function isErrALM4() {
    if (!memoShowSectionAlm4)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateALM4DetailsItems) {
        rowCount++;
        if (isNull(o.rpt_ALM4_EOS) ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_SalesProceeds) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_RentalIncome) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_TotalIncome) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_ReceiversFee) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_ReceiverOutlays) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_SalesAgentFee) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_SaleAgentMarketingCosts) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_LegalFeesConveyance) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_LegalFeesLegalOutlay) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_TaxAdvise) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_BERCerts) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_Insurance) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_ServiceCharges) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_Rates) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_RepairsMaintenanceCleanup) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_CGT) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_HHC) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_LPT) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_NPPR) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_BankCharge) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_TotalIncVAT) == null ||
          GenUtil.safeToNumberOrNull(o.rpt_decimal_ALM4_NetProceeds) == null)
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= ProposalBreakdown: if you start the table, it must be completed

  const memoIsErrProposalBreakdown = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProposalBreakdown();
  }, [stateFormSubmitted, memoShowSectionProposalBreakdown, stateProposalBreakdownDetailsItems]);

  function isErrProposalBreakdown() {
    if (!memoShowSectionProposalBreakdown)
      return false;
    else {
      let rowCount = 0;
      let emptyFieldCount = 0;
      for (const o of stateProposalBreakdownDetailsItems) {
        rowCount++;
        if (isNull(o.rpt_EarliestExpectedDate) ||
          GenUtil.safeToNumberOrNull(o.cv_ExpMinAmtOrig) == null ||
          isNull(o.cv_ExpMinAmtCurOrig) ||
          isNull(o.rpt_Source))
          emptyFieldCount++;
      }
      return emptyFieldCount > 0;
    }
  }


  // ======================= Case Manager
  const memoIsErrCaseManager = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCaseManager();
  }, [stateFormSubmitted, stateSelCaseManagerUser]);

  function isErrCaseManager() {
    // required for submitting
    return isNull(stateSelCaseManagerUser);
  }

  // ======================= Servicer Submitters
  const memoIsErrServicerSubmitter = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrServicerSubmitter();
  }, [stateFormSubmitted, stateSelServicerSubmitter]);

  function isErrServicerSubmitter() {
    // required for submitting
    return isNull(stateSelServicerSubmitter);
  }



  // ======================= Workflow Sections


  // ======================= field: Actions ddl
  // NOTE: if section is not hidden, not readonly, then the user must make a choice

  const memoIsErrAppL1Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrAppL1Action();
  }, [stateFormSubmitted, memoWFSectionHideAppL1, memoWFSectionDisableAppL1, stateSelAppL1Action]);

  function isErrAppL1Action() {
    return !memoWFSectionHideAppL1 && !memoWFSectionDisableAppL1 && !stateSelAppL1Action;
  }


  // *deprecated
  // const memoIsErrAppL2Action = useMemo<boolean>(() => {
  //   if (!stateFormSubmitted) return false;
  //   return isErrAppL2Action();
  // }, [stateFormSubmitted, memoWFSectionHideAppL2, memoWFSectionDisableAppL2, stateSelAppL2Action]);

  // function isErrAppL2Action() {
  //   return !memoWFSectionHideAppL2 && !memoWFSectionDisableAppL2 && !stateSelAppL2Action;
  // }


  const memoIsErrApp6Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6Action();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, stateSelApp6Action]);

  function isErrApp6Action() {
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && !stateSelApp6Action;
  }


  const memoIsErrApp3Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp3Action();
  }, [stateFormSubmitted, memoWFSectionHideApp3, memoWFSectionDisableApp3, stateSelApp3Action]);

  function isErrApp3Action() {
    return !memoWFSectionHideApp3 && !memoWFSectionDisableApp3 && !stateSelApp3Action;
  }


  const memoIsErrApp8Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp8Action();
  }, [stateFormSubmitted, memoWFSectionHideApp8, memoWFSectionDisableApp8, stateSelApp8Action]);

  function isErrApp8Action() {
    return !memoWFSectionHideApp8 && !memoWFSectionDisableApp8 && !stateSelApp8Action;
  }


  const memoIsErrApp4Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp4Action();
  }, [stateFormSubmitted, memoWFSectionHideApp4, memoWFSectionDisableApp4, stateSelApp4Action]);

  function isErrApp4Action() {
    return !memoWFSectionHideApp4 && !memoWFSectionDisableApp4 && !stateSelApp4Action;
  }


  const memoIsErrApp1Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp1Action();
  }, [stateFormSubmitted, memoWFSectionHideApp1, memoWFSectionDisableApp1, stateSelApp1Action]);

  function isErrApp1Action() {
    return !memoWFSectionHideApp1 && !memoWFSectionDisableApp1 && !stateSelApp1Action;
  }


  const memoIsErrApp7Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7Action();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, stateSelApp7Action]);

  function isErrApp7Action() {
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && !stateSelApp7Action;
  }


  const memoIsErrApp2Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp2Action();
  }, [stateFormSubmitted, memoWFSectionHideApp2, memoWFSectionDisableApp2, stateSelApp2Action]);

  function isErrApp2Action() {
    return !memoWFSectionHideApp2 && !memoWFSectionDisableApp2 && !stateSelApp2Action;
  }


  const memoIsErrApp5Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp5Action();
  }, [stateFormSubmitted, memoWFSectionHideApp5, memoWFSectionDisableApp5, stateSelApp5Action]);

  function isErrApp5Action() {
    return !memoWFSectionHideApp5 && !memoWFSectionDisableApp5 && !stateSelApp5Action;
  }


  const memoIsErrApp9Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp9Action();
  }, [stateFormSubmitted, memoWFSectionHideApp9, memoWFSectionDisableApp9, stateSelApp9Action]);

  function isErrApp9Action() {
    return !memoWFSectionHideApp9 && !memoWFSectionDisableApp9 && !stateSelApp9Action;
  }


  const memoIsErrApp10Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp10Action();
  }, [stateFormSubmitted, memoWFSectionHideApp10, memoWFSectionDisableApp10, stateSelApp10Action]);

  function isErrApp10Action() {
    return !memoWFSectionHideApp10 && !memoWFSectionDisableApp10 && !stateSelApp10Action;
  }


  // ======================= field: Comments mlot
  // NOTE: comments are required when each section is not hidden, not disabled, and user choice is "Reject" or "Request Additional Information"

  const memoIsErrAppL1Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrAppL1Comment();
  }, [stateFormSubmitted, memoWFSectionHideAppL1, memoWFSectionDisableAppL1, stateAppL1Comment, stateSelAppL1Action]);

  function isErrAppL1Comment() {
    return !memoWFSectionHideAppL1 && !memoWFSectionDisableAppL1 && isNull(stateAppL1Comment) && inn(stateSelAppL1Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  // *deprecated
  // const memoIsErrAppL2Comment = useMemo<boolean>(() => {
  //   if (!stateFormSubmitted) return false;
  //   return isErrAppL2Comment();
  // }, [stateFormSubmitted, memoWFSectionHideAppL2, memoWFSectionDisableAppL2, stateAppL2Comment, stateSelAppL2Action]);

  // function isErrAppL2Comment() {
  //   return !memoWFSectionHideAppL2 && !memoWFSectionDisableAppL2 && isNull(stateAppL2Comment) && inn(stateSelAppL2Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  // }


  const memoIsErrApp6Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, stateApp6Comment, stateSelApp6Action]);

  function isErrApp6Comment() {
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && isNull(stateApp6Comment) && inn(stateSelApp6Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo, StaticData.wfActionNotSupported);
  }


  const memoIsErrApp3Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp3Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp3, memoWFSectionDisableApp3, stateApp3Comment, stateSelApp3Action]);

  function isErrApp3Comment() {
    return !memoWFSectionHideApp3 && !memoWFSectionDisableApp3 && isNull(stateApp3Comment) && inn(stateSelApp3Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp8Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp8Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp8, memoWFSectionDisableApp8, stateApp8Comment, stateSelApp8Action]);

  function isErrApp8Comment() {
    return !memoWFSectionHideApp8 && !memoWFSectionDisableApp8 && isNull(stateApp8Comment) && inn(stateSelApp8Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp4Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp4Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp4, memoWFSectionDisableApp4, stateApp4Comment, stateSelApp4Action]);

  function isErrApp4Comment() {
    return !memoWFSectionHideApp4 && !memoWFSectionDisableApp4 && isNull(stateApp4Comment) && inn(stateSelApp4Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp1Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp1Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp1, memoWFSectionDisableApp1, stateApp1Comment, stateSelApp1Action]);

  function isErrApp1Comment() {
    return !memoWFSectionHideApp1 && !memoWFSectionDisableApp1 && isNull(stateApp1Comment) && inn(stateSelApp1Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp7Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, stateApp7Comment, stateSelApp7Action]);

  function isErrApp7Comment() {
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && isNull(stateApp7Comment) && inn(stateSelApp7Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp2Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp2Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp2, memoWFSectionDisableApp2, stateApp2Comment, stateSelApp2Action]);

  function isErrApp2Comment() {
    return !memoWFSectionHideApp2 && !memoWFSectionDisableApp2 && isNull(stateApp2Comment) && inn(stateSelApp2Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp5Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp5Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp5, memoWFSectionDisableApp5, stateApp5Comment, stateSelApp5Action]);

  function isErrApp5Comment() {
    return !memoWFSectionHideApp5 && !memoWFSectionDisableApp5 && isNull(stateApp5Comment) && inn(stateSelApp5Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp9Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp9Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp9, memoWFSectionDisableApp9, stateApp9Comment, stateSelApp9Action]);

  function isErrApp9Comment() {
    return !memoWFSectionHideApp9 && !memoWFSectionDisableApp9 && isNull(stateApp9Comment) && inn(stateSelApp9Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  const memoIsErrApp10Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp10Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp10, memoWFSectionDisableApp10, stateApp10Comment, stateSelApp10Action]);

  function isErrApp10Comment() {
    return !memoWFSectionHideApp10 && !memoWFSectionDisableApp10 && isNull(stateApp10Comment) && inn(stateSelApp10Action, StaticData.wfActionReject, StaticData.wfActionRequestAddtlInfo);
  }


  // ======================= field: BBP ddl

  const memoIsErrApp6BBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6BBP();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, memoHideBBP, stateSelApp6BBP]);

  function isErrApp6BBP() {
    // if section is not hidden, not disabled, and BBP is not hidden, then user must make choice at this step
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && !memoHideBBP && isNull(stateSelApp6BBP);
  }


  const memoIsErrApp7BBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7BBP();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, memoHideBBP, stateSelApp7BBP]);

  function isErrApp7BBP() {
    // if section is not hidden, not disabled, and BBP is not hidden, then user must make choice at this step
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && !memoHideBBP && isNull(stateSelApp7BBP);
  }


  // ======================= field: special ddl

  // ----------- app8Ovr

  const memoIsErrApp8OVR = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp8OVR();
  }, [stateFormSubmitted, memoWFSectionHideApp8, memoWFSectionDisableApp8, stateSelApp8OVR]);

  function isErrApp8OVR() {
    // if section is not hidden, not disabled, then user must make choice at this step
    return !memoWFSectionHideApp8 && !memoWFSectionDisableApp8 && isNull(stateSelApp8OVR);
  }

  // ----------- App8KeyDecOcr

  const memoIsErrApp8KeyDecOvr = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp8KeyDecOvr();
  }, [stateFormSubmitted, memoWFSectionHideApp8, memoWFSectionDisableApp8, stateSelApp8KeyDecOvr]);

  function isErrApp8KeyDecOvr() {
    // if section is not hidden, not disabled, then user must make choice at this step
    return !memoWFSectionHideApp8 && !memoWFSectionDisableApp8 && isNull(stateSelApp8KeyDecOvr);
  }

  //#endregion


  //#region 'RENDER'
  //-------------------------


  /*
 
  ########  ######## ##    ## ########  ######## ########
  ##     ## ##       ###   ## ##     ## ##       ##     ##
  ##     ## ##       ####  ## ##     ## ##       ##     ##
  ########  ######   ## ## ## ##     ## ######   ########
  ##   ##   ##       ##  #### ##     ## ##       ##   ##
  ##    ##  ##       ##   ### ##     ## ##       ##    ##
  ##     ## ######## ##    ## ########  ######## ##     ##
 
*/

  return (
    <>
      <Stack tokens={Consts.stackTokens}>

        <h1 className="flu-page-title2 flu-bottomborder1 wbss">Advisory Note<span className="flu-page-title-sub2">{StaticData.defaultServicerName}</span></h1>

        {Consts.isDevEnv() && (
          <MessageBar messageBarType={MessageBarType.warning} className='wbss'>
            {`DEVELOPER ENVIRONMENT`}
          </MessageBar>
        )}

        <MessageBar messageBarType={MessageBarType.warning} className='wbss'>
          {`Please only use the latest Chrome or Edge browsers or else unexpected results may occur.`}
        </MessageBar>

        {
          (statePageLoading || stateLoadingANItem) && (
            <div className='wrapper25'>
              <Spinner label="Loading data, please wait..." size={SpinnerSize.large} />
            </div>
          )
        }

        {
          (!statePageLoading && !stateLoadingANItem) && (
            <>




              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Advisory Note Details</h2>


                <div className="ms-Grid" dir="ltr">

                  <div className="ms-Grid-row">
                    {
                      !isNull(memoANNote.AN_No) && (
                        <div className="ms-Grid-col ms-sm6">
                          <Label>AN:</Label>
                          <Label className='ms-fontWeight-regular'>{memoANNote.AN_No}</Label>
                        </div>
                      )
                    }
                    <div className="ms-Grid-col ms-sm6">
                      <Label>Status:</Label>
                      <Label className='ms-fontWeight-regular'>{NVL(memoANNote.WFStatus, StaticData.wfStatusDraft)}</Label>
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {!memoFormIsReadOnly && (
                        <ComboBox
                          disabled={stateLoadingConnection || stateSelUniqueConns.length > 0}
                          selectedKey={stateSelProject}
                          label="Project Name:"
                          placeholder='Select a Project'
                          options={stateAllANEntities.filter(o => !isNull(o.fields.Title)).map((o, i) => {
                            return { key: o.fields.Title, text: o.fields.Title };
                          })}
                          // autoComplete={'on'}
                          onChange={onProjectNameChange}
                          errorMessage={memoIsErrProject ? 'Project is required.' : ''}
                        />
                      )}
                      {memoFormIsReadOnly && (<>
                        <Label>Project Name:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelProject}</Label>
                      </>)}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      <Label>Entities:</Label>
                      <Label className='ms-fontWeight-regular'>{memoProjLUEntity}</Label>
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {!memoFormIsReadOnly && (
                        <ComboBox
                          disabled={stateSelProjectItem == null}
                          selectedKey={stateSelANType}
                          label="Type of AN:"
                          placeholder='Select a Type'
                          options={stateANTypeOptions}
                          // autoComplete={'on'}
                          onChange={onANTypeChange}
                          errorMessage={memoIsErrANType ? 'Type is required.' : ''}
                        />
                      )}
                      {memoFormIsReadOnly && (<>
                        <Label>Type of AN:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelANType}</Label>
                      </>)}
                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      {!memoFormIsReadOnly && (
                        <ComboBox
                          disabled={stateSelProjectItem == null || stateSelANTypeObject == null}
                          selectedKey={stateSelANSubType}
                          label="Subtype of Advisory Note:"
                          placeholder='Select a Subtype'
                          options={stateANSubTypeOptions}
                          // autoComplete={'on'}
                          onChange={onANSubTypeChange}
                          errorMessage={memoIsErrANSubType ? 'Subtype is required.' : ''}
                        />
                      )}
                      {memoFormIsReadOnly && (<>
                        <Label>Subtype of Advisory Note:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelANSubType}</Label>
                      </>)}
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {!memoFormIsReadOnly && (
                        <>
                          <Label>Does the Case Manager have SFS Assessment Information?</Label>
                          <ComboBox
                            className=''
                            selectedKey={stateSelSFSInfoAvailable}
                            // label="Does the Case Manager have SFS Assessment Information"
                            placeholder='Choose...'
                            options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                            autoComplete={'on'}
                            onChange={onChangeSFSInfoAvailable}
                            errorMessage={memoIsErrSFSInfoAvailable ? 'SFS choice is required.' : ''}
                          />
                        </>
                      )}
                      {memoFormIsReadOnly && (<>
                        <Label>Does the Case Manager have SFS Assessment Information?</Label>
                        <Label className='ms-fontWeight-regular'>{stateSelSFSInfoAvailable}</Label>
                      </>)}
                    </div>

                    <div className="ms-Grid-col ms-sm6">

                      <Label>{`Key Decision:`}</Label>
                      <Label className='ms-fontWeight-regular'>{GenUtil.boolToHtmlYesNo(memoKeyDecision)}</Label>

                      {/* DEPRECATED - keep internal, do not show */}
                      {/* <Label>{`Internal/External LTH:`}</Label>
                      <Label className='ms-fontWeight-regular'>{memoProjLUIntExt}</Label> */}

                      {/* DEPRECATED - not used anymore */}
                      {/* <ComboBox
                        className=''
                        selectedKey={stateSelLenderApprovalSecurityRelease}
                        label="Senior Lending Approval Release:"
                        options={StaticData.luYesNoNA.split(',').map(o => { return { key: o, text: o }; })}
                        autoComplete={'on'}
                        onChange={onChangeLenderApprovalSecurityRelease}
                      /> */}

                    </div>
                  </div>

                </div>

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul debug2col'>
                      <li>FormStatus (Actual/Memo): {memoANNote.FormStatus || ''} / {memoFormStatus || ''}</li>
                      <li>memoALMTypeNum: {memoALMTypeNum + ''}</li>
                      <li>memoWorkflowPath: {memoWorkflowPath + ''}</li>
                      <li>memoProjLURegulated: <input type='checkbox' checked={memoProjLURegulated} onChange={() => { }} /></li>
                      <li>memoProjLUIntExt: {memoProjLUIntExt || ""}</li>
                      <li>memoCCMAFlag: <input type='checkbox' checked={memoCCMAFlag} onChange={() => { }} /></li>
                      <li>memoKeyDecision: <input type='checkbox' checked={memoKeyDecision} onChange={() => { }} /></li>
                      <li>stateSelApp8KeyDecOvr: {stateSelApp8KeyDecOvr + ''}</li>
                      <li>memoShowBBP: <input type='checkbox' checked={memoShowBBP} onChange={() => { }} /></li>
                      <li>stateSelApp6BBP: {stateSelApp6BBP + ''}</li>
                      <li>stateSelApp7BBP: {stateSelApp7BBP + ''}</li>
                      <li>stateSelApp8OVR: {stateSelApp8OVR + ''}</li>
                    </ul>
                  )
                }

              </div>











              {
                memoShowSectionConnectionDetails && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Connection Details</h2>

                      {
                        !memoFormIsReadOnly && memoShowConnectionPickerSection && (
                          <>

                            <Stack tokens={{ childrenGap: 2 }} className='wbss'>
                              <Label>{`Connection ID:`}</Label>
                              <Stack tokens={Consts.stackTokens} horizontal>
                                <AjaxPicker
                                  disabled={stateSaving}
                                  itemLimit={1}
                                  getSuggestedTags={getSuggestedANConnections}
                                  onTagsChanged={onTagsChangedANConnections}
                                  selectedTags={stateSelANConnectionPicker}
                                  cssClassNames='w400'
                                  noResultsFoundText=''
                                  suggestionsHeaderText='Search for Connection'
                                  searchingText='Loading...'
                                  placeholder={`Enter Connection ID`}
                                />
                                <PrimaryButton text="Add Connection ID" allowDisabledFocus onClick={handleAddConnectionId} disabled={stateLoadingConnection || stateSaving} />
                                {memoShowDeleteAllTablesButton && <PrimaryButton text="Delete All Tables" allowDisabledFocus onClick={handleDeleteAllTables} disabled={stateLoadingConnection || stateSaving} />}
                                {stateLoadingConnection && <Spinner label='Loading...' size={SpinnerSize.small} labelPosition={'right'} />}
                              </Stack>
                            </Stack>

                          </>
                        )
                      }

                      {
                        memoFormIsReadOnly && stateANNote && (
                          <ANConnectionDetailRO
                            project={stateSelProjectItem}
                            data={stateANNote.fields.ConnectionDetails}
                            onTotalUpdated={onUpdateAppSectionTotals} />
                        )
                      }

                      {
                        !memoFormIsReadOnly && stateSelUniqueConns.length > 0 && (
                          <ANConnectionDetail
                            uniqueConns={stateSelUniqueConns}
                            project={stateSelProjectItem}
                            onChangeConn={onChangeConnectionDetail}
                            onDeleteConn={onDeleteConnectionDetail}
                            onTotalUpdated={onUpdateAppSectionTotals}
                            stateFormSubmitted={stateFormSubmitted}
                            saving={stateSaving} />
                        )
                      }

                      {
                        memoIsErrConnection && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`Connection is required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateSelProject: {stateSelProject}</li>
                            <li>stateSelProjectItem: {JSON.stringify(stateSelProjectItem, null, 2)}</li>
                            <hr></hr>
                            {/* <li>stateANTypeOptions: {JSON.stringify(stateANTypeOptions, null, 2)}</li> */}
                            <li>stateSelANType: {stateSelANType}</li>
                            <li>stateSelANTypeObject: {JSON.stringify(stateSelANTypeObject, null, 2)}</li>
                            <li>stateSelANSubType: {stateSelANSubType}</li>
                            <li>stateSelANSubTypeObject: {JSON.stringify(stateSelANSubTypeObject, null, 2)}</li>
                            <hr></hr>
                            <li>stateSelANConnectionPicker: {JSON.stringify(stateSelANConnectionPicker, null, 2)}</li>
                            <li>memoConnPickerId: {memoConnPickerId}</li>
                            <hr></hr>
                            <li>stateANNote.fields.ConnectionDetails: {stateANNote && stateANNote.fields.ConnectionDetails}</li>
                            <li>stateANSavedConnDetails: {stateANSavedConnDetails.length}, {JSON.stringify(stateANSavedConnDetails, null, 2)}</li>
                            <hr></hr>
                            <li>stateCurANConnIDs: {stateCurANConnIDs.length}, {JSON.stringify(stateCurANConnIDs, null, 2)}</li>
                            <li>stateSelUniqueConns: {stateSelUniqueConns.length}, {JSON.stringify(stateSelUniqueConns, null, 2)}</li>
                            <li>stateSelAllConns: {stateSelAllConns.length}</li>
                            {/* <li>stateSelAllConns: {stateSelAllConns.length}, {JSON.stringify(stateSelAllConns, null, 2)}</li> */}
                          </ul>
                        )
                      }

                    </div>
                  </>
                )
              }






              {
                memoShowSectionBorrowerInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">{`Borrower & Loan Information`}</h2>

                      <BorLoanBorBody
                        xml={stateBorLoanInfo}
                        borOptions={stateBorrowerOptions}
                        // loanOptions={stateLoanOptions}
                        borConnections={stateBorrowerObjects}
                        loanConnections={stateLoanObjects}
                        currencys={stateAllANCurrencys}
                        onDataUpdated={onUpdateSectionData}
                        onTotalUpdated={onUpdateAppSectionTotals}
                        isReadOnly={memoFormIsReadOnly}
                        removeConnId={stateBorrowerConnId2Del}
                        stateFormSubmitted={stateFormSubmitted}
                        saving={stateSaving}
                      />

                      {
                        memoIsErrBorLoans && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Borrowers are required, each Borrower must have one or more Loans selected, and Borrower Recourse value must be selected.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateBorLoanInfoItems: {stateBorLoanInfoItems.length}, {JSON.stringify(stateBorLoanInfoItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              }





              {/* {
                memoShowSectionBorrowerInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Borrower Information</h2>

                      <BorrowerInfoBody
                        options={stateBorrowerOptions}
                        data={stateBorrowerInfo}
                        connections={stateBorrowerObjects}
                        onDataUpdated={onUpdateSectionData}
                        isReadOnly={memoFormIsReadOnly}
                        removeConnId={stateBorrowerConnId2Del} />

                      {
                        memoIsErrBorrowerInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Borrowers are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateBorrowerInfoItems: {stateBorrowerInfoItems.length}, {JSON.stringify(stateBorrowerInfoItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              } */}






              {/* {
                memoShowSectionLoanInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Loan Information</h2>

                      <LoanInfoBody
                        options={stateLoanOptions}
                        data={stateLoanInfo}
                        connections={stateLoanObjects}
                        onDataUpdated={onUpdateSectionData}
                        isReadOnly={memoFormIsReadOnly}
                        currencys={stateAllANCurrencys}
                        removeConnId={stateLoanConnId2Del} />

                      {
                        memoIsErrLoanInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Loans are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateLoanInfoItems: {stateLoanInfoItems.length}, {JSON.stringify(stateLoanInfoItems, null, 2)}</li>
                            <li>stateOtherNPLevelItems: {stateOtherNPLevelItems.length}, {JSON.stringify(stateOtherNPLevelItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              } */}




              {/* {
                memoShowSectionSuppDetailsInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Supporting Details<span className="flu-page-title-sub1">ccma loans</span></h2>

                      <SupportingDetailsRO
                        data={stateSupportingDetailsItems}
                      ></SupportingDetailsRO>

                    </div>
                  </>
                )
              } */}


              {
                memoShowSectionOtherNPLevel && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Alternative Repayment Arrangement (ARA)</h2>

                      <OtherNPLevelBody
                        xml={stateOtherNPLevel}
                        loans={stateBorLoanInfoItems}
                        loansAsJson={JSON.stringify(stateBorLoanInfoItems)}
                        araDetails={stateAllARADetailtems}
                        onDataUpdated={onUpdateSectionData}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly}
                      ></OtherNPLevelBody>

                      {/* {
                        memoIsErrOtherNPLevelARA && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more ARA rows are invalid.`}
                            </MessageBar>
                          </>
                        )
                      } */}

                    </div>
                  </>
                )
              }








              {
                memoShowSectionAssetInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Information</h2>

                      {
                        !memoFormIsReadOnly && stateAssetOptions.length <= 0 && (
                          <>
                            <MessageBar messageBarType={MessageBarType.warning} className='wbss'>
                              {`No Assets found for selected Connection(s).`}
                            </MessageBar>
                          </>
                        )
                      }

                      <AssetInfoBody
                        options={stateAssetOptions}
                        xml={stateAssetInfo}
                        connections={stateAssetObjects}
                        currencys={stateAllANCurrencys}
                        onDataUpdated={onUpdateSectionData}
                        onTotalUpdated={onUpdateAppSectionTotals}
                        isReadOnly={memoFormIsReadOnly}
                        removeConnId={stateAssetConnId2Del}
                        saving={stateSaving} />

                      {
                        memoIsErrAssetInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Assets are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateAssetInfoItems: {stateAssetInfoItems.length}, {JSON.stringify(stateAssetInfoItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              }









              {/* INFO: rename this section as per dev request #87, hide this section as per dev request #101 */}
              {/* <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Background Info</h2>

                <Stack tokens={Consts.stackTokens}>

                  <RichTextArea content={statePropertyDetailsBgInfo} onUpdate={() => { }} onUpdate2={onChangePropertyDetailsBgInfo}></RichTextArea>

                </Stack>

              </div> */}







              {
                memoShowSectionSfsAssessment && (
                  <div className='flu-section'>

                    <h2 className="flu-heading1 wbsss">SFS Assessment</h2>

                    <div className='wbss'>
                      {!memoFormIsReadOnly && (
                        <TextField label='Assessment:' onChange={onChangeSFSComments} value={stateSFSComments} placeholder='Please enter text here' className='' multiline rows={2} maxLength={Consts.maxLengthComments} />
                      )}
                      {memoFormIsReadOnly && (<>
                        <Label>Assessment:</Label>
                        <Label className='ms-fontWeight-regular'>{stateSFSComments}</Label>
                      </>)}
                    </div>

                    <table className='sub-table2'>
                      <tbody>

                        <tr>
                          <td className='w100'><Label>Interest and Fixed Capital Arrangement (IFC):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentIAFCA}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentIAFCA.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentIAFCA}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentIAFCA}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Interest Only (IO):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentIO}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentIO.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentIO}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentIO}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Less than Interest Only (LTIO):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentLTIO}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentLTIO.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentLTIO}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentLTIO}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Moratorium - Full payment break:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentMFPB}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentMFPB.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentMFPB}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentMFPB}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Settlement:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentSettle}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentSettle.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentSettle}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentSettle}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Capitalisation of Arrears:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentCOA}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentCOA.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentCOA}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentCOA}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Extension of Loan Term:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentEOLT}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentEOLT.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentEOLT}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentEOLT}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Rate Amendment (RA):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentRA}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentRA.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentRA}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentRA}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Debt Write Down:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentDWD}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentDWD.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentDWD}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentDWD}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Split Mortgage:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentSM}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentSM.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentSM}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentSM}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Long-term Interest Only:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentLIO}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentLIO.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentLIO}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentLIO}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Mortgage to Rent (MTR):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentMTR}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentMTR.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentMTR}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentMTR}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Voluntary Sale (VS):</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentVSale}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentVSale.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentVSale}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentVSale}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Voluntary Surrender:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentVSurr}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentVSurr.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentVSurr}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentVSurr}</Label>
                            </>)}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            {!memoFormIsReadOnly && (
                              <TextField label='Reasons Why Statement:' onChange={onChangeSFSReasonsWhyStmt} value={stateSFSReasonsWhyStmt} placeholder='Please enter text here' className='' multiline rows={2} maxLength={Consts.maxLengthComments} />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label>Reasons Why Statement:</Label>
                              <Label className='ms-fontWeight-regular'>{stateSFSReasonsWhyStmt}</Label>
                            </>)}
                          </td>
                        </tr>

                        <tr>
                          <td className=''><Label>Please select customers ability to repay:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentATR}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentATR.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentATR}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentATR}</Label>
                            </>)}
                          </td>
                        </tr>
                        <tr>
                          <td className=''><Label>Is this a sustainable ARA:</Label></td>
                          <td>
                            {!memoFormIsReadOnly && (
                              <ComboBox
                                disabled={false}
                                selectedKey={stateSelSfsAssessmentSARA}
                                placeholder='Please select a value'
                                options={StaticData.luSfsAssessmentSARA.split(',').map(o => { return { key: o, text: o }; })}
                                onChange={onChangeSfsAssessmentSARA}
                              />
                            )}
                            {memoFormIsReadOnly && (<>
                              <Label className='ms-fontWeight-regular'>{stateSelSfsAssessmentSARA}</Label>
                            </>)}
                          </td>
                        </tr>

                      </tbody>
                    </table>

                  </div>
                )
              }







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Proposal</h2>
                {/* aka Request */}

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateProposal || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      {/* <Label className='ms-fontWeight-regular'>Heading:</Label> */}
                      <RichTextArea content={stateProposal} onUpdate={() => { }} onUpdate2={onChangeProposal} />
                      {
                        memoIsErrProposalMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Proposal is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>









              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Details / Terms of Contracts</h2>

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateDetailsTOC || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <RichTextArea content={stateDetailsTOC} onUpdate={() => { }} onUpdate2={onChangeDetailsTOC} />
                      {
                        memoIsErrDetailsMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Details / Terms of Contracts is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Proposed Costs</h2>

                <div className='wbsss'>
                  <Checkbox label="No Proposed Costs associated with this AN" checked={stateProposedCostIsNA} onChange={memoFormIsReadOnly ? () => { } : onChangeProposedCostIsNA} />
                </div>

                <div className={`${stateProposedCostIsNA ? 'hideme' : 'showme'}`}>
                  {/* lets only hide this, if we conditionally render then any changes in the UI will be lost if toggled again */}
                  <ProposedCostsBody
                    selANSubType={stateSelANSubTypeObject}
                    data={stateProposedCostsDetailsInfo}
                    vendors={stateAllANVendors}
                    rateCardItems={stateAllANRateCardItems}
                    onDataUpdated={onUpdateSectionData}
                    onTotalUpdated={onUpdateAppSectionTotals}
                    stateFormSubmitted={stateFormSubmitted}
                    isReadOnly={memoFormIsReadOnly}
                    saving={stateSaving} />
                </div>

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateProposedCostsDetailsItems: {JSON.stringify(stateProposedCostsDetailsItems, null, 2)}</li>
                    </ul>
                  )
                }

              </div>
















              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Pro's</h2>

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${statePros || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <RichTextArea content={statePros} onUpdate={() => { }} onUpdate2={onChangePros} />
                      {
                        memoIsErrProsMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Pro's is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Supporting Tables</h2>

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateSupportingTables || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <RichTextArea content={stateSupportingTables} onUpdate={() => { }} onUpdate2={onChangeSupportingTables} />
                      {
                        memoIsErrSupportingTablesMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Supporting Tables is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>





              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Recommendation</h2>

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateRecommendation || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <RichTextArea content={stateRecommendation} onUpdate={() => { }} onUpdate2={onChangeRecommendation} />
                      {
                        memoIsErrRecommendationMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Recommendation is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Comments</h2>

                {
                  memoFormIsReadOnly ? (
                    AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateRecComments || ''}</div>`)
                  ) : (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <RichTextArea content={stateRecComments} onUpdate={() => { }} onUpdate2={onChangeRecComments} />
                      {
                        memoIsErrCommentsMlot && (
                          <MessageBar messageBarType={MessageBarType.error} className='wts'>
                            {`Comments is required.`}
                          </MessageBar>
                        )
                      }
                    </Stack>
                  )
                }

              </div>







              {
                memoShowSectionConnectionMetrics && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Connection Metrics</h2>

                      <table className={`sub-table3 wbss ${memoFormIsReadOnly ? 'w600' : ''}`}>
                        <thead>

                          <tr>
                            <th>
                            </th>
                            <th>
                              {`UW`}
                            </th>
                            <th>
                            </th>
                            <th>
                              {`Rev-BP`}
                            </th>
                            <th>
                            </th>
                            <th>
                              {`Delta`}
                            </th>
                            <th>
                            </th>
                          </tr>

                        </thead>
                        <tbody>

                          <tr>
                            <td>
                              {`Collections`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMCollectionsUW} value={stateCMCollectionsUW} className='' errorMessage={memoIsErrCMCollectionsUW ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMCollectionsUW))
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMCollectionsRevBP} value={stateCMCollectionsRevBP} className='' errorMessage={memoIsErrCMCollectionsRevBP ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMCollectionsRevBP))
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField value={memoDeltaCMColl + ''} readOnly onChange={() => { }} className='bgGrey' />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(memoDeltaCMColl))
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`Multiple`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMMultipleUW} value={stateCMMultipleUW} className='' errorMessage={memoIsErrCMMultipleUW ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMMultipleUW))
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMMultipleRevBP} value={stateCMMultipleRevBP} className='' errorMessage={memoIsErrCMMultipleRevBP ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMMultipleRevBP))
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField value={memoDeltaCMMult + ''} readOnly onChange={() => { }} className='bgGrey' />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(memoDeltaCMMult))
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`IRR`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMIRRUW} value={stateCMIRRUW} className='' errorMessage={memoIsErrCMIRRUW ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMIRRUW))
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMIRRRevBP} value={stateCMIRRRevBP} className='' errorMessage={memoIsErrCMIRRRevBP ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMIRRRevBP))
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField value={memoDeltaCMIrr + ''} readOnly onChange={() => { }} className='bgGrey' />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(memoDeltaCMIrr))
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`WAL`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMWALUW} value={stateCMWALUW} className='' errorMessage={memoIsErrCMWALUW ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMWALUW))
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField onChange={onChangeCMWALRevBP} value={stateCMWALRevBP} className='' errorMessage={memoIsErrCMWALRevBP ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(stateCMWALRevBP))
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                            <td>
                              {
                                !memoFormIsReadOnly ? (
                                  <TextField value={memoDeltaCMWal + ''} readOnly onChange={() => { }} className='bgGrey' />
                                ) : (
                                  GenUtil.numberToCurrency(GenUtil.safeToNumber(memoDeltaCMWal))
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                          </tr>

                        </tbody>
                      </table>

                      <div className='hideme'>
                        <Checkbox label="Request Metrics from CES?" checked={stateRequestMetricsFromCES} onChange={onChangeRequestMetricsFromCES} disabled={memoFormIsReadOnly} />
                      </div>

                    </div>

                  </>
                )
              }













              {
                memoShowSectionLitigationMatrix && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Litigation Matrix<span className="flu-page-title-sub1">(exisiting litigation approved budget only)</span></h2>

                      <div className='wbsss'>
                        <Checkbox label="No existing litigation budget approved" checked={stateLitigationMatrixIsNA} onChange={memoFormIsReadOnly ? () => { } : onChangeLitigationMatrixIsNA} />
                      </div>

                      <div className={`${stateLitigationMatrixIsNA ? 'hideme' : 'showme'}`}>
                        {/* lets only hide this, if we conditionally render then any changes in the UI will be lost if toggled again */}
                        <LitigationMatrixBody
                          data={stateLitigationMatrixDetailsInfo}
                          onDataUpdated={onUpdateSectionData}
                          onTotalUpdated={onUpdateAppSectionTotals}
                          stateFormSubmitted={stateFormSubmitted}
                          isReadOnly={memoFormIsReadOnly}
                          saving={stateSaving} />
                      </div>

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateLitigationMatrixDetailsItems: {JSON.stringify(stateLitigationMatrixDetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }

                    </div>

                  </>
                )
              }







              {
                memoShowSectionAlm3 && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Level Matrix</h2>

                      <ALM3Body
                        xml={stateALM3DetailsInfo}
                        selANSubType={stateSelANSubTypeObject}
                        onDataUpdated={onUpdateSectionData}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly}
                        saving={stateSaving} />

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateALM3DetailsItems: {JSON.stringify(stateALM3DetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>

                  </>
                )
              }









              {
                memoShowSectionAlm2 && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Level Matrix</h2>

                      <ALM2Body
                        xml={stateALM2DetailsInfo}
                        selANSubType={stateSelANSubTypeObject}
                        onDataUpdated={onUpdateSectionData}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly}
                        saving={stateSaving} />

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateALM2DetailsItems: {JSON.stringify(stateALM2DetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>

                  </>
                )
              }








              {
                memoShowSectionAlm1 && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Level Matrix</h2>

                      <ALM1Body
                        xml={stateALM1DetailsInfo}
                        selANSubType={stateSelANSubTypeObject}
                        onDataUpdated={onUpdateSectionData}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly}
                        saving={stateSaving} />

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateALM1DetailsItems: {JSON.stringify(stateALM1DetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>

                  </>
                )
              }








              {
                memoShowSectionAlm4 && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Level Matrix</h2>

                      <ALM4Body
                        xml={stateALM4DetailsInfo}
                        selANSubType={stateSelANSubTypeObject}
                        onDataUpdated={onUpdateSectionData}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly} />

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateALM4DetailsItems: {JSON.stringify(stateALM4DetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>

                  </>
                )
              }









              {
                memoShowSectionProposalBreakdown && (
                  <>

                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Proposal Breakdown</h2>

                      <ProposalBreakdownBody
                        data={stateProposalBreakdownDetailsInfo}
                        currencys={stateAllANCurrencys}
                        onDataUpdated={onUpdateSectionData}
                        onTotalUpdated={onUpdateAppSectionTotals}
                        stateFormSubmitted={stateFormSubmitted}
                        isReadOnly={memoFormIsReadOnly}
                        saving={stateSaving} />

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateProposalBreakdownDetailsItems: {JSON.stringify(stateProposalBreakdownDetailsItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>

                  </>
                )
              }







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Supporting Documents</h2>

                <Stack tokens={Consts.stackTokens}>
                  {stateAttsEnabled && <>
                    <ANAttachments
                      attType={StaticData.defaultListAttType}
                      savedANAtts={stateANAtts}
                      onDataUpdated={handleAttsUpdate}
                      stateFormSubmitted={stateFormSubmitted}
                      isReadOnly={memoFormIsReadOnly}
                      saving={stateSaving}
                    />
                  </>}

                </Stack>

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateAttsEnabled: {stateAttsEnabled + ''}</li>
                      <li>memoANNote.AttachmentData: {safeTrim(memoANNote.AttachmentData)}</li>
                      <li>stateANAttsFromQuery: {stateANAttsFromQuery.length}, {JSON.stringify(stateANAttsFromQuery, null, 2)}</li>
                      <li>stateANAtts: {stateANAtts.length}, {JSON.stringify(stateANAtts, null, 2)}</li>
                      <li>stateNewAtts: {stateNewAtts.length}, {JSON.stringify(stateNewAtts, null, 2)}</li>
                      <li>stateDelAttIds: {stateDelAttIds.length}, {JSON.stringify(stateDelAttIds, null, 2)}</li>
                    </ul>
                  )
                }

              </div>




              {
                memoLegalTrackerEnabled && (
                  <div className='flu-section'>

                    <LegalTrackerRO
                      data={stateLegalTrackerItems}
                      webUrl={stateANLegalTrackerListWebUrl}
                    />

                  </div>
                )
              }




              <div className='flu-section'>

                <CompletedANsRO
                  data={stateCompletedANItems}
                ></CompletedANsRO>

              </div>




              <div className='flu-section'>

                <h2 className="flu-heading1 wbss">Case Manager</h2>

                <Stack tokens={Consts.stackTokens} className='wbsss'>

                  <ComboBox
                    // className='w400'
                    style={{ maxWidth: 400 }}
                    selectedKey={stateSelCaseManagerUser}
                    // label="Case Manager:"
                    placeholder='Please select a value...'
                    options={memoCaseManagerOptions}
                    // autoComplete={'on'}
                    onChange={onCaseManagerUserChange}
                    errorMessage={memoIsErrCaseManager ? 'Case Manager is required.' : ''}
                    disabled={memoCaseManagerIsReadOnly || stateSaving}
                  />

                </Stack>


                <h2 className="flu-heading1 wbss">Servicer</h2>

                <Stack tokens={Consts.stackTokens}>

                  <ComboBox
                    // className='w400'
                    style={{ maxWidth: 400 }}
                    selectedKey={stateSelServicerSubmitter}
                    // label="Servicer Submitter:"
                    placeholder='Please select a value...'
                    options={memoServicerSubmitterOptions}
                    // autoComplete={'on'}
                    onChange={onServicerSubmitterChange}
                    errorMessage={memoIsErrServicerSubmitter ? 'Servicer is required.' : ''}
                    disabled={memoCaseManagerIsReadOnly || stateSaving}
                  />

                </Stack>

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateSelCaseManagerUser: {JSON.stringify(stateSelCaseManagerUser, null, 2)}</li>
                      <li>stateSelServicerSubmitter: {JSON.stringify(stateSelServicerSubmitter, null, 2)}</li>
                      <li>memoSelServicerSubmitter: {JSON.stringify(memoSelServicerSubmitter, null, 2)}</li>
                    </ul>
                  )
                }

              </div>







              {
                stateANNote && (
                  <>


                    {/*

##      ##  #######  ########  ##    ## ######## ##        #######  ##      ## 
##  ##  ## ##     ## ##     ## ##   ##  ##       ##       ##     ## ##  ##  ## 
##  ##  ## ##     ## ##     ## ##  ##   ##       ##       ##     ## ##  ##  ## 
##  ##  ## ##     ## ########  #####    ######   ##       ##     ## ##  ##  ## 
##  ##  ## ##     ## ##   ##   ##  ##   ##       ##       ##     ## ##  ##  ## 
##  ##  ## ##     ## ##    ##  ##   ##  ##       ##       ##     ## ##  ##  ## 
###  ###   #######  ##     ## ##    ## ##       ########  #######   ###  ###  

                    */}










                    {
                      !memoWFSectionHideAppL1 && (
                        <>
                          {
                            memoWFSectionDisableAppL1 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Cabot1 ${Consts.admOvrShowDebugInfo() ? '(AppL1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.AppL1Name, memoANNote.AppL1Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.AppL1Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.AppL1Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.AppL1Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCABOT1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Cabot1 ${Consts.admOvrShowDebugInfo() ? '(AppL1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelAppL1Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoAppL1ActionChoices}
                                          onChange={onChangeAppL1Action}
                                          errorMessage={memoIsErrAppL1Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeAppL1Comment} value={stateAppL1Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrAppL1Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }































                    {
                      !memoWFSectionHideApp6 && (
                        <>
                          {
                            memoWFSectionDisableApp6 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Case Manager ${Consts.admOvrShowDebugInfo() ? '(App6)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App6Name, memoANNote.App6Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App6Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App6Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App6Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">

                                        <Label>Attachments:</Label>

                                        {/* NOTE: this attachment section is safe, it just reads data from SP document library, no onedrive interaction */}

                                        <ANAttachments
                                          attType='CES1'
                                          savedANAtts={stateANAtts}
                                          onDataUpdated={handleAttsUpdate}
                                          stateFormSubmitted={stateFormSubmitted}
                                          isReadOnly={true}
                                          saving={stateSaving}
                                        />

                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCES1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Case Manager ${Consts.admOvrShowDebugInfo() ? '(App6)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp6Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp6ActionChoices}
                                          onChange={onChangeApp6Action}
                                          errorMessage={memoIsErrApp6Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <ComboBox
                                              className='m150'
                                              disabled={false}
                                              selectedKey={isNull(stateSelApp6BBP) ? undefined : stateSelApp6BBP}
                                              label="Below Business Plan?"
                                              placeholder=''
                                              options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                              onChange={onChangeApp6BBP}
                                              errorMessage={memoIsErrApp6BBP ? 'BBP is required.' : ''}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp6Comment} value={stateApp6Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp6Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">

                                        {
                                          stateRequestMetricsFromCES && (
                                            <MessageBar messageBarType={MessageBarType.warning} className='wts12'>
                                              {`Please do not forget to add an attachment of the Metrics!`}
                                            </MessageBar>
                                          )
                                        }

                                        <Label>Attachments:</Label>

                                        {stateAttsEnabled && <>
                                          <ANAttachments
                                            attType='CES1'
                                            savedANAtts={stateANAtts}
                                            onDataUpdated={handleAttsUpdate}
                                            stateFormSubmitted={stateFormSubmitted}
                                            isReadOnly={!stateAttsEnabled}
                                            saving={stateSaving}
                                          />
                                        </>}

                                      </div>
                                    </div>
                                  </div>


                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }

















                    {
                      !memoWFSectionHideApp3 && (
                        <>
                          {
                            memoWFSectionDisableApp3 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Servicer ${Consts.admOvrShowDebugInfo() ? '(App3)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App3Name, memoANNote.App3Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App3Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App3Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App3Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusServicer) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Servicer ${Consts.admOvrShowDebugInfo() ? '(App3)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp3Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp3ActionChoices}
                                          onChange={onChangeApp3Action}
                                          errorMessage={memoIsErrApp3Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp3Comment} value={stateApp3Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp3Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }




















                    {
                      !memoWFSectionHideApp8 && (
                        <>
                          {
                            memoWFSectionDisableApp8 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Regulated Prom Entity - ${memoProjLURegulatedEntity} ${Consts.admOvrShowDebugInfo() ? '(App8)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App8Name, memoANNote.App8Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        {
                                          memoShowApp8OVR && (
                                            <>
                                              <Label>Regulated Entity to Check?</Label>
                                              <Label className='ms-fontWeight-regular'>{memoANNote.App8OVR}</Label>
                                            </>
                                          )
                                        }

                                        {
                                          memoShowApp8KeyDecOvr && (
                                            <>
                                              <Label>Regulated Entity to Check?</Label>
                                              <Label className='ms-fontWeight-regular'>{memoANNote.App8KeyDecOvr}</Label>
                                            </>
                                          )
                                        }

                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App8Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App8Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App8Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusRPE1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Regulated Prom Entity - ${memoProjLURegulatedEntity} ${Consts.admOvrShowDebugInfo() ? '(App8)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        {
                                          memoShowApp8OVR && (
                                            <>
                                              <Label>Regulated Entity to Check?</Label>
                                              <ComboBox
                                                className='w75'
                                                style={{ paddingBottom: 5 }}
                                                disabled={false}
                                                selectedKey={isNull(stateSelApp8OVR) ? undefined : stateSelApp8OVR}
                                                // label="Regulated Entity to Check?"
                                                placeholder=''
                                                options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                                onChange={onChangeApp8OVR}
                                                errorMessage={memoIsErrApp8OVR ? 'Choice is required.' : ''}
                                              />
                                            </>
                                          )
                                        }

                                        {
                                          memoShowApp8KeyDecOvr && (
                                            <>
                                              <Label>Regulated Entity to Check?</Label>
                                              <ComboBox
                                                className='w75'
                                                style={{ paddingBottom: 5 }}
                                                disabled={false}
                                                selectedKey={isNull(stateSelApp8KeyDecOvr) ? undefined : stateSelApp8KeyDecOvr}
                                                // label="Regulated Entity to Check?"
                                                placeholder=''
                                                options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                                onChange={onChangeApp8KeyDecOvr}
                                                errorMessage={memoIsErrApp8KeyDecOvr ? 'Choice is required.' : ''}
                                              />
                                            </>
                                          )
                                        }

                                        <ComboBox
                                          // className='w150'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp8Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp8ActionChoices}
                                          onChange={onChangeApp8Action}
                                          errorMessage={memoIsErrApp8Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp8Comment} value={stateApp8Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp8Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }
















                    {
                      !memoWFSectionHideApp4 && (
                        <>
                          {
                            memoWFSectionDisableApp4 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`PSI ${Consts.admOvrShowDebugInfo() ? '(App4)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App4Name, memoANNote.App4Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App4Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App4Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App4Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusPSI) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`PSI ${Consts.admOvrShowDebugInfo() ? '(App4)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp4Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp4ActionChoices}
                                          onChange={onChangeApp4Action}
                                          errorMessage={memoIsErrApp4Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp4Comment} value={stateApp4Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp4Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }

















                    {
                      !memoWFSectionHideApp1 && (
                        <>
                          {
                            memoWFSectionDisableApp1 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Recommender ${Consts.admOvrShowDebugInfo() ? '(App1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App1Name, memoANNote.App1Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App1Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App1Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App1Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCES2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Recommender ${Consts.admOvrShowDebugInfo() ? '(App1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp1Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp1ActionChoices}
                                          onChange={onChangeApp1Action}
                                          errorMessage={memoIsErrApp1Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp1Comment} value={stateApp1Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp1Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }





















                    {
                      !memoWFSectionHideApp7 && (
                        <>
                          {
                            memoWFSectionDisableApp7 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 1 - ${memoProjLUAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App7)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App7Name, memoANNote.App7Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App7Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App7Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App7Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCGI1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 1 - ${memoProjLUAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App7)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp7Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp7ActionChoices}
                                          onChange={onChangeApp7Action}
                                          errorMessage={memoIsErrApp7Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <ComboBox
                                              className='m150'
                                              disabled={false}
                                              selectedKey={isNull(stateSelApp7BBP) ? undefined : stateSelApp7BBP}
                                              label="Below Business Plan?"
                                              placeholder=''
                                              options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                              onChange={onChangeApp7BBP}
                                              errorMessage={memoIsErrApp7BBP ? 'BBP is required.' : ''}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp7Comment} value={stateApp7Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp7Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }


















                    {
                      !memoWFSectionHideApp2 && (
                        <>
                          {
                            memoWFSectionDisableApp2 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 2 - ${memoProjLUAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App2Name, memoANNote.App2Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App2Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App2Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App2Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    !memoHasSameCGIs && Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCGI2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                  {
                                    memoHasSameCGIs && Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusCGI2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`CGI 2 Approver must be different than CGI 1 Approver.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 2 - ${memoProjLUAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App2)' : ''}`}</h2>

                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp2Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp2ActionChoices}
                                          onChange={onChangeApp2Action}
                                          errorMessage={memoIsErrApp2Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp2Comment} value={stateApp2Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp2Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }

























                    {
                      !memoWFSectionHideApp5 && (
                        <>
                          {
                            memoWFSectionDisableApp5 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`${memoProjLULTHName} ${Consts.admOvrShowDebugInfo() ? '(App5)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App5Name, memoANNote.App5Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App5Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App5Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App5Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusLTH) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`${memoProjLULTHName} ${Consts.admOvrShowDebugInfo() ? '(App5)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp5Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp5ActionChoices}
                                          onChange={onChangeApp5Action}
                                          errorMessage={memoIsErrApp5Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp5Comment} value={stateApp5Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp5Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }

























                    {
                      !memoWFSectionHideApp9 && (
                        <>
                          {
                            memoWFSectionDisableApp9 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Economic Owner - ${memoProjLULTHName} ${Consts.admOvrShowDebugInfo() ? '(App9)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App9Name, memoANNote.App9Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App9Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App9Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App9Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusEO) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Economic Owner - ${memoProjLULTHName} ${Consts.admOvrShowDebugInfo() ? '(App9)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp9Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp9ActionChoices}
                                          onChange={onChangeApp9Action}
                                          errorMessage={memoIsErrApp9Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp9Comment} value={stateApp9Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp9Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }














                    {
                      !memoWFSectionHideApp10 && (
                        <>
                          {
                            memoWFSectionDisableApp10 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Regulated Prom Entity - ${memoProjLURegulatedEntity} ${Consts.admOvrShowDebugInfo() ? '(App10)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{NVL(memoANNote.App10Name, memoANNote.App10Email)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App10Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App10Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App10Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && inn(memoFormStatus, StaticData.wfStatusRPE2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Regulated Prom Entity - ${memoProjLURegulatedEntity} ${Consts.admOvrShowDebugInfo() ? '(App10)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp10Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp10ActionChoices}
                                          onChange={onChangeApp10Action}
                                          errorMessage={memoIsErrApp10Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* BBP n/a */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp10Comment} value={stateApp10Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp10Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }















                  </>
                )
              }







              {
                !Consts.admOvrEditMode() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Submit Form</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        {memoShowSaveButton && <PrimaryButton text="Save" allowDisabledFocus onClick={handleSave} disabled={stateSaving} />}
                        <PrimaryButton text="Submit Form" allowDisabledFocus onClick={handleSubmit} disabled={stateSaving || !memoEnableSubmitButton} />
                        <DefaultButton text="Cancel" allowDisabledFocus onClick={handleCancel} disabled={stateSaving || !memoEnableCancelButton} />
                      </Stack>

                    </div>
                  </>
                )
              }

              {
                memoAdmOvrAvail && Consts.admOvrEditMode() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Admin Submit Form</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        <PrimaryButton text="Draft Override Save" allowDisabledFocus onClick={() => { adminSaveForm(3); }} disabled={stateSaving} />
                      </Stack>

                    </div>
                  </>
                )
              }




              {
                stateANNote && Consts.admOvrShowAdminSection() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Admin Override Actions</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        <PrimaryButton text="Reset To Draft" allowDisabledFocus onClick={() => { adminSaveForm(1); }} disabled={stateSaving} />
                        <PrimaryButton text="Reset To RPE1" allowDisabledFocus onClick={() => { adminSaveForm(5); }} disabled={stateSaving} />
                        <PrimaryButton text="Reset To CGI1" allowDisabledFocus onClick={() => { adminSaveForm(4); }} disabled={stateSaving} />
                        <PrimaryButton text="Clone Record" allowDisabledFocus onClick={() => { adminSaveForm(2); }} disabled={stateSaving} />
                      </Stack>



                      <div className='wbsss w500'>
                        <TextField label='Field Name:' onChange={onChangeAdmOvrFieldName} value={stateAdmOvrFieldName} placeholder='List Field Name' className='' />
                        <TextField label='Field Value:' onChange={onChangeAdmOvrFieldVal} value={stateAdmOvrFieldVal} placeholder='List Field Value' className='' multiline rows={5} />
                      </div>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        <PrimaryButton text="Update Field Value" allowDisabledFocus onClick={() => { adminSaveForm(6); }} disabled={stateSaving} />
                      </Stack>

                    </div>
                  </>
                )
              }




              {/* end main form after loading here */}
            </>
          )
        }





        {
          Consts.admOvrShowDebugInfo() && (
            <>
              <div className='debug-wrapper' style={{ margin: '50px 0' }}>

                <h2 className="flu-heading1 wbsss">Debug Info</h2>

                <Stack tokens={Consts.stackTokens}>

                  <TextField label="stateANItemId" readOnly value={stateANItemId + ''} />
                  <TextField label="memoIsNewItem" readOnly value={memoIsNewItem + ''} />
                  {/* <TextField label="stateFormSubmitted" readOnly value={stateFormSubmitted + ''} /> */}

                  <TextField label="memoCurUserDispName" readOnly value={memoCurUserDispName} />
                  <TextField label="memoCurUsername" readOnly value={memoCurUsername} />

                  <TextField label="ListTitleANNotesCabot" readOnly value={Config.Settings().ListTitleANNotesCabot} />
                  <TextField label="ListTitleConnectionsCabot" readOnly value={Config.Settings().ListTitleConnectionsCabot} />

                  <TextField label="stateANNote" readOnly value={JSON.stringify(stateANNote ? stateANNote : null, null, 2)} multiline rows={6} />
                  <TextField label="memoANNote" readOnly value={JSON.stringify(memoANNote, null, 2)} multiline rows={6} />
                  <TextField label="memoFormIsReadOnly" readOnly value={memoFormIsReadOnly + ''} />

                  <TextField label="memoProjLUEntity" readOnly value={memoProjLUEntity + ''} />
                  <TextField label="memoProjLULTHName" readOnly value={memoProjLULTHName + ''} />
                  <TextField label="memoProjLURegulatedEntity" readOnly value={memoProjLURegulatedEntity + ''} />
                  <TextField label="memoProjLUAssetManager" readOnly value={memoProjLUAssetManager + ''} />
                  <TextField label="memoProjLUIntExt" readOnly value={memoProjLUIntExt + ''} />
                  <TextField label="memoProjLURegulated" readOnly value={memoProjLURegulated + ''} />

                  <TextField label="FormStatus (Actual)" readOnly value={memoANNote.FormStatus || ''} />
                  <TextField label="FormStatus" readOnly value={memoFormStatus || ''} />
                  <TextField label="WFStatus" readOnly value={memoANNote.WFStatus || ''} />
                  <TextField label="WorkflowPath" readOnly value={memoANNote.WorkflowPath || ''} />

                  <TextField label="stateAppSectionTotals" readOnly value={JSON.stringify(stateAppSectionTotals, null, 2)} multiline rows={6} />

                </Stack>

              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>memoWFSectionIsAnyShownEnabled: <input type='checkbox' checked={GenUtil.safeToBool(memoWFSectionIsAnyShownEnabled)} onChange={() => { }} /></li>
              <li>memoPermsIsCurUserAnyRole: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserAnyRole)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsAppL1Cabot1Users: {memoPermsAppL1Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserAppL1Cabot1: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserAppL1)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp6CES1Users: {memoPermsApp6Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp6CES1: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp6)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp3SRVUsers: {memoPermsApp3Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp3SRV: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp3)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp8RPE1Users: {memoPermsApp8Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp8RPE1: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp8)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp4PSIUsers: {memoPermsApp4Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp4PSI: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp4)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp1CES2Users: {memoPermsApp1Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp1CES2: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp1)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp7CGI1Users: {memoPermsApp7Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp7CGI1: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp7)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp2CGI2Users: {memoPermsApp2Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp2CGI2: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp2)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp5LTHUsers: {memoPermsApp5Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp5LTH: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp5)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp9EOUsers: {memoPermsApp9Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp9EO: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp9)} onChange={() => { }} /></li>
              <hr></hr>
              <li>memoPermsApp10RPE2Users: {memoPermsApp10Users.replace(/;/ig, '; ')}</li>
              <li>memoPermsIsCurUserApp10RPE2: <input type='checkbox' checked={GenUtil.safeToBool(memoPermsIsCurUserApp10)} onChange={() => { }} /></li>
              <hr></hr>

            </ul>
          )
        }




        {
          (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
            Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
            Consts.admOvrAddCurUserToAllRoles() || Consts.admOvrShowAllSectionsFields())) && (
            <>
              <div className='debug-wrapper' style={{ margin: '50px 0' }}>

                <h2 className="flu-heading1 wbsss">Admin Overrides Enabled <Label>Samples are shown, not real values</Label></h2>

                <Stack className='wbs'>
                  <Toggle inlineLabel={true} label="admOvrShowDebugInfo=1" checked={!!Consts.admOvrShowDebugInfo()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrShowAdminSection=1" checked={!!Consts.admOvrShowAdminSection()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrAddCurUserToAllRoles=1" checked={!!Consts.admOvrAddCurUserToAllRoles()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrShowAllSectionsFields=1" checked={!!Consts.admOvrShowAllSectionsFields()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrEditMode=1" checked={!!Consts.admOvrEditMode()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrUsername=user@domain.onmicrosoft.com" checked={!!Consts.admOvrUsername()} onChange={() => { }} />
                </Stack>

                <div className='wbs'>
                  <TextField label="Admin Override Path:" readOnly value={
                    (function () {
                      let path = '?';
                      if (Consts.admOvrShowDebugInfo())
                        path += '&admOvrShowDebugInfo=1';
                      if (Consts.admOvrShowAdminSection())
                        path += '&admOvrShowAdminSection=1';
                      if (Consts.admOvrAddCurUserToAllRoles())
                        path += '&admOvrAddCurUserToAllRoles=1';
                      if (Consts.admOvrShowAllSectionsFields())
                        path += '&admOvrShowAllSectionsFields=1';
                      if (Consts.admOvrEditMode())
                        path += '&admOvrEditMode=1';
                      if (Consts.admOvrUsername())
                        path += `&admOvrUsername=${Consts.admOvrUsername()}`;
                      return path.length <= 1 ? '' : path.replace(/\?&/ig, '?');
                    })()
                  } />
                </div>

              </div>
            </>
          )
        }



      </Stack >




      <Dialog
        hidden={!stateShowDialog}
        // onDismiss={toggleHideDialog} // do not allow dismiss, user must make a choice
        dialogContentProps={{
          type: DialogType.largeHeader
        }}
        maxWidth={400}
        minWidth={400}
        modalProps={{
          isBlocking: true
        }}
      >
        <Stack tokens={Consts.stackTokens} horizontalAlign='center'>

          <div className='wbs25'>
            <img src={BigConsts.imgSuccess} style={{ width: '72px' }} alt='Success'></img>
          </div>

          <div className='wbs25 ms-fontSize-28'>
            {stateDialogMsg}
          </div>

          <Stack tokens={Consts.stackTokens} horizontal className=''>
            <PrimaryButton onClick={handleBackToList} text="Back to List" />
            {Consts.admOvrShowAdminSection() && (<PrimaryButton onClick={() => { (window as any).open(GenUtil.getLocationInfo('href'), "_top"); }} text="Reload" />)}
          </Stack>

        </Stack>
      </Dialog>



    </>
  );

  //#endregion

};