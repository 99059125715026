/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { ALM3Row } from './ALM3Row';
import { AssetLevelMatrixThree1, AssetLevelMatrixThreeItem } from '../../../Models/Link/GridModels/AssetLevelMatrixThree1';
import { AssetLevelMatrixThreeN } from '../../../Models/Link/GridModels/AssetLevelMatrixThreeN';
import { ANType } from '../../../Models/Link/ANType';


export interface IALM3BodyProps {
  xml: string | undefined;
  selANSubType: ANType | null;
  onDataUpdated: (s: string, d: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ALM3Body: React.FunctionComponent<IALM3BodyProps> = (props: React.PropsWithChildren<IALM3BodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM3Body]");
  }, []);


  const [stateRows, setStateRows] = useState<AssetLevelMatrixThreeItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('alm3', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: AssetLevelMatrixThreeItem[] = [];

    if (!!props.xml && !GenUtil.isNull(props.xml) && !GenUtil.isInt(props.xml)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ALM3', props.xml);

        if (!AppHelper.xmlHasMultipleItems(props.xml)) {
          let _t = (obj as AssetLevelMatrixThree1).RepeaterData.Items.Item;

          let item: AssetLevelMatrixThreeItem = {
            cv_RowNum: GenUtil.generateGuid(),
            rpt_Actual2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Actual2Gross)),
            rpt_Actual2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Actual2Net)),
            rpt_dt_ALAct2Exit: GenUtil.safeTrim(AppHelper.getText(_t.rpt_dt_ALAct2Exit)),
            cv_dt_ALAct2Exit: GenUtil.safeTrim(AppHelper.getText(_t.cv_dt_ALAct2Exit)),
            rpt_BP2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BP2Gross)),
            rpt_BP2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BP2Net)),
            rpt_dt_ALBP2Exit: GenUtil.safeTrim(AppHelper.getText(_t.rpt_dt_ALBP2Exit)),
            cv_dt_ALBP2Exit: GenUtil.safeTrim(AppHelper.getText(_t.cv_dt_ALBP2Exit)),
            rpt_cv_Del2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_cv_Del2Gross)),
            rpt_cv_ALDel2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_cv_ALDel2Net)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_Actual2Gross).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as AssetLevelMatrixThreeN;

          let _col: AssetLevelMatrixThreeItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {
              cv_RowNum: GenUtil.generateGuid(),
              rpt_Actual2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Actual2Gross)),
              rpt_Actual2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Actual2Net)),
              rpt_dt_ALAct2Exit: GenUtil.safeTrim(AppHelper.getText(_t.rpt_dt_ALAct2Exit)),
              cv_dt_ALAct2Exit: GenUtil.safeTrim(AppHelper.getText(_t.cv_dt_ALAct2Exit)),
              rpt_BP2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BP2Gross)),
              rpt_BP2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_BP2Net)),
              rpt_dt_ALBP2Exit: GenUtil.safeTrim(AppHelper.getText(_t.rpt_dt_ALBP2Exit)),
              cv_dt_ALBP2Exit: GenUtil.safeTrim(AppHelper.getText(_t.cv_dt_ALBP2Exit)),
              rpt_cv_Del2Gross: GenUtil.safeTrim(AppHelper.getText(_t.rpt_cv_Del2Gross)),
              rpt_cv_ALDel2Net: GenUtil.safeTrim(AppHelper.getText(_t.rpt_cv_ALDel2Net)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.rpt_Actual2Gross));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in ALM3`, props.xml, error); }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        cv_RowNum: GenUtil.generateGuid(),
        rpt_Actual2Gross: '',
        rpt_Actual2Net: '',
        rpt_dt_ALAct2Exit: '',
        cv_dt_ALAct2Exit: '',
        rpt_BP2Gross: '',
        rpt_BP2Net: '',
        rpt_dt_ALBP2Exit: '',
        cv_dt_ALBP2Exit: '',
        rpt_cv_Del2Gross: '',
        rpt_cv_ALDel2Net: ''
      }];
    }

    setStateRows(col);

  }, [props.xml]);


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        cv_RowNum: GenUtil.generateGuid(),
        rpt_Actual2Gross: '',
        rpt_Actual2Net: '',
        rpt_dt_ALAct2Exit: '',
        cv_dt_ALAct2Exit: '',
        rpt_BP2Gross: '',
        rpt_BP2Net: '',
        rpt_dt_ALBP2Exit: '',
        cv_dt_ALBP2Exit: '',
        rpt_cv_Del2Gross: '',
        rpt_cv_ALDel2Net: ''
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];

      if (GenUtil.eq(fieldName, 'ALMActualGP')) o.rpt_Actual2Gross = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'ALMActualNP')) o.rpt_Actual2Net = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'ALMBusPlanGP')) o.rpt_BP2Gross = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'ALMBusPlanNP')) o.rpt_BP2Net = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));

      else if (GenUtil.eq(fieldName, 'DeltaGross')) o.rpt_cv_Del2Gross = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'DeltaNet')) o.rpt_cv_ALDel2Net = GenUtil.safeTrim(fieldVal);

      else if (GenUtil.eq(fieldName, 'ALMActualExitDate')) {
        o.cv_dt_ALAct2Exit = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_dt_ALAct2Exit = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }
      else if (GenUtil.eq(fieldName, 'ALMBusPlanExitDate')) {
        o.cv_dt_ALBP2Exit = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_dt_ALBP2Exit = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }

      return t;
    });
  }


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className={`sub-table3 ${props.isReadOnly ? 'w600' : ''}`}>
          <thead>

            <tr>
              <th>
              </th>
              <th>
                {`Gross Proceeds`}
              </th>
              <th>
                {`Net Proceeds`}
              </th>
              <th>
                {`Exit Date`}
              </th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <ALM3Row
                  key={o.cv_RowNum}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving} />)
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton disabled={props.saving} iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow}>Add a new row</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.xml: {JSON.stringify(props.xml, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};