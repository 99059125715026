/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as StaticData from "../../../StaticData/Link/StaticData";

import { ANConnection } from '../../../Models/Link/ANConnection';
import { BorLoanBorItem, BorLoanLoanItem } from '../../../Models/Link/GridModels/BorLoan';

import { BorLoanBorRow } from './BorLoanBorRow';
import { ANCurrency } from '../../../Models/Link/ANCurrency';


export interface IBorLoanBorBodyProps {
  xml: string | undefined;
  borOptions: IDropdownOption[];
  borConnections: ANConnection[];
  loanConnections: ANConnection[];
  currencys: ANCurrency[];
  onDataUpdated: (s: string, d: any) => void;
  onTotalUpdated: (s: string, d: number) => void;
  isReadOnly: boolean;
  removeConnId: string;
  saving: boolean;
  stateFormSubmitted: boolean;
}

export const BorLoanBorBody: React.FunctionComponent<IBorLoanBorBodyProps> = (props: React.PropsWithChildren<IBorLoanBorBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorLoanBorBody]");
  }, []);


  const [stateRows, setStateRows] = useState<BorLoanBorItem[]>([]);

  const [stateSelItem, setStateSelItem] = useState<IDropdownOption>();
  const onChange = (event: any, option?: IDropdownOption, index?: number) => { setStateSelItem(option); };

  const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);


  useEffect(() => {
    props.onDataUpdated('BorrowerLoans', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // use local state for options, since we have to remove options that are already added to the list
    // remove from options any item that is added to the Grid
    let ids = stateRows.map(o => o.rpt_BorrowerID);
    let col = [...props.borOptions].filter(o => ids.indexOf(o.key + '') < 0);
    setStateOptions(col);
  }, [props.borOptions, stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: BorLoanBorItem[] = [];

    if (!!props.xml && !GenUtil.isNull(props.xml) && !GenUtil.isInt(props.xml)) {
      try {
        col = JSON.parse(props.xml);
      } catch (error) { console.warn(`Error parsing data in BorLoanBorBody`, props.xml, error); }
    }
    else {
      col = [];
    }

    // when form is in readonly mode, collapse the loans, otherwise override the saved data and show the loans
    for (const bo of col) {
      bo.cv_ShowLoans = props.isReadOnly ? false : true;
    }

    setStateRows(col);
  }, [props.xml]);


  function onClickAddID() {
    // add specific item from DDL to grid
    if (!stateSelItem) {
      return;
    }

    let selKey = GenUtil.safeTrim(stateSelItem.key);

    if (GenUtil.isNull(selKey)) {
      return;
    }

    // prevent same item adding more than once
    if (stateRows.filter(x => GenUtil.eq(x.rpt_BorrowerID, selKey)).length > 0) return;

    let col: BorLoanBorItem[] = props.borConnections.filter(o => GenUtil.eq(o.fields.Borrower_x0020_ID, selKey)).map((o, i) => {

      let loans = mapBorrowerLoans(o.fields.Borrower_x0020_ID);

      let [sumUPB, sumColl, countActive] = calcSums(loans);

      return {
        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),
        rpt_BorrowerID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        rpt_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        rpt_RecourseValue: '', // user input
        rpt_JointBorrowerNames: GenUtil.safeTrim(o.fields.JointBorrowerNames),
        col_LoanItems: loans,
        cv_UPBSum: GenUtil.numberToCurrency(sumUPB),
        cv_CollectionsSum: GenUtil.numberToCurrency(sumColl),
        cv_ActiveLoanCount: countActive + '',
        cv_ShowLoans: true
      } as BorLoanBorItem;
    });

    if (col.length <= 0) {
      return;
    }

    let obj = col[0];

    setStateRows(p => {
      return [...p, obj];
    });
  }


  function onClickAddAll() {
    // add all items from DDL to grid
    let col = [...stateRows];

    let col2 = props.borConnections.filter(a => {
      return col.findIndex(b => b.rpt_BorrowerID === a.fields.Borrower_x0020_ID) < 0 && !GenUtil.isNull(a.fields.Borrower_x0020_ID);
    }).map(o => {

      let loans = mapBorrowerLoans(o.fields.Borrower_x0020_ID);

      let [sumUPB, sumColl, countActive] = calcSums(loans);

      return {
        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),
        rpt_BorrowerID: GenUtil.safeTrim(o.fields.Borrower_x0020_ID),
        rpt_BorrowerName: GenUtil.safeTrim(o.fields.Borrower_x0020_Name),
        rpt_RecourseValue: '', // user input
        rpt_JointBorrowerNames: GenUtil.safeTrim(o.fields.JointBorrowerNames),
        col_LoanItems: loans,
        cv_UPBSum: GenUtil.numberToCurrency(sumUPB),
        cv_CollectionsSum: GenUtil.numberToCurrency(sumColl),
        cv_ActiveLoanCount: countActive + '',
        cv_ShowLoans: true
      } as BorLoanBorItem;
    });

    col = [...col, ...col2];

    setStateRows(col);
  }


  function mapBorrowerLoans(borId: string): BorLoanLoanItem[] {
    // get all loans matching the borrower id, convert to loan items

    let loanItems: BorLoanLoanItem[] = props.loanConnections
      .filter(o => GenUtil.eq(o.fields.Borrower_x0020_ID, borId))
      .map((o, i) => {

        // convert currency to EUR
        let origAmt = GenUtil.safeToNumber(o.fields.UPB);
        let origCurr = GenUtil.safeTrim(o.fields.UPB_x0020_Currency);

        let newAmt = AppHelper.convertCurrency(origAmt, origCurr, props.currencys);
        let newCurr = StaticData.costsCurrency;

        let dispAmt = GenUtil.eq(origCurr, StaticData.costsCurrency) ? GenUtil.numberToCurrency(newAmt) : `${GenUtil.numberToCurrency(newAmt)} (${GenUtil.numberToCurrency(origAmt)})`;
        let dispCurr = GenUtil.eq(origCurr, StaticData.costsCurrency) ? StaticData.costsCurrency : `${StaticData.costsCurrency} (${origCurr})`;

        return {

          cv_IsActive: true,

          rpt_LoanID: GenUtil.safeTrim(o.fields.Loan_x0020_ID),

          rpt_ServicerLoanID: GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_ID),

          cv_UPBActual: GenUtil.safeTrim(newAmt),
          cv_UPBCurrencyActual: GenUtil.safeTrim(newCurr),
          cv_UPBOrig: GenUtil.safeTrim(origAmt),
          cv_UPBCurrencyOrig: GenUtil.safeTrim(origCurr),
          rpt_UPBDisp: GenUtil.safeTrim(dispAmt),
          rpt_UPBCurrencyDisp: GenUtil.safeTrim(dispCurr),

          rpt_RegulatoryStatus: GenUtil.safeTrim(o.fields.Regulatory_x0020_Status),

          rpt_Collections: GenUtil.safeTrim(o.fields.Collections),
          cv_CollectionsCurrency: GenUtil.safeTrim(o.fields.Collections_x0020_Currency),

        } as BorLoanLoanItem;
      });

    return loanItems;
  }


  function handleDeleteItem(id: string) {
    // remove the item from the grid
    setStateRows(p => {
      return p.filter(o => !GenUtil.eq(o.rpt_BorrowerID, id));
    });
  }


  useEffect(() => {
    // delete all rows with the connId sent from parent
    let id = GenUtil.safeTrim(props.removeConnId);

    if (!GenUtil.isNull(id)) {
      setStateRows(p => {
        return p.filter(o => !GenUtil.eq(o.cv_ConnId, id));
      });
    }
  }, [props.removeConnId]);


  function updateFieldVal(id: string, fieldName: string, fieldVal: any) {
    // update user controlled Borrower row property value
    setStateRows(p => {
      let t = [...p];
      let idx = t.findIndex(o => o.rpt_BorrowerID === id);
      if (idx >= 0) {
        let o = t[idx];
        if (GenUtil.eq(fieldName, 'Recourse')) o.rpt_RecourseValue = GenUtil.safeTrim(fieldVal);
      }
      return t;
    });
  }


  function onUpdateLoanActive(borId: string, loanId: string) {

    setStateRows(p => {
      let col = [...p];

      col.forEach(bo => {

        if (GenUtil.eq(bo.rpt_BorrowerID, borId)) {

          if (GenUtil.isNull(loanId)) {
            /**
             * when loanid passed is blank, lets flip all loan items, but in a special way
             * if all items are checked, uncheck them all
             * if all items are unchecked, check them all
             * else, some items are checked, uncheck them all
            */

            let tmp = bo.col_LoanItems;
            let decision = false;
            let countAllActive = tmp.filter(x => x.cv_IsActive).length;
            let countAllInActive = tmp.filter(x => !x.cv_IsActive).length;

            if (countAllActive === tmp.length)
              decision = false;
            else if (countAllInActive === tmp.length)
              decision = true;
            else
              decision = false;

            bo.col_LoanItems.forEach(lo => { lo.cv_IsActive = decision; });

          }
          else {
            // flip this loan 'active' status
            bo.col_LoanItems.forEach(lo => {
              if (GenUtil.eq(lo.rpt_ServicerLoanID, loanId)) {
                lo.cv_IsActive = !lo.cv_IsActive;
              }
            })
          }

        }

      });


      // NOTE: trying to resolve the subcomp not rendering when expected, deep object change in object with coll of objs inside, DID NOT WORK, created jsonString approach instead, that works
      // col.filter(bo => GenUtil.eq(bo.rpt_BorrowerID, borId))
      //   .forEach(bo => {
      //     if (GenUtil.isNull(loanId)) {
      //       /**
      //        * when loanid passed is blank, lets flip all loan items, but in a special way
      //        * if all items are checked, uncheck them all
      //        * if all items are unchecked, check them all
      //        * else, some items are checked, uncheck them all
      //       */

      //       let tmp = bo.col_LoanItems;
      //       let decision = false;
      //       let countAllActive = tmp.filter(x => x.cv_IsActive).length;
      //       let countAllInActive = tmp.filter(x => !x.cv_IsActive).length;

      //       if (countAllActive === tmp.length)
      //         decision = false;
      //       else if (countAllInActive === tmp.length)
      //         decision = true;
      //       else
      //         decision = false;

      //       bo.col_LoanItems.forEach(lo => { lo.cv_IsActive = decision; });

      //     }
      //     else {
      //       // flip this loan 'active' status
      //       bo.col_LoanItems.filter(lo => GenUtil.eq(lo.rpt_ServicerLoanID, loanId)).forEach(lo => {
      //         lo.cv_IsActive = !lo.cv_IsActive;
      //       });
      //     }
      //   });



      // recalc totals/sums for active loans
      col.forEach(bo => {
        let [sumUPB, sumColl, countActive] = calcSums(bo.col_LoanItems);
        bo.cv_UPBSum = GenUtil.numberToCurrency(sumUPB);
        bo.cv_CollectionsSum = GenUtil.numberToCurrency(sumColl);
        bo.cv_ActiveLoanCount = countActive + '';
      });


      return col;
    });
  }


  function calcSums(loans: BorLoanLoanItem[]) {
    // given collection of loans, calc the total/sum for 'active' records
    let sumUPB = 0;
    let sumColl = 0;
    let countActive = 0;

    loans.filter(lo => lo.cv_IsActive)
      .forEach(lo => {
        sumUPB += GenUtil.safeToNumber(lo.cv_UPBActual);
        sumColl += GenUtil.safeToNumber(lo.rpt_Collections);
        countActive++;
      });

    return [sumUPB, sumColl, countActive];
  }


  function onUpdateShowLoans(borId: string) {
    // toggle show/hide/expand
    setStateRows(p => {
      let col = [...p];

      col.filter(x => GenUtil.eq(x.rpt_BorrowerID, borId))
        .forEach(x => {
          x.cv_ShowLoans = !x.cv_ShowLoans;
        });

      return col;
    });
  }


  function onClickReset() {
    setStateRows([]);
    setStateOptions([...props.borOptions]);
    if (props.borOptions.length > 1) setStateSelItem(undefined);
    // setStateSelItem(undefined); // do not reset this, single value DDLs get stuck, cannot trigger an onchange
  }


  const memoSumValuesCollections = useMemo(() => {
    let tot: number = 0;
    for (const bo of stateRows)
      tot += GenUtil.safeToNumber(bo.cv_CollectionsSum);
    return tot;
  }, [stateRows]);

  const memoSumValuesUPB = useMemo(() => {
    let tot: number = 0;
    for (const bo of stateRows)
      tot += GenUtil.safeToNumber(bo.cv_UPBSum);
    return tot;
  }, [stateRows]);

  useEffect(() => {
    props.onTotalUpdated('BorrowerLoansCollections', memoSumValuesCollections);
  }, [memoSumValuesCollections]);

  useEffect(() => {
    props.onTotalUpdated('BorrowerLoansUPB', memoSumValuesUPB);
  }, [memoSumValuesUPB]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        {
          !props.isReadOnly && (
            <>
              <Stack tokens={Consts.stackTokens} horizontal className='wbss' verticalAlign='baseline'>
                <div>{`Borrower ID:`}</div>
                <Dropdown
                  className='w400'
                  selectedKey={stateSelItem ? stateSelItem.key : undefined}
                  onChange={onChange}
                  placeholder={stateOptions.length > 0 ? "Select a Borrower" : 'No Borrowers Found'}
                  options={stateOptions}
                />
                <PrimaryButton text="Add Borrower ID" allowDisabledFocus onClick={onClickAddID} disabled={stateOptions.length <= 0 || props.saving} />
                <PrimaryButton text="Add All" allowDisabledFocus onClick={onClickAddAll} disabled={stateOptions.length <= 0 || props.saving} />
                {stateRows.length > 0 && <PrimaryButton text="Reset" allowDisabledFocus onClick={onClickReset} disabled={props.saving} />}
              </Stack>
            </>
          )
        }

        <table className='sub-table3'>
          <thead>

            <tr>

              <th>
                {/* show/hide expander */}
              </th>

              <th>{`Borrower ID`}</th>

              <th>{`Borrower Name`}</th>

              <th>{`Recourse`}</th>

              <th>{`Loan Count`}</th>

              <th>{`Total UPB (€)`}</th>

              <th>{`Total Collections (€)`}</th>

              <th>
                {/* delete row button */}
              </th>

            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((bor, i) =>
                <BorLoanBorRow
                  key={i}
                  bor={bor}
                  borAsJson={JSON.stringify(bor)}
                  updateFieldVal={updateFieldVal}
                  onUpdateLoanActive={onUpdateLoanActive}
                  onUpdateShowLoans={onUpdateShowLoans}
                  handleDeleteItem={handleDeleteItem}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving}
                  stateFormSubmitted={props.stateFormSubmitted}
                />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  <td></td>
                  <td style={{ textAlign: 'right' }} colSpan={4}>
                    <Label>{`Totals (€):`}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumValuesUPB)}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumValuesCollections)}</Label>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.xml: {(props.xml || '').replace(/","/ig, '", "')}</li>
              <li>props.borOptions: {JSON.stringify(props.borOptions, null, 2)}</li>
              <li>stateOptions: {JSON.stringify(stateOptions, null, 2)}</li>
              <li>stateSelItem: {JSON.stringify(stateSelItem, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};