/* eslint-disable @typescript-eslint/no-unused-vars */
import { IStackTokens, Stack } from '@fluentui/react';
import * as React from 'react';
import * as Consts from "../../Helpers/Consts";


export interface ISpaceProps {
  horizontal?: boolean;
  sizeIsSmall?: boolean;
  sizeIsMed?: boolean;
  sizeIsLarge?: boolean;
  className?: string;
}


export const Space: React.FunctionComponent<ISpaceProps> = (props: React.PropsWithChildren<ISpaceProps>) => {

  let stackTokens: IStackTokens = {
    childrenGap: props.sizeIsLarge ? 24 : props.sizeIsMed ? 16 : props.sizeIsSmall ? 12 : 8
  };

  return (
    <>
      <Stack
        tokens={stackTokens}
        horizontal={props.horizontal}
        className={props.className}
        verticalAlign={props.horizontal ? 'center' : undefined}>
        {props.children}
      </Stack>
    </>
  );
};
