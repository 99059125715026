/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { AssetLevelMatrixOneItem } from '../../../Models/Link/GridModels/AssetLevelMatrixOne1';
import { ANType } from '../../../Models/Link/ANType';


export interface IALM1RowProps {
  selANSubType: ANType | null;
  data: AssetLevelMatrixOneItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ALM1Row: React.FunctionComponent<IALM1RowProps> = (props: React.PropsWithChildren<IALM1RowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM1Row]");
  }, []);


  // user controlled input
  const [stateGuideLabel, setStateGuideLabel] = useState<string>('');

  const [stateAssetID, setStateAssetID] = useState(props.data.rpt_ALM1_AssetID);
  const onChangeAssetID = useCallback((evt: any, v?: string) => { setStateAssetID(v || ''); }, []);

  const [stateGuide, setStateGuide] = useState(props.data.rpt_ALM1_Guide);
  const onChangeGuide = useCallback((evt: any, v?: string) => { setStateGuide(v || ''); }, []);

  const [stateBPGdp, setStateBPGdp] = useState(props.data.rpt_decimal_ALM1_BPGDP);
  const onChangeBPGdp = useCallback((evt: any, v?: string) => { setStateBPGdp(v || ''); }, []);

  const [stateBPExitDate, setStateBPExitDate] = React.useState<Date | undefined>(GenUtil.safeToDateOrUndefined(props.data.cv_ALM1_BPExitDate));


  useEffect(() => {
    let lbl = !props.selANSubType ? 'Loading' :
      GenUtil.contains(props.selANSubType.fields.Secondaryoptions, 'non-auction') ? 'Sale Price' : 'Guide';
    setStateGuideLabel(lbl);
  }, [props.selANSubType]);


  // send field updates back to parent

  useEffect(() => {
    props.updateFieldVal(props.counter, 'GuideLabel', GenUtil.safeTrim(stateGuideLabel));
  }, [stateGuideLabel]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'AssetID', GenUtil.safeTrim(stateAssetID));
  }, [stateAssetID]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'Guide', GenUtil.safeTrim(stateGuide));
  }, [stateGuide]);

  useEffect(() => {
    props.updateFieldVal(props.counter, 'BPGdp', GenUtil.safeTrim(stateBPGdp));
  }, [stateBPGdp]);

  useEffect(() => {
    // sending date/undefined value
    props.updateFieldVal(props.counter, 'BPExitDate', stateBPExitDate);
  }, [stateBPExitDate]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //#region 'validation'
  //-------------------------

  const memoIsErrAssetID = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrAssetID();
  }, [props.stateFormSubmitted, stateAssetID]);

  function isErrAssetID() {
    return GenUtil.isNull(stateAssetID);
  }


  const memoIsErrBPGdp = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrBPGdp();
  }, [props.stateFormSubmitted, stateBPGdp]);

  function isErrBPGdp() {
    return GenUtil.safeToNumberOrNull(stateBPGdp) == null ? true : false;
  }


  const memoIsErrGuide = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrGuide();
  }, [props.stateFormSubmitted, stateGuide]);

  function isErrGuide() {
    return GenUtil.safeToNumberOrNull(stateGuide) == null ? true : false;
  }


  const memoIsErrBPExitDate = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrBPExitDate();
  }, [props.stateFormSubmitted, stateBPExitDate]);

  function isErrBPExitDate() {
    return !(stateBPExitDate);
  }

  //#endregion


  return (
    <>

      <tr className='sep'>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeAssetID} value={stateAssetID} placeholder='Please enter text here' className='' errorMessage={memoIsErrAssetID ? 'Value is required.' : ''} maxLength={255} />
            ) : (
              stateAssetID
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeGuide} value={stateGuide} placeholder='Please enter text here' className='' errorMessage={memoIsErrGuide ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              stateGuide
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeBPGdp} value={stateBPGdp} placeholder='Please enter text here' className='' errorMessage={memoIsErrBPGdp ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              stateBPGdp
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <Stack tokens={{ childrenGap: 4 }}>
                <DatePicker
                  formatDate={(d) => !d ? '' : GenUtil.getCalDate(d)}
                  placeholder="Select a date"
                  value={stateBPExitDate}
                  onSelectDate={setStateBPExitDate as (date: Date | null | undefined) => void}
                  allowTextInput
                  className={`w175 ${(memoIsErrBPExitDate ? "invalid-datepicker" : "")}`}
                />
                {
                  memoIsErrBPExitDate && (
                    <Label className='red-msg-text clr'>Date is required</Label>
                  )
                }
              </Stack>
            ) : (
              GenUtil.getCalDate(stateBPExitDate)
            )
          }
        </td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            props.totCount > 1 && !props.isReadOnly && (
              <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem} disabled={props.saving}></IconButton>
            )
          }
        </td>

      </tr>

    </>
  );
};