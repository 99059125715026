/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { ConnectionDetailsItem } from '../../../Models/Link/GridModels/ConnectionDetails1';
import { ANConnection } from '../../../Models/Link/ANConnection';
import { ANEntity } from '../../../Models/Link/ANEntity';


export interface IANConnectionDetailProps {
  uniqueConns: ConnectionDetailsItem[];
  project: ANEntity | null;
  onChangeConn: (id: string, status: string) => void;
  onDeleteConn: (id: string) => void;
  onTotalUpdated: (s: string, d: number) => void;
  stateFormSubmitted: boolean;
  saving: boolean;
}

export const ANConnectionDetail: React.FunctionComponent<IANConnectionDetailProps> = (props: React.PropsWithChildren<IANConnectionDetailProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANConnectionDetail]");
  }, []);


  const [stateRows, setStateRows] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    setStateRows([...props.uniqueConns]);
  }, [props.uniqueConns]);


  // const onChangeStatus = (connId: string, status: string) => {

  //   setStateRows(p => {
  //     let t = [...p];
  //     let idx = t.findIndex(x => x.rpt_ConnectionID === connId);
  //     if (idx >= 0)
  //       t[idx].cv_ConnectionStatus = status;
  //     return t;
  //   });

  //   props.onChangeConn(connId, status);
  // };


  const onDeleteItem = (connId: string) => {
    props.onDeleteConn(connId);
  };


  // const memoStatusOptions = useMemo(() => {
  //   let col = StaticData.connectionStatusOptions.split(',').map(o => { return { key: o, text: o }; });
  //   return col;
  // }, []);


  const memoShowFullyAssigned = useMemo<boolean>(() => {
    if (props.project && GenUtil.safeToBool(props.project.fields.ShowConnFullyAssigned))
      return true;
    else
      return false;
  }, [props.project]);


  const memoSumTotalCollAmts = useMemo<number>(() => {
    // rpt_TotalCollectionsAmount is already summed per connectionid(unique connection), so just need to sum all of these to get the actual total
    let tot: number = 0;
    props.uniqueConns.forEach(o => tot += GenUtil.safeToNumber(o.rpt_TotalCollectionsAmount));
    return tot;
  }, [stateRows]);


  useEffect(() => {
    props.onTotalUpdated('ConnectionDetails', memoSumTotalCollAmts);
  }, [memoSumTotalCollAmts]);


  if (stateRows.length <= 0) {
    return null;
  }
  else {
    return (
      <>
        <table className='sub-table wbsss'>
          <thead>
            <tr>

              <th>{`Connection ID`}</th>

              <th>{`Connection Name`}</th>

              {/* <th>{`Connection Status`}</th> */}

              {memoShowFullyAssigned && <th>{`Fully Assigned?`}</th>}

              <th>{`Deal Life Collections (€)`}</th>

            </tr>
          </thead>
          <tbody>

            {
              stateRows.map((o, i) => {
                return (
                  <tr key={i}>

                    <td>{o.rpt_ConnectionID}</td>

                    <td>{o.rpt_ConnectionName}</td>

                    {/* <td>
                      <ComboBox
                        className='w225'
                        style={{ maxWidth: 400 }}
                        selectedKey={conn.cv_ConnectionStatus || undefined}
                        placeholder="Please select a value"
                        options={memoStatusOptions}
                        // autoComplete={'on'}
                        onChange={(event: any, option?: IComboBoxOption, index?: number, value?: string) => onChangeStatus(conn.rpt_ConnectionID, option ? option.key + '' : '')}
                        errorMessage={props.stateFormSubmitted && GenUtil.isNull(conn.cv_ConnectionStatus) ? 'Status is required.' : ''}
                      />
                    </td> */}

                    {memoShowFullyAssigned && <td>{o.rpt_FullyAssigned}</td>}

                    <td>{o.rpt_TotalCollectionsAmount}</td>

                    <td style={{ maxWidth: 25, textAlign: 'right' }}>
                      <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={() => onDeleteItem(o.rpt_ConnectionID)}></IconButton>
                    </td>

                  </tr>
                );
              })
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>

                  <td>&nbsp;</td>

                  {memoShowFullyAssigned && <td>&nbsp;</td>}

                  <td style={{ textAlign: 'right' }}>
                    <Label>{`Total (€):`}</Label>
                  </td>

                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumTotalCollAmts)}</Label>
                  </td>

                </tr>
              )
            }

          </tbody>
        </table>
      </>
    );
  }
};