/* eslint-disable @typescript-eslint/no-unused-vars */

export const defaultServicerName: string = 'Cabot';
export const defaultBrokerCode: string = 'CABOT';

export const defaultReqConnMetricsCESValue: string = 'Request Metrics from CES?';
export const defaultEmailSubject: string = 'Please Review Advisory Note';

export const luYesNo: string = 'No,Yes';
// export const luYesNoUnknown: string = 'Yes,No,Unknown';
export const luYesNoNA: string = 'Yes,No,N/A';
// export const luLegalStatus: string = 'Foreclosure,Involvency,N/A';

export const luVacant: string = 'Yes,No,Unknown';
export const luUnknown: string = 'Unknown';

export const costsCurrency: string = 'EUR'; // default cost currency for this servicer (Cabot)
export const costsCurrency2: string = 'EUR,GBP';
export const costsCurrency3: string = 'USD,EUR,GBP';

export const propBrdSourceOptions: string = 'Sale of Lender-Secured RE,Sale of Lender-Secured non-RE,Finance,Own Funds,Friends and Family funding,Sale of other asset (not lender security)'; // proposal breakdown, field: Source, ddl options

export const paymentMethodOptions: string = 'Promontoria B.V.,Out of Sales Proceeds,N/A'; // prop costs rate card items related

export let htmlDefaultRecComments: string = `${defaultServicerName} requests approval for [...]`;

export const connectionStatusOptions: string = 'Resolved,Litigation,Performing,Unsecured,Outstanding';

export const defaultListAttType: string = 'Supporting'; // options: "Supporting", "CES1"


// STATUS
// NOTE: updated 4/21/23, scraped from latest JS file
export const wfStatusSaved: string = 'Saved';
export const wfStatusDraft: string = 'Draft';
export const wfStatusCABOT1: string = 'CABOT1';
// export const wfStatusCABOT2: string = 'CABOT2'; // deprecated
export const wfStatusCES1: string = 'CES1';
export const wfStatusServicer: string = 'Servicer';
export const wfStatusRPE1: string = 'RPE1';
export const wfStatusPSI: string = 'PSI';
export const wfStatusCES2: string = 'CES2';
export const wfStatusCGI1: string = 'CGI1';
export const wfStatusCGI2: string = 'CGI2';
export const wfStatusLTH: string = 'LTH';
export const wfStatusEO: string = 'EO';
export const wfStatusRPE2: string = 'RPE2';
export const wfStatusRejected: string = 'Rejected'; // terminal
export const wfStatusWithdrawn: string = 'Withdrawn'; // terminal
export const wfStatusApproved: string = 'Approved'; // terminal
// export const wfStatusCompleted: string = 'Completed'; // terminal (not actually found in JS file as of 4/21/23)


// ACTIONS:
// send forwards:
export const wfActionSignAndRecommend: string = 'Sign and recommend';
export const wfActionSignAndApprove: string = 'Sign and approve';
export const wfActionSubmit: string = 'Submit';
export const wfActionRecommend: string = 'Recommend';
export const wfActionNotSupported: string = 'Not Supported'; // special case for "Submit" with required comments
export const wfActionSeeksAdvice: string = 'Seeks Advice'; // spelling on purpose
export const wfActionSeekAdvice: string = 'Seek Advice'; // spelling on purpose
export const wfActionReassign: string = 'Reassign'; // for CES1 to resubmit changing the case manager
// send backwards:
export const wfActionRequestAddtlInfo: string = 'Request Additional Information'; // 1 step back
export const wfActionReturnToServicer: string = 'Return to CABOT1'; // back to CABOT1. as of 8-22-23 changing to "Return to CABOT1"
export const wfActionReturnToCES: string = 'Return to CES'; // back to CES1
// submit after request more info:
export const wfActionProvideAddtlInfo: string = 'Provide Additional Information';
export const wfActionSubmitAddtlInfo: string = 'Submit Additional Information';
// abort:
export const wfActionReject: string = 'Reject';
export const wfActionWithdraw: string = 'Withdraw';


export const luSfsAssessmentIAFCA: string = 'customer(s) financial circumstances require a long term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,customer(s) cannot afford the monthly repayment under this repayment arrangement,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentIO: string = 'customer(s) financial circumstances require a long term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,customer(s) cannot afford the monthly repayment under this repayment arrangement,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentLTIO: string = 'customer(s) financial circumstances require a long term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,customer(s) cannot afford the monthly repayment under this repayment arrangement,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentMFPB: string = 'customer(s) financial circumstances require a long term solution,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to make a repayment,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentSettle: string = 'customer(s) financial circumstances require a short term solution,customer(s) not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,we have identified a more suitable long term solution,customer has not provided evidence that they can afford a lump sum settlement amount,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentCOA: string = 'customer(s) financial circumstances require a short term solution,there are no arrears to be capitalised on the mortgage account,customer(s) can afford to clear the arrears,customer(s) cannot afford the monthly repayment if arrears are capitalised,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentEOLT: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) cannot afford the monthly repayment if the term is extended,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,the existing term of the mortgage is at the maximum allowable,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentRA: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,customer(s) cannot afford the monthly repayment under this repayment arrangement,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,Customer (s) do not satisfy the criteria to avail of this option,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentDWD: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,we have identified a more suitable long term solution,customer(s) do not satisfy the criteria to avail of this option,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentSM: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,customer(s) have not demonstrated the ability to meet the balance outstanding at the end of the term,customer(s) can afford to pay a higher amount,we have identified a more suitable long term solution,customer(s) do not satisfy the criteria to avail of this option,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentLIO: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer(s) can afford to repay a higher amount,customer(s) cannot afford the monthly repayment under this repayment arrangement,customer(s) have not demonstrated the ability to meet this repayment amount on a regular basis,customer(s) do not satisfy the criteria to avail of this option,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentMTR: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,We have identified a more suitable long term solution,customer(s) do not satisfy the criteria to avail of this option,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentVSale: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer not willing to enter into this arrangement,we have identified a more suitable long term solution,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentVSurr: string = 'customer(s) financial circumstances require a short term solution,customer(s) are not in arrears,customer(s) can afford to clear the arrears,customer(s) can afford to meet the current monthly repayment,customer not willing to enter into this arrangement,we have identified a more suitable long term solution,best option(s) for customer (see reasons why statement below)';
export const luSfsAssessmentATR: string = 'High (pay at least 90% of original or ARA balance),Moderate (pay between 50-90% of original or ARA balance,Low (pay <50% of original or ARA balance)';
export const luSfsAssessmentSARA: string = 'Sustainable ARA,Unsustainable ARA';
