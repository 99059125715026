/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { LitigationMatrixItem } from '../../../Models/Link/GridModels/LitigationMatrix1';


export interface ILitigationMatrixRowProps {
  data: LitigationMatrixItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const LitigationMatrixRow: React.FunctionComponent<ILitigationMatrixRowProps> = (props: React.PropsWithChildren<ILitigationMatrixRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: LitigationMatrixRow]");
  }, []);


  // user controlled input
  const [stateLitRef, setStateLitRef] = useState(props.data.rpt_LMLitigationReference);
  const onChangeLitRef = useCallback((evt: any, v?: string) => { setStateLitRef(v || ''); }, []);

  const [stateProceedings, setStateProceedings] = useState(props.data.rpt_LMProceedings);
  const onChangeProceedings = useCallback((evt: any, v?: string) => { setStateProceedings(v || ''); }, []);

  const [stateRecNum, setStateRecNum] = useState(props.data.rpt_LMRecordNumber);
  const onChangeRecNum = useCallback((evt: any, v?: string) => { setStateRecNum(v || ''); }, []);

  const [stateLegalBudget, setStateLegalBudget] = useState(props.data.rpt_LMLegalBudget);
  const onChangeLegalBudget = useCallback((evt: any, v?: string) => { setStateLegalBudget(v || ''); }, []);


  // send field updates back to parent

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'LitRef', GenUtil.safeTrim(stateLitRef));
  }, [stateLitRef]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'Proceedings', GenUtil.safeTrim(stateProceedings));
  }, [stateProceedings]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'RecNum', GenUtil.safeTrim(stateRecNum));
  }, [stateRecNum]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'LegalBudget', GenUtil.safeTrim(stateLegalBudget));
  }, [stateLegalBudget]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //#region 'validation'
  //-------------------------

  const memoIsErrLegalBudget = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrLegalBudget();
  }, [props.stateFormSubmitted, stateLegalBudget]);

  function isErrLegalBudget() {
    return GenUtil.safeToNumberOrNull(stateLegalBudget) == null ? true : false;
  }


  const memoIsErrLitRef = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrLitRef();
  }, [props.stateFormSubmitted, stateLitRef]);

  function isErrLitRef() {
    return GenUtil.isNull(stateLitRef);
  }


  const memoIsErrProceedings = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrProceedings();
  }, [props.stateFormSubmitted, stateProceedings]);

  function isErrProceedings() {
    return GenUtil.isNull(stateProceedings);
  }


  const memoIsErrRecNum = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRecNum();
  }, [props.stateFormSubmitted, stateRecNum]);

  function isErrRecNum() {
    return GenUtil.isNull(stateRecNum);
  }

  //#endregion


  return (
    <>

      <tr className='sep'>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeLitRef} value={stateLitRef} placeholder='Enter text here' className='' errorMessage={memoIsErrLitRef ? 'Value is required.' : ''} maxLength={255} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateLitRef}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeProceedings} value={stateProceedings} placeholder='Enter text here' className='' multiline rows={2} errorMessage={memoIsErrProceedings ? 'Value is required.' : ''} maxLength={255} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateProceedings}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeRecNum} value={stateRecNum} placeholder='Enter text here' className='' errorMessage={memoIsErrRecNum ? 'Value is required.' : ''} maxLength={255} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateRecNum}</Label>
            )
          }
        </td>

        <td>
          {
            !props.isReadOnly ? (
              <TextField onChange={onChangeLegalBudget} value={stateLegalBudget} placeholder='Enter cost here' className='' errorMessage={memoIsErrLegalBudget ? 'Number is required.' : ''} maxLength={255} />
            ) : (
              <Label className='ms-fontWeight-regular'>{stateLegalBudget}</Label>
            )
          }
        </td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            props.totCount > 1 && !props.isReadOnly && (
              <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>

      </tr>

    </>
  );
};