/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import * as GraphHelper from '../Helpers/GraphHelper';
import * as GenUtil from '../Helpers/GenUtil2';
import * as AppHelper from '../Helpers/AppHelper';
import * as Consts from '../Helpers/Consts';
import * as Config from '../Helpers/ConfigData';
// import { msalScopes, msalScopesDefault } from '../Helpers/AuthConfig';
// import { GraphBaseResp } from '../Models/GraphBaseResp';
import { FileUpload } from '../Models/AppModels';
import { getTokenDefault } from './GraphDataService';


/**
 * 
 * NOTE: shared functions across loan servicers
 * these functions should not reference anything specific to any loan servicer
 * 
 */


/** usefull for testing logicapp auth, get token using default scope */
export async function getTokenForAAD(accounts: AccountInfo[], instance: IPublicClientApplication): Promise<any> {
  // expecting back JSON body, array of objects

  let tok = await getTokenDefault(accounts, instance);

  console.log("### Default scoped AAD Token ###");
  console.log("Bearer", tok);

  return tok;
}


/** get listitem attachments from logicapp custom http endpoint */
export async function getListItemAttachments(accounts: AccountInfo[], instance: IPublicClientApplication,
  svcUrl: string,
  svcSiteAbsUrl: string,
  listTitle: string,
  id: number
): Promise<any> {
  // expecting back JSON body, array of objects

  let tok = await getTokenDefault(accounts, instance);

  let payload: any = {
    id: id + "",
    action: "GET",
    siteaddress: svcSiteAbsUrl,
    listname: listTitle
  };

  // if (Consts.isDevTenant()) {
  //   payload.id = 45;
  //   payload.siteaddress = "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link";
  //   payload.listname = "Advisory Notes Framework";
  // }
  // NOTE: this is not needed, since locking down logic app with oauth

  let config: any = {
    "method": "POST",
    "headers": {
      "Content-Type": "application/json"
    },
    "body": JSON.stringify(payload)
  };

  if (_USE_OAUTH(svcUrl))
    config.headers['Authorization'] = `Bearer ${tok}`;

  let resp = await fetch(svcUrl + "&attAction=GET", config);

  let data = await resp.json();

  return {
    data,
    httpStatus: resp.status,
    httpStatusText: resp.statusText
  };
}


/** delete a listitem attachment using logicapp custom http endpoint */
export async function deleteListItemAttachment(accounts: AccountInfo[], instance: IPublicClientApplication,
  svcUrl: string,
  svcSiteAbsUrl: string,
  listTitle: string,
  id: number,
  absFileUrl: string
): Promise<any> {
  // not expecting anything back, but service should return "something" in the body for a 200 request

  let tok = await getTokenDefault(accounts, instance);

  let payload: any = {
    id: id + "",
    action: "DELETE",
    siteaddress: svcSiteAbsUrl, // ex. "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link"
    listname: listTitle, // ex. "Advisory Notes Framework"
    fileidentifier: getFileIdentifierFromAbsFileUrl(absFileUrl) // ex. "/Lists/ANFramework/Attachments/45/bentest3.txt"
  };

  // if (Consts.isDevTenant()) {
  //   payload.id = 45;
  //   payload.siteaddress = "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link";
  //   payload.listname = "Advisory Notes Framework";
  //   payload.fileidentifier = `/Lists/ANFramework/Attachments/45/${AppHelper.getFilename(absFileUrl)}`;
  // }
  // NOTE: this is not needed, since locking down logic app with oauth

  let config: any = {
    "method": "POST",
    "headers": {
      "Content-Type": "application/json"
    },
    "body": JSON.stringify(payload)
  };

  if (_USE_OAUTH(svcUrl))
    config.headers['Authorization'] = `Bearer ${tok}`;

  let resp = await fetch(svcUrl + "&attAction=DELETE", config);

  return {
    httpStatus: resp.status,
    httpStatusText: resp.statusText
  };
}


/** add a listitem attachment using logicapp custom http endpoint */
export async function addListItemAttachment(accounts: AccountInfo[], instance: IPublicClientApplication,
  svcUrl: string,
  svcSiteAbsUrl: string,
  listTitle: string,
  id: number,
  file: FileUpload
): Promise<any> {
  // expecting back the absUrl to the new file attachment in the list item, as text (not json!)

  let tok = await getTokenDefault(accounts, instance);

  let b64: any = await toBase64(file);

  if (!b64) {
    throw new Error("File content not found.");
  }

  let payload: any = {
    id: id + "",
    action: "ADD",
    filename: file.name.trim(), // ex. "bentest3.txt"
    siteaddress: svcSiteAbsUrl, // ex. "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link"
    listname: listTitle, // ex. "Advisory Notes Framework"
    base64content: b64 + ''
  };

  // if (Consts.isDevTenant()) {
  //   payload.id = 45;
  //   payload.siteaddress = "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link";
  //   payload.listname = "Advisory Notes Framework";
  //   payload.fileidentifier = `/Lists/ANFramework/Attachments/45/${file.name.trim()}`;
  // }
  // NOTE: this is not needed, since locking down logic app with oauth

  let config: any = {
    "method": "POST",
    "headers": {
      "Content-Type": "application/json"
    },
    "body": JSON.stringify(payload)
  };

  if (_USE_OAUTH(svcUrl))
    config.headers['Authorization'] = `Bearer ${tok}`;

  let resp = await fetch(svcUrl + "&attAction=ADD", config);

  // logic app will return the abspath for the new attachment as plain text response
  let data = await resp.text();

  return {
    data: data,
    httpStatus: resp.status,
    httpStatusText: resp.statusText
  };
}


//=============================================


/** helper used to detect if service url has shared access signature param */
function _USE_OAUTH(url: string) {
  // logic app cannot use Shared Access Signature and OAuth(bearer token) both at the same time
  // so if logic app url has sas/sig in it, then do not enable bearer token oauth approach
  return GenUtil.contains(url, "&sig=") ? false : true;
}


/** helper to convert a full abs url to an attachment to a site(web) relative url */
function getFileIdentifierFromAbsFileUrl(url: string) {
  // input url will be full absolute path to attachment, "https://cerberuseu.sharepoint.com/sites/AdvisoryNoteWorkflow-Dev/Link/Lists/ANFramework/Attachments/45/bentest1.txt"
  // convert to site(web) relative url, "/Lists/ANFramework/Attachments/45/bentest1.txt"
  let s = GenUtil.safeTrim(url);
  let i = s.indexOf('/Lists/');
  s = s.substring(i);
  return GenUtil.safeTrim(s);
}


/** helper to convert a File Blob to base64 string data */
const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let encoded = reader.result || '';
    // Building up on Dmitri Pavlutin and joshua.paling answers, here's an extended version that extracts the base64 content (removes the metadata at the beginning) and also ensures padding is done correctly.
    // https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
    encoded = encoded.toString().replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }
    resolve(encoded);
  };
  reader.onerror = error => reject(error);
});
