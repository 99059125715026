/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Finsolutia/GraphDataService';
import * as StaticData from "../../../StaticData/Finsolutia/StaticData";
import { ProposedCostsDetailsItem } from '../../../Models/Finsolutia/GridModels/ProposedCostsDetails1';
import { ANVendor } from '../../../Models/Finsolutia/ANVendor';
import { AjaxPicker } from '../../Controls/AjaxPicker';
import { ANTypeOfCost } from '../../../Models/Finsolutia/ANTypeOfCost';


export interface IProposedCostsRowProps {
  vendors: ANVendor[];
  typeOfCosts: ANTypeOfCost[];
  data: ProposedCostsDetailsItem;
  counter: number;
  totCount: number;
  updateFieldVal: (idx: number, fieldName: string, fieldVal: any) => void;
  handleDeleteItem: (i: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
}

export const ProposedCostsRow: React.FunctionComponent<IProposedCostsRowProps> = (props: React.PropsWithChildren<IProposedCostsRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposedCostsRow]");
  }, []);


  // user controlled input

  const [stateSelVendorPicker, setStateSelVendorPicker] = useState<ITag[]>(GenUtil.isNull(props.data.dd_Vendors) ? [] : [{ key: props.data.dd_Vendors, name: props.data.cv_VendorsValue }]);

  const [stateCost, setStateCost] = useState(GenUtil.safeTrim(props.data.rpt_decimal_Costs));
  const onChangeCost = useCallback((evt: any, v?: string) => { setStateCost(v || ''); }, []);

  const [stateDescr, setStateDescr] = useState(props.data.rpt_Description);
  const onChangeDescr = useCallback((evt: any, v?: string) => { setStateDescr(v || ''); }, []);

  const [stateSelTypeOfCosts, setStateSelTypeOfCosts] = useState<IDropdownOption | undefined>(GenUtil.isNull(props.data.dd_TypeOfCosts) ? undefined : { key: props.data.dd_TypeOfCosts, text: props.data.cv_TypeOfCosts });
  const onChangeTypeOfCosts = (event: any, option?: IDropdownOption, index?: number) => { setStateSelTypeOfCosts(option); };


  // send field updates back to parent

  useEffect(() => {
    // sending ITag object back, not a string
    props.updateFieldVal(props.counter, 'vendor', stateSelVendorPicker.length > 0 ? stateSelVendorPicker[0] : null);
  }, [stateSelVendorPicker]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'cost', GenUtil.safeTrim(stateCost));
  }, [stateCost]);

  useEffect(() => {
    // sending string value
    props.updateFieldVal(props.counter, 'descr', GenUtil.safeTrim(stateDescr));
  }, [stateDescr]);

  useEffect(() => {
    // sending IDropdownOption object back, not a string
    props.updateFieldVal(props.counter, 'typeOfCosts', stateSelTypeOfCosts);
  }, [stateSelTypeOfCosts]);


  function onTagsChangedVendors(items?: ITag[]) {
    setStateSelVendorPicker(!items ? [] : items);

    // when vendor picker changes, reset TypeOfCosts state
    setStateSelTypeOfCosts(undefined);
  }


  const memoVendorOptions = useMemo(() => {
    // convert vendors into picker tag options
    let col = props.vendors.map(o => { return { key: o.fields.id, name: o.fields.Title }; });
    // add "n/a" to head of list
    col = [{ key: '-999', name: 'Not Applicable (N/A)' }, ...col];
    return col;
  }, [props.vendors]);


  const memoTypeOfCostsOptions = useMemo(() => {
    // convert typeofcosts into dropdown options
    return props.typeOfCosts.map(o => { return { key: o.fields.id, text: o.fields.Title }; });
  }, [props.typeOfCosts]);


  const memoIsVendorNA = useMemo(() => {
    return stateSelVendorPicker.length > 0 && stateSelVendorPicker[0].key === '-999';
  }, [stateSelVendorPicker]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.counter);
  }


  //------ validation


  const memoIsErrVendor = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrVendor();
  }, [props.stateFormSubmitted, stateSelVendorPicker]);

  function isErrVendor() {
    if (stateSelVendorPicker.length <= 0) return true;
    return false;
  }


  const memoIsErrCost = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrCost();
  }, [props.stateFormSubmitted, stateCost]);

  function isErrCost() {
    // required, and validate number
    if (GenUtil.safeToNumberOrNull(stateCost) == null) return true;
    return false;
  }


  const memoIsErrTypeOfCost = useMemo(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrTypeOfCost();
  }, [props.stateFormSubmitted, memoIsVendorNA, stateSelTypeOfCosts]);

  function isErrTypeOfCost() {
    return memoIsVendorNA && !stateSelTypeOfCosts;
  }


  return (
    <>

      <tr className='sep'>

        <td className=''>
          {props.counter + 1}
        </td>

        <td className=''>
          {
            props.isReadOnly ? (
              <>
                {memoIsVendorNA ? props.data.cv_TypeOfCosts : props.data.cv_VendorsValue}
              </>
            ) : (
              <>
                <Stack tokens={Consts.stackTokens}>

                  <AjaxPicker
                    cssClassNames='w350'
                    disabled={false}
                    itemLimit={1}
                    suggestedTags={memoVendorOptions}
                    onTagsChanged={onTagsChangedVendors}
                    selectedTags={stateSelVendorPicker}
                    noResultsFoundText=''
                    suggestionsHeaderText={`Search for Vendors`}
                    searchingText='Loading...'
                    placeholder={`Enter Vendor Name or 'N/A'`}
                  />

                  {
                    memoIsErrVendor && (
                      <>
                        <div className='clr red-msg-text' style={{ marginTop: '5px' }}>
                          {`Please choose a Vendor or 'N/A'.`}
                        </div>
                      </>
                    )
                  }

                  {
                    memoIsVendorNA && (
                      <>
                        <Dropdown
                          className='w350'
                          selectedKey={stateSelTypeOfCosts ? stateSelTypeOfCosts.key : undefined}
                          onChange={onChangeTypeOfCosts}
                          placeholder="Please select a Type of Cost"
                          options={memoTypeOfCostsOptions}
                          errorMessage={memoIsErrTypeOfCost ? 'Type of Cost is required.' : ''}
                        />
                      </>
                    )
                  }

                </Stack>
              </>
            )
          }
        </td>

        <td>
          {
            props.isReadOnly ? (
              <>
                {GenUtil.numberToCurrency(props.data.rpt_decimal_Costs)}
              </>
            ) : (
              <>
                <TextField onChange={onChangeCost} value={stateCost} placeholder='Enter cost' className='w150' errorMessage={memoIsErrCost ? 'Invalid currency.' : ''} maxLength={255} />
              </>
            )
          }
        </td>

        <td><Label className='ms-fontWeight-regular'>{StaticData.costsCurrency}</Label></td>

        <td style={{ maxWidth: 15, textAlign: 'right' }}>
          {
            !props.isReadOnly && (
              <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
            )
          }
        </td>

      </tr>

      <tr>
        <td></td>
        <td colSpan={3}>
          <Stack tokens={Consts.stackTokens} horizontal>
            {
              props.isReadOnly ? (
                <>
                  <Label>{`Description:`}</Label>
                  <Label className='ms-fontWeight-regular'>{props.data.rpt_Description}</Label>
                </>
              ) : (
                <>
                  <Label>{`Description:`}</Label>
                  <TextField onChange={onChangeDescr} value={stateDescr} placeholder='Please enter text here' className='w65p' maxLength={255} />
                </>
              )
            }
          </Stack>
        </td>
      </tr>

    </>
  );
};