/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Cabot/GraphDataService';
import * as StaticData from "../../../StaticData/Cabot/StaticData";
import { ALM1Row } from './ALM1Row';
import { AssetLevelMatrixOne1, AssetLevelMatrixOneItem } from '../../../Models/Cabot/GridModels/AssetLevelMatrixOne1';
import { AssetLevelMatrixOneN } from '../../../Models/Cabot/GridModels/AssetLevelMatrixOneN';
import { ANType } from '../../../Models/Cabot/ANType';


export interface IALM1BodyProps {
  xml: string | undefined;
  selANSubType: ANType | null;
  onDataUpdated: (s: string, d: any) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ALM1Body: React.FunctionComponent<IALM1BodyProps> = (props: React.PropsWithChildren<IALM1BodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ALM1Body]");
  }, []);


  const [stateRows, setStateRows] = useState<AssetLevelMatrixOneItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('alm1', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: AssetLevelMatrixOneItem[] = [];

    if (!!props.xml && !GenUtil.isNull(props.xml) && !GenUtil.isInt(props.xml)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ALM1', props.xml);

        if (!AppHelper.xmlHasMultipleItems(props.xml)) {
          let _t = (obj as AssetLevelMatrixOne1).RepeaterData.Items.Item;

          let item: AssetLevelMatrixOneItem = {
            cv_RowNum: GenUtil.generateGuid(),
            rpt_ALM1_AssetID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_AssetID)),
            cv_ALM1_Guide_Label: GenUtil.safeTrim(AppHelper.getText(_t.cv_ALM1_Guide_Label)),
            rpt_ALM1_Guide: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_Guide)),
            rpt_decimal_ALM1_BPGDP: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM1_BPGDP)),
            rpt_ALM1_BPExitDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_BPExitDate)),
            cv_ALM1_BPExitDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_ALM1_BPExitDate)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_ALM1_AssetID).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as AssetLevelMatrixOneN;

          let _col: AssetLevelMatrixOneItem[] = _t.RepeaterData.Items.Item.map((_t, i) => {
            return {
              cv_RowNum: GenUtil.generateGuid(),
              rpt_ALM1_AssetID: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_AssetID)),
              cv_ALM1_Guide_Label: GenUtil.safeTrim(AppHelper.getText(_t.cv_ALM1_Guide_Label)),
              rpt_ALM1_Guide: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_Guide)),
              rpt_decimal_ALM1_BPGDP: GenUtil.safeTrim(AppHelper.getText(_t.rpt_decimal_ALM1_BPGDP)),
              rpt_ALM1_BPExitDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ALM1_BPExitDate)),
              cv_ALM1_BPExitDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_ALM1_BPExitDate)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.rpt_ALM1_AssetID));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in ALM1`, props.xml, error); }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        cv_RowNum: GenUtil.generateGuid(),
        rpt_ALM1_AssetID: '',
        cv_ALM1_Guide_Label: '',
        rpt_ALM1_Guide: '',
        rpt_decimal_ALM1_BPGDP: '',
        rpt_ALM1_BPExitDate: '',
        cv_ALM1_BPExitDate: '',
      }];
    }

    setStateRows(col);

  }, [props.xml]);


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        cv_RowNum: GenUtil.generateGuid(),
        rpt_ALM1_AssetID: '',
        cv_ALM1_Guide_Label: '',
        rpt_ALM1_Guide: '',
        rpt_decimal_ALM1_BPGDP: '',
        rpt_ALM1_BPExitDate: '',
        cv_ALM1_BPExitDate: '',
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];

      if (GenUtil.eq(fieldName, 'GuideLabel')) o.cv_ALM1_Guide_Label = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'AssetID')) o.rpt_ALM1_AssetID = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'Guide')) o.rpt_ALM1_Guide = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'BPGdp')) o.rpt_decimal_ALM1_BPGDP = GenUtil.numberToCurrency(GenUtil.safeToNumberOrNull(fieldVal));
      else if (GenUtil.eq(fieldName, 'BPExitDate')) {
        o.cv_ALM1_BPExitDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_ALM1_BPExitDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }

      return t;
    });
  }


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  const memoGuideLabel = useMemo<string>(() => {
    return !props.selANSubType ? 'Loading' :
      GenUtil.contains(props.selANSubType.fields.Secondaryoptions, 'non-auction') ? 'Sale Price' : 'Guide';
  }, [props.selANSubType]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className={`sub-table3 ${props.isReadOnly ? 'w600' : ''}`}>
          <thead>

            <tr>
              <th>{`Asset ID`}</th>
              <th>{memoGuideLabel}</th>
              <th>{`BP GDP (€)`}</th>
              <th>{`BP Exit Date`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <ALM1Row
                  selANSubType={props.selANSubType}
                  key={o.cv_RowNum}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving} />)
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow} disabled={props.saving}>Add a new row</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.xml: {JSON.stringify(props.xml, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};