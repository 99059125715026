/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";

import { ANLegalTrackerItem } from '../../../Models/Link/ANLegalTracker';
import { Space } from '../../Controls/Space';


export interface ILegalTrackerROProps {
  webUrl: string;
  data: ANLegalTrackerItem[];
}

export const LegalTrackerRO: React.FunctionComponent<ILegalTrackerROProps> = (props: React.PropsWithChildren<ILegalTrackerROProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: LegalTrackerRO]");
  }, []);


  return (
    <>

      <h2 className="flu-heading1 wbsss">{`Legal Tracker`}</h2>

      {
        props.data.length <= 0 && (
          <>
            <MessageBar messageBarType={MessageBarType.warning} className='wts12'>
              {`No associated Legal Tracker records found with matching Connection ID.`}
            </MessageBar>
          </>
        )
      }

      {
        props.data.length > 0 && (
          <>
            <table className='sub-table3'>
              <thead>
                <tr>
                  <th>Litigation ID</th>
                  <th>Connection ID</th>
                  {/* <th>Litigation Name</th> */}
                  <th>Litigation Type</th>
                  <th>Status</th>
                  <th>Next Court Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.data.map((o, i) => (
                    <tr key={o.id}>
                      <td>
                        <Stack horizontal verticalAlign='center'>
                          <Link href={`${props.webUrl}/EditForm.aspx?ID=${o.fields.id}`} target='_blank'>{o.fields.id}</Link>
                          <IconButton iconProps={{ iconName: 'OpenInNewWindow' }} allowDisabledFocus title="Open" ariaLabel="Open" onClick={() => window.open(`${props.webUrl}/EditForm.aspx?ID=${o.fields.id}`, "_blank")}></IconButton>
                        </Stack>
                      </td>
                      <td>{o.fields.ConnectionID}</td>
                      {/* <td>{o.fields.TitleToProceedings_x0028_Plainti}</td> */}
                      <td>{o.fields.Litigation_x0020_Type}</td>
                      <td>{o.fields.Status}</td>
                      <td>{GenUtil.getCalDate(o.fields.NextCourtDateValid)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        )
      }

    </>
  );
};