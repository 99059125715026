/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Route,
  Routes
} from 'react-router-dom';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Consts from "../Helpers/Consts";
import * as GenUtil from '../Helpers/GenUtil2';
import { useKeyPhraseMatch, useMobileCheck, konamiCode } from '../Helpers/UseHooksDotCom';
import * as Config from '../Helpers/ConfigData';

import ScrollToTop from './Controls/ScrollToTop';

import { ANDetail as ANDetailLink } from './Servicers/Link/ANDetail';
import { ANHome as ANHomeLink } from './Servicers/Link/ANHome';

import { ANDetail as ANDetailCabot } from './Servicers/Cabot/ANDetail';
import { ANHome as ANHomeCabot } from './Servicers/Cabot/ANHome';

import { ANDetail as ANDetailFinsol } from './Servicers/Finsolutia/ANDetail';
import { ANHome as ANHomeFinsol } from './Servicers/Finsolutia/ANHome';

import { ANDetail as ANDetailAltamira } from './Servicers/Altamira/ANDetail';
import { ANHome as ANHomeAltamira } from './Servicers/Altamira/ANHome';

import { ANDetail as ANDetailCopernicus } from './Servicers/Copernicus/ANDetail';
import { ANHome as ANHomeCopernicus } from './Servicers/Copernicus/ANHome';

import { ANDetailLoader as ANDetailGescobroLoader } from './Servicers/Gescobro/ANDetailLoader';
import { ANDetail as ANDetailGescobro } from './Servicers/Gescobro/ANDetail';
import { ANHome as ANHomeGescobro } from './Servicers/Gescobro/ANHome';

import { ANDetailLoader as ANDetailTDXLoader } from './Servicers/TDX/ANDetailLoader';
import { ANDetail as ANDetailTDX } from './Servicers/TDX/ANDetail';
import { ANHome as ANHomeTDX } from './Servicers/TDX/ANHome';

import { ANDetailLoader as ANDetailIntrumLoader } from './Servicers/Intrum/ANDetailLoader';
import { ANDetail as ANDetailIntrum } from './Servicers/Intrum/ANDetail';
import { ANHome as ANHomeIntrum } from './Servicers/Intrum/ANHome';

import { AuthTest1 } from './Testing/AuthTest1';
import { Test1 } from './Testing/Test1';
import { Test2 } from './Testing/Test2';
import { Test3 } from './Testing/Test3';
import { Test4 } from './Testing/Test4';
import { Test5 } from './Testing/Test5';
import { Test6 } from './Testing/Test6';
import { Test7 } from './Testing/Test7';
import { Test8 } from './Testing/Test8';


export interface IHomeProps {
  useAuth: boolean;
}

export const Home: React.FunctionComponent<IHomeProps> = (props: React.PropsWithChildren<IHomeProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: Home]");
  }, []);


  const { accounts } = useMsal();


  useEffect(() => {
    if (Consts.isDevEnv()) {
      console.log("#################### THIS IS NOT PRODUCTION ####################");
      console.log("#################### THIS IS NOT PRODUCTION ####################");
      console.log("#################### THIS IS NOT PRODUCTION ####################");
    }
  }, []);


  //#region 'INIT APP INSIGHTS'
  //-------------------------

  useEffect(() => {
    try {

      if (!GenUtil.safeToBool(Config.Settings().AppInsightsEnabled))
        return;

      const appInsights = new ApplicationInsights({
        config: {
          connectionString: Config.Settings().AppInsightsConnString,
          disableTelemetry: false
        }
      });

      appInsights.loadAppInsights();

      if (GenUtil.safeToBool(Config.Settings().AppInsightsTrackPageView)) {
        appInsights.trackPageView();
      }

      (window as any).appInsights = appInsights;

      console.log("*** APP INSIGHTS ENABLED ***");

    } catch (error) { }
  }, []);

  //#endregion


  const memoUsername = useMemo(() => {
    if (props.useAuth && accounts.length > 0) {
      (window as any).curUserEmail = accounts[0].username;
      (window as any).curUserDispName = accounts[0].name;
      if (Consts.admOvrUsername()) { return Consts.admOvrUsername(); }
      else { return accounts[0].username; }
    }
    else {
      return '#Anonymous#';
    }
  }, [props.useAuth, accounts]);


  const memoCurUserDispName = useMemo(() => {
    if (Consts.admOvrUsername()) { return Consts.admOvrUsername(); }
    else { return accounts.length > 0 ? (accounts[0].name ? accounts[0].name : accounts[0].username) : ''; }
  }, [accounts]);


  const isMobile = useMobileCheck();


  //#todo when a servicer tab is found in the URL, the other tabs should go away: ex, when "/Finsolutia/new" is found only the "Finsolutia" tab should be shown, others should be hidden


  //#region 'turn on admin mode using konami code'
  //-------------------------

  const match = useKeyPhraseMatch(konamiCode);

  useEffect(() => {
    if (match) {
      let url = window.location.href;

      let qs = 'admOvrShowDebugInfo=1&admOvrShowAdminSection=1&admOvrAddCurUserToAllRoles=1&admOvrShowAllSectionsFields=0&admOvrEditMode=0&admOvrUsername=';

      window.alert('Admin Overrides Accepted');

      url = url.indexOf('?') >= 0 ? url + '&' : url + '?'; // append correct qs param
      url = url.replace(new RegExp(qs, 'gi'), ''); // remove all instances of qs
      url = url.replace(/&{2,}/ig, '&'); // fix duplicate "&&" issues
      url = url.replace(/\?&/ig, '?'); // fix "?&" issues
      url += qs;

      window.location.href = url;

    }
  }, [match]);

  //#endregion


  // determine which menu item is shown in top nav section
  const isLink = !!window.location.href.match(/\/Link(\/(new|\d+))?/ig);
  const isCabot = !!window.location.href.match(/\/Cabot(\/(new|\d+))?/ig);
  const isFinsol = !!window.location.href.match(/\/Finsolutia(\/(new|\d+))?/ig);
  const isAltamira = !!window.location.href.match(/\/Altamira(\/(new|\d+))?/ig);
  const isCopernicus = !!window.location.href.match(/\/Copernicus(\/(new|\d+))?/ig);
  const isGescobro = !!window.location.href.match(/\/Gescobro(\/(Unsecured|Secured))?(\/(new|\d+))?/ig);
  const isTDX = !!window.location.href.match(/\/TDX(\/(Unsecured|Secured))?(\/(new|\d+))?/ig);
  const isIntrum = !!window.location.href.match(/\/Intrum(\/(Unsecured|Secured))?(\/(new|\d+))?/ig);


  return (
    <>
      <Router>

        <ScrollToTop />

        <div className="topnav wbss" id='hello'>

          <Link to="/" className='topnav-logo'><img src='/cerberus_logo_white.svg' alt=''></img></Link>

          {/* <NavLink to="/" end>Home</NavLink> */}

          {isLink && <NavLink to="Link">Link/BCM</NavLink>}

          {isCabot && <NavLink to="Cabot">Cabot</NavLink>}

          {isFinsol && <NavLink to="Finsolutia">Finsolutia</NavLink>}

          {isAltamira && <NavLink to="Altamira">Altamira</NavLink>}

          {isCopernicus && <NavLink to="Copernicus">Copernicus</NavLink>}

          {isGescobro && <NavLink to="Gescobro">Gescobro</NavLink>}

          {isTDX && <NavLink to="TDX">TDX</NavLink>}

          {isIntrum && <NavLink to="Intrum">Intrum</NavLink>}

          {Consts.isWorkbench() && <NavLink to="testing">Testing</NavLink>}

          {
            !isMobile && (
              <a href='#hello' style={{ float: 'right' }} onClick={() => { alert(`Hello ${memoCurUserDispName}!`); }}>{`${memoUsername}`}</a>
            )
          }
        </div>

        <div className='main-app'>
          <Routes>

            <Route path="/" >

              <Route path="Link">
                <Route path=":id" element={<ANDetailLink />} />
                <Route index element={<ANHomeLink />} />
              </Route>

              <Route path="Cabot">
                <Route path=":id" element={<ANDetailCabot />} />
                <Route index element={<ANHomeCabot />} />
              </Route>

              <Route path="Finsolutia">
                <Route path=":id" element={<ANDetailFinsol />} />
                <Route index element={<ANHomeFinsol />} />
              </Route>

              <Route path="Altamira">
                <Route path=":id" element={<ANDetailAltamira />} />
                <Route index element={<ANHomeAltamira />} />
              </Route>

              <Route path="Copernicus">
                <Route path=":id" element={<ANDetailCopernicus />} />
                <Route index element={<ANHomeCopernicus />} />
              </Route>

              {/* expecting path like "/Gescobro/[Un]Secured/[new|ID]" */}
              <Route path="Gescobro">
                <Route path="Unsecured/:id" element={<ANDetailGescobro isSecured={false} />} />
                <Route path="Secured/:id" element={<ANDetailGescobro isSecured={true} />} />
                <Route path=":id" element={<ANDetailGescobroLoader />} />
                <Route index element={<ANHomeGescobro />} />
              </Route>

              {/* expecting path like "/TDX/[Un]Secured/[new|ID]" */}
              <Route path="TDX">
                <Route path="Unsecured/:id" element={<ANDetailTDX isSecured={false} />} />
                <Route path="Secured/:id" element={<ANDetailTDX isSecured={true} />} />
                <Route path=":id" element={<ANDetailTDXLoader />} />
                <Route index element={<ANHomeTDX />} />
              </Route>

              {/* expecting path like "/Intrum/[Un]Secured/[new|ID]" */}
              <Route path="Intrum">
                <Route path="Unsecured/:id" element={<ANDetailIntrum isSecured={false} />} />
                <Route path="Secured/:id" element={<ANDetailIntrum isSecured={true} />} />
                <Route path=":id" element={<ANDetailIntrumLoader />} />
                <Route index element={<ANHomeIntrum />} />
              </Route>

              <Route path="testing" >
                <Route path="test1" element={<Test1></Test1>}></Route>
                <Route path="test2" element={<Test2></Test2>}></Route>
                <Route path="test3" element={<Test3></Test3>}></Route>
                <Route path="test4" element={<Test4></Test4>}></Route>
                <Route path="test5" element={<Test5></Test5>}></Route>
                <Route path="test6" element={<Test6></Test6>}></Route>
                <Route path="test7" element={<Test7></Test7>}></Route>
                <Route path="test8" element={<Test8></Test8>}></Route>
                <Route path="authtest1" element={<AuthTest1></AuthTest1>}></Route>
                <Route index element={
                  <>
                    <h1 className="flu-page-title2 flu-bottomborder1 wbss">Testing</h1>
                    <ul>
                      <li><Link to='test1'>Test1</Link></li>
                      <li><Link to='test2'>Test2</Link></li>
                      <li><Link to='test3'>Test3</Link></li>
                      <li><Link to='test4'>Test4</Link></li>
                      <li><Link to='test5'>Test5</Link></li>
                      <li><Link to='test6'>Test6</Link></li>
                      <li><Link to='test7'>Test7</Link></li>
                      <li><Link to='test8'>Test8</Link></li>
                      <li><Link to='authtest1'>AuthTest1</Link></li>
                    </ul>
                  </>
                }></Route>
              </Route>

              <Route path="settings" element={
                <>

                  <h1 className="flu-page-title2 flu-bottomborder1 wbss">Settings</h1>

                  <p>Under Construction</p>

                </>
              } />

              <Route index element={
                <>

                  <h1 className="flu-page-title2 flu-bottomborder1 wbss">Dashboard</h1>

                  <p>{`Welcome to the Azure Hosted Advisory Notes web application. Please select a Loan Servicer from the menu above to continue.`}</p>

                </>
              } />

            </Route>

          </Routes>
        </div>

      </Router>

      <ToastContainer />

    </>
  );
};