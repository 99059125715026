/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";

import { BorLoanBorItem } from '../../../Models/Link/GridModels/BorLoan';
import { BorLoanLoanBody } from './BorLoanLoanBody';


export interface IBorLoanBorRowProps {
  bor: BorLoanBorItem;
  borAsJson: string; // NOTE: used to force a render when the deep collection changes (loan col in bor col)
  updateFieldVal: (id: string, fieldName: string, fieldVal: any) => void;
  onUpdateLoanActive: (borId: string, loanId: string) => void;
  onUpdateShowLoans: (borId: string) => void;
  handleDeleteItem: (a: string) => void;
  isReadOnly: boolean;
  saving: boolean;
  stateFormSubmitted: boolean;
}

export const BorLoanBorRow: React.FunctionComponent<IBorLoanBorRowProps> = (props: React.PropsWithChildren<IBorLoanBorRowProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: BorLoanBorRow]");
  }, []);


  const [stateSelRecourse, setStateSelRecourse] = useState<string>(props.bor.rpt_RecourseValue);
  const onChangeRecourse = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelRecourse(option ? option.key + '' : ''); }


  // send field updates back to parent

  useEffect(() => {
    // sending string value, convert IDropdownOption object to string
    props.updateFieldVal(props.bor.rpt_BorrowerID, 'recourse', GenUtil.safeTrim(stateSelRecourse));
  }, [stateSelRecourse]);


  function onClickDeleteItem() {
    // delete this item in parent component
    props.handleDeleteItem(props.bor.rpt_BorrowerID);
  }


  function onUpdateLoanActive(loanId: string) {
    props.onUpdateLoanActive(props.bor.rpt_BorrowerID, loanId);
  }


  function onChangeShowLoans() {
    props.onUpdateShowLoans(props.bor.rpt_BorrowerID);
  }


  const memoShowLoans = useMemo<boolean>(() => {
    return props.bor.cv_ShowLoans;
  }, [props.isReadOnly, props.bor.cv_ShowLoans]);


  const memoIsErrRecourse = useMemo<boolean>(() => {
    if (!props.stateFormSubmitted) return false;
    return isErrRecourse();
  }, [props.stateFormSubmitted, stateSelRecourse]);

  function isErrRecourse() {
    // always required
    return GenUtil.isNull(stateSelRecourse);
  }


  return (
    <>

      <tr className='sep'>

        <td>
          <IconButton disabled={props.saving} iconProps={{ iconName: memoShowLoans ? 'ChevronDown' : 'ChevronUp' }} onClick={onChangeShowLoans} />
        </td>

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.bor.rpt_BorrowerID}
            {Consts.isWorkbench() && <span style={{ color: 'purple' }}>{` `}({props.bor.cv_ConnId})</span>}
          </Label>
        </td>

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.bor.rpt_BorrowerName}
          </Label>
        </td>

        <td>
          {
            props.isReadOnly && (
              <>
                <Label className='ms-fontWeight-regular'>{props.bor.rpt_RecourseValue}</Label>
              </>
            )
          }
          {
            !props.isReadOnly && (
              <>
                <ComboBox
                  className='w125'
                  disabled={false}
                  selectedKey={stateSelRecourse}
                  placeholder='Choose...'
                  options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                  onChange={onChangeRecourse}
                  errorMessage={memoIsErrRecourse ? "Recourse is required." : ""}
                />
              </>
            )
          }
        </td>

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.bor.cv_ActiveLoanCount}
          </Label>
        </td>

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.bor.cv_UPBSum}
          </Label>
        </td>

        <td>
          <Label className='ms-fontWeight-regular'>
            {props.bor.cv_CollectionsSum}
          </Label>
        </td>

        {
          !props.isReadOnly && (
            <>
              <td style={{ maxWidth: 25, textAlign: 'right' }}>
                <IconButton disabled={props.saving} iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={onClickDeleteItem}></IconButton>
              </td>
            </>
          )
        }

      </tr>

      {
        !GenUtil.isNull(props.bor.rpt_JointBorrowerNames) && (
          <tr>
            <td></td>
            <td colSpan={6} style={{ paddingLeft: '20px' }} >
              <Label>{`Joint Borrower Names:`}</Label>
              <Label className='ms-fontWeight-regular'>{props.bor.rpt_JointBorrowerNames}</Label>
            </td>
          </tr>
        )
      }

      {
        props.bor.cv_ShowLoans && (
          <tr>
            <td></td>
            <td colSpan={6} style={{ paddingLeft: '20px' }} >
              <BorLoanLoanBody
                loans={props.bor.col_LoanItems}
                loansAsJson={JSON.stringify(props.bor.col_LoanItems)}
                onUpdateLoanActive={onUpdateLoanActive}
                isReadOnly={props.isReadOnly}
                saving={props.saving}
              />
            </td>
          </tr>
        )
      }

    </>
  );
};
