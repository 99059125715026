/* eslint-disable @typescript-eslint/no-unused-vars */

import * as GraphHelper from '../../Helpers/GraphHelper';
import * as GenUtil from '../../Helpers/GenUtil2';
import * as Consts from '../../Helpers/Consts';
// import { msalScopes } from '../../Helpers/AuthConfig';
import * as Config from '../../Helpers/ConfigData';

import { GraphBaseResp } from '../../Models/GraphBaseResp';
import { ANEntities, ANEntity } from '../../Models/Cabot/ANEntity';
import { ANType, ANTypes } from '../../Models/Cabot/ANType';
//import { ANIrishBankHoliday, ANIrishBankHolidays } from '../../Models/Cabot/ANIrishBankHoliday';
import { ANVendor, ANVendors } from '../../Models/Cabot/ANVendor';
import { ANUser, ANUsers } from '../../Models/Cabot/ANUser';
import { ANConnection, ANConnections } from '../../Models/Cabot/ANConnection';
import { ANNote } from '../../Models/Cabot/ANNote';
//import { ANLegalTitleHolderMaps } from '../../Models/Cabot/ANLegalTitleHolderMap';
//import { ANLinkApprovers } from '../../Models/Cabot/ANLinkApprover';
//import { ANCGIDirectors } from '../../Models/Cabot/ANCGIDirector';
import { ANCurrencys } from '../../Models/Cabot/ANCurrency';
import { ANRateCardItems } from '../../Models/Cabot/ANRateCardItem';
import * as AppModels from '../../Models/AppModels';

import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { getToken } from '../GraphDataService';
import { ANLegalTrackerItems } from '../../Models/Cabot/ANLegalTracker';
import { CompletedANs } from '../../Models/Cabot/CompletedAN';
import { ARADetailItems } from '../../Models/Cabot/ARADetail';
import { ANServicerSubmitters } from '../../Models/Cabot/ANServicerSubmitters';


/*
NOTES:
sample: https://graph.microsoft.com/v1.0/sites/cerberus.sharepoint.com:/sites/CerberusPOC1:/lists/Vendors/items?$expand=fields&$orderBy=fields/Title
if using "orderby", then the field in the list MUST be indexed
if expecting to return all the items from the list on page load, then can sort them using clientside (lodash)

NOTE:
You can use the orderby query string to control the sort order of the items returned from the OneDrive API. For a collection of items, use the following fields in the orderby parameter.
  name
  size
  lastModifiedDateTime

Note that in OneDrive for Business and SharePoint Server 2016, the orderby query string only works with name and url.
https://learn.microsoft.com/en-us/onedrive/developer/rest-api/concepts/optional-query-parameters?view=odsp-graph-online#sorting-collections

*/


function buildGraphBaseUri() {

  let a = `https://graph.microsoft.com/v1.0/sites/${Config.Settings().HostName}`;

  let b = Config.Settings().SiteRelPathCabot.trim().length === 0 ?
    '' :
    `:${encodeURIComponent(Config.Settings().SiteRelPathCabot)}:`;

  return `${a}${b}`;
}


// =============================================================


export async function getANNotesList(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<GraphBaseResp> {
  // get list info about ANNotes, to build weburls (back to list)

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANNotesCabot}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANLegalTrackerList(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<GraphBaseResp> {
  // get list info about Legal Tracker, to build weburls (link to form)

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANLegalTrackerCabot}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANEntities(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANEntities> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleEntitiesCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANServicerSubmitters(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANServicerSubmitters> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANServicerSubmittersCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANTypes(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANTypes> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANTypesCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANVendors(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANVendors> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleVendorsCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANUsers(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANUsers> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANUsersCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANCurrencys(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANCurrencys> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleCurrencyCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANRateCardItems(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ANRateCardItems> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANRateCardItemsCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getARADetails(accounts: AccountInfo[], instance: IPublicClientApplication, token?: string): Promise<ARADetailItems> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleARADetailsCabot}/items?$expand=fields&$top=${Consts.graphMaxAllItemsCount}`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANLegalTrackerItems(accounts: AccountInfo[], instance: IPublicClientApplication,
  connId: string,
  top: number = Consts.graphMaxAllItemsCount,
  token?: string
): Promise<ANLegalTrackerItems> {

  let tok = await getToken(accounts, instance, token);

  // https://graph.microsoft.com/v1.0/sites/test.sharepoint.com:%2Fsites%2FCerberusPOC1:/lists/LegalTracker042823a/items?$expand=fields&$top=5000&$orderBy=id&$filter=fields/ConnectionID eq 'CNS606681'

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANLegalTrackerCabot}/items?$expand=fields&$top=${top}`;

  url += `&$filter=fields/ConnectionID eq '${encodeURIComponent(connId.trim())}'`;

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, that has HTTP codes in it too, this endpoint may fail on searches that are too general
  return data;
}


export async function getCompletedANs(accounts: AccountInfo[], instance: IPublicClientApplication,
  connId: string,
  top: number = Consts.graphMaxAllItemsCount,
  token?: string
): Promise<CompletedANs> {

  let tok = await getToken(accounts, instance, token);

  // https://graph.microsoft.com/v1.0/sites/test.sharepoint.com:/sites/CerberusPOC1:/lists/CompletedANs/items?$expand=fields&$top=500&$filter=fields/ConnectionID eq 'CNS606681'

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleCompletedANsCabot}/items?$expand=fields&$top=${top}`;

  url += `&$filter=fields/ConnectionID eq '${encodeURIComponent(connId.trim())}'`;

  let data = await GraphHelper.getAsync2(url, tok);

  return data;
}


export async function getANConnections(accounts: AccountInfo[], instance: IPublicClientApplication,
  connId: string,
  projName: string,
  top: number = Consts.graphMaxAllItemsCount,
  token?: string
): Promise<ANConnections> {
  /**
   * this single function is used for both connection picker and loading the connection
   */

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleConnectionsCabot}/items?$expand=fields&$top=${top}&$orderBy=fields/Connection_x0020_ID`;

  if (connId.trim().length > 0 && projName.trim().length > 0) {
    // both: startsWith match (connId) AND exact match (projName)
    url += `&$filter=startsWith(fields/Connection_x0020_ID, '${encodeURIComponent(connId.trim())}') and fields/Project eq '${encodeURIComponent(projName.trim())}'`;
  }
  else if (connId.trim().length > 0) {
    // exact match
    url += `&$filter=fields/Connection_x0020_ID eq '${encodeURIComponent(connId.trim())}'`;
  }
  else if (projName.trim().length > 0) {
    // #todo is this condition ever used???
    // exact match
    url += `&$filter=fields/Project eq '${encodeURIComponent(projName.trim())}'`;
  }

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, that has HTTP codes in it too, this endpoint may fail on searches that are too general
  return data;
}


export async function getANNote(accounts: AccountInfo[], instance: IPublicClientApplication,
  id: number,
  token?: string
): Promise<ANNote> {

  let tok = await getToken(accounts, instance, token);

  let url = `${buildGraphBaseUri()}/lists/${Config.Settings().ListTitleANNotesCabot}/items/${id}?$expand=fields`;

  let data = await GraphHelper.getAsync2(url, tok);

  // NOTE: return the entire wrapper, as this endpoint is volatile (items that do not exist cause graph return errors), also the "/items/222" response is not an array of objects, its a single object
  return data;
}
