/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, Checkbox
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Link/GraphDataService';
import * as StaticData from "../../../StaticData/Link/StaticData";
import { ProposalBreakdownRow } from './ProposalBreakdownRow';
import { ProposalBreakdown1, ProposalBreakdownItem } from '../../../Models/Link/GridModels/ProposalBreakdown1';
import { ProposalBreakdownN } from '../../../Models/Link/GridModels/ProposalBreakdownN';
import { ANCurrency } from '../../../Models/Link/ANCurrency';


export interface IProposalBreakdownBodyProps {
  data: string | undefined;
  currencys: ANCurrency[];
  onDataUpdated: (s: string, d: any) => void;
  onTotalUpdated: (s: string, d: number) => void;
  stateFormSubmitted: boolean;
  isReadOnly: boolean;
  saving: boolean;
}

export const ProposalBreakdownBody: React.FunctionComponent<IProposalBreakdownBodyProps> = (props: React.PropsWithChildren<IProposalBreakdownBodyProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ProposalBreakdownBody]");
  }, []);


  const [stateRows, setStateRows] = useState<ProposalBreakdownItem[]>([]);


  useEffect(() => {
    props.onDataUpdated('ProposalBreakdown', stateRows);
  }, [stateRows]);


  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    let col: ProposalBreakdownItem[] = [];

    if (!!props.data && !GenUtil.isNull(props.data) && !GenUtil.isInt(props.data)) {

      try {
        let obj = AppHelper.getJsonObjFromXmlStr('ProposalBreakdown', props.data);

        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          let _t = (obj as ProposalBreakdown1).RepeaterData.Items.Item;

          let item: ProposalBreakdownItem = {
            cv_RowNum: GenUtil.safeTrim(AppHelper.getText(_t.cv_RowNum)),

            rpt_EarliestExpectedDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_EarliestExpectedDate)),
            cv_EarliestExpectedDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_EarliestExpectedDate)),

            cv_ExpMinAmtAct: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtAct)),
            cv_ExpMinAmtCurAct: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtCurAct)),
            cv_ExpMinAmtOrig: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtOrig)),
            cv_ExpMinAmtCurOrig: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtCurOrig)),
            rpt_ExpMinAmtDisp: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ExpMinAmtDisp)),
            rpt_ExpMinAmtCurDisp: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ExpMinAmtCurDisp)),

            rpt_Source: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Source)),
          };

          // only add if the object is not nintex empty xml record
          if ((GenUtil.safeTrim(item.rpt_Source).length) > 0) {
            col = [item];
          }

        }
        else {
          let _t = obj as ProposalBreakdownN;

          let _col: ProposalBreakdownItem[] = _t.RepeaterData.Items.Item.map(_t => {
            return {
              cv_RowNum: GenUtil.safeTrim(AppHelper.getText(_t.cv_RowNum)),

              rpt_EarliestExpectedDate: GenUtil.safeTrim(AppHelper.getText(_t.rpt_EarliestExpectedDate)),
              cv_EarliestExpectedDate: GenUtil.safeTrim(AppHelper.getText(_t.cv_EarliestExpectedDate)),

              cv_ExpMinAmtAct: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtAct)),
              cv_ExpMinAmtCurAct: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtCurAct)),
              cv_ExpMinAmtOrig: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtOrig)),
              cv_ExpMinAmtCurOrig: GenUtil.safeTrim(AppHelper.getText(_t.cv_ExpMinAmtCurOrig)),
              rpt_ExpMinAmtDisp: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ExpMinAmtDisp)),
              rpt_ExpMinAmtCurDisp: GenUtil.safeTrim(AppHelper.getText(_t.rpt_ExpMinAmtCurDisp)),

              rpt_Source: GenUtil.safeTrim(AppHelper.getText(_t.rpt_Source)),
            }
          });

          // only add if the object is not nintex empty xml record
          _col = _col.filter(o => !GenUtil.isNull(o.rpt_Source));

          col = _col;
        }

      } catch (error) { console.warn(`Error parsing Xml in ProposalBreakdown`, props.data, error); }
    }

    if (col.length <= 0) {
      // when section is shown, and no rows saved, add a default row
      col = [{
        cv_RowNum: '',
        rpt_EarliestExpectedDate: '',
        cv_EarliestExpectedDate: '',
        cv_ExpMinAmtAct: '',
        cv_ExpMinAmtCurAct: '',
        cv_ExpMinAmtOrig: '',
        cv_ExpMinAmtCurOrig: '',
        rpt_ExpMinAmtDisp: '',
        rpt_ExpMinAmtCurDisp: '',
        rpt_Source: '',
      }];
    }

    setStateRows(col);

  }, [props.data]);


  function onClickAddRow() {
    // add empty row to collection

    setStateRows(p => {
      return [...p, {
        cv_RowNum: '',
        rpt_EarliestExpectedDate: '',
        cv_EarliestExpectedDate: '',
        cv_ExpMinAmtAct: '',
        cv_ExpMinAmtCurAct: '',
        cv_ExpMinAmtOrig: '',
        cv_ExpMinAmtCurOrig: '',
        rpt_ExpMinAmtDisp: '',
        rpt_ExpMinAmtCurDisp: '',
        rpt_Source: '',
      }];
    });
  }


  function updateFieldVal(index: number, fieldName: string, fieldVal: any) {

    setStateRows(p => {
      let t = [...p];
      let o = t[index];

      if (GenUtil.eq(fieldName, 'ExpectedMinAmt')) o.cv_ExpMinAmtOrig = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'Currency')) o.cv_ExpMinAmtCurOrig = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'Source')) o.rpt_Source = GenUtil.safeTrim(fieldVal);
      else if (GenUtil.eq(fieldName, 'EarliestExpectedDate')) {
        o.cv_EarliestExpectedDate = GenUtil.safeTrim(fieldVal).replace(/"/ig, '');
        o.rpt_EarliestExpectedDate = fieldVal ? GenUtil.getCalDate(fieldVal) : '';
      }

      // convert currency to EUR
      let origAmt = GenUtil.safeToNumber(o.cv_ExpMinAmtOrig);
      let origCurr = GenUtil.safeTrim(o.cv_ExpMinAmtCurOrig);

      let newAmt = AppHelper.convertCurrency(origAmt, origCurr, props.currencys);
      let newCurr = StaticData.costsCurrency;

      let dispAmt = GenUtil.eq(origCurr, StaticData.costsCurrency) ? GenUtil.numberToCurrency(newAmt) : `${GenUtil.numberToCurrency(newAmt)} (${GenUtil.numberToCurrency(origAmt)})`;
      let dispCurr = GenUtil.eq(origCurr, StaticData.costsCurrency) ? StaticData.costsCurrency : `${StaticData.costsCurrency} (${origCurr})`;

      o.cv_ExpMinAmtAct = GenUtil.safeTrim(newAmt);
      o.cv_ExpMinAmtCurAct = GenUtil.safeTrim(newCurr);
      o.cv_ExpMinAmtOrig = GenUtil.safeTrim(origAmt);
      o.cv_ExpMinAmtCurOrig = GenUtil.safeTrim(origCurr);
      o.rpt_ExpMinAmtDisp = GenUtil.safeTrim(dispAmt);
      o.rpt_ExpMinAmtCurDisp = GenUtil.safeTrim(dispCurr);

      return t;
    });
  }


  function handleDeleteItem(index: number) {
    // remove the item from the grid
    setStateRows(p => {
      let t = [...p];
      t.splice(index, 1);
      return t;
    });
  }


  const memoSumExpMinAmts = useMemo<number>(() => {
    let tot: number = 0;
    stateRows.forEach(o => tot += GenUtil.safeToNumber(o.cv_ExpMinAmtAct));
    return tot;
  }, [stateRows]);

  useEffect(() => {
    props.onTotalUpdated('ProposalBreakdown', memoSumExpMinAmts);
  }, [memoSumExpMinAmts]);


  return (
    <>

      <Stack tokens={Consts.stackTokens}>

        <table className='sub-table3'>
          <thead>

            <tr>
              <th>{`Earliest Expected Date`}</th>
              <th>{`Expected Min Amount`}</th>
              <th>{`Currency`}</th>
              <th>{`Source`}</th>
              <th></th>
            </tr>

          </thead>
          <tbody>

            {
              stateRows.map((o, i) =>
                <ProposalBreakdownRow
                  key={o.cv_RowNum}
                  data={o}
                  counter={i}
                  totCount={stateRows.length}
                  handleDeleteItem={handleDeleteItem}
                  updateFieldVal={updateFieldVal}
                  stateFormSubmitted={props.stateFormSubmitted}
                  isReadOnly={props.isReadOnly}
                  saving={props.saving} />)
            }

            {
              stateRows.length > 0 && (
                <tr className='sep'>
                  <td style={{ textAlign: 'right' }} >
                    <Label>{`Total (€):`}</Label>
                  </td>
                  <td>
                    <Label className='ms-fontWeight-regular'>{GenUtil.numberToCurrency(memoSumExpMinAmts)}</Label>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>

        {
          !props.isReadOnly && (
            <>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6">
                    <ActionButton disabled={props.saving} iconProps={{ iconName: 'Add' }} allowDisabledFocus title="Add" ariaLabel="Add" onClick={onClickAddRow}>Add a new row</ActionButton>
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          Consts.admOvrShowDebugInfo() && (
            <ul className='debug-ul'>
              <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
              <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
            </ul>
          )
        }

      </Stack>

    </>
  );
};