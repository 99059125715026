/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

export interface IAuthTest1Props { }

export const AuthTest1: React.FunctionComponent<IAuthTest1Props> = (props: React.PropsWithChildren<IAuthTest1Props>) => {
  const [value, setValue] = React.useState('');

  return (
    <>
    </>
  );
};


// import * as React from 'react';
// import { useState, useEffect, useRef } from 'react';
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
// import * as GraphHelper from "../../Helpers/GraphHelper";
// import * as Consts from "../../Helpers/Consts";
// import { msalConfig, msalScopes } from '../../Helpers/AuthConfig';


// // const loginRequest = {
// //   scopes: ["User.Read", "Sites.Read.All"]
// // };


// /* ###########################################
// single signon
// silently sign the user in
// using redirect, not popup
// when user is signed in, it loads the next component to get started "<SignedIn>"
// ########################################### */
// export interface IAuthTest1Props { }

// export const AuthTest1: React.FunctionComponent<IAuthTest1Props> = (props: React.PropsWithChildren<IAuthTest1Props>) => {


//   const { instance, accounts } = useMsal();
//   const isAuthenticated = useIsAuthenticated();


//   useEffect(() => {
//     let ignore = false;

//     setTimeout(async () => {

//       try {
//         let tokenResponse = await instance.handleRedirectPromise();

//         let accountObj;
//         if (tokenResponse) {
//           accountObj = tokenResponse.account;
//         } else {
//           accountObj = instance.getAllAccounts()[0];
//         }

//         if (accountObj && tokenResponse) {
//           console.log("[AuthService.init] Got valid accountObj and tokenResponse");
//         } else if (accountObj) {
//           console.log("[AuthService.init] User has logged in, but no tokens.");
//           try {
//             tokenResponse = await instance.acquireTokenSilent({
//               account: instance.getAllAccounts()[0],
//               ...msalScopes
//             });
//           } catch (err) {
//             await instance.acquireTokenRedirect({ ...msalScopes });
//           }
//         } else {
//           console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
//           await instance.loginRedirect({ ...msalScopes });
//         }
//       } catch (error) {
//         console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
//       }

//     }, Consts.sleepMsSignIn);

//     return () => { ignore = true; }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);


//   return (
//     <>

//       <h1>Helloworld</h1>

//       <div style={{ display: isAuthenticated ? 'block' : 'none' }}>

//         <AuthenticatedTemplate>
//           <SignedIn></SignedIn>
//         </AuthenticatedTemplate>

//         <UnauthenticatedTemplate>
//           <h3>You are not signed in!</h3>
//         </UnauthenticatedTemplate>

//       </div>

//       <div style={{ color: 'red' }}>
//         isAuthenticated={isAuthenticated + ''}
//       </div>

//     </>
//   );
// };




// /* ###########################################
// after user is signed in, we can start making Graph requests

// ########################################### */
// export interface ISignedInProps { }

// export const SignedIn: React.FunctionComponent<ISignedInProps> = (props: React.PropsWithChildren<ISignedInProps>) => {

//   const { instance, accounts } = useMsal();
//   const isAuthenticated = useIsAuthenticated();

//   const [dump, setDump] = useState('');

//   const username = accounts[0].username;

//   useEffect(() => {
//     console.log("Component [SignedIn] Mounted");
//     console.log("instance", instance);
//     console.log("accounts", accounts);
//     console.log("isAuthenticated", isAuthenticated);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);


//   async function fnTest1() {

//     const request = {
//       ...msalScopes,
//       account: accounts[0]
//     };

//     let response = await instance.acquireTokenSilent(request);

//     let url = 'https://graph.microsoft.com/v1.0/sites/{dbb8603e-134f-4caf-8359-f3db7769e7a7}/lists/{f69afd8a-1504-4760-b57c-1a6b3699227e}/items?$expand=fields';

//     let [resp, data] = await GraphHelper.getAsync(url, response.accessToken);

//     GraphHelper.getAsync(url, response.accessToken).then(resp => {

//       console.log(data.value);
//       console.log(data.value[0].webUrl);
//       console.log(data.value[0].fields.Title);

//       setDump(JSON.stringify(data.value, null, 2));

//       //setDump(`webUrl: ${resp.value[0].webUrl};\r\ntitle: ${resp.value[0].fields.Title}`);
//     });
//   }


//   return (
//     <>

//       <h3>Hello: {username}</h3>

//       <div style={{ margin: 10 }}>
//         <input type='button' value='Get List Items' onClick={fnTest1}></input>
//       </div>

//       <div style={{ margin: 10, color: 'red' }}>
//         <textarea rows={25} style={{ width: 800, whiteSpace: 'nowrap' }} value={dump} onChange={(e) => setDump(e.target.value)}></textarea>
//       </div>

//     </>
//   );
// };
