/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles, Label,
  TextField,
  DefaultButton, PrimaryButton, IconButton, ActionButton,
  Spinner, SpinnerSize,
  Shimmer, ShimmerElementType, IShimmerElement,
  MessageBar, MessageBarType, MessageBarButton,
  Icon,
  Separator,
  DatePicker, DayOfWeek, defaultDatePickerStrings,
  ComboBox, IComboBoxOption, IComboBoxStyles,
  TagPicker, IBasePicker, ITag, IInputProps, IBasePickerSuggestionsProps,
  Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles
} from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import * as StaticData from "../../../StaticData/Copernicus/StaticData";
import { ConnectionDetailsItem } from '../../../Models/Copernicus/GridModels/ConnectionDetails1';


export interface IANConnectionDetailProps {
  connections: ConnectionDetailsItem[];
  onChangeConn: (id: string, status: string) => void;
  onDeleteConn: (id: string) => void;
  stateFormSubmitted: boolean;
}

export const ANConnectionDetail: React.FunctionComponent<IANConnectionDetailProps> = (props: React.PropsWithChildren<IANConnectionDetailProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANConnectionDetail]");
  }, []);


  const [stateConnCache, setStateConnCache] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    setStateConnCache([...props.connections]);
  }, [props.connections]);


  const onChangeStatus = (connId: string, status: string) => {

    setStateConnCache(p => {
      let t = [...p];
      let idx = t.findIndex(x => x.rpt_ConnecID === connId);
      if (idx >= 0)
        t[idx].rpt_ConnectionStatus = status;
      return t;
    });

    props.onChangeConn(connId, status);
  };


  const onDeleteItem = (connId: string) => {
    props.onDeleteConn(connId);
  };


  const memoStatusOptions = useMemo(() => {
    let col = StaticData.connectionStatusOptions.split(',').map(o => { return { key: o, text: o }; });
    return col;
  }, []);


  if (stateConnCache.length <= 0) {
    return null;
  }
  else {
    return (
      <>
        <table className='sub-table wbsss'>
          <thead>
            <tr>
              <th>{`CES Connection ID`}</th>
              <th>{`Servicer Connection ID`}</th>
              <th>{`Connection Name`}</th>
              <th>{`Connection Status`}</th>
            </tr>
          </thead>
          <tbody>

            {
              stateConnCache.map((conn, i) => {
                return (
                  <tr key={i}>
                    <td>{conn.rpt_CESConnID}</td>
                    <td>{conn.rpt_ConnecID}</td>
                    <td>{conn.rpt_ConnectionName}</td>
                    <td>
                      <ComboBox
                        className='w225'
                        style={{ maxWidth: 400 }}
                        selectedKey={conn.rpt_ConnectionStatus || undefined}
                        placeholder="Please select a value"
                        options={memoStatusOptions}
                        // autoComplete={'on'}
                        onChange={(event: any, option?: IComboBoxOption, index?: number, value?: string) => onChangeStatus(conn.rpt_ConnecID, option ? option.key + '' : '')}
                        errorMessage={props.stateFormSubmitted && GenUtil.isNull(conn.rpt_ConnectionStatus) ? 'Status is required.' : ''}
                      />
                    </td>
                    <td style={{ maxWidth: 25, textAlign: 'right' }}>
                      <IconButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus title="Delete" ariaLabel="Delete" onClick={() => onDeleteItem(conn.rpt_ConnecID)}></IconButton>
                    </td>
                  </tr>
                );
              })
            }

          </tbody>
        </table>
      </>
    );
  }
};